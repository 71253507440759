import React from 'react';
import { Grid } from "@mui/material";
import DashboardCard from "../Components/Dashboard/DashboardCard";
import IconTextList from "../Components/Dashboard/IconTextList";
import { FeaturedPlayListSharp } from "@material-ui/icons";
import { admin_menu_items } from "./admin_menu_items";
import HomeContainer from "../Studio/HomeContainer";
// import StatusList from "../Components/StatusList/StatusList";

export const AdminHomeContainer = (props) => {
    return (
        <HomeContainer
            menu_items={admin_menu_items}
            title={'Admin'}
            // rootPath={'/'}
            // rootTitle={'Home'}
            initialBreadcrumbs={[
                { href: '/', title: 'Home' },
                // { href: '/admin/', title: 'Admin' },
                // { href: '/admin/create-account/', title: 'Create Account' },
            ]}
        >
            <Grid item xs={12}>
                <DashboardCard title="Manage Accounts">
                    {/*<CardMenu menu_items={admin_menu_items} className={'m-4'} />*/}
                    <IconTextList items={[...admin_menu_items]} />
                </DashboardCard>
            </Grid>
        </HomeContainer>

    );
};


export default AdminHomeContainer;
