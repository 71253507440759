import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Button,
    Grid,
    Stack,
    FormControl,
    InputLabel,
    Container,
    Box,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    OutlinedInput,
    Radio,
    Checkbox,
    Alert,
    CardActions, FormGroup, Typography
} from "@mui/material";
import apiClient from '../../context/APIClient';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import Divider from "@material-ui/core/Divider";

const AccountCreation = ({ accountTemplate }) => {
    const navigate = useNavigate();
    const { register, handleSubmit, trigger, setError, formState: { errors }, control } = useForm();

    const createAccount = (data) => {
        const payload = data;
        apiClient.post('/api/create_account/', payload)
            .then((response) => {
                console.log(response);
                navigate(`/`)
            })
            .catch((error) => {
                console.log(error);
                // if the code is 400
                if (error.response.status === 400) {
                    // iterate over error.response.data and set errors
                    for (const key in error.response.data) {
                        setError(key, { type: 'custom', message: error.response.data[key][0] });
                    }

                } else {
                    setError('serverError', { type: 'custom', message: error.message });
                }
            });
    }

    const showError = (error, text = null) => {
        if (error) {
            let message = text;
            if (!message) {
                if (error.type === 'required') message = 'This field is required';
                else message = error.message;
            }

            return (
                <div className='error-container text-red-500'>
                    <p className='error-tag'>{`* ${message}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <Container maxWidth="md" className='mt-0' spacing={2}>
            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <Typography variant="h4" marginTop={0} marginBottom={0}>
                        Account Details
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Card className='px-5'>
                        <CardHeader
                            title='Account Details'
                            // subheader={'Account Details'}
                        />
                        <Divider />
                        <CardContent>
                            <form onSubmit={handleSubmit(createAccount)}>
                                <Stack spacing={2}>
                                    {errors?.serverError && <Alert severity="error">{errors.serverError?.message}</Alert>}

                                    <FormLabel id="demo-row-radio-buttons-group-label">Information</FormLabel>
                                    <FormControl fullWidth>
                                        <InputLabel>Name</InputLabel>
                                        <OutlinedInput
                                            id='name'
                                            label='Name'
                                            {...register("name", { required: true })}
                                        />
                                        {showError(errors.name)}

                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel>Email</InputLabel>
                                        <OutlinedInput
                                            id='email'
                                            label='Email'
                                            {...register("email", { required: false })}
                                        />
                                        {showError(errors.email)}
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel>UserName</InputLabel>
                                        <OutlinedInput
                                            id='username'
                                            label='UserName'
                                            {...register("username", { required: false })}
                                        />
                                        {showError(errors.username)}
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel>Password</InputLabel>
                                        <OutlinedInput
                                            id='password'
                                            label='Password'
                                            name='password'
                                            {...register("password")}
                                        />
                                    </FormControl>

                                    <Divider />

                                    <FormControl fullWidth>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Account Type</FormLabel>
                                        <Controller
                                            name={"type"}
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <RadioGroup
                                                    row
                                                    {...field}
                                                >
                                                    <FormControlLabel value="parent" control={<Radio />} label="Parent" />
                                                    <FormControlLabel value="teacher" control={<Radio />} label="Teacher" />
                                                </RadioGroup>
                                            )}
                                        />
                                        {showError(errors.type)}
                                    </FormControl>

                                    <Divider />

                                    <FormControl fullWidth>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Account Options</FormLabel>
                                        <FormControlLabel control={<Checkbox />} label="Create Registration Link" {...register("registration_link", { required: false })} />
                                        <FormControlLabel control={<Checkbox />} label="Email Registration Link" {...register("email_registration_link", { required: false })} />
                                        <FormControlLabel control={<Checkbox />} label="Require Email Verification" {...register("require_email_verification", { required: false })} />
                                        <FormControlLabel control={<Checkbox />} label="Attach Demo Data" {...register("require_email_verification", { required: false })} />
                                    </FormControl>

                                    <Divider />

                                    <Divider />
                                    <div className='my-5 flex justify-end'>
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            size='small'
                                            type="submit"
                                            onClick={() => trigger()}
                                        >Create Account</Button>
                                    </div>
                                </Stack>
                            </form>
                        </CardContent>
                    </Card>

                </Grid>
            </Grid>
        </Container>
    );
};

export default AccountCreation;