import React, { useCallback, useEffect, useRef, useState } from 'react';
// import {
//     Player
//     // PlaybackConfig,
//     // PlayerAPI,
//     // SourceConfig,
//     // StyleConfig
// } from 'bitmovin-player';
import { Player, PlayerEvent, ViewMode } from 'bitmovin-player';
import { PlaybackToggleOverlay, UIContainer, UIFactory, UIManager } from 'bitmovin-player/bitmovinplayer-ui';
import 'bitmovin-player/bitmovinplayer-ui.css';
import VideoPanel from "../../Studio/Videos/panels/VideoPanel";
// import { time_ranges_to_array } from '../../util';
// import { createVideoPoster } from '../../../../app/util';

require('./VideoPlayer.scss');

// const BITMOVIN_KEY = '7ae3ff0f-3493-4bd4-b2e7-d622d225bfbb'; // old bitdash key
// const BITMOVIN_ANALYTICS_KEY = null;
const BITMOVIN_KEY = '99837356-f248-4b19-b299-d032177cb5c1'; // bitmovin key
const BITMOVIN_ANALYTICS_KEY = '83C8D8FD-80B9-4FA4-8039-13B2E86DFB66'; // bitmovin analytics key

const WatchingEvents = [
    PlayerEvent.Error,
    PlayerEvent.Warning,
    PlayerEvent.Destroy,
    PlayerEvent.Metadata,
    PlayerEvent.Muted,
    PlayerEvent.Paused,
    PlayerEvent.Play,
    PlayerEvent.PlaybackFinished,
    PlayerEvent.PlaybackSpeedChanged,
    PlayerEvent.PlayerResized,
    PlayerEvent.Playing,
    PlayerEvent.Ready,
    PlayerEvent.Seek,
    PlayerEvent.Seeked,
    PlayerEvent.Unmuted,
    PlayerEvent.ViewModeChanged,
    PlayerEvent.VolumeChanged
];

// this takes a video thumbnail and attempts to replace the suffix with a different size
export const createVideoPoster = (url) => {
    // typical url: https://muzology-public-media.s3.amazonaws.com/presentations/add_and_subtract_integers_4712981a3b8a2f930e290ed5e08923eb3d79-480w.jpg
    // replace '-480w.jpg' with 1440 pixels wide
    if (url.endsWith('-480w.jpg')) {
        return url.replace('-480w.jpg', '-1440w.jpg');
    }
    return url;
};


const time_ranges_to_array = (time_ranges) => {
    let ranges = [];
    for (let i = 0; i < time_ranges.length; i++) {
        let range = {};
        range.start = Math.round(time_ranges.start(i));
        range.end = Math.round(time_ranges.end(i));
        ranges.push(range);
    }
    return ranges;
};

// const BitmovinPlayer = React.forwardRef((props, videoNode) => {
const BitmovinPlayer = React.forwardRef((props, elementRef) => {
    // const { playerRef } = props;
    // const bitmovinKey = props.bitmovinKey ?? BITMOVIN_KEY;
    // const BITMOVIN_KEY = useConfig('BITMOVIN_KEY');
    // useEffect(() => {
    //     if (player) {
    //         console.log('[BitmovinPlayer] Player created', player);
    //         // attach events
    //
    //         return () => {
    //             // detach events
    //             console.log('[BitmovinPlayer] Player destroyed', player);
    //         };
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [player]);

    // store the player in a reference
    const playerRef = useRef();

    // access the player
    const getPlayer = () => playerRef.current;

    // get the ranges played - access the HTML5 video element
    const getPlayed = () => time_ranges_to_array(getPlayer()?.getVideoElement()?.played);

    // // save the player and connect the event handler
    // const attachPlayer = useCallback((bitmovinPlayer) => {
    //
    //     // save the player in the reference
    //     playerRef.current = bitmovinPlayer;
    //
    //     // attach to the caller reference if provided
    //     if (props.playerRef)
    //         props.playerRef.current = bitmovinPlayer;
    // }, [props.playerRef]);

    // detach the current player
    const detachPlayer = useCallback(() => {
        // clear the references
        playerRef.current = null;
        if (props.playerRef)
            props.playerRef.current = null;
    }, [props.playerRef]);

    const [playButtonUIManager, setPlayButtonUIManager] = useState(null);

    // create the player, load the source from props, save the player
    const createPlayer = useCallback((element) => {
        console.log('[BitmovinPlayer] createPlayer() for video:', props.video);

        // check for a video
        if (!props.video?.id) {
            // TODO: exception?
            console.error('[BitmovinPlayer] Aborting player setup, video not set');
            return null;
        }

        // special event handlers
        const onPlaybackFinished = (event) => {
            // get the ranges played - access the HTML5 video element
            const played = time_ranges_to_array(playerRef.current?.getVideoElement()?.played);
            console.log('on playback finished', { event, played });

            // return the event with the played ranges attached
            return { ...event, played };
        };

        // handle special events
        const playerEvents = {
            [PlayerEvent.PlaybackFinished]: onPlaybackFinished
        };

        // handle all events
        const onPlayerEvent = (event) => {
            // console.log('[BitmovinPlayer] Event: ', event.type, event, playerRef.current);
            console.log('[BitmovinPlayer] on event ', event.type, { event, player: playerRef.current });

            // switch (type) {
            //     case PlayerEvent.PlaybackFinished:
            //         onPlaybackFinished(event);
            //         break;
            // }

            // handle special cases
            const handler = playerEvents[event.type];
            if (handler)
                event = handler(event);

            // pass on all player events to the parent, along with the player
            if (props.onPlayerEvent)
                props.onPlayerEvent(event, playerRef.current);
        };

        // create the bitmovin config
        let config = {
            key: BITMOVIN_KEY,
            playback: {
                // muted: true,
                autoplay: false
            },

            // create a map of event names connected to the event handler
            events: WatchingEvents.reduce((all, name) => ({ ...all, [name]: onPlayerEvent }), {}),

            logs: {
                bitmovin: true
                // level: bitmovin.player.LOGLEVEL.DEBUG
                // level: window.bitmovin.player.LOGLEVEL.WARNING
            },
            skin: {
                // disable bitmovin logo
                screenLogoImage: null // /static/images/muzology_logo.png"
            },

            // disable the context menu
            tweaks: {
                context_menu_entries: []
            }
        };

        // add analytics
        if (BITMOVIN_ANALYTICS_KEY) {
            const analytics = {
                key: BITMOVIN_ANALYTICS_KEY,
                videoId: props.video?.id,
                title: props.video?.title,
                userId: props.user?.id,
                cdnProvider: 'AWS CloudFront',
                customData1: 'pre-algebra', // video series
                customData2: 'student',   // user role
                customData3: 'account name',   // account name
                customData4: 'account type',   // account type
                customData5: 'account style'   // account style
            };
            config = { ...config, analytics };
        }

        // create the player and ui
        let bitmovinPlayer = null;
        try {
            console.log('[BitmovinPlayer] create bitmovin player:', config);
            bitmovinPlayer = new Player(element, config);

            // build a simple UI with just a playback button
            const playButtonUI = new UIContainer({
                components: [
                    new PlaybackToggleOverlay()
                ]
            });

            const myUiManager = new UIManager(bitmovinPlayer, playButtonUI);
            setPlayButtonUIManager(myUiManager);

            const onSwitchUI = (event) => {
                // remove the ui manager
                console.log('[SwitchUI] on playing');
                // remove the play button
                if (myUiManager) {
                    console.log('[SwitchUI] removing play button');
                    myUiManager.release();
                    setPlayButtonUIManager(null);
                    UIFactory.buildDefaultUI(bitmovinPlayer);

                    // remove this event handler
                    bitmovinPlayer.off(PlayerEvent.Playing, onSwitchUI);
                }
            };

            // exit fullscreen on playback end
            const onPlaybackFinished = (event) => {
                if (bitmovinPlayer.getViewMode() === ViewMode.Fullscreen)
                    bitmovinPlayer.setViewMode(ViewMode.Inline);
            };

            bitmovinPlayer.on(PlayerEvent.Playing, onSwitchUI);
            bitmovinPlayer.on(PlayerEvent.PlaybackFinished, onPlaybackFinished);

            // save the player in the reference
            playerRef.current = bitmovinPlayer;

            // attach to the caller reference if provided
            if (props.playerRef)
                props.playerRef.current = bitmovinPlayer;

            // done
            console.log('[BitmovinPlayer] Player created:', bitmovinPlayer);

        } catch (error) {
            console.log('[BitmovinPlayer] Error creating Bitmovin player:', error);
            throw error;
        }

        // TODO: setup mux


        // load the video sources
        const sourceConfig = createSourceConfig(props.video);
        console.log('[BitmovinPlayer] Loading source config:', props.video, sourceConfig);
        return bitmovinPlayer.load(sourceConfig)
            .then(() => {
                console.log('[BitmovinPlayer] Successfully created Bitmovin Player instance', bitmovinPlayer);
            })
            .catch((err) => {
                // TODO: notify parent that something went wrong
                console.error('[BitmovinPlayer] Error creating player:', err);
                console.dir(err);
                // setError(err);
            });
    }, [props]);
    const videoRef = useRef();
    // load video player on mount
    useEffect(() => {
        // create the video player
        console.log('[BitmovinPlayer] Creating player on node:', videoRef?.current);
        const player = createPlayer(videoRef?.current);
        console.log('[BitmovinPlayer] Player created:', player);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // let style = {
    //     width: '100%',
    //     height: '100%',
    //     // zIndex: 10,
    //     backgroundColor: 'black',
    //     ...this.props.style
    // };
    //
    return (
        <div id='player' ref={videoRef} />
    );
});

export const createSourceConfig = (video) => {
    const config = {};
    if( video.url_dash ) {
        config.dash = video.url_dash;
    }
    if( video.url_hls ) {
        config.hls = video.url_hls;
    }
    if( video.url_mp4 ) {
        config.progressive = video.url_mp4;
    }
    if( video.url_thumbnail ) {
        config.poster = createVideoPoster(video.url_thumbnail);
    }
    return config;
    // create a source config
    // return {
    //     // title: video.title,
    //     // description: video.description,
    //
    //     dash: video.url_dash,
    //     hls: video.url_hls,
    //     progressive: video.url_mp4,
    //     // smooth: 'https://test.playready.microsoft.com/smoothstreaming/SSWSS720H264/SuperSpeedway_720.ism/manifest',
    //
    //     poster: createVideoPoster(video.url_thumbnail)
    // };
};

// var sourceConfig = {
//     "title": "Default Demo Source Config",
//     "description": "Select another example in \"Step 4 - Load a Source\" to test it here",
//     "dash": "https://bitmovin-a.akamaihd.net/content/MI201109210084_1/mpds/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.mpd",
//     "hls": "https://bitmovin-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",
//     "smooth": "https://test.playready.microsoft.com/smoothstreaming/SSWSS720H264/SuperSpeedway_720.ism/manifest",
//     "progressive": "https://bitmovin-a.akamaihd.net/content/MI201109210084_1/MI201109210084_mpeg-4_hd_high_1080p25_10mbits.mp4",
//     "poster": "https://bitmovin-a.akamaihd.net/content/MI201109210084_1/poster.jpg"
// }
export default BitmovinPlayer;
