import React, { useMemo, useState } from 'react';
import { useVideos } from "../Videos/VideosContainer";
import { useNavigate } from "react-router-dom";
import { Button, Fab, IconButton } from "@mui/material";
import AddIcon from "@material-ui/icons/Add";
import { PageContainer } from "../../Home/PageContainer";
import { DataGrid } from "@mui/x-data-grid";
import { useItemEditor } from "./StudioContainer";
import AddCourseDialog from './Dialogs/AddCourseDialog';
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import ConfirmDialog from '../Quizzes/QuizEditor/ConfirmDialog';
import { useStudio } from "../Context/StudioContext";
import apiClient from '../../context/APIClient';

const ItemList = (props) => {

    const [openNewCourse, setOpenNewCourse] = useState(false)
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [courseId, setCourseId] = useState(null)
    const navigate = useNavigate();
    const { itemTypeDef, items, columns, itemType } = useItemEditor();
    const { mutateCourses } = useStudio();
    const columnsWithDeleteAttr = [].concat({ field: 'delete' }, columns)


    const handleDelete = () => {
        // delete the course and change the url
        apiClient.delete(`/api/admin/courses/${courseId}`)
            .then(response => {
                setCourseId(null);
                mutateCourses()
                    .then(() => {
                        navigate('/studio/groupings');
                    })
            })
            .catch(error => {
                console.log(error);
                alert("There was an error deleting the course.")
            })
    }

    const columnDefs = useMemo(() => {
        return columnsWithDeleteAttr.map((column) => {
            console.log('column', column)
            if (column.field === 'delete') {
                return {
                    field: 'delete',
                    headerName: '',
                    width: 6,
                    cellClassName: 'expand-icon font-bold ',

                    renderCell: (params) => {

                        const disableClick = (e) => {
                            e.stopPropagation();

                        }
                        const onClick = (e) => {
                            e.stopPropagation();
                            setCourseId(params.id);
                            setOpenConfirmDelete(true);
                        };
                        return (
                            <div className='w-full h-full flex items-center justify-center' onClick={disableClick}>
                                <IconButton onClick={onClick}>
                                    <DeleteSharpIcon />
                                </IconButton>
                            </div>

                        );
                    }
                }
            }
            if (column.name === 'course_type') {
                return {
                    field: 'course_type',
                    headerName: 'Type',
                    width: 100,
                    cellClassName: 'course_type font-bold ',

                    renderCell: (params) => {
                        console.log("🚀 ~ file: ItemList.js:85 ~ returncolumnsWithDeleteAttr.map ~ params:", params)
                        const parent = params.row.course_type?.parent ? 'parent' : '';
                        const teacher = params.row.course_type?.teacher ? 'teacher' : '';
                        return (
                            <div className='w-full h-full flex items-center justify-start'>
                                {parent}  {teacher}
                            </div>

                        );
                    }
                }

            }
            return {
                field: column.name,
                headerName: column.label,
                headerAlign: 'center',
                cellClassName: 'font-bold cursor-pointer select-none text-center',
                align: column.align ?? 'left',
                flex: column.flex ?? false,
                sortable: true,
            };
        });
    }, [columns]);

    const rows = useMemo(() => {
        return items?.map((item, index) => {
            return {
                ...item,
                // id: video.id,
                // title: video.title,
                // slug: video.slug,
            };
        });
    }, [items])

    const onRowClick = (params, event, details) => {
        console.log('row clicked', params, event, details);
        navigate(`/studio/${itemType}/${params.id}`);
    }

    const addCourse = () => {
        setOpenNewCourse(true)
    };

    // const addButton2 = (
    //     <Button key={'add-video'} onClick={addVideo}>
    //         <AddIcon />
    //         Add Video
    //     </Button>
    // );

    const addButton = (
        <Fab color="primary" aria-label="add" onClick={addCourse}>
            <AddIcon />
        </Fab>
    )
    console.log(items);
    console.log(columnDefs);
    console.log(rows);
    return (
        <PageContainer
            title={itemTypeDef?.namePlural ?? 'Items'}
            actions={[addButton]}

            paths={[
                { title: 'Home', href: '/', },
                { title: 'Studio', href: '/studio/' },
            ]}

        >
            <div className={'p-4 w-full h-full'}>

                {rows && <DataGrid
                    tw={'bg-white'}
                    rows={rows}
                    columns={columnDefs}
                    autoHeight={true}
                    onRowClick={onRowClick}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'title', sort: 'asc' }],
                        },
                    }}
                />}
            </div>
            <AddCourseDialog open={openNewCourse} onClose={() => setOpenNewCourse(false)} />
            <ConfirmDialog
                open={openConfirmDelete}
                setOpen={setOpenConfirmDelete}
                onConfirm={handleDelete}
                title={'Delete Course'}
                message={'Are you sure you want to delete this course?'}
            />
        </PageContainer>
    );
};


export default ItemList;
