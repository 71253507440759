import React from 'react';
import useSWR from "swr";
import { useDevTool } from "../../../context/DevTool";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Grid, Stack, Typography } from "@mui/material";
import Divider from "@material-ui/core/Divider";
import SourceVideoDetails from "../SourceVideos/SourceVideoDetails";
import VideoUploader from "../SourceVideos/VideoUploader";
import DropboxChooser from "../DropboxChooser";
import apiClient from "../../../context/APIClient";
import SourceVideo from "./SourceVideo";

const SourceVideos = ({ video, uploadId, updateVideo, onSelectUpload }) => {
    // get all the source videos for this video
    const { data, loading, mutate } = useSWR(`/api/video/${video?.id}/sources/`);

    const sourceVideos = data?.data;
    useDevTool('SourceVideos', sourceVideos);

    // const rows = data?.data?.map(upload => ({
    //         revision: upload.revision,
    //         id: upload.id,
    //         created: upload.created,
    //         file_type: upload.file_type,
    //         file_name: upload.file_name,
    //         file_size: upload.file_size,
    //         status: upload.status,
    //         upload_success: upload.upload_success,
    //     })
    // );
    //
    const rows = data?.data;

    const columns = [
        {
            field: 'revision',
            headerName: 'Revision',
            className: 'text-center',
            align: 'center',
            headerAlign: 'center',
            renderCell: ({ row }) => {
                return String(row.revision)
            }
        },
        { field: 'file_name', headerName: 'Name', flex: 1 },
        {
            field: 'created',
            headerName: 'Date',
            width: 160,
            renderCell: ({ row }) => {
                if (row.created) {
                    return new Date(row.created).toLocaleString();
                }
            }
        },
        // { field: 'id', headerName: 'ID', flex: 1 },
        {
            field: 'video_duration',
            headerName: 'Duration',
            width: 130,
            renderCell: ({ row }) => {
                if (row.video_duration) {
                    const duration = new Date(row.video_duration * 1000).toISOString().substr(11, 8);
                    return duration;
                }
            }
        },
        // { field: 'file_type', headerName: 'Type', width: 130 },
        {
            field: 'file_size',
            headerName: 'Size',
            width: 130,
            renderCell: ({ row }) => {
                if (row.file_size) {
                    // add , separator
                    return row.file_size.toLocaleString();
                    // return (row.file_size / 1000000).toFixed(2) + ' MB';
                }
            }
        },
        { field: 'status', headerName: 'Status', width: 130 },
        // { field: 'upload_success', headerName: 'Uploaded', width: 130 },
    ];

    const onRowClick = (params, event) => {
        console.log('onRowClick', params, event);
        onSelectUpload(params.row);
    };

    const handleUploadFromDropbox = (files) => {
        console.log('handleUploadFromDropbox', files);
        // const file = files[0];
        // updateVideo({ video_url: file.link });
        const payload = {
            files,
            upload_id: uploadId,
            video_id: video?.id
        }
        return apiClient.post('/api/video_upload_dropbox/', payload)
            .then(res => {
                console.log('response', res.data);
                console.log("mutating list");
                mutate(({ data }) => {
                    console.log('video files', data);
                    return (
                        [...data, {
                            id: res.data.id,
                            name: 'new',
                            status: 'pending',
                        }]
                    )
                });
            })
            .catch(error => {
                console.error("Error uploading from dropbox:", error);
            });
    }

    if (uploadId) {
        // selected file
        return (
            <SourceVideo video={video} uploadId={uploadId} sourceVideos={sourceVideos} onSelectUpload={onSelectUpload} />
        );
    }
    return (
        <Stack spacing={2}>

            <Typography variant={'h5'}>
                Video Uploads
            </Typography>

            <Divider />

            <DataGrid
                // tw={'min-h-[300px]'}
                rows={rows ?? []}
                columns={columns}
                onRowClick={onRowClick}
                autoHeight={true}
                hideFooter={true}
                // initialState={{
                //     sorting: {
                //         sortModel: [{ field: 'title', sort: 'asc' }],
                //     },
                // }}

            />

            <Divider />

            <DropboxChooser
                appKey="w7l7sbxwq4gyquq"
                success={handleUploadFromDropbox}
                multiselect={false}
                // extensions={['.mp4', '.mov', '.avi', '.wmv', '.flv', '.mpg', '.mpeg', '.m4v', '.webm', '.mkv']}
                // linkType="preview"
                linkText="direct"
            >
                <Button className={'w-full'} variant='outlined' size='small' color='primary'>
                    Select a video from Dropbox
                </Button>

            </DropboxChooser>

            <VideoUploader video={video} />

        </Stack>
    );
};


export default SourceVideos;
