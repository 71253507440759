import { Entity, Modifier, EditorState, AtomicBlockUtils } from 'draft-js';

let count = 0;
const examples = [
    'f(x)=\\frac{ax^2}{y}+bx+c',

    'P(E) = \\binom{n}{k} p^k (1-p)^{ n-k}',

    '\\frac{1}{(\\sqrt{\\phi \\sqrt{5}}-\\phi) e^{\\frac25 \\pi}} =\n' +
    '1+\\frac{e^{-2\\pi}} {1+\\frac{e^{-4\\pi}} {1+\\frac{e^{-6\\pi}}\n' +
    '{1+\\frac{e^{-8\\pi}} {1+\\ldots} } } }',
];


export function insert(editorState, setEditorState) {
    let texContent = null; //'f(x)=\\frac{ax^2}{y}+bx+c';
    if (!texContent) {
        const nextFormula = count % examples.length;
        count += 1;
        texContent = examples[nextFormula];
    }

    const contentState = editorState.getCurrentContent();
    const newContentState = contentState.createEntity('KateX', 'IMMUTABLE', {
        value: texContent,
        inputValue: texContent,
        displayMode: true,
    });
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        newContentState.getLastCreatedEntityKey(),
        ' '
    );

    const finalEditorState = EditorState.forceSelection(newEditorState, editorState.getCurrentContent().getSelectionAfter());



    if( setEditorState )
        setEditorState(finalEditorState);

    console.log("inserted math entity", finalEditorState.toJS());
    return finalEditorState;
}

const MathComponent = ({content}) => {
    console.log('math component', content);
    return (
        <div className={'bg-red-300'}>
            math:
            {content}
        </div>
    );
};

export function mathBlockRenderer(contentBlock, contentState) {
    // console.log('blockRendererFn', contentBlock.toJS());
    // console.log(contentBlock.getType());

    if (contentBlock.getType() !== 'atomic')
        return null;
    const entity = contentState.getEntity(contentBlock.getEntityAt(0));
    const type = entity.getType();
    // console.log(type);
    if (type === 'KateX') {
        console.log('render math block', entity.getData());
        return {
            component: MathComponent,
            editable: true,
            props: { content: entity.getData().value }
        }
    }

    // const entityKey = contentBlock.getEntityAt(0);
    // if (entityKey) {
    //     const entity = contentState.getEntity(entityKey);
    //     const entityType = entity.getType();
    //     if (entityType === 'TEX') {
    //         return {
    //             component: MathComponent,
    //             editable: false,
    //             props: {
    //                 content: entity.getData().content,
    //             },
    //         };
    //     }
    // }
    return null;
}


export function insert2(editorState, setEditorState) {
    const currentContent = editorState.getCurrentContent();
    const selectionState = editorState.getSelection()

    const entity = Entity.create(
        'TEX',
        'IMMUTABLE',
        { content: "<math>\frac 1 2</math>" }
    );

    const entityKey = entity.getLastCreatedEntityKey();

    const contentStateWithLink = Modifier.applyEntity(
        currentContent,
        selectionState,
        entityKey,
    );

    const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithLink,
    });

    setEditorState(newEditorState);

    // const selection = editorState.getSelection();
    //
    // const firstBlank = Modifier.insertText(
    //     currentContent,
    //     selection,
    //     " ",
    //     null,
    //     null
    // );
    //
    // const withEntity = Modifier.insertText(
    //     firstBlank,
    //     selection,
    //     " ",
    //     null,
    //     entity
    // );
    //
    // const withBlank = Modifier.insertText(
    //     withEntity,
    //     selection,
    //     " ",
    //     null,
    //     null,
    // );

    // return EditorState.push(
    //     editorState,
    //     withBlank,
    //     'insert-text'
    // );
}
