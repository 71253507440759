import React, { useCallback } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { Card } from "@mui/material";
import { QUESTION_TYPE_CHOICES, QUESTION_TYPE_MULTIPLE_CHOICE, QUIZ_COLORS, QUIZ_LEVEL_CHOICES, QUIZ_LEVELS } from "./constants";
import RichText from "../../../Components/RichText/RichText";
import apiClient from "../../../context/APIClient";
import { useQuizEditor } from "./QuizEditorProvider";
import { QUESTION_TYPE_MULTIPLE_SELECT } from "./QuestionSettingsDialog";

const QuizAudioTracks = ({ quiz }) => {
    const { updateQuestion, updateChoice } = useQuizEditor();

    const rows = quiz.questions.reduce((acc, question) => {
        if (!question.content_image) {
            acc = acc.concat({
                id: question.id,
                level: question.level,
                question_index: question.question_index,
                choice_index: 0,
                type: 'Question',
                audio_url: question.audio,
                question: question,
                choice: null

                // id: question.audio.id,
                // name: question.audio.name,
                // description: question.audio.description,
            });
            if (question.type === QUESTION_TYPE_MULTIPLE_CHOICE || question.type === QUESTION_TYPE_MULTIPLE_SELECT) {
                question.choices.forEach((choice, index) => {
                    if (!choice.content_image) {
                        acc = acc.concat({
                            id: choice.id,
                            level: question.level,
                            question_index: question.question_index,
                            choice_index: choice.choice_index,
                            type: 'Choice',
                            audio_url: choice.audio,
                            question: question,
                            choice: choice
                            // id: choice.audio.id,
                            // name: choice.audio.name,
                            // description: choice.audio.description,
                        });
                    }
                });
            }
        }
        return acc;
    }, []);

    const regenerateAudio = useCallback((question, choice) => {
        let data = {}
        if (choice)
            data = { choice_id: choice.id }
        else if (question)
            data = { question_id: question.id };
        else
            throw new Error('Must provide a question or choice');

        // setLoadingAudio(true);
        apiClient.post('/api/quiz/audio/', data)
            .then((response) => {
                console.log(response);
                // setLoadingAudio(false);
                // updateQuestion({ id: question.id, audio: response.data.audio_url });
                const items = response.data;
                if (items.length >= 1) {
                    if (choice) {
                        console.log('updating choice', items[0].audio_url);
                        // updateChoice({ id: choice.id, audio: items[0].audio_url }, false);
                    } else {
                        console.log('updating question', items[0].audio_url);
                        updateQuestion({ id: question.id, audio: items[0].audio_url }, false);
                    }
                }
                //     // update the question audio
                //     if (items[0].audio_url) {
                //         updateQuestion({ id: question.id, audio: items[0].audio_url }, false);
                //     }
                //     // now update each choice, skipping the first one
                //     items.slice(1).forEach((item, index) => {
                //         if (item.audio_url) {
                //             console.log('updating choice', index, item.audio_url);
                //             updateChoice({ id: question.choices[index].id, audio: item.audio_url }, false);
                //         }
                //     });
                //
                // }
            })
            .catch((error) => {
                console.log(error);
                // setLoadingAudio(false);
            });
    }, [updateChoice, updateQuestion]);

    const columns = [
        {
            field: 'id',
            headerName: 'Location',
            headerAlign: 'center',
            width: 200,
            cellClassName: 'font-bold cursor-pointer select-none',
            align: 'left',
            renderCell: (params) => {
                const level = params.row.level;
                const title = QUIZ_LEVELS[level];
                const color = QUIZ_COLORS[level];

                // construct a description
                let description = `${title} Question ${params.row.question_index + 1}`;

                // if the choice index exists
                if (params.row.choice_index) {
                    description = `${description} Choice ${params.row.choice_index}`;
                }
                return <div className={`font-bold  ${color}`}>{description}</div>;
            }
        },
        /*
        {
            field: 'level',
            headerName: 'Level',
            headerAlign: 'center',
            // width: 100,
            cellClassName: 'font-bold cursor-pointer select-none',
            align: 'center',
            renderCell: (params) => {
                if (params.value === 0)
                    return <div className={'font-bold text-gray-600'}>Silver</div>;
                if (params.value === 1)
                    return <div className={'font-bold text-yellow-500'}>Gold</div>;
                if (params.value === 2)
                    return <div className={'font-bold text-blue-500'}>Platinum</div>;
                return <div className={'font-bold'}>{params.value}</div>
            }
        },
        {
            field: 'question_index',
            headerName: 'Question #',
            // width: 100,
            align: 'center',
            headerAlign: 'center',
            cellClassName: 'font-normal cursor-pointer select-none',
            renderCell: (params) => {
                return params.value + 1;
                // if (params.value === 0)
                //     return <div className={'font-bold text-gray-600'}>Silver</div>;
                // if (params.value === 1)
                //     return <div className={'font-bold text-yellow-500'}>Gold</div>;
                // if (params.value === 2)
                //     return <div className={'font-bold text-blue-500'}>Platinum</div>;
                // return <div className={'font-bold'}>{params.value}</div>
            }

        },
        {
            field: 'choice_index',
            headerName: 'Choice #',
            // width: 100,
            align: 'center',
            headerAlign: 'center',
            cellClassName: 'font-normal cursor-pointer select-none',
            renderCell: (params) => {
                if (params.value)
                    return params.value;
                return '';
                // if (params.value === 0)
                //     return <div className={'font-bold text-gray-600'}>Silver</div>;
                // if (params.value === 1)
                //     return <div className={'font-bold text-yellow-500'}>Gold</div>;
                // if (params.value === 2)
                //     return <div className={'font-bold text-blue-500'}>Platinum</div>;
                // return <div className={'font-bold'}>{params.value}</div>
            }

        },
        {
            field: 'type',
            headerName: 'Type',
            width: 160,
            cellClassName: 'font-normal cursor-pointer select-none',
            renderCell: (params) => {
                // find the choice title with the given value
                const choice = QUESTION_TYPE_CHOICES.find((choice) => choice[0] === params.value)
                if (choice)
                    return choice[1];
                return params.value;
                // return choice[1];
                // QUESTION_TYPE_CHOICES.map((choice) => {
                //     if (choice[0] === params.value)
                //         return choice[1];
                // }
            }
        },
        */
        // {
        //     field: 'question_text',
        //     headerName: 'Question',
        //     flex: 1,
        //     cellClassName: 'font-normal cursor-pointer select-none',
        // },
        // { field: 'question_index', headerName: 'Question' },
        // { field: 'choice_index', headerName: 'Choice' },
        // { field: 'type', headerName: 'Type', width: 140 },
        {
            field: 'valid',
            headerName: 'Valid',
            // flex: 1
            renderCell: (params) => {
                if (params.value === 0)
                    return <div className={'font-bold text-red-500'}>No</div>;
                return <div className={'font-bold text-green-500'}>Yes</div>;
            }
        },

        {
            field: 'text',
            headerName: 'Text',
            flex: 1,
            renderCell: (params) => {
                console.log(params.row)
                // if this is a choice
                if (params.row.choice) {
                    // return the choice text
                    return <RichText text={params.row.choice.choice_text} />
                    // return params.row.choice.choice_text;
                } else if (params.row.question) {
                    // return the question text
                    return <RichText text={params.row.question.question_text} />
                    // return params.row.question.question_text;
                }
                return params.value;
            }
        },
        {
            field: 'audio_url',
            headerName: 'URL',
            flex: 0,
            renderCell: (params) => {
                if (!params.value)
                    return <div>No Audio</div>;
                // play the audio
                const playAudio = () => {
                    const audio = new Audio(params.value);
                    audio.play();
                }
                return (
                    <div className={'flex flex-row gap-2'}>
                        <div
                            className={'font-bold text-blue-500 cursor-pointer'}
                            onClick={playAudio}
                        >
                            {/*{params.value}*/}
                            Play
                        </div>
                    </div>
                );
            }
        },
        {
            id: 'regenerate',
            headerName: 'Regenerate',
            flex: 0,
            renderCell: (params) => {
                return (
                    <div className={'flex flex-row gap-2'}>
                        <div
                            className={'font-bold text-blue-500 cursor-pointer'}
                            onClick={() => regenerateAudio(params.row.question, params.row.choice)}
                        >
                            Regenerate
                        </div>
                    </div>
                );
            }
        },
    ];

    const onRowClick = (event) => {
        console.log('onRowClick', event);
    }

    console.log('rows', rows);

    return (
        <Card className={'flex-grow h-full'}>

            <DataGrid
                rows={rows}
                columns={columns}
                onRowClick={onRowClick}
                autoHeight={true}
                // hideFooter={true}

                // showBorders={true}
                // columnAutoWidth={true}
                // columnHidingEnabled={true}
                // showColumnLines={true}
                // showRowLines={true}
                // rowAlternationEnabled={true}
                // onRowUpdating={props.onRowUpdating}
                // onRowInserting={props.onRowInserting}
                // onRowRemoving={props.onRowRemoving}
                // onRowPrepared={props.onRowPrepared}
                // onCellPrepared={props.onCellPrepared}
                // onEditingStart={props.onEditingStart}
                // onEditorPreparing={props.onEditorPreparing}
                // onInitNewRow={props.onInitNewRow}
                // onToolbarPreparing={props.onToolbarPreparing}
                // onRowValidating={props.onRowValidating}
                // onRowUpdated={props.onRowUpdated}

            />
        </Card>
    );
};


export default QuizAudioTracks;
