import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, } from "@mui/material";
import apiClient from '../../../context/APIClient';
import { useTopics } from '../TopicsEditorProvider';
import { useParams } from "react-router-dom";

const DeleteVideoDialog = ({ open, onClose, videoId, playlistId }) => {
    const { topics, fetchTopics } = useTopics();
    const { topicId } = useParams();

    // Find the object that contains the secondary array
    const filteredObject = topics?.find(obj => obj.id === topicId);

    // Filter the secondary array by the id
    const resultArray = filteredObject?.playlists?.filter(playlist => playlist.id === playlistId);

    const handleClose = () => {
        onClose();
    }

    const onSubmit = () => {
        let videos = resultArray[0].videos.filter(v => v !== videoId)
        let data = { videos: videos }
        apiClient.patch(`/api/admin/playlist_definitions/${playlistId}/`, data)
            .then((response) => {
                console.log(response);
                fetchTopics();
                handleClose();
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const showError = (error, text = null) => {
        if (error) {
            // console.log(error);
            let message = text;
            if (!message) {
                if (error.type === 'required') message = 'This field is required';
                else message = error.message;
            }

            return (
                <div className='error-container text-red-500'>
                    <p className='error-tag'>{`* ${message}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <Dialog onClose={handleClose} open={open} maxWidth={"sm"} fullWidth={true}>

            <DialogTitle>This will remove the video from the playlist</DialogTitle>
            <DialogActions>
                <Button type="button" color="primary" variant="outlined" size="small" onClick={onClose}>
                    Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" size="small" onClick={() => onSubmit()}>
                    Delete
                </Button>
            </DialogActions >

        </Dialog>
    );
};

export default DeleteVideoDialog;