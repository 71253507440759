import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteVideoDialog from '../../Studio/Playlists/Dialogs/DeleteVideoDialog';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import { blue } from '@mui/material/colors';

const VideoCard = ({ video, videoSelection, showDelete, onClick, className, playlistId, handleClick, lesson, handleChange, videoId }) => {

    const [openDeleteVideo, setOpenDeleteVideo] = React.useState(false);


    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return (
        <div className={className} onClick={onClick}>
            <img className='aspect-video bg-[cyan]' src={video?.url_thumbnail} />
            <div className='h-[60px] select-none text-left text-lg text-[white] bg-[#1976d2] p-5 flex justify-between items-center'>
                <div className={'h-full justify-center items-center flex'}>
                    {video?.title}
                </div>
                <div className='cursor-pointer'>
                    {videoSelection && <Checkbox {...label} onClick={() => handleClick(video.id)} sx={{
                        color: blue[50],
                        '&.Mui-checked': {
                            color: blue[50],
                        },
                    }} />}
                    {showDelete && <div onClick={() => setOpenDeleteVideo(true)}>
                        <DeleteIcon />
                    </div>}
                    {lesson && <Radio
                        checked={video.id === videoId}
                        onChange={handleChange}
                        value={video.id}
                        name="radio-buttons"
                        sx={{
                            color: blue[50],
                            '&.Mui-checked': {
                                color: blue[50],
                            },
                        }}
                    />}
                </div>
            </div>
            <DeleteVideoDialog open={openDeleteVideo} onClose={() => setOpenDeleteVideo(false)} videoId={video?.id} playlistId={playlistId} />
        </div>
    );
};

export default VideoCard;