import React, { useCallback, useContext, useEffect, useState } from 'react';
import produce from "immer";
import { useImmer } from "use-immer";
import { useDevTool } from "../context/DevTool";
import { ADD_LAYER, UPDATE_LAYER } from "./ProjectEditor";
import api from '../context/APIClient';

export const ACTION = 'ACTION';

const StoreContext = React.createContext();
const DispatchContext = React.createContext();

const ProjectWorkspaceProvider = (props) => {

    const [data, updateData] = useImmer(props.data ?? {});
    useDevTool("Project Workspace", data);

    const addLayer = useCallback((layer) => {
        updateData((draft) => {
            draft.layers.push(layer);
        });
    }, [updateData]);

    const updateLayer = useCallback((layer) => {
        updateData((draft) => {
            draft.layers = draft.layers?.map(l => l.id === layer.id ? layer : l)
            // const index = draft.layers.findIndex(l => l.id === layer.id)
            // if (index !== -1)
            //     draft[index].done = true
        });
    }, [updateData]);

    const deleteLayer = useCallback((layerId) => {
        updateData((draft) => {
            draft.layers = draft.layers?.filter(l => l.id !== layerId)
        });
    }, [updateData]);

    // const [store, dispatch] = React.useReducer(reducer, initialState);
    // const performAction = (arg) => {
    //     dispatch({ type: ACTION, payload: arg });
    //     // .then(r => {
    //     //     console.log(r);
    //     // });
    // };

    useEffect(() => {
        // let data = store.data ?? {};
        console.log('SAVING', data, props.projectId);
        if (!props.projectId)
            return;

        // if (store.project_id === null || store.project_id === "" || store.project_id === undefined)
        //     return;

        // the data contains the layers and the video
        // const data = { data: store.data }
        // console.log('saving project', data);

        api.patch(`/api/projects/${props.projectId}/`, { data }, {})
            .then(response => {
                // const data = response.data;
            })
            .catch(error => {
                console.log("Error saving project", error);
            })
    }, [data, props]);


    return (
        <DispatchContext.Provider value={{ addLayer, updateLayer, deleteLayer }}>
            <StoreContext.Provider value={data}>
                {props.children}
            </StoreContext.Provider>
        </DispatchContext.Provider>
    );
};

export function useProjectData() {
    // console.log('** useProject');
    const data = useContext(StoreContext);
    if (data === undefined) {
        throw new Error(`useProjectData must be used within a ProjectWorkspaceProvider`)
    }
    return data;
}

export function useLayers() {
    // console.log('** useProject');
    const data = useProjectData();
    return data?.layers ?? [];
}

export function useLayerActions() {
    const dispatch = useContext(DispatchContext);
    if (dispatch === undefined) {
        throw new Error(`useLayerActions must be used within a ProjectWorkspaceProvider`)
    }
    return dispatch;
}


export default ProjectWorkspaceProvider;
