import React, { useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import SplitPane, { Pane } from "react-split-pane";
import CourseTree from "../CourseTree";
import CourseEditorContainer from "../../Courses/CourseEditorContainer";
import LessonGroupEditor from "../../Lessons/LessonGroupEditor";
import LessonEditor from "../../Lessons/LessonEditor";
import VideoEditor from "../../Videos/VideoEditor";
import ItemTree from "../ItemTree";
import CourseListContainer from "../../Courses/CourseListContainer";
import CoursesContainer from "../../Courses/CoursesContainer";
import { Outlet, useNavigate } from "react-router-dom";
import QuizzesContainer from "../../Quizzes/QuizzesContainer";
import StudioTree from "./StudioTree";
import useSWR from "swr";
import HomeContainer from "../../HomeContainer";

const StudioWorkspace = (props) => {
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = React.useState(null);
    const { loading: quizzesLoading, error: quizzesError, data: quizzesData } = useSWR('/api/quizzes/');

    /*
    const editorView = useMemo(() => {
        if (!selectedItem) {
            return <HomeContainer />;
        }
        switch (selectedItem?.itemType) {
            case 'Courses':
                return <CoursesContainer />
            case 'Quizzes':
                return <QuizzesContainer />
            case 'Course':
                return <CourseEditorContainer course={selectedItem} />
            case 'LessonGroup':
                return <LessonGroupEditor lessonGroup={selectedItem} />
            case 'Lesson':
                return <LessonEditor lesson={selectedItem} />
            case 'Video':
                return <VideoEditor video={selectedItem} videoId={selectedItem?.id} />
            case 'Quiz':
                return <div>Activity Editor</div>
            default:
                return <div>{selectedItem?.itemType}</div>
        }
    }, [selectedItem]);

    const handleSelectedItem = (item) => {
        console.log('handleSelectedItem', item);
        setSelectedItem(item);
    };
    */

    const rootNodes = useMemo(() => {
        let quizzes = [];
        if( quizzesData ) {
            quizzes = quizzesData.data?.map(quiz => {
                return {
                    parent: 'quizzes',
                    id: quiz.id,
                    index: quiz.id,
                    itemType: 'Quiz',
                    title: quiz.title,
                    children: [],
                    item: quiz
                }
            });
        }

        return [
            {
                id: 'courses',
                parent: null,
                itemType: 'Course',
                title: 'Groupings',
                children: []
            },
            {
                id: 'quizzes',
                parent: null,
                itemType: 'Quiz',
                title: 'Quizzes',
                // children: []
                // children: [...quizzes?.map(quiz => quiz.id)]
                children: quizzes
            },
            {
                id: 'videos',
                parent: null,
                itemType: 'Video',
                title: 'Videos',
                children: []
            },
            {
                id: 'lessons',
                parent: null,
                itemType: 'Lesson',
                title: 'Lessons',
                children: []
            },
            // ...quizzesData
            // ...quizzes,
        ];
    }, [quizzesData]);

    // if( quizzesLoading || !quizzesData || quizzesData?.length == 0 ) {
    //     return <div>loading quizzes...</div>
    // }

    const [showTree, setShowTree] = React.useState(false);
    return (
        <SplitPane
            // key={quizzesData?.length}
            style={{ height: 'calc( 100vh - 64px )' }}
            split="vertical"
            minSize={300}
            // defaultSize={400}
            defaultSize={showTree ? 400 : '100%'}
            className={'h-full- bg-red-500- overflow-auto'}
            allowResize={true}
        >
            {showTree && <Pane className={'h-full overflow-scroll bg-white text-black select-none'}>
                <StudioTree
                    roots={rootNodes}
                    // onSelected={handleSelectedItem}
                />
            </Pane>}

            <Pane className={'h-full overflow-auto'}>
                {/*
                <button className={'absolute left-0 top-0 select-none'} onClick={() => setShowTree(!showTree)}>
                    tree
                </button>
                */}

                {/*{editorView}*/}
                <Outlet />
                {/*
                <div className={'bg-red-100- bg-gray-1002 overflow-auto'}>
                </div>
                */}
            </Pane>
        </SplitPane>
    );
};


export default StudioWorkspace;
