import React from 'react';
import { Button, Grid } from '@material-ui/core';
import tw, { css } from 'twin.macro';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    root: {
        ...tw`w-full h-full flex-grow font-size[inherit]`,
    },
    button: {
        ...tw`w-full h-full text-white hover:bg-blue-100 md:max-h-[20vw] font-size[inherit]`,
        // maxHeight: '20vw',
        // aspectRatio: '1/1',
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);',
    },
});


const MenuGrid = ({ items, onSelected }) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.root} spacing={2}>
            {items?.map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                    <Button
                        variant="contained"
                        className={classes.button}
                        onClick={() => onSelected ? onSelected(item, index) : (item.onClick ?? null)}
                    >
                        {item.label}
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
};
export default MenuGrid;
