import { useEffect, useState } from "react";
import apiClient from "./APIClient";

const getAuthToken = (initialValue = null) => {
    try {
        // Get from local storage by key
        const item = window.localStorage.getItem('auth');
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item)['token'] : initialValue;
    } catch (error) {
        // If error also return initialValue
        console.log(error);
        return initialValue;
    }
};

console.log("Auth Token: ", getAuthToken());

// export const api = axios.create({
//     // baseURL: 'http://localhost:8000',
//     // baseURL: 'https://dynamo.muzology.com',
//     baseURL: MUZOLOGY_API_URL,
//     timeout: 10000,
//     // headers: {
//     //     Authorization: `Token ${getAuthToken()}`
//     // }
// });

// api.interceptors.request.use(
//     config => {
//         const token = getAuthToken(null);
//         if(token) {
//             config.headers.Authorization = `Token ${token}`;
//         }
//         return config;
//     },
//     error => Promise.reject(error)
// );

//
export function useGetAPI(path, options = null) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            return apiClient.get(path)
                .then(response => {
                    setData(response.data);
                    if (options?.setData)
                        options.setData(response.data);
                    // console.log('download data', data);
                    return response.data;
                })
                .catch(error => {
                    setError(error);
                    console.error('Error fetching data: ', error);
                });
        };

        setLoading(true);
        fetchData().then(r => setLoading(false));
    }, [options, path]);
    return { data, error, loading };
}

