import React from 'react';
import Panel from "../../../Components/Dashboard/Panel";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Stack, Typography } from "@mui/material";
import { getLevelName, QUESTION_TYPE_MULTIPLE_CHOICE, QUESTION_TYPE_MULTIPLE_SELECTION, QUIZ_BACKGROUNDS, QUIZ_COLORS, QUIZ_FOREGROUNDS } from "./constants";
import { ExpandMore } from "@mui/icons-material";
import useSWR from "swr";
import apiClient from "../../../context/APIClient";
import { useDevTool } from "../../../context/DevTool";


const StatusRow = ({ text, status, errors }) => {
    const [showErrors, setShowErrors] = React.useState(true);

    const toggleShowErrors = () => {
        console.log('toggleShowErrors');
        // setShowErrors(value => !value)
        setShowErrors(true);
    }

    return (
        <>
            <tr className="cursor-pointer px-4 py-2 border-b last:border-none" onClick={() => setShowErrors(value => !value)}>
                <td className="px-4 py-2 w-full" onClick={toggleShowErrors}>
                    {text}
                </td>
                <td className="px-4 py-2 ">
                    {status ?
                        <span className="m-auto bg-green-200 text-green-700 px-2 py-1 rounded">PASS</span>
                        :
                        <span className="m-auto  bg-red-200 text-red-700 px-2 py-1 rounded">FAIL</span>
                    }
                </td>
            </tr>
            {showErrors && errors?.length > 0 && <tr className="px-4 py-2 border-b last:border-none">
                <td className="px-4 py-2 w-full" colSpan={2}>
                    <div className="bg-red-200 text-red-700 px-2 py-1 rounded">
                        {errors.map((error, index) => {
                            return <div key={index}>{error}</div>
                        })}
                    </div>
                </td>
            </tr>}
        </>
    );
};

export const addStatusRow = (text, status, errors=null) => {
    return (
        <StatusRow key={text} text={text} status={status} errors={errors} />
    );
}

const isQuestionValid = (question) => {
    let reason = null;
    // must have at least 2 choices
    if (question.choices.length < 2) {
        reason = 'Must have at least 2 choices'
        return [false, reason];
    }

    // must have a correct answer
    if (question.choices.filter((choice) => choice.correct).length === 0) {
        reason = 'Must have a correct answer';
        return [false, reason];
    }

    return [true, reason];
}


// checks that a level is complete (all questions are valid and have a correct answer)
const isLevelComplete = (quiz, level, questionCount) => {
    // get all questions for this level
    const questions = quiz.questions.filter((question) => question.level === level);

    // check if there are the correct number of questions
    if (questions.length !== questionCount) {
        console.log('level', level, 'has', questions.length, 'questions, expected', questionCount);
        return false;
    }

    // check if all questions are valid
    const invalidQuestions = questions.filter((question) => {
        if (question.type === QUESTION_TYPE_MULTIPLE_CHOICE || question.type === QUESTION_TYPE_MULTIPLE_SELECTION) {
            const [isValid] = isQuestionValid(question);
            if (!isValid) {
                return true;
            }
        }
        return false;
    });
    return invalidQuestions.length === 0;
}

const checkQuizLevelComplete = (quiz, levelCount, questionsPerLevel) => {

    // check the totals
    const totalQuestions = levelCount * questionsPerLevel;
    if (quiz.questions.length !== totalQuestions) {
        console.log('quiz has', quiz.questions.length, 'questions, expected', totalQuestions);
        return false;
    }

    // check if there are 3 levels, each with 10 questions
    const levels = quiz.questions.reduce((acc, question) => {
        if (!acc.includes(question.level)) {
            acc.push(question.level);
        }
        return acc;
    }, []);

    if (levels.length !== levelCount) {
        console.log('quiz has', levels.length, 'levels, expected', levelCount);
        return false;
    }

    // check if each level has 5 questions
    for (let i = 0; i < levelCount; i++) {
        if (!isLevelComplete(quiz, i, questionsPerLevel)) {
            return false;
        }
    }

}

// const ProductionHealthCheck = ({ quiz }) => {
//     // const productionQuiz = useSWR('/api/quiz/production', api).data;
//     // production
//     let productionQuestionsMatch = false
//     return (
//         <table className="table-auto w-full">
//             {addStatusRow('Production published matches', productionQuestionsMatch)}
//             {productionQuestionsMatch === false && <div>
//                 <div>
//                     <ul>
//                         <li>Standard quiz should have 3 levels of 10 questions</li>
//                         <li>Mini quiz should have 3 levels of 5 questions</li>
//                     </ul>
//                 </div>
//             </div>}
//         </table>
//     )
//
// }
//
//
// function AudioHealthCheck({ quiz }) {
//     let productionQuestionsMatch = false
//     return (
//         <table className="table-auto w-full">
//             {addStatusRow('Audio', productionQuestionsMatch)}
//             {productionQuestionsMatch === false && <div>
//                 <div>
//                     <ul>
//                         <li>Standard quiz should have 3 levels of 10 questions</li>
//                         <li>Mini quiz should have 3 levels of 5 questions</li>
//                     </ul>
//                 </div>
//             </div>}
//         </table>
//     )
//
// }

const HealthCheck = ({ quiz }) => {
    const [checks, setChecks] = React.useState([]);
    const [isValid, setIsValid] = React.useState(null);

    useDevTool('checks', checks);

    React.useEffect(() => {
        apiClient.get(`/api/quiz/${quiz.id}/check/`)
            .then(response => {
                console.log('response', response);
                const { valid, checks } = response.data;
                setIsValid(valid);
                setChecks(checks);
            })
            .catch(error => {
                console.log('error', error);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // check if the number of questions and levels match the type
    let hasCorrectNumberOfQuestions = null;
    let allQuestionsValid = null;
    let allQuestionsHaveTtsAudio = null;

    if (quiz.type === 'standard') {
        // should have 3 levels of 10 questions
        hasCorrectNumberOfQuestions = checkQuizLevelComplete(quiz, 3, 10);
    } else if (quiz.type === 'mini') {
        // should have 3 levels of 5 questions
        hasCorrectNumberOfQuestions = checkQuizLevelComplete(quiz, 3, 5);
    } else {
        hasCorrectNumberOfQuestions = null;
    }


    // check if all questions are valid
    const invalidQuestions = quiz.questions.filter((question) => {
        if (question.type === QUESTION_TYPE_MULTIPLE_CHOICE || question.type === QUESTION_TYPE_MULTIPLE_SELECTION) {
            const [isValid] = isQuestionValid(question);
            if (!isValid) {
                return true;
            }
        }
        return false;
    });
    allQuestionsValid = invalidQuestions.length === 0;


    // check if all questions and choices have tts audio
    allQuestionsHaveTtsAudio = quiz.questions.filter((question) => {
        if (question.audio === null)
            return true;
        if (question.type === QUESTION_TYPE_MULTIPLE_CHOICE || question.type === QUESTION_TYPE_MULTIPLE_SELECTION) {
            if (question.choices.filter((choice) => choice.audio === null).length > 0)
                return true;
            // // must have at least 2 choices
            // if (question.choices.length < 2)
            //     return true;
            // // must have a correct answer
            // if (question.choices.filter((choice) => choice.correct).length === 0)
            //     return true;
        }
        return false;
    });

    if (isValid === null) {
        return (
            <Typography variant={'h4'} className={'w-full'}>
                <div className="w-full m-auto bg-gray-200 text-gray-700 px-2 py-1 rounded">Checking...</div>
            </Typography>
        );
    }

    return (
        <Grid container rowSpacing={4} spacing={1}>
            <Grid item xs={12}>
                <Stack spacing={2}>
                    <Typography variant={'h4'}>{isValid ? <div className="m-auto bg-green-200 text-green-700 px-2 py-1 rounded">Quiz Check: PASS</div> :
                        <div className="m-auto  bg-red-200 text-red-700 px-2 py-1 rounded">Quiz Check: FAIL</div>}</Typography>

                    <Panel title={'Health Checks'}>
                        <Stack spacing={0}>
                            <table className="table-auto w-full">
                                <tbody>
                                {checks.map(check => {
                                    console.log(check);
                                    return addStatusRow(check.title, check.valid, check.errors)
                                })}
                                </tbody>
                            </table>
                        </Stack>
                    </Panel>
                    {/*

                    <Panel title={'Health Check'}>
                        <Stack spacing={0}>
                            <table className="table-auto w-full">
                                {addStatusRow('number of questions and levels match type', hasCorrectNumberOfQuestions)}
                                {hasCorrectNumberOfQuestions === false && <div>
                                    <div>
                                        <ul>
                                            <li>Standard quiz should have 3 levels of 10 questions</li>
                                            <li>Mini quiz should have 3 levels of 5 questions</li>
                                        </ul>
                                    </div>
                                </div>}
                                {addStatusRow('all questions are valid and have a correct answer', allQuestionsValid)}
                                {hasCorrectNumberOfQuestions === false && <div>
                                    <table>
                                        <tbody>
                                        <tr className="px-4 py-2 border-b last:border-none">
                                            <td className="px-4 py-2 w-full">
                                                <span className="m-auto bg-red-200 text-red-700 px-2 py-1 rounded">
                                                    Gold #1
                                                </span>
                                                Question text
                                            </td>
                                            <td className="px-4 py-2">
                                                <span className="m-auto bg-red-200 text-red-700 px-2 py-1 rounded">FAIL</span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>}
                                {addStatusRow('all questions have tts audio', allQuestionsHaveTtsAudio)}
                            </table>
                        </Stack>
                    </Panel>
                    <Panel title={'Health Check'}>
                        <AudioHealthCheck />
                    </Panel>*/}
                </Stack>
            </Grid>

            {/*
            <Grid item xs={12}>
                <Panel title={'Production'}>
                    <ProductionHealthCheck />
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Accordion 1</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                malesuada lacus ex, sit amet blandit leo lobortis eget.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>


                    <table className="table-auto w-full">



                        {addStatusRow('Production questions match', productionQuestionsMatch)}
                        <tr className="px-4 py-2 border-b last:border-none">
                            <td className="px-4 py-2 w-full" colSpan={2}>
                                {'Production questions match'}
                            </td>
                            <td className="px-4 py-2 ">
                                {productionQuestionsMatch ?
                                    <span className="m-auto bg-green-200 text-green-700 px-2 py-1 rounded">PASS</span>
                                    :
                                    <span className="m-auto  bg-red-200 text-red-700 px-2 py-1 rounded">FAIL</span>
                                }
                            </td>
                        </tr>


                        {quiz.questions.map((question, index) => {
                            return (
                                <tr key={index} className="px-4 py-2 border-b last:border-none">
                                    <td className="px-4 py-2">
                                        <span
                                            className="inline-block m-auto text-black px-2 py-1 rounded w-[120px]"
                                            style={{
                                                color: QUIZ_FOREGROUNDS[question.level],
                                                backgroundColor: QUIZ_BACKGROUNDS[question.level],
                                                // color: 'white',
                                            }}
                                        >
                                          {getLevelName(question.level)} #{index + 1}
                                        </span>

                                        <span className="m-auto  text-black px-2 py-1">
                                            {question.question_text}
                                        </span>
                                    </td>
                                    <td className="px-4 py-2">
                                        <span className="m-auto bg-red-200 text-red-700 px-2 py-1 rounded">FAIL</span>
                                    </td>
                                </tr>
                            )
                        })}


                        {hasCorrectNumberOfQuestions === false && <div>
                            <div>
                                <ul>
                                    <li>Standard quiz should have 3 levels of 10 questions</li>
                                    <li>Mini quiz should have 3 levels of 5 questions</li>
                                </ul>
                            </div>
                        </div>}
                        {addStatusRow('all questions are valid and have a correct answer', allQuestionsValid)}
                        {hasCorrectNumberOfQuestions === false && <div>
                            <table>
                                <tbody>
                                <tr className="px-4 py-2 border-b last:border-none">
                                    <td className="px-4 py-2 w-full">
                                                <span className="m-auto bg-red-200 text-red-700 px-2 py-1 rounded">
                                                    Gold #1
                                                </span>
                                        Question text
                                    </td>
                                    <td className="px-4 py-2">
                                        <span className="m-auto bg-red-200 text-red-700 px-2 py-1 rounded">FAIL</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>}
                        {addStatusRow('all questions have tts audio', allQuestionsHaveTtsAudio)}

                    </table>
                </Panel>
            </Grid>
*/}

        </Grid>

    );
};


export default HealthCheck;
