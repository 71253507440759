import React, { useCallback, useMemo, useRef, useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import {
    ControlledTreeEnvironment,
    StaticTreeDataProvider,
    Tree,
    UncontrolledTreeEnvironment
} from "react-complex-tree";
import { useDevTool } from "../../context/DevTool";
import "react-complex-tree/lib/style-modern.css";
import { v4 as uuidv4 } from "uuid";
import { useStudio } from "../Context/StudioContext";
import { useItemEditor } from "../StudioContainer/StudioContainer";
import cx from "classnames";
import { addLessonPalette } from "./LessonPalette";
import { useVideos } from "../Videos/VideosContainer";
import { libraryRenderers, renderers } from "./LibraryRenderers";
import { Button } from "@mui/material";
import LibrarySettingsDialog from "./LibrarySettingsDialog";
import NewGroupDialog from "./NewGroupDialog";
import AddLessonsGroupDialog from "../StudioContainer/Dialogs/AddLessonsGroupDialog";
import apiClient from "../../context/APIClient";
import TreeInteractionManager from "./TreeInteractionManager";
// import crypto from "crypto";

const TreeStyle = styled.div`
    .rct-tree-item-li {
        font-size: 1rem;
    }
`;

const ItemTitle = (props) => {
    return (
        <div {...props} />
    );
};

const LessonGroupTree = ({ course, createNewGroup, addVideo, removeVideo, showVideosPanel, showSettingsPanel, onSelectItem = null, selectedItem = null }) => {
    const { lessons: allLessons, videos } = useStudio();
    const { volumes } = useItemEditor();
    const [selectedGroup, setSelectedGroup] = useState(null);
    const treeEnvironmentRef = useRef(null);

    // const course = courses.find(course => course.slug === 'course-by-grade');
    const volume = useMemo(() => volumes?.find(volume => volume.id === course?.volume_id), [course?.volume_id, volumes]);
    // console.log('volume', volume);
    // const [treeData, setTreeData] = React.useState({});
    const [viewState, setViewState] = React.useState({});

    const handleSelectGroup = (items, treeId) => {
        // setSelectedGroup(itemId);
        console.log('!!!! handleSelectGroup', items, treeId);
        if( items.length === 0 ) {

        } else {
            // select the item
            console.log('!!! onSelectItems', items, treeId);
            const item = treeData[items[0]];
            console.log("!!! SELECTED ITEM", item);
            if (!item) {
                console.log('!!! Item not found: ', treeData);
                return;
            }

            if (onSelectItem) {
                onSelectItem({...item});
            }

            const treeIds = treeEnvironmentRef.current?.treeIds.filter(id => id !== 'lesson-palette' && id !== treeId) ?? [];
            setTimeout(() => {
                treeIds.forEach(id => {
                    treeEnvironmentRef.current?.selectItems([], id);
                });
                // treeIds.map(id => {
                //     if (id === 'lesson-palette')
                //         return id;
                //     if (id !== treeId) {
                //     }
                //     return id;
                // })
            }, 100);
        }
        // treeEnvironmentRef.current?.selectItems(items, treeId);

        /*
        setViewState((vs) => {
            console.log('vs', vs);
            const treeIds = Object.keys(vs);
            treeIds.forEach(id => {
                vs[id].selectedItems = [];
            });
            vs[treeId].selectedItems = items;
            return vs;
            // const newVs = { ...vs };
            //
            // // {
            // //     ['tree-2']: {
            // //     focusedItem,
            // //         expandedItems,
            // //         selectedItems,
            // // },
            // // }
            // // newVs[treeId].focusedItem = itemId
            //
            // // newVs['']
            // //     'tree-1': {
            // //         focusedItem: selectedNode,
            // //     },
            // // }}
            //
            // // toggle expanded items
            // // if (vs['lesson-palette']?.selectedItems?.includes(itemId)) {
            // //     // remove from expanded items
            // //     const index = vs['lesson-palette'].selectedItems.indexOf(itemId);
            // //     if (index > -1) {
            // //         newVs['lesson-palette'].selectedItems.splice(index, 1);
            // //     }
            // // } else {
            // //     // add to expanded items
            // //     newVs['lesson-palette'] = {
            // //         ...newVs['lesson-palette'],
            // //         selectedItems: [...newVs['lesson-palette'].selectedItems, itemId]
            // //     }
            // // }
            // return newVs;
        });
        */
    };

    // const treeId = 'lesson-group-tree';
    // const [expandedItems, setExpandedItems] = React.useState(['groupContainer']);
    // {
    //     'lesson-tree': {
    //     focusedItem: 'groupContainer',
    //         selectedItems: ['groupContainer'],
    //         expandedItems: ['groupContainer'],
    // },
    // const viewState2 = React.useMemo(() => {
    //     return (
    //         {
    //             expandedItems, setExpandedItems
    //         });
    //
    // }, [expandedItems, setExpandedItems]);

    const rootGroups = useMemo(() => course?.groups?.filter(group => !group.parent) ?? [], [course?.groups]);

    const lessonPaletteData = useMemo(() => {
        // const palette = addLessonPalette(course, allLessons);
        const paletteData = {};
        // return palette;
        // setup the lesson palette
        paletteData['lesson-palette'] = {
            id: 'lesson-palette',
            index: 'lesson-palette',
            itemType: 'Lesson Palette',
            isFolder: true,
            children: [],
            title: 'Lesson Palette',
        };

        allLessons?.forEach(lesson => {
            // console.log('lesson', lesson, course?.volume_id, lesson.volume_id);
            if (course?.volume_id && lesson.volume_id !== course?.volume_id) {
                // console.log('skipping lesson', lesson.id, lesson.title, lesson.volume_id, course?.volume_id);
                return;
            }
            const lessonNodeId = `lesson-palette-lesson-${lesson.id}`;
            paletteData[lessonNodeId] = {
                id: lessonNodeId,
                index: lessonNodeId,
                itemType: 'Lesson',
                isFolder: false,
                children: [],
                title: lesson.title,
                data: lesson.title,
                item: lesson
            }
            paletteData['lesson-palette'].children.push(lessonNodeId);
        });
        return paletteData;
    }, [allLessons, course]);

    const treeData = useMemo(() => {

        const tree = {
            root: {
                id: 'root',
                index: 'root',
                itemType: 'Root',
                isFolder: true,
                children: [],
                data: 'Root',
                title: 'Root',
                item: null
            }
        };
        const vs = {}

        const addChildGroups = (group, tree, treeId, viewState, depth = 1) => {

            // find the children of this group
            const childGroups = course?.groups?.filter(childGroup => childGroup.parent === group.id) ?? [];
            childGroups.forEach(childGroup => {
                tree[childGroup.id] = {
                    id: childGroup.id,
                    index: childGroup.id,
                    parentId: group.id,
                    itemType: 'Group',
                    isFolder: true,
                    children: [],
                    title: childGroup.title,
                    data: childGroup.title,
                    item: childGroup,
                    depth: depth
                };

                // add the child group to the parent group
                if (tree[group.id]) {
                    tree[group.id].children.push(childGroup.id);
                }

                viewState[treeId]?.expandedItems.push(childGroup.id);

                // viewState = { ...viewState, [group.id]: {
                //     focusedItem: childGroup.id,
                //     selectedItems: [childGroup.id],
                //     expandedItems: [childGroup.id],
                // }};
                // viewState[group.id]?.expandedItems.push(childGroup.id);

                // add the group's lessons
                childGroup?.lessons?.forEach(lessonId => {
                    // console.log('adding lesson', lessonId);
                    const lesson = allLessons?.find(lesson => lesson.id === lessonId);
                    // console.log('lesson', lesson);
                    if (!lesson) return null;
                    const lessonNodeId = `group-${childGroup.id}-lesson-${lesson.id}`;
                    // console.log('lessonNodeId', lessonNodeId);
                    tree[lessonNodeId] = {
                        id: lessonNodeId,
                        index: lessonNodeId,
                        parentId: childGroup.id,
                        itemType: 'Lesson',
                        isFolder: false,
                        children: [],
                        title: lesson.title,
                        data: lesson.title,
                        item: lesson
                    }
                    tree[childGroup.id].children.push(lessonNodeId);
                    // vs[group.id].expandedItems.push(lessonNodeId);
                });

                // add the child group's children recursively
                // console.log('add child groups for', childGroup);
                addChildGroups(childGroup, tree, treeId, viewState, depth + 1);
            });

        };

        // find the top level groups
        rootGroups.forEach(group => {
            const nodeId = `container-${group.id}`;

            // create the root container for this tree
            tree[nodeId] = {
                id: nodeId,
                index: nodeId,
                itemType: 'Group Container',
                isFolder: true,
                children: [group.id],
                title: 'container: ' + group.title,
                data: group.title,
                item: group,
                depth: -1
            };

            // the parent group item
            tree[group.id] = {
                id: group.id,
                index: group.id,
                itemType: 'Parent Group',
                isFolder: true,
                children: [],
                title: group.title,
                data: group.title,
                item: group,
                depth: 0
            };

            // create the view state
            vs[group.id] = {
                focusedItem: group.id,
                selectedItems: [group.id],
                expandedItems: [group.id, nodeId],
            }

            // add the children
            addChildGroups(group, tree, group.id, vs, 1);
            setViewState(vs);
        });

        // // setup the lesson palette
        // tree['lesson-palette'] = {
        //     id: 'lesson-palette',
        //     index: 'lesson-palette',
        //     itemType: 'Lesson Palette',
        //     isFolder: true,
        //     children: [],
        //     title: 'Lesson Palette',
        // };
        //
        // allLessons?.forEach(lesson => {
        //     // console.log('lesson', lesson, course?.volume_id, lesson.volume_id);
        //     if (course?.volume_id && lesson.volume_id !== course?.volume_id) {
        //         // console.log('skipping lesson', lesson.id, lesson.title, lesson.volume_id, course?.volume_id);
        //         return;
        //     }
        //     const lessonNodeId = `lesson-palette-lesson-${lesson.id}`;
        //     tree[lessonNodeId] = {
        //         id: lessonNodeId,
        //         index: lessonNodeId,
        //         itemType: 'Lesson',
        //         isFolder: false,
        //         children: [],
        //         title: lesson.title,
        //         data: lesson.title,
        //         item: lesson
        //     }
        //     tree['lesson-palette'].children.push(lessonNodeId);
        // });


        return tree;
    }, [allLessons, course?.groups, rootGroups, lessonPaletteData]);

    // perform a super fast hash of the tree data
    const treeHash = useMemo(() => {
        // hash of the string
        const jsonString = JSON.stringify(treeData);
        let hash = 5381;
        for (let i = 0; i < jsonString.length; i++) {
            hash = (hash * 33) ^ jsonString.charCodeAt(i);
        }
        return hash >>> 0;
        // return crypto.createHash('md5').update(jsonString).digest('hex');
    }, [treeData]);
    console.log('treeHash', treeHash);

    // console.log('trees:', trees);
    // const lessons = course?.lessons?.map(lessonId => allLessons?.find(lesson => lesson?.id === lessonId)).filter(lesson => lesson) ?? [];
    useDevTool('CourseTree', { course, treeData, viewState, allLessons });
    // const uniqueId = uuidv4();
    // console.log(uniqueId, treeData);

    const [openNewGroup, setOpenNewGroup] = useState(false);

    const handleAddGroup = (item) => {
        console.log('add group', item);
        setSelectedGroup(item.id);
        setOpenNewGroup(true);
    }

    const handleAddVideo = (item) => {
        console.log('add video', item);
        setSelectedGroup(item.id);
        addVideo(item.id);
    }

    const handleRemoveLesson = (event, item) => {
        event.stopPropagation();
        const groupId = item.parentId;
        const lessonId = item.item.id;
        console.log('remove lesson', groupId, lessonId, item);
        // setSelectedGroup(item.id);
        // addVideo(item.id);
        removeVideo(groupId, lessonId);
    };

    const renderItemTitle = ({ title, item, context, info }) => {
        if (info.treeId === 'lesson-palette') {
            return (
                <ItemTitle className={'w-full pt-2 text-base'}>
                    {title}
                </ItemTitle>
            );
        }

        // if (item.itemType === 'Root') return null;
        // console.log('render item title', context, item, info);
        const isRoot = item.itemType === 'Root';
        const isContainer = item.itemType === 'Group Container';
        const isParent = item.itemType === 'Parent Group';
        const isGroup = item.itemType === 'Group';
        const isLesson = item.itemType === 'Lesson';
        // console.log('item type:', item.itemType);


        // the lesson shows a thumbnail image
        if (isLesson) {
            const video = videos?.find(video => video.id === item?.item.video);
            // console.log('video', video);
            let image = video?.url_thumbnail ?? video?.thumbnail ?? video?.image;
            if (image === '/static/images/muzology_thumbnail.png') {
                image = video?.image;
            }
            return (
                <ItemTitle className={'border border-[#aaa] relative p-1 text-left text-base w-full flex flex-row items-center gap-2'}>
                    <div
                        className={'bg-[black] h-[50px] aspect-video'}
                    >
                        <img
                            className={'bg-[green] h-[50px] aspect-video'}
                            src={image}
                            width={'auto'}
                            height={'50px'}
                        />
                    </div>
                    {title}
                    <div className={'absolute top-1 right-2 bottom-0 flex justify-center items-start'}>
                        <button className={'text-[#ddd] hover:text-[#666]'} onClick={(ev) => handleRemoveLesson(ev, item)}>
                            &#10005;
                        </button>
                    </div>
                </ItemTitle>
            );
        }

        return (
            <ItemTitle
                className={cx('text-left relative w-full', {
                    // 'pl-2 py-4 text-xl font-bold': isParent,
                    // 'text-left': isContainer,
                    'text-xl mb-2 border-b-[black] border-b-2': item.depth === 1,
                    'text-lg': item.depth === 2,
                    'text-base': item.depth >= 3,
                    // 'text-left text-lg': isGroup,
                    // 'bg-orange-400': item.depth === 0,
                    // 'bg-blue-400': item.depth === 1,
                    // 'bg-green-400': item.depth === 2,
                    // 'bg-yellow-400': item.depth === 3,
                    // 'text-sm': depth === 1,
                })}
                // onClick={(event) => {
                //     event.stopPropagation();
                //     console.log('click item', item.id);
                //     setSelectedGroup(item.id);
                // }}

            >
                {title}
                <div className={cx('absolute top-0 right-0 bottom-0 flex justify-center itens-center', {
                    // 'pl-2 py-4 text-xl font-bold': isParent,
                    // 'text-left': isContainer,
                    // 'text-left text-lg': isGroup,
                    // 'bg-orange-400': item.depth === 0,
                    // 'bg-blue-400': item.depth === 1,
                    // 'bg-green-400': item.depth === 2,
                    // 'bg-yellow-400': item.depth === 3,
                    // 'text-sm': depth === 1,
                })}>
                    <button
                        className={'px-2 bg-[transparent] border-none text-[#aaa] hover:text-[green] active:text-[red]'}
                        size={'small'}
                        onClick={(event) => {
                            event.stopPropagation();
                            handleAddGroup(item)
                        }}
                    >
                        +
                    </button>
                    <button
                        className={'px-2 bg-[transparent] border-none text-[#aaa] hover:text-[green] active:text-[red]'}
                        size={'small'}
                        onClick={(event) => {
                            event.stopPropagation();
                            handleAddVideo(item)
                        }}
                    >
                        +
                    </button>
                </div>
            </ItemTitle>
        );

        // if (isContainer) {
        //     return (
        //         <ItemTitle className={'bg-[green]'}>
        //             {title}
        //         </ItemTitle>
        //     );
        // }
        // if (isParent) {
        //     return (
        //         <ItemTitle className={'py-4 text-xl font-bold'}>
        //             {title}
        //         </ItemTitle>
        //     );
        // }
        // if (isGroup) {
        //     return (
        //         <ItemTitle className={'text-left text-lg'}>
        //             {title}
        //         </ItemTitle>
        //     );
        // }
        // return (
        //     <ItemTitle className={'w-full bg-[pink]'}>{title}</ItemTitle>
        // )
        // return (
        //     <ItemTitle
        //         className={'w-full bg-[red]'}
        //         css={[
        //             tw`text-2xl font-black`,
        //             tw`w-full bg-[yellow]`,
        //             // isGroup && tw`text-[yellow] text-xl font-bold`,
        //             // isLesson && tw`text-[blue]`,
        //             isContainer && tw`bg-[red]`,
        //             isParent && tw`bg-[blue]`,
        //             isGroup && tw`bg-[orange]`,
        //             isLesson && tw`bg-[yellow]`,
        //         ]}
        //     >
        //         {title}
        //     </ItemTitle>
        // );
        // return (
        //     <span>
        //         {title}
        //     </span>
        // );
    }

    const renderItemArrow = ({ item, context }) => {
        if (!item.isFolder) return null;
        return (
            <span
                {...context.arrowProps}
            >
                {context.isExpanded ? 'v ' : '> '}
            </span>
        );
    }

    const renderPaletteItem = (props) => {
        const { title, arrow, depth, item, context, children, info } = props;

        // get the image
        let image = item?.item?.thumbnail ?? item?.item?.image;
        if (image === '/static/images/muzology_thumbnail.png') {
            image = item?.item?.image;
        }

        // find the Video
        const video = videos?.find(video => video?.id === item?.item?.video);
        if (video) {
            image = video?.url_thumbnail;
        }

        // console.log('renderPaletteItem', props);
        // console.log('renderPaletteItem', depth, item, image);
        // console.log('videos', videos);
        // console.log('found video', video )

        if (depth === 0) {
            return (
                <div
                    {...context.itemContainerWithChildrenProps}
                    // className={'aspect-video bg-[blue] flex-grow'}
                    // className={'flex flex-col gap-0 bg-[white] p-0 rounded-xl border-black border-2'}
                >
                    <div
                        // className={'flex flex-row px-0 gap-2 justify-start items-center flex-grow bg-[yellow]'}
                        className={'flex flex-col p-2 gap-0 justify-start items-center flex-grow bg-[#ddd]'}
                        {...context.itemContainerWithoutChildrenProps}
                        {...context.interactiveElementProps}
                    >
                        <div
                            className={'aspect-video bg-[yellow] w-full'}
                        >
                            <img
                                src={image}
                                width={'100%'}
                                height={'auto'}
                                // className={'bg-[red] w-full h-full'}
                                alt={item?.item?.title}
                            />
                        </div>
                        {title}
                    </div>
                </div>
            );
        } else {
            return <div>depth {depth}</div>;
        }
    }

    const toggleExpandedItem = useCallback((treeId, itemId) => {
        setViewState((vs) => {
            const newVs = { ...vs };
            // toggle expanded items
            if (vs[treeId]?.expandedItems?.includes(itemId)) {
                // remove from expanded items
                const index = vs[treeId].expandedItems.indexOf(itemId);
                if (index > -1) {
                    newVs[treeId].expandedItems.splice(index, 1);
                }
            } else {
                // add to expanded items
                newVs[treeId] = {
                    ...newVs[treeId],
                    expandedItems: [...newVs[treeId].expandedItems, itemId]
                }
            }
            return newVs;
        });

    }, []);

    const renderTreeContainer = ({ children, containerProps }) => {
        // console.log('renderTreeContainer', containerProps);
        return (
            <div
                {...containerProps}
                className={'py-6 px-2 bg-[transparent]'}
                // className={'py-10 px-2 bg-[gray]'}
                // className={'border-[red] border-2 m-0 p-0 bg-[yellow] gap-10'}
                // className={'bg-[#f0f0f0] p-0 m-0 border-[#666] border-8'}
                // className='bg-[white] flex flex-col gap-4'
            >
                {children}
            </div>
        );
    }

    const renderItemsContainer = (props) => {
        const { children, containerProps } = props;
        // console.log('render items container', props);
        // console.log(props.containerProps.role);
        // console.log('RENDER CONTAINER:', props.parentId, props.containerProps.role, props);
        // console.log(props.containerProps);
        // const isSelected = props.item?.id === selectedGroup;
        // console.log('render items container', props, selectedGroup, isSelected);
        if (props.info.treeId === 'lesson-palette') {
            return (
                <div
                    {...containerProps}
                    className={'grid grid-cols-3 gap-1 bg-[white] select-none'}
                >
                    {children}
                </div>
            );
        }
        return (
            <div
                className={cx('flex flex-col select-none', {
                    // 'bg-[red] m-2 p-2 border-black border': isSelected,
                })}
                {...props.containerProps}
            >
                {props.children}
            </div>
        );
        // return (
        //     <div
        //         {...containerProps}
        //         // className={'border-[red] bg-[yellow] border-2 m-2 p-2 max-w-[400px] flex flex-col gap-10 select-none'}
        //         className={cx('flex flex-col gap-10 select-none', {
        //             // 'border-[green] bg-[orange] border-2': depth === 1,
        //             // [`border-[red] bg-[yellow] border-2`]: true,
        //             // 'border-2 m-2 p-2': false
        //             // 'bg-white order border-[#888] rounded-lg drop-shadow-lg- shadow-2xl': depth === 1,
        //             // "border-2 border-[black] border-solid bg-red-300": false
        //
        //         })}
        //     >
        //         {children}
        //     </div>
        // );
    }

    const renderItem = useCallback((props) => {
        // console.log('renderItem', props);
        if (props.info.treeId === 'lesson-palette') {
            return renderPaletteItem(props);
        }
        const { title, arrow, depth, item, context, children, info } = props;
        const isRoot = item.itemType === 'Root';
        const isContainer = item.itemType === 'Group Container';
        const isParent = item.itemType === 'Parent Group';
        const isGroup = item.itemType === 'Group';
        const isLesson = item.itemType === 'Lesson';
        // const isSelected = item.id === selectedGroup;
        const isSaving = item.saving === true;

        const isSelected = context.isSelected;
        const selectedStyle = 'border-[2.5px] border-[blue] rounded';
        const unselectedStyle = 'border-[2.5px] border-[transparent]';
        // if (isSelected) {
        //     // console.log("***SELECTED", context, item);
        // }

        // console.log('selection ', item.id, selectedGroup, isSelected);

        // const isSelected = info.selectedItems.includes(item.id);

        // let style = {
        //     marginLeft: depth * 10,
        //     padding: '5px',
        //     border: item.id === selectedNode ? '1px solid blue' : 'none', // Add border if item is the selected node
        // };

        // if( depth === 0 ) return null;
        // console.log('renderItem', depth, context, children);

        // return (
        //     <div className={'bg-red-400'}>
        //         renderItem()
        //     </div>
        // )
        // console.log('renderItem', depth, item, context);
        // return null;
        if (depth === 0) {
            // this is a root level group
            return (
                <div
                    // onClick={() => handleSelectGroup(item.id, info.treeId)}
                    {...context.itemContainerWithChildrenProps}
                    className={cx('flex flex-col gap-4 p-0', {
                        // [selectedStyle]: isSelected,
                        'bg-white order border-[#888] rounded-lg drop-shadow-lg- shadow-2xl': false,
                        // 'bg-[green]': isSaving,
                        'bg-[pink] border border-black': context.isDraggingOver,
                        // 'rct-tree-item-li-isFolder': item.isFolder,
                        // 'rct-tree-item-li-selected': context.isSelected,
                        // 'rct-tree-item-li-expanded': context.isExpanded,
                        // 'rct-tree-item-li-focused': context.isFocused,
                        // 'rct-tree-item-li-dragging-over': context.isDraggingOver,
                        // 'rct-tree-item-li-search-match': context.isSearchMatching,
                    })}
                    // className={cx(
                    //     'rct-tree-item-li',
                    //     item.isFolder && 'rct-tree-item-li-isFolder',
                    //     context.isSelected && 'rct-tree-item-li-selected',
                    //     context.isExpanded && 'rct-tree-item-li-expanded',
                    //     context.isFocused && 'rct-tree-item-li-focused',
                    //     context.isDraggingOver && 'rct-tree-item-li-dragging-over',
                    //     context.isSearchMatching && 'rct-tree-item-li-search-match'
                    // )}
                    // className={'flex flex-col gap-0 bg-[white] p-0 rounded-xl border-black border-2'}
                >
                    {/*
                    Selected: {isSelected}
                    depth: {depth}
                    */}

                    {/*
                    <div
                        // className={'flex flex-row items-start gap-0 p-0'}
                        // className={'RootGroup min-w-[300px] bg-white border border-[#888] rounded-lg -drop-shadow-lg -shadow-2xl flex flex-row px-4 gap-2 justify-start items-center flex-grow'}
                        className={cx('RootGroup min-w-[300px] flex flex-row px-4 gap-2 justify-start items-center flex-grow', {
                            'bg-[#ddd]': isSelected,
                            'bg-white order border-[#888] rounded-lg drop-shadow-lg- shadow-2xl': true,
                        })}
                        {...context.itemContainerWithoutChildrenProps}
                        {...context.interactiveElementProps}
                        // className={'bg-[#f0f0f0] p-0 m-0 flex flex-row items-center gap-4'}
                        // className={'flex flex-row items-center gap-4 bg-[green]'}

                        // onClick={() => {
                        //     const treeId = info.treeId;
                        //     const itemId = 'container-' + item.id;
                        //     console.log('click item', treeId, itemId);
                        //     // toggleExpandedItem(treeId, itemId);
                        //     toggleExpandedItem(treeId, item.id);
                        // }}

                    >
                        {arrow}
                        <span className={'m-[10px] bg-[red]'}>
                            {arrow}
                        </span>
                        {title}
                    </div>
                    */}

                    {children}
                    {/*
                    <div className={'flex flex-col'}>
                        {children}
                    </div>
                    */}

                </div>
            );
        } else if (depth === 1) {
            // console.log('render item', depth, item, context);
            // first group under grade level
            return (
                <div
                    {...context.itemContainerWithChildrenProps}
                    className={cx('mx-0 flex flex-col gap-0 mb-6', {
                        'bg-white order border-[#888] rounded-lg drop-shadow-lg shadow-2xl overflow-clip': true,
                        // 'bg-[red] m-2 p-2 border-black border': isSelected,
                        'bg-[green]': isSaving,
                        // 'bg-[blue]': true,
                        // "border-2 border-[black] border-solid bg-red-300": depth === 2,
                        // "border-[1px solid green] bg-green-300":depth===2,
                        // "border-[1px solid blue] bg-blue-300":depth===3,

                        // 'rct-tree-item-li-isFolder bg-[red]': item.isFolder,
                        // 'rct-tree-item-li-selected bg-[orange]': context.isSelected,
                        // 'rct-tree-item-li-expanded': context.isExpanded,
                        // 'rct-tree-item-li-focused': context.isFocused,
                        // 'rct-tree-item-li-dragging-over': context.isDraggingOver,
                        // 'rct-tree-item-li-search-match': context.isSearchMatching,

                    })}
                    // onClick ={(event) => {
                    //     event.stopPropagation();
                    //     console.log('click item', item.id);
                    //     setSelectedGroup(item.id);
                    // }}
                >

                    <div className={cx('flex flex-col p-2 m-2', {
                        // 'border-2 border-[blue]': isSelected,
                        [selectedStyle]: isSelected,
                        // 'rct-tree-item-li-isFolder': item.isFolder,
                        // 'rct-tree-item-li-selected': context.isSelected,
                        // 'bg-[red]': context.isExpanded,
                        // 'bg-[white]': !context.isExpanded,
                        'bg-[yellow] border border-black': context.isDraggingOver,
                        // 'bg-[purple]': context.isDraggingOver,
                        // 'rct-tree-item-li-focused': context.isFocused,
                        // 'rct-tree-item-li-search-match': context.isSearchMatching,

                    })}>
                        <button
                            className={cx('flex flex-row gap-2 align-left justify-start items-center flex-grow',
                                {
                                    // "bg-[red]":depth===1,
                                    // "bg-[green]":depth===2,
                                    // "bg-[blue]":depth===3,
                                })}

                            // className={'flex flex-row px-4 gap-2 justify-start items-center flex-grow bg-[red]'}
                            {...context.itemContainerWithoutChildrenProps}
                            {...context.interactiveElementProps}
                            // className={'flex flex-row items-start gap-0 p-0'}
                            // className={'bg-[#f0f0f0] p-0 m-0 flex flex-row items-center gap-4'}
                            // className={'flex flex-row items-center gap-4 bg-[green]'}

                            // onClick={() => {
                            //     const treeId = info.treeId;
                            //     const itemId = item.id;
                            //     console.log('click item', treeId, itemId);
                            //     toggleExpandedItem(treeId, itemId);
                            // }}
                        >
                            {/*({depth})*/}
                            {arrow}
                            {title}
                        </button>

                        {item.isFolder && <div className={'mt-0 pl-4'}>
                            {children}
                        </div>}
                    </div>

                </div>
            )
        } else {
            // sub groups
            return (
                <div
                    {...context.itemContainerWithChildrenProps}
                    className={cx('mx-0 flex flex-col gap-0 p-0', {
                        // 'bg-[red] m-2 p-2 border-black border': isSelected,
                        // "border-2 border-[black] border-solid ": true,
                        [selectedStyle]: isSelected,
                        [unselectedStyle]: !isSelected,
                        // 'bg-[red]': context.isExpanded,
                        // 'bg-[white]': !context.isExpanded,
                        'bg-[yellow] border border-black': context.isDraggingOver,
                        // 'bg-[green]': isSaving,
                        // 'bg-[red]': depth === 2,
                        // 'bg-white order border-[#888] rounded-lg drop-shadow-lg- shadow-2xl': depth === 1,
                        // 'mb-10': depth === 1,
                        // "border-2 border-[black] border-solid bg-red-300": depth === 2,
                        // "border-[1px solid green] bg-green-300":depth===2,
                        // "border-[1px solid blue] bg-blue-300":depth===3,
                    })}
                >
                    <div className={cx('flex flex-row gap-2 align-left justify-start items-center flex-grow',
                        {
                            // "bg-[red]":depth===1,
                            // "bg-[green]":depth===2,
                            // "bg-[blue]":depth===3,
                        })}

                        // className={'flex flex-row px-4 gap-2 justify-start items-center flex-grow bg-[red]'}
                         {...context.itemContainerWithoutChildrenProps}
                         {...context.interactiveElementProps}
                        // className={'flex flex-row items-start gap-0 p-0'}
                        // className={'bg-[#f0f0f0] p-0 m-0 flex flex-row items-center gap-4'}
                        // className={'flex flex-row items-center gap-4 bg-[green]'}

                        // onClick={() => {
                        //     const treeId = info.treeId;
                        //     const itemId = item.id;
                        //     console.log('click item', treeId, itemId);
                        //     toggleExpandedItem(treeId, itemId);
                        // }}
                    >
                        {/*({depth})*/}
                        {arrow}
                        {title}
                    </div>

                    {(item.isFolder) && <div className={
                        cx('p-0 flex flex-col gap-0 m-0 py-1 pl-4', {
                            // 'border-2 border-[black] border-solid': true,
                            'bg-green-400': false
                        })}
                    >
                        {children}
                    </div>}

                </div>
            )
        }
    }, [renderPaletteItem]);

    const dataProvider = useMemo(
        () => {
            if (!treeData || Object.keys(treeData).length === 0) return null;
            return new StaticTreeDataProvider(treeData, (item, data) => ({
                ...item,
                data,
            }));
        }, [treeData]);


    console.log('treeEnvironment', viewState, treeData, Object.keys(treeData).length);
    if (!dataProvider || !treeData || Object.keys(treeData).length === 0) return null;
    // const uniqueId = uuidv4();

    console.log('showSettings', showSettingsPanel);

    const handleCreateNewGroup = ({ name, parentId = null }) => {
        console.log('handleCreateNewGroup', name);
        if (createNewGroup) {
            const selectedItem = treeData[selectedGroup]?.item;
            console.log('selectedItem', selectedItem);
            createNewGroup(selectedItem, name);
        }
        setOpenNewGroup(false);
    };

    return (
        <TreeStyle
            // key={uniqueId}
            className='w-full h-full bg-[transparent] flex flex-row flex-grow overflow-auto'
        >

            <UncontrolledTreeEnvironment
                ref={treeEnvironmentRef}
                defaultInteractionMode={TreeInteractionManager}
                // key={treeHash}
                key={selectedGroup ?? treeHash}
                dataProvider={dataProvider}
                items={treeData}
                viewState={viewState}
                getItemTitle={item => item?.title}

                onSelectItems={(items, treeId) => handleSelectGroup(items, treeId)}

                canDragAndDrop={true}
                canDropOnFolder={true}
                canDropOnNonFolder={false}
                canReorderItems={true}

                canDrag={(items) => {
                    if (items.length !== 1 || !items[0]) return false;
                    const item = items[0];
                    const draggableItemTypes = ['Lesson'];
                    const can = draggableItemTypes.includes(item?.itemType);
                    // console.log('can drag', item?.itemType, can);
                    return can;
                    // return items.map(item => item.data.startsWith('A')).reduce((a, b) => a && b, true)
                }}

                onDrop={(items, target) => {
                    if (items.length !== 1 || !items[0]) return false;
                    const item = items[0];
                    console.log('onDrop', item?.itemType, target);
                }}

                canDropAt={(items, target) => {

                    if (items.length !== 1 || !items[0]) return false;
                    const item = items[0];
                    // console.log('canDropAt', item?.itemType, target);
                    const { targetType, treeId } = target;

                    if (targetType === 'root') {
                        // root
                        const { targetItem, depth, linearIndex } = target;
                        console.log('can drop on root', targetItem, depth, linearIndex);
                        return false;
                    } else if (targetType === 'between-items') {
                        // between items
                        const { childIndex, depth, linePosition, linearIndex, parentItem } = target;
                        console.log('can drop between: ', linearIndex, 'on', parentItem, 'at', childIndex, linePosition, 'depth', depth);
                        // const targetItem = treeData[target.targetItem];
                        // console.log(targetItem);
                        const item = treeData[parentItem];
                        // console.log('parent', item, target);
                        return true;
                    } else if (targetType === 'item') {
                        // on item
                        const { depth, linearIndex, parentItem, targetItem } = target;
                        console.log('can drop on item', targetItem, parentItem, depth, linearIndex);
                        // get the item
                        const item = treeData[targetItem];
                        // console.log('item', item);
                        return true;
                    } else {
                        console.log('can drop on unknown', targetType)
                    }
                    // console.log(target.targetType, target.depth, );
                    return false;
                    return true;
                }}

                onDragOver={(items, target) => {
                    console.log('onDragOver', items, target);
                    // if( items.length !== 1 || !items[0]) return false;
                    // const item = items[0];
                }}
                /*

                //     const findTargetInTree = loadedTree[target.targetItem];
                //     const item = items[0];
                //     if (findTargetInTree?.itemType === 'LessonGroup') {
                //
                //         if (item.itemType === 'Lesson') {
                //             handleUpdateLessons(findTargetInTree, item)
                //         }
                //         if (item.itemType === 'LessonGroup') {
                //             handleUpdateLessonGroup(findTargetInTree, item)
                //         }
                //         if (target.targetType === 'between-items') {
                //             if (item.itemType === 'Lesson') {
                //                 handleUpdateLessons(findTargetInTree, item, target.childIndex)
                //             }
                //         }
                //     }
                //
                //
                // }}
                // canDropAt={(items, target) => {
                //     const findTargetInTree = loadedTree[target.targetItem];
                //     const findTargetParent = loadedTree[target.parentItem]
                //     const itemType = items[0].itemType;
                //     if (target.targetType === 'between-items') {
                //         if (itemType === 'Lesson' && findTargetParent?.itemType === 'Course') return false
                //         if (itemType === 'Lesson' && findTargetParent?.itemType === 'Root') return false
                //         if (itemType === 'LessonGroup' && findTargetInTree?.itemType === 'Root') return false
                //     }
                //     if (itemType === 'Lesson' && findTargetInTree?.itemType === 'Course') return false
                //     if (itemType === 'LessonGroup' && findTargetInTree?.itemType === 'Lesson') return false
                //
                //     else return true;
                //
                // }}
                // onSelectItems={(item) => onSelected(loadedTree[item[0]])}

                onExpandItem={(item, treeId) => {
                    // const treeId = info.treeId;
                    // const itemId = 'container-' + item.id;
                    const itemId = item.id;
                    console.log('click item', treeId, itemId);
                    // toggleExpandedItem(treeId, itemId);
                    toggleExpandedItem(treeId, itemId);
                }}
                // onExpandItem={item => setExpandedItems([...expandedItems, item.index])}
                */

                renderItemArrow={renderItemArrow}
                renderTreeContainer={renderTreeContainer}
                renderItemTitle={renderItemTitle}
                renderItem={renderItem}
                renderItemsContainer={renderItemsContainer}
            >
                <div className={'w-full h-full overflow-hidden flex items-stretch flex-row bg-[transparent] gap-0'}>

                    <div className={'flex-shrink-1 h-full flex-grow flex flex-row bg-[transparent] gap-4 px-6 overflow-auto'}>

                        {rootGroups.map((group, index) => {
                            return (
                                <div key={group.id} className={'bg-[transparent] mx-auto max-w-[600px] min-w-[400px] w-full'}>
                                    <div className={'mt-4 flex-grow'}>

                                        <div className={'w-full'}>
                                            <div className={'select-none relative flex flex-row bg-[#eeeeee] px-4 py-2 flex-grow items-center justify-between'}>
                                                <div className={'text-lg font-bold'}>
                                                    {group.title}
                                                </div>

                                                <div className={cx(' top-0 right-0 bottom-0 flex justify-center item-center', {
                                                    // 'pl-2 py-4 text-xl font-bold': isParent,
                                                    // 'text-left': isContainer,
                                                    // 'text-left text-lg': isGroup,
                                                    // 'bg-orange-400': item.depth === 0,
                                                    // 'bg-blue-400': item.depth === 1,
                                                    // 'bg-green-400': item.depth === 2,
                                                    // 'bg-yellow-400': item.depth === 3,
                                                    // 'text-sm': depth === 1,
                                                })}>
                                                    <button
                                                        className={'px-1 bg-[transparent] border-none text-[#aaa] hover:text-[#666] active:text-[#333]'}
                                                        size={'small'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleAddGroup(group)
                                                        }}
                                                    >
                                                        + Group
                                                    </button>
                                                    <button
                                                        className={'px-2 bg-[transparent] border-none text-[#aaa] hover:text-[#666] active:text-[#333]'}
                                                        size={'small'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleAddVideo(group)
                                                        }}
                                                    >
                                                        + Video
                                                    </button>
                                                    <button
                                                        className={'px-1 bg-[transparent] border-none text-[#aaa] hover:text-[#666] active:text-[#333]'}
                                                        size={'small'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleAddGroup(group)
                                                        }}
                                                    >
                                                        Edit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>


                                        <Tree
                                            // className={'w-full h-full bg-[orange]'}
                                            treeId={group.id}
                                            rootItem={'container-' + group.id}
                                            treeLabel={group.title}
                                        />
                                    </div>
                                </div>
                            );
                        })}

                        {/*
                        <div className={' h-full w-full overflow-auto bg-white border border-[black] flex-grow'}>
                            <div className={'bg-[orange] flex-grow p-2 text-lg'}>
                                {group.title}
                            </div>
                        </div>
                        */}

                    </div>
{/*
                    <div className={cx('Sidebar w-full max-w-[400px] h-full flex-grow overflow-x-hidden overflow-y-auto flex-row bg-[white]', {
                        'flex': showSettingsPanel,
                        'hidden': !showSettingsPanel,
                    })}>
                        settings
                    </div>

                    {showVideosPanel && <div className={'LessonPallet w-full max-w-[400px] h-full flex-grow overflow-x-hidden overflow-y-auto flex flex-row bg-[white]'}>

                        <Tree
                            treeId='lesson-palette'
                            rootItem='lesson-palette'
                            treeLabel='Lesson Palette'
                        />

                    </div>}*/}
                </div>

            </UncontrolledTreeEnvironment>

            <NewGroupDialog open={openNewGroup} onClose={() => setOpenNewGroup(false)} onSuccess={handleCreateNewGroup} />

        </TreeStyle>
    );
};

export default LessonGroupTree;
