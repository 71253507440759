import React from 'react';
import { Button, Grid, Stack } from "@mui/material";
import InputText from "../../Quizzes/QuizEditor/FormControls";
import ThumbnailImage from "../ThumbnailImage";

const ThumbnailPanel = ({ video, updateVideo }) => {
    return (
        <Stack spacing={2}>
            {/*
            <img src={video.url_thumbnail} alt="video thumbnail" width={'100%'} height={'auto'} />
            */}
            <ThumbnailImage video={video} updateVideo={updateVideo} />

            {video.url_thumbnail && <a href={video.url_thumbnail} target="_blank" rel="noreferrer">Link</a>}
            {/*<InputText value={video.url_thumbnail} label={'Thumbnail URL'} />*/}
        </Stack>
    )
    // return (
    //     <Grid container spacing={2}>
    //         <Grid item xs={12} spacing={2}>
    //             <img src={video.url_thumbnail} alt="video thumbnail" width={'100%'} height={'auto'} />
    //         </Grid>
    //         <Grid item xs={12}>
    //             <Button size="small" color="primary">
    //                 Upload
    //             </Button>
    //         </Grid>
    //     </Grid>
    // );
};


export default ThumbnailPanel;
