import React, { useMemo } from 'react';
import { useGetAPI } from "../../context/api";
import { Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";

const ImagesContainer = (props) => {
    const { loading, error, data } = useGetAPI('/api/questions/');

    console.log('data', data, error, loading);
    const images = useMemo(() => {
        if (data) {
            console.log('data', data);
            return data.reduce((acc, question) => {
                // return quiz.questions.reduce((acc, question) => {
                if (question.image_url)
                    acc = [...acc, question.image_url];
                if (question.choices) {
                    return question.choices.reduce((acc, choice) => {
                        if (choice.content_image)
                            return [...acc, choice.choice_text];
                        return acc;
                    }, acc);
                }
                return acc;
                // }, acc);
                // console.log(quiz);
                // return acc.concat(quiz.questions.reduce((acc, question) => {
                //     if (question.image_url)
                //         return [...acc, question.image_url];
                //     return acc;
                // }, []));
            }, []);
        }
        return [];
    }, [data]);
    console.log(images);

    return (
        <div>
            {loading && <div>Loading...</div>}
            <Grid container>
                {images.map( (image,index) => (
                    <Grid item xs={1}>
                        <img src={image} />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};


export default ImagesContainer;
