import React from 'react';
import { MathEditor } from "./MathEditor";
// import 'mathlive/dist/mathlive-fonts.css';
// import { MathfieldComponent } from "react-mathlive";

const MathLive = (props) => {
    const [latex, setLatex] = React.useState("");
    return (
        <div className={''}>
            <MathEditor
                value={latex}
                onChange={setLatex}
                containerStyle={{ border: '4px solid red' }}
                className={'block'}
            />
            <div>{latex}</div>
            {/*<MathfieldComponent initialLatex={latex} onChange={setLatex} />*/}
        </div>
    );
};


export default MathLive;
