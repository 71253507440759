import React from 'react';
import tw, { css, styled } from 'twin.macro';
// import { QUESTION_TYPE_MULTIPLE_CHOICE, QUESTION_TYPE_MULTIPLE_SELECT } from 'modules/quiz/constants';
// import { QUIZ_SHOW_AUDIO_CLIPS, QUIZ_SHOW_CORRECT_ANSWER } from 'features';
import Choice from './Choice';
import ChoiceButton from './ChoiceButton';
// import { choiceStateClassNames } from './constants';

// import AudioButton from 'components/AudioButton/AudioButton';
// import { getQuizAudioURL } from 'app/model/muzology';
import CheckmarkIcon from '../../assets/icons/Checkmark.js';
import white_check from './white_check.png';
import { QUESTION_TYPE_MULTIPLE_CHOICE, QUESTION_TYPE_MULTIPLE_SELECTION } from "../../Studio/Quizzes/QuizEditor/constants";
import AudioButton from "../AudioButton/AudioButton";

const QUIZ_SHOW_AUDIO_CLIPS = true;
const QUIZ_SHOW_CORRECT_ANSWER= true;

const ChoiceItemStyle = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 100%;

    .grid-item-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 46px;
        //font-family: KaTeX_Main, serif;
        text-shadow: none;

        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        //font-weight: 400;
        //font-size: 22px;
        //line-height: normal;
        /* bold-gray */
        color: #515151;

        @media only screen and (min-width: 500px) {
            font-size: 20px;
        }
    }

    .decorator {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        flex-shrink: 0;
        flex-grow: 0;

        &.check-box {
            height: 44px;
            width: 44px;
            margin-right: 20px;

            display: flex;
            justify-content: center;
            align-items: center;

            .checkmark {
                color: white;
                display: none;
            }
        }

        &.radio-button {
            height: 44px;
            width: 44px;
            margin-right: 20px;
            
            //border: 1px solid #dedede;
            border-radius: 50%;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        // hide the checkmark by default
        .checkmark {
            font-size: 24px;
            color: white;
            display: none;
        }

        .wrong {
            color: white;
            display: none;
        }
    }

    &.selected,
    &.correct {
        .checkmark {
            display: block !important;
        }
    }

    &.incorrect {
        .wrong {
            display: block !important;
        }
    }

    .choice-button {
        position: relative;
        cursor: pointer;
        border-radius: 2px;
        padding: 5px;
        width: 100%;
        height: 100%;
        max-width: 702px;
        line-height: normal;
        //font-family: KaTeX_Main, Avenir, sans-serif;
        //font-size: 22px;
        //font-weight: 400;
        color: #515151;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        text-align: left;

        &.correct-outline {
            border: 1px solid green;
        }
    }

    /*
        TODO: we need to re-enable some sort of focus outline
     */

    &:not(.disabled) &:not(.answer-key-correct) {
        .choice-button {
            &:hover {
                background-color: #F1F0FB;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.26);
            }

            &:active {
                background-color: #E3E2F7;
                //background-color: #bbbbbb;
                //padding: 3px;
                //border-width: 1px;
                box-shadow: none;
            }
        }

        .decorator {
            &:focus {
                outline: none;
            }
        }
    }

    // choice item states
    &.correct {
        .decorator.radio-button,
        .decorator.check-box,
        .choice-button,
        .choice-audio-button {
            background-color: #38db96;
            color: white;
        }
    }

    &.incorrect {
        .decorator.radio-button,
        .decorator.check-box,
        .choice-button,
        .choice-audio-button {
            background-color: #f05561;
            color: white;
        }
    }

    &.selected {
        .decorator.radio-button,
        .decorator.check-box,
        .choice-button,
        .choice-audio-button {
                /* background: ${(props) => props.choice_color}; */
            background: linear-gradient(93.96deg, #867ef9 19.73%, #f059bb 73.17%);
            color: white;
            //border: solid 1px #cbcbcb;
        }
    }

    &.normal {
        .decorator.radio-button,
        .decorator.check-box,
        .choice-button {
            background-color: #ffffff;
            //border: solid 1px #dedede;
        }
    }

    &.correct-outline {
        .decorator.radio-button,
        .decorator.check-box,
        .choice-button {
            position: relative;
            border: 2px solid green;
        }
    }

    &.answer-key {
        .decorator.radio-button,
        .decorator.check-box,
        .choice-button {
            cursor: default;
            background: white;
            color: #515151;
        }
    }

    &.answer-key-correct {
        .decorator.radio-button,
        .decorator.check-box,
        .choice-button,
        .choice-audio-button {
            cursor: default;
            background-color: #38db96;
            color: white;

        }
    }

`;


export const getQuizAudioURL = (id) => `https://public.muzology.com/quiz-audio/${id}.mp3`;


const ChoiceItem = ({ choice, index, buttonState, enableAnswerButtons, onClickChoice, question, choiceColor, className, audioButtonClicked, answerKey }) => {
    let buttonStateClass = 'normal';
    let state = 0;
    // let correctChoices = question.choices.filter((choice) => choice.correct === true);

    if (buttonState && index < buttonState.length) {
        state = buttonState[index];
        // buttonStateClass = choiceStateClassNames[state];
    }

    let buttonClassName = 'grid-item-content choice-button ';
    let decoratorButtonClass = 'decorator ';
    let itemClassName = `choice-item ${className} ${buttonStateClass}`;

    if (!enableAnswerButtons) {
        itemClassName += ' disabled ';
        buttonClassName += ' disabled';
    }

    // DEBUG
    if (QUIZ_SHOW_CORRECT_ANSWER && choice?.correct) {
        itemClassName += ' correct-outline ';
    }

    // Answer Key
    if (answerKey) {
        if (choice?.correct)
            itemClassName = 'answer-key-correct';
        else
            itemClassName = 'answer-key';
    }

    const handleAudioButtonClick = (choice) => {
        console.log('handleAudioButtonClick', choice);
        if (audioButtonClicked)
            audioButtonClicked(choice);
    };

    // choice.audioSample
    // console.log('choice', choice);
    const audioButton =
        QUIZ_SHOW_AUDIO_CLIPS && choice.id ? (
            <AudioButton
                tabIndex={10}
                // ref={(node) => this.choiceAudio[index] = node}
                // className='choice-audio-button'
                onClick={() => (audioButtonClicked ? audioButtonClicked(choice) : null)}
                audioSample={getQuizAudioURL(choice.id)}
                // left='-12px'
                // tw='hidden'
            />
        ) : null;

    let decorator = null;

    if (question.type === QUESTION_TYPE_MULTIPLE_CHOICE) {
        decoratorButtonClass += ' radio-button ';
        decorator = (
            <div className={decoratorButtonClass} disabled={!enableAnswerButtons} onClick={() => onClickChoice(index)}>
                <CheckmarkIcon className='checkmark' />
                <svg
                    className='wrong' width='19' height='19' viewBox='0 0 19 19'
                    fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M11.5569 9.50011L17.8277 3.24386C18.1023 2.96925 18.2566 2.5968 18.2566 2.20844C18.2566 1.82008 18.1023 1.44763 17.8277 1.17302C17.5531 0.898415 17.1807 0.744141 16.7923 0.744141C16.404 0.744141 16.0315 0.898415 15.7569 1.17302L9.50064 7.44386L3.24439 1.17302C2.96978 0.898415 2.59733 0.744141 2.20898 0.744141C1.82062 0.744141 1.44817 0.898415 1.17356 1.17302C0.89895 1.44763 0.744676 1.82008 0.744676 2.20844C0.744676 2.5968 0.89895 2.96925 1.17356 3.24386L7.44439 9.50011L1.17356 15.7564C1.03687 15.8919 0.928381 16.0532 0.854344 16.2309C0.780306 16.4086 0.742188 16.5993 0.742188 16.7918C0.742188 16.9843 0.780306 17.1749 0.854344 17.3526C0.928381 17.5303 1.03687 17.6916 1.17356 17.8272C1.30913 17.9639 1.47042 18.0724 1.64814 18.1464C1.82585 18.2204 2.01646 18.2586 2.20898 18.2586C2.40149 18.2586 2.59211 18.2204 2.76982 18.1464C2.94753 18.0724 3.10882 17.9639 3.24439 17.8272L9.50064 11.5564L15.7569 17.8272C15.8925 17.9639 16.0538 18.0724 16.2315 18.1464C16.4092 18.2204 16.5998 18.2586 16.7923 18.2586C16.9848 18.2586 17.1754 18.2204 17.3532 18.1464C17.5309 18.0724 17.6922 17.9639 17.8277 17.8272C17.9644 17.6916 18.0729 17.5303 18.1469 17.3526C18.221 17.1749 18.2591 16.9843 18.2591 16.7918C18.2591 16.5993 18.221 16.4086 18.1469 16.2309C18.0729 16.0532 17.9644 15.8919 17.8277 15.7564L11.5569 9.50011Z'
                        fill='white'
                    />
                </svg>
            </div>
        );
    } else if (question.type === QUESTION_TYPE_MULTIPLE_SELECTION) {
        decoratorButtonClass += ' check-box ';
        decorator = (
            <div className={decoratorButtonClass} disabled={!enableAnswerButtons} onClick={() => onClickChoice(index)}>
                <img src={white_check} className='checkmark' alt='checkmark' />
                <svg
                    className='wrong' width='19' height='19' viewBox='0 0 19 19'
                    fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M11.5569 9.50011L17.8277 3.24386C18.1023 2.96925 18.2566 2.5968 18.2566 2.20844C18.2566 1.82008 18.1023 1.44763 17.8277 1.17302C17.5531 0.898415 17.1807 0.744141 16.7923 0.744141C16.404 0.744141 16.0315 0.898415 15.7569 1.17302L9.50064 7.44386L3.24439 1.17302C2.96978 0.898415 2.59733 0.744141 2.20898 0.744141C1.82062 0.744141 1.44817 0.898415 1.17356 1.17302C0.89895 1.44763 0.744676 1.82008 0.744676 2.20844C0.744676 2.5968 0.89895 2.96925 1.17356 3.24386L7.44439 9.50011L1.17356 15.7564C1.03687 15.8919 0.928381 16.0532 0.854344 16.2309C0.780306 16.4086 0.742188 16.5993 0.742188 16.7918C0.742188 16.9843 0.780306 17.1749 0.854344 17.3526C0.928381 17.5303 1.03687 17.6916 1.17356 17.8272C1.30913 17.9639 1.47042 18.0724 1.64814 18.1464C1.82585 18.2204 2.01646 18.2586 2.20898 18.2586C2.40149 18.2586 2.59211 18.2204 2.76982 18.1464C2.94753 18.0724 3.10882 17.9639 3.24439 17.8272L9.50064 11.5564L15.7569 17.8272C15.8925 17.9639 16.0538 18.0724 16.2315 18.1464C16.4092 18.2204 16.5998 18.2586 16.7923 18.2586C16.9848 18.2586 17.1754 18.2204 17.3532 18.1464C17.5309 18.0724 17.6922 17.9639 17.8277 17.8272C17.9644 17.6916 18.0729 17.5303 18.1469 17.3526C18.221 17.1749 18.2591 16.9843 18.2591 16.7918C18.2591 16.5993 18.221 16.4086 18.1469 16.2309C18.0729 16.0532 17.9644 15.8919 17.8277 15.7564L11.5569 9.50011Z'
                        fill='white'
                    />
                </svg>
            </div>
        );
    }

    // construct a choice button

    return (
        <div className='relative flex flex-row items-center justify-stretch'>
            <ChoiceItemStyle
                tw='h-full'
                choice_color={choiceColor}
                className={itemClassName}
            >
                {decorator}

                <ChoiceButton
                    key={choice.id}
                    tabIndex={1}
                    className={buttonClassName}
                    disabled={!enableAnswerButtons}
                    onClick={() => onClickChoice(index)}
                    data-choice-id={choice.id}
                >
                    <Choice question={question} choice={choice} />
                </ChoiceButton>

                <div tw='w-[44px] h-[44px] ml-[20px] flex items-center justify-start'>
                    {!choice.content_image && <div tw='zIndex[100]'>
                        {audioButton}
                    </div>}
                </div>

            </ChoiceItemStyle>
        </div>
    );
};

export default ChoiceItem;
