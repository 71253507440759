import React, { useMemo } from 'react';
import tw, { styled, css } from 'twin.macro';
import { MUZOLOGY_APP_URL } from "../../../config";
import Box from "@mui/material/Box";

const objectToBase64 = (obj) => {
    if (!obj) return null;
    const json = JSON.stringify(obj);
    const encoded = btoa(json);
    console.log('obj -> encoded:', encoded);
    return encoded;
}

const base64ToObject = (encoded) => {
    if (!encoded) return null;
    console.log('encoded -> obj:', encoded);
    const json = atob(encoded);
    const obj = JSON.parse(json);
    return obj;
}

function encodeDictionaryAsQueryParam(dictionary) {
    // Convert the dictionary to a JSON string
    const jsonString = JSON.stringify(dictionary);
    console.debug(jsonString);

    // Create a Base64 string from the JSON string
    const encoded = decodeURIComponent(encodeURIComponent(jsonString));
    if (encoded != jsonString) {
        console.warn('encoded != jsonString');
        console.debug(encoded);
    }

    const base64String = btoa(encoded);
    console.debug(base64String);

    // Return the Base64 string in a URL-friendly format
    return encodeURIComponent(base64String);
}

const QuizPreviewClient = ({ show, quizId, questionId, quiz, question, className, showLinks }) => {

    const queryString = useMemo(() => {
        let query = '';
        if (questionId)
            query += `question_id=${questionId}&`;
        if (quizId)
            query += `quiz_id=${quizId}&`;
        if (quiz) {
            const quizEncoded = encodeDictionaryAsQueryParam(quiz);
            query += `quiz=${quizEncoded}&`;
        }
        if (question) {
            const questionEncoded = encodeDictionaryAsQueryParam(question);
            query += `question=${questionEncoded}&`;
        }
        return query;
    }, [question, questionId, quiz, quizId]);

    // const questionEncoded = useMemo(() => {
    //     try {
    //         const previewData = JSON.stringify(question);
    //         console.log(previewData);
    //         const questionEncoded = btoa(previewData)
    //         return questionEncoded;
    //     } catch (error) {
    //         console.log(error);
    //         return null;
    //     }
    // }, [question]);

    const previewUrl = `${MUZOLOGY_APP_URL}preview/${quiz?.id ?? (quizId ?? 'none')}/question/${question?.id ?? (questionId ?? 'none')}/`;
    return (
        <div
            className={`mt-2 ${show ? 'visible' : 'invisible absolute'} ${className ?? ''}`}

            // className={'visibility-hidden- hidden- invisible'}
            // className={'invisible'}
            // tw={'invisible'}
            // css={show && tw`visible`}
            // css={[
            //     css`
            //         background-color: #000000;
            //     `,
            //     tw`bg-blue-700`,
            //     // tw`bg-blue-700`,
            //     // css`background-color: #000000;`,
            //     // show ? css`visibility: visible;` : css`visibility: hidden;`,
            //     // show && tw`visible`,
            // ]}
        >
            <div key={quiz?.id ?? 'unloaded'} className={' gap-2 flex flex-row min-h-[800px] w-full items-stretch'}>
                <div key={question?.id ?? 'loading'} className={'flex flex-row items-center bg-blue-700 flex-grow min-h-[400px]'}>
                    <iframe
                        title={'Muzology Quiz Preview'}
                        // src={`${MUZOLOGY_APP_URL}preview/${quiz?.id ?? (quizId ?? 'none')}/question/${question?.id ?? (questionId ?? 'none')}/${queryString}`}
                        src={`${previewUrl}?${queryString}`}
                        width={'100%'}
                        height={'100%'}
                    />
                </div>
            </div>
            <p className={showLinks ? 'block' : 'hidden'}>
                PreviewURL: <a href={previewUrl}>{previewUrl}</a>
            </p>
        </div>
    );
};


export default QuizPreviewClient;
