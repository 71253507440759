import React from 'react';
import { Stack, TextField, Typography } from "@mui/material";
import FormContainer from "../../../Components/Form/FormContainer";
import { useForm } from "react-hook-form";

const AccountInfoForm = ({ variant }) => {
    console.log(variant);
    const { register, handleSubmit, trigger, formState: { errors }, control } = useForm();
    return (
        <Stack spacing={2}>
            <Typography variant={'h6'}>
                {variant.label}
            </Typography>
            <FormContainer elevation={0}>
                <TextField autoFocus fullWidth label={"Name"} {...register("name", { required: true })} />
            </FormContainer>
        </Stack>
    );
};

// onChange={onChange}
// value={value}
// {...register("title", { required: true })}

export default AccountInfoForm;
