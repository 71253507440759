import apiClient from "../../../context/APIClient";

export const convertToMath = (text) => {
    // call the server
    const prompt = `You are a converter from textual / english representations of math to katex/latex representation.\n` +
        `You will be given text representation of a math equation and you will convert it to katex/latex.\n` +
        `Do not output anything but the katex/latex math. Do not wrap the answer in $'s.\n` +
        'Text should be wrapped in \\text{...}.\n' +
        `For example, if the string is: "1/5" then you would output: "\frac{1}{5}" \n\n` +
        `Do not output anything but the katex for the given input string\n\n` +
        `Input: '${text}'\n` +
        `Output: `;

    // setLoading(true);
    return apiClient.post('/chatgpt/', { 'prompt': prompt })
        .then(response => {
            const { data } = response;
            let output = data.choices[0].message?.content;

            // if the content is wrapped in ' ', remove them
            if (output.startsWith("'") && output.endsWith("'")) {
                output = output.substring(1, output.length - 1);
            }

            console.log("handleConvertToMath", output);
            // setMath(output);
            // setLoading(false);
            return output;
        })
        .catch(error => {
            console.log(error);
            // setLoading(false);
        });
};
