import React from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import apiClient from "../../../context/APIClient";
import { useItemEditor } from "../../StudioContainer/StudioContainer";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField } from "@mui/material";
import DropdownFilter from "../../../Admin/Accounts/DropdownFilter";

const CourseEditor = ({ course, onUpdate }) => {
    console.log('course', course);
    const { register, handleSubmit, trigger, formState: { errors }, control } = useForm(
        {
            defaultValues: {
                name: course?.title,
                // parent: course.course_type.parent,
                // teacher: course.course_type.teacher,
            }
        }
    );

    const onSubmit = data => {
        console.log("🚀 ~ file: AddCourseDialog.js:17 ~ onSubmit ~ data:", data, volume)
        const payload = {
            title: data.name,
            volume_id: volume,
            course_type: { parent: data.parent, teacher: data.teacher }
        }
        apiClient.patch(`/api/admin/courses/${course.id}/`, payload)
            .then((response) => {
                console.log(response);
                const { data } = response;
                // navigate(`/studio/course/${data.id}`)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const showError = (error, text = null) => {
        if (error) {
            // console.log(error);
            let message = text;
            if (!message) {
                if (error.type === 'required') message = 'This field is required';
                else message = error.message;
            }

            return (
                <div className='error-container text-red-500'>
                    <p className='error-tag'>{`* ${message}`}</p>
                </div>
            );
        }
        return null;
    };

    const { volumes} = useItemEditor();
    const volumeList = volumes?.map(volume => [volume.id, volume.title]) ?? [];
    const [volume, setVolume] = React.useState(course?.volume_id);

    console.log('volumeList',volumeList);
    console.log('volume', volume);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Course Library Settings</DialogTitle>
            <DialogContent>

                <DialogContentText>
                    <Stack spacing={3} sx={{ paddingTop: '10px' }}>
                        <TextField
                            autoFocus
                            fullWidth
                            // onChange={onChange}
                            // value={value}
                            label={"Name"}
                            {...register("name", { required: true })}
                        />
                        {showError(errors.name)}
                    </Stack>
                </DialogContentText>

                <DialogContentText>
                    <div className='mt-6 text-black'>Volume:</div>

                    <DropdownFilter
                        className={'w-full m-0'}
                        value={volume}
                        // label={'Volume'}
                        items={volumeList}
                        onChange={setVolume}
                    />

                </DialogContentText>
                <DialogContentText>
                    <div className='mt-6 text-black'>Account type:</div>
                    <div className='flex'>
                        <label className='flex mr-4'>
                            <input
                                type='checkbox'
                                {...register("teacher", { required: false })}
                            />
                            <div className='text-black ml-2'>Teacher</div>

                        </label>
                        <label className='flex'>
                            <input
                                type='checkbox'
                                {...register("parent", { required: false })}
                            />
                            <div className='text-black ml-2'>Parent</div>
                        </label>
                    </div>
                </DialogContentText>

            </DialogContent>
            <DialogActions>
                <Button type="button" color="primary" variant="outlined" size="small" onClick={null}>
                    Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" size="small" onClick={() => trigger()}>
                    Save
                </Button>
            </DialogActions>
        </form>
    );
};


export default CourseEditor;
