import React from 'react';
import tw, { css, styled } from 'twin.macro';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const ShareDialog = ({ open, setOpen, quiz }) => {
    const title = "Share Quiz";
    // const share_id = uuencode.encode(quiz.id);
    const share_id = quiz.id;
    const url = `https://studio.muzology.com/share/${quiz.id}`;
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">
                {title}
            </DialogTitle>
            <DialogContent>
                <div className={'flex flex-col gap-4 select-none'}>
                    <div className={'flex flex-col gap-4'}>
                        <div className={'text-lg'}>
                            Share this quiz with this link:
                        </div>
                        <div className={'text-lg select-all'}>
                            {url}
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => setOpen(false)}
                    color="primary"
                >
                    No
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpen(false);
                        // onConfirm();
                    }}
                    color="error"
                >
                    Yes
                </Button>
            </DialogActions>

        </Dialog>
    );
};

export default ShareDialog;
