import * as React from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import DeleteIcon from '@mui/icons-material/Delete';
import Label from '@mui/icons-material/Label';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InfoIcon from '@mui/icons-material/Info';
import ForumIcon from '@mui/icons-material/Forum';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import HelpSharpIcon from '@mui/icons-material/HelpSharp';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import VideoLibrarySharpIcon from '@mui/icons-material/VideoLibrarySharp';
import LibraryBooksSharpIcon from '@mui/icons-material/LibraryBooksSharp';

import IconButton from '@material-ui/core/IconButton';
import { useCallback, useMemo, useState } from "react";
import { Stack } from "@mui/material";

// const useStyles = makeStyles({
//     iconButton: {
//         "&:hover": {
//             backgroundColor: "transparent" // disable the default button hover effect
//         }
//     }
// });

function CustomIconButton(props) {
    // const classes = useStyles();
    return (
        <IconButton
            {...props}
            onClick={(e) => {
                e.stopPropagation(); // stop the click event from propagating to the parent TreeViewItem
                props.onClick && props.onClick(e);
            }}
            // className={classes.iconButton}
        />
    );
}


const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.primary,
    // color: theme.palette.text.secondary,
    // [`& .${treeItemClasses.content}`]: {
    //     color: theme.palette.text.secondary,
    //     borderTopRightRadius: theme.spacing(2),
    //     borderBottomRightRadius: theme.spacing(2),
    //     paddingRight: theme.spacing(1),
    //     fontWeight: theme.typography.fontWeightMedium,
    //     '&.Mui-expanded': {
    //         fontWeight: theme.typography.fontWeightRegular,
    //     },
    //     '&:hover': {
    //         backgroundColor: theme.palette.action.hover,
    //     },
    //     '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
    //         backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
    //         color: 'var(--tree-view-color)',
    //     },
    //     [`& .${treeItemClasses.label}`]: {
    //         fontWeight: 'inherit',
    //         color: 'inherit',
    //     },
    // },
    // [`& .${treeItemClasses.group}`]: {
    //     marginLeft: 0,
    //     [`& .${treeItemClasses.content}`]: {
    //         paddingLeft: theme.spacing(2),
    //     },
    // },
}));

function StyledTreeItem(props) {
    const {
        nodeId,
        nodeType,
        bgColor,
        color,
        labelIcon,
        labelInfo,
        labelText,
        label,
        onNodeDoubleClick,
        ...other
    } = props;

    const handleClick = (e) => {
        // e.stopPropagation();
        console.log(e.target);
        console.log('clicked', nodeType, nodeId);
        // check if this node is expanded or not

    }

    const handleDoubleClick = (e) => {
        console.log('double-clicked', nodeId);
        e.stopPropagation();
        if (onNodeDoubleClick) {
            onNodeDoubleClick(e, nodeId);
        }
        //     console.log('double click');
        //     // expand or collapse the node when double clicked
        //     if (props.onNodeToggle) {
        //         props.onNodeToggle(e, props.nodeId);
        //     }
    }
    const defaultCollapseIcon = (<ExpandMoreIcon />);
    const defaultExpandIcon = (<ChevronRightIcon />);
    const LabelIcon = labelIcon || ExpandMoreIcon;

    // console.log('icon', LabelIcon);
    // // iconCollapse={<CustomIconButton><ExpandMoreIcon /></CustomIconButton>}
    // // iconExpand={<CustomIconButton><ChevronRightIcon /></CustomIconButton>}
    return (
        <StyledTreeItemRoot
            expandIcon={defaultExpandIcon}
            collapseIcon={defaultCollapseIcon}
            nodeId={nodeId}
            // label={'shit'}
            label={
                <Stack direction={'row'}>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', pr: '10px', my: '4px', mr: '10px' }}
                        onDoubleClick={handleDoubleClick}
                    >
                        <Box
                            // onClick={handleClick}
                            // onDoubleClick={handleDoubleClick}
                            component={LabelIcon}
                            color="inherit"
                            sx={{
                                // mr: 1,
                                width: '32px',
                                height: '32px',
                            }}
                        />
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'inherit',
                                fontSize: '18px',
                                flexGrow: 1
                            }}
                            // onClick={handleClick}
                        >
                            {label}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            {labelInfo}
                        </Typography>
                    </Box>
                </Stack>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            {...other}
        />
    );
}

export const ItemTree = ({ root, roots, className, onSelected }) => {
    // console.log(root);
    // const [expanded, setExpanded] = useState([]);
    //
    // const onNodeDoubleClick = (event, nodeId) => {
    //     console.log('toggle expand', nodeId);
    //     event.preventDefault();
    //     setExpanded((prevExpanded) =>
    //         prevExpanded.includes(nodeId) ? prevExpanded.filter((id) => id !== nodeId) : [...prevExpanded, nodeId]
    //     );
    // };


    // const renderTree = (node) => (
    //     <TreeItem key={node.id} nodeId={node.id} label={node.title ?? node.name}>
    //         {Array.isArray(node.children)
    //             ? node.children.map((node) => renderTree(node))
    //             : null}
    //     </TreeItem>
    // );

    // selected={selected}

    // render the tree
    const tree = useMemo(() => {
        const renderTree = (node) => {
            if (!node || !node.id) return null;
            return (
                <StyledTreeItem
                    key={node.id}
                    nodeId={node.id}
                    label={node.title ?? node.name}
                    // icon={node.icon ? <node.icon /> : <HelpSharpIcon />}
                    labelText={node.title ?? node.name}
                    labelIcon={node.icon ?? HelpSharpIcon}
                    // onNodeDoubleClick={onNodeDoubleClick}
                    // labelInfo="90"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    sx={{
                        fontSize: '26px',
                    }}
                >
                    {Array.isArray(node.children) ? node.children.map((node) => renderTree(node)) : null}
                </StyledTreeItem>
            )
        };
        return renderTree(root);
    }, [root]);

    const findNode = useCallback((nodeId) => {
        const findNode = (node, id) => {
            if (!node || !node.id) return null;
            if (node.id === id) return node;
            if (node.children) {
                for (let i = 0; i < node.children.length; i++) {
                    const found = findNode(node.children[i], id);
                    if (found) return found;
                }
            }
            return null;
        }
        return findNode(root, nodeId);
    }, [root]);

    const [selected, setSelected] = useState(null);
    const [expanded, setExpanded] = useState([]);

    const onNodeSelect = (event, nodeId) => {
        // find the node
        // console.log('find node', nodeIds, 'root', root);
        // console.log('Clicked node:', node?.itemType, node.id);
        // event.stopPropagation();

        // // console.log(nodeId);

        if (selected === nodeId) {
            setExpanded((prevExpanded) =>
                prevExpanded.includes(nodeId) ? prevExpanded.filter((id) => id !== nodeId) : [...prevExpanded, nodeId]
            );
        } else {
            // If the clicked node is not selected, select it without expanding
            setSelected(nodeId);

            if (onSelected) {
                const node = findNode(nodeId);
                if (node) {
                    onSelected(node);
                }
            }
        }
    };

    return (
        <div className={'flex flex-col p-4 ' + className}>
            <TreeView
                expanded={expanded}
                selected={selected}
                onNodeSelect={onNodeSelect}
                // onNodeDoubleClick={handleDoubleClick}
                // aria-label="rich object"
                // defaultExpanded={['root']}
                defaultExpanded={[root?.id]}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{
                    // height: 110,
                    flexGrow: 1,
                    // maxWidth: 400,
                    overflowY: 'auto'
                }}

                aria-label="tree"
                // defaultExpanded={['3']}
                // defaultCollapseIcon={<ArrowDropDownIcon />}
                // defaultExpandIcon={<ArrowRightIcon />}
                // iconCollapse={<CustomIconButton><ExpandMoreIcon /></CustomIconButton>}
                // iconExpand={<CustomIconButton><ChevronRightIcon /></CustomIconButton>}
                // defaultEndIcon={<div style={{ width: 24 }} />}
                // sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
            >
                {tree}
            </TreeView>
        </div>
    );
}

export default ItemTree;