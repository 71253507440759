import React, { useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useDevTool } from "../context/DevTool";
import { useGetAPI } from "../context/api";
import useAxios from "axios-hooks";
import axios from "axios";
import ProjectWorkspaceProvider from "./ProjectWorkspaceProvider";

export const SET_PROJECT = 'SET_PROJECT';
export const ADD_LAYER = 'ADD_LAYER';
export const UPDATE_LAYER = 'UPDATE_LAYER';

const StoreContext = React.createContext();
const DispatchContext = React.createContext();

const initialState = {
    // items: {},
};


const reducer = (state, action) => {
    switch (action.type) {
        case SET_PROJECT:
            // console.log('set project:', action.payload);
            return action.payload;

        case ADD_LAYER:
            return {
                ...state,
                data: {
                    ...state.data,
                    layers: [...state.data?.layers ?? [], action.payload]
                }
            }

        case UPDATE_LAYER:
            return {
                ...state,
                data: {
                    ...state.data,
                    layers: state.data?.layers?.map(layer => layer.id === action.payload.id ? action.payload : layer)
                }
            }

        default:
            return state;
    }
};

/*
const projectReducer = (state, action) => {
    switch (action.type) {
        case SET_PROJECT:
            // console.log('set song:', action.payload);
            return action.payload;

        case UPDATE_PROJECT:
            // console.log('update song:', action.payload);
            return { ...state, ...action.payload };

        case SET_NAME:
            // console.log('set name:', action.payload);
            // if( action.payload === null || action.payload === "" || action.payload === undefined )
            //     return state;
            return { ...state, name: action.payload };

        case SET_SECTION_SYNC_CHORUS: {
            // console.log('update song section sync:', action.payload);
            const { sectionIndex, syncChorus } = action.payload;

            // get the section from draft data
            let data = state.data || {};
            let draft = data.draft || {};
            let sections = draft.sections || {};
            let section = sections[sectionIndex] || {};

            // update the section
            section = { ...section, sync_chorus: syncChorus };
            sections = { ...sections, [sectionIndex]: section };
            return { ...state, data: { ...state.data, draft: { ...draft, sections: sections } } };
        }

        case SET_PHRASE: {
            // console.log('set phrase:', action.payload);
            const { phrase, sectionIndex, phraseIndex } = action.payload;

            // get the section from draft data
            let data = state.data || {};
            let draft = data.draft || {};
            let sections = draft.sections || {};
            let section = sections[sectionIndex] || {};

            // update the section
            section = { ...section, phrases: { ...section.phrases, [phraseIndex]: phrase } };
            sections = { ...sections, [sectionIndex]: section };
            return { ...state, data: { ...state.data, draft: { ...draft, sections: sections } } };
        }

        case DELETE_DRAFT: {
            // delete the draft
            const data = state.data || {};
            if (data.draft) delete data.draft;
            return { ...state, data: data };
        }

        case ADD_RECORDING: {
            // add a recording item
            console.log("Add recording: ", action.payload);
            let data = state.data || {};
            let draft = data.draft || {};
            let recordings = draft.recordings || [];

            const new_state = {
                ...state,
                data: { ...state.data, draft: { ...draft, recordings: [...recordings.filter((r) => !doRecordingsOverlap(r, action.payload)), action.payload] } }
            };
            console.log("Add recording new state:", new_state);
            return new_state;
        }

        case REMOVE_RECORDING: {
            // add a recording item
            let data = state.data || {};
            let draft = data.draft || {};
            let recordings = draft.recordings || [];
            recordings.splice(action.payload, 1);
            return { ...state, data: { ...state.data, draft: { ...draft, recordings: recordings } } };
        }

        case REPLACE_RECORDING: {
            // add a recording item
            let data = state.data || {};
            let draft = data.draft || {};
            let recordings = draft.recordings || [];
            recordings = recordings.map((recording) => {
                if (recording.blob === action.payload.blob) {
                    return action.payload.recording;
                }
                return recording;
            });
            return { ...state, data: { ...state.data, draft: { ...draft, recordings: recordings } } };
        }

        case SET_VIDEO_COLOR:
            return { ...state, data: { ...state.data, draft: { ...state.data.draft, video_style: { ...state.data.draft.video_style, color: action.payload } } } };
        // return { ...state, video_style: { ...state.data.draft.video_style, color: action.payload } };

        case SET_VIDEO_FONT:
            return { ...state, data: { ...state.data, draft: { ...state.data.draft, video_style: { ...state.data.draft.video_style, font: action.payload } } } };
        // return { ...state, video_style: { ...state.video_style, font: action.payload } };

        default:
            return state;
    }
};
*/

const ProjectEditor = ({ children, projectId }) => {
    const [store, dispatch] = React.useReducer(reducer, initialState);
    useDevTool("Project Editor", store);

    const setProject = (project) => {
        // console.log("loading project", project);

        const fps = project.fps ?? 30;
        const seconds = project.duration ?? ((60 * 3) + 18);
        const frames = fps * seconds;

        // attach video specs
        const proj = {
            ...project,

            data: {
                ...project.data,
                video: {
                    width: project.data.video.width ?? 1920,
                    height: 1080,
                    fps,
                    duration: seconds,
                    frames,
                    url: 'https://dynamo-project-files.s3.amazonaws.com/project_files/02.mp4',
                },
            }
            // project_type: 'video',
            // video: {
            //     width: 1920,
            //     height: 1080,
            //     fps,
            //     duration: seconds,
            //     frames,
            //     url: 'https://dynamo-project-files.s3.amazonaws.com/project_files/02.mp4',
            // },
            // layers: project.layers ?? [],
        }

        // setProject(proj);
        dispatch({ type: SET_PROJECT, payload: project });
    }

    // load the project
    const { data, loading, error } = useGetAPI(`/api/projects/${projectId}/`, { setData: setProject });

    // save the project when it changes
    // useEffect(() => {
    //     // let data = store.data ?? {};
    //     if (store.project_id === null || store.project_id === "" || store.project_id === undefined)
    //         return;
    //
    //     // console.log("Saving project")
    //     //     data = {...data, name: "[none]"}
    //
    //     // the data contains the layers and the video
    //     const data = { data: store.data }
    //     console.log('saving project', data);
    //     // saveProject({ data: { data:data } });
    //
    //     api.patch(`/api/projects/${projectId}/`, data, {
    //         // headers: {
    //         //     'Content-Type': 'multipart/form-data',
    //         //     "X-Requested-With": "XMLHttpRequest",
    //         //     "Content-Disposition": `attachment; filename="${file.name}"`
    //         // },
    //     }).then(response => {
    //         const data = response.data;
    //         // const fileURL = data.secure_url // You should store this URL for future references in your app
    //         // console.log(data);
    //         // items.push(data);
    //     })
    //
    // }, [store]);

    // const setProject = (project) => {
    //     dispatch({ type: SET_PROJECT, payload: project });
    //     // .then(r => {
    //     //     console.log(r);
    //     // });
    // };

    // loading & errors
    if (loading || !data)
        return 'Loading...';
    if (error)
        return `Error! ${error?.message}`;

    return (
        <DispatchContext.Provider value={dispatch}>
            <StoreContext.Provider value={store}>
                <ProjectWorkspaceProvider data={data?.data} projectId={projectId}>
                    {children}
                </ProjectWorkspaceProvider>
            </StoreContext.Provider>
        </DispatchContext.Provider>
    );
};

export function useProject() {
    // console.log('** useProject');
    const project = useContext(StoreContext);
    return project;
}

// export function useLayers() {
//     // console.log('** useProject');
//     const project = useContext(StoreContext);
//     return project.data?.layers ?? [];
// }

export function useLayerActions() {
    const dispatch = useContext(DispatchContext);

    const addLayer = (layer) => {
        dispatch({ type: ADD_LAYER, payload: layer });
    };

    const updateLayer = (layer) => {
        dispatch({ type: UPDATE_LAYER, payload: layer });
    };

    return { addLayer, updateLayer };
}

export default ProjectEditor;
