import React from 'react';
import tw from 'twin.macro';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FolderIcon from '@material-ui/icons/Folder';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';

const example_menu_item = [
    {
        title: 'Quizzes',
        description: 'Create and manage quizzes',
        url: '/quizzes',
        icon: <FolderIcon />,
    },
];

const IconTextList = ({ items }) => {
    const navigate = useNavigate();
    return (
        <List tw="max-w-md mx-auto">
            {items?.map((item, index) => (
                <ListItem key={index}>
                    <ListItemButton
                        component={'a'}
                        to={item.url ?? item.path}
                        onClick={(e) =>{
                            e.preventDefault();
                            e.stopPropagation();
                            navigate(item.url ?? item.path);
                        }}
                    >
                        {/*
                        <ListItemAvatar>
                            <Avatar>
                                {item.icon ?? <FolderIcon />}
                            </Avatar>
                        </ListItemAvatar>
                        */}

                        <ListItemIcon>
                            {item.icon ?? <FolderIcon />}
                        </ListItemIcon>

                        <ListItemText primary={item.title} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
};

export default IconTextList;
