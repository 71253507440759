import React from 'react';
import PropTypes from 'prop-types';
import loadScript from 'load-script';

const DROPBOX_SDK_URL = 'https://www.dropbox.com/static/api/2/dropins.js';
const SCRIPT_ID = 'dropboxjs';

let scriptLoadingStarted = false;

const DropboxChooser = ({
                            children,
                            appKey,
                            success,
                            cancel = () => {
                            },
                            linkType = 'preview',
                            multiselect = false,
                            extensions = [],
                            disabled = false,
                        }) => {
    const isDropboxReady = () => !!window.Dropbox;

    const onChoose = () => {
        if (!isDropboxReady() || disabled) {
            return null;
        }

        window.Dropbox.choose({
            success,
            cancel,
            linkType,
            multiselect,
            extensions,
        });
    };

    React.useEffect(() => {
        if (!isDropboxReady() && !scriptLoadingStarted) {
            scriptLoadingStarted = true;
            loadScript(DROPBOX_SDK_URL, {
                attrs: {
                    id: SCRIPT_ID,
                    'data-app-key': appKey,
                },
            });
        }
    }, [appKey]);

    return (
        <div onClick={onChoose}>
            {children ? children : <button>Open Dropbox Chooser</button>}
        </div>
    );
};

DropboxChooser.propTypes = {
    children: PropTypes.node,
    appKey: PropTypes.string.isRequired,
    success: PropTypes.func.isRequired,
    cancel: PropTypes.func,
    linkType: PropTypes.oneOf(['preview', 'direct']),
    multiselect: PropTypes.bool,
    extensions: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
};

export default DropboxChooser;
