import React from 'react';
import { Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MenuGrid = ({ menu_items }) => {
    const navigate = useNavigate();
    return (
        <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            // columns={{ xs: 4, sm: 8, md: 12 }}
            justify="left"
        >
            {menu_items?.map((item) => (
                <Grid key={item.url} item xs={6} sm={3} md={3}>
                    <Button fullWidth size='large' variant="contained" color="primary" onClick={() => navigate(item.url)}>
                        {item.title}
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
};


export default MenuGrid;
