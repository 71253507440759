
// website url: www.muzology.com
export const MUZOLOGY_WEB_URL = process.env.REACT_APP_MUZOLOGY_WEBSITE_URL ?? 'https://web-dev.muzology.com/';

// frontend url (this site): app.muzology.com
export const MUZOLOGY_APP_URL = process.env.REACT_APP_MUZOLOGY_PLATFORM_URL ?? 'https://v3.muzology.com/';

// server url: api.muzology.com
// export const MUZOLOGY_API_URL = process.env.REACT_APP_MUZOLOGY_API_URL ?? 'https://dynamo.muzology.com/';
// export const MUZOLOGY_API_URL = process.env.REACT_APP_MUZOLOGY_API_URL ?? 'http://127.0.0.1:7000/';
export const MUZOLOGY_API_URL = process.env.REACT_APP_MUZOLOGY_API_URL ?? 'https://app-server-staging.muzology.com/';
