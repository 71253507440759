import React, { useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import apiClient from "../../../context/APIClient";
import { useStudio } from '../../Context/StudioContext';


const AddLessonsGroupDialog = ({ open, onClose, courseId, parentId, createLesson }) => {
    const { register, handleSubmit, trigger, formState: { errors }, control } = useForm();
    const { mutateCourses, mutateLessons, courses } = useStudio();
    console.log('courses', courses);

    const handleClose = () => {
        onClose();
    }

    const onSubmitLesson = data => {
        const payload = { title: data?.name, lesson_group: parentId }
        apiClient.post('/api/admin/lessons/', payload)
            .then((response) => {
                console.log(response);
                mutateLessons();
                mutateCourses();
                handleClose();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const onSubmitLessonGroup = useCallback( data => {
        console.log(data);
        // post the data to the server
        let payload = { title: data.name, course: courseId }
        if (parentId) payload = { title: data.name, course: courseId, parent: parentId }
        console.log("Pre save", payload);
        console.log('courses', courses);

        apiClient.post('/api/admin/lesson_groups/', payload)
            .then((response) => {
                console.log(response);
                console.log('old courses', courses);
                const newCourses = courses?.map(course => {
                    if (course.id === courseId) {
                        return {
                            ...course,
                            groups: [...course.groups, response.data]
                        }
                    }
                    return course;
                });
                console.log('new courses', newCourses);
                mutateCourses(newCourses, false);
                handleClose();
            })
            .catch((error) => {
                console.log(error);
            });

    }, [courseId, courses, handleClose, mutateCourses, parentId] );

    const showError = (error, text = null) => {
        if (error) {
            // console.log(error);
            let message = text;
            if (!message) {
                if (error.type === 'required') message = 'This field is required';
                else message = error.message;
            }

            return (
                <div className='error-container text-red-500'>
                    <p className='error-tag'>{`* ${message}`}</p>
                </div>
            );
        }
        return null;
    };
    // console.log(errors);
    return (
        <Dialog onClose={handleClose} open={open} maxWidth={"sm"} fullWidth={true}>
            <form onSubmit={handleSubmit(createLesson ? onSubmitLesson : onSubmitLessonGroup)}>
                <DialogTitle>{`Create New Lesson ${createLesson ? '' : 'Group'}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Stack spacing={3} sx={{ paddingTop: '10px' }}>
                            <TextField
                                autoFocus
                                fullWidth
                                // onChange={onChange}
                                // value={value}
                                label={"Name"}
                                {...register("name", { required: true })}
                            />
                            {showError(errors.name)}
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type="button" color="primary" variant="outlined" size="small" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" variant="contained" size="small" onClick={() => trigger()}>
                        Create
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default AddLessonsGroupDialog;