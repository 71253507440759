import React, { useEffect, useMemo, useState } from 'react';
import { useVideos } from "./VideosContainer";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../../Home/PageContainer";
import { Avatar, Button, Fab, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import AddIcon from '@material-ui/icons/Add';
import NewQuizDialog from "../Quizzes/NewQuizDialog";
import NewVideoDialog from "./NewVideoDialog";
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import { VideoCard } from "../../Components";
import apiClient from "../../context/APIClient";
import { Autocomplete } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import VolumeDropdown from "../StudioContainer/VolumeDropdown";
import { useStudio } from "../Context/StudioContext";

const columns = [
    {
        field: 'url_thumbnail',
        headerName: 'Thumbnail',
        renderCell: (params) => {
            return (
                <img src={params.value} alt={params.value} style={{ width: '100%' }} />
            );
        }
    },
    { field: 'title', headerName: 'Title', flex: 1, align: 'left' },
    { field: 'slug', headerName: 'Slug', flex: 1, align: 'left' },
    { field: 'updated', headerName: 'Updated', width: 150 },
    { field: 'duration', headerName: 'Duration', width: 200 },
    {
        field: 'healthcheck_valid', headerName: 'Health Check', width: 200, renderCell: (params) => {
            return params.value ? <div className="m-auto bg-green-200 text-green-700 px-2 py-1 rounded">PASS</div> : <div className="m-auto  bg-red-200 text-red-700 px-2 py-1 rounded">FAIL</div>
        }
    },

    // { field: 'index', headerName: '#', width: 50 },
    // { field: 'id', headerName: 'ID', width: 150 },
    // { field: 'id', headerName: 'ID', width: 100, cellClassName: 'font-bold', align: 'center' },
    // { field: 'slug', headerName: 'Slug', flex: 1, align: 'left' },
    // { field: 'text', headerName: 'Question', flex: 1 },
    // { field: 'col2', headerName: 'Column 2', width: 150 },
];

// const useStyles = makeStyles((theme) => ({
//     avatar: {
//         backgroundColor: theme.palette.grey[50],
//         border: `1px solid ${theme.palette.info.main}`,
//         color: theme.palette.info.main
//     }
// }));


const SearchField = ({ value, onSearch }) => {
    // const [options, setOptions] = useState([]);
    // const [inputValue, setInputValue] = useState('');
    return (
        <TextField
            label="Search"
            variant="outlined"
            value={value}
            onChange={(e) => onSearch(e.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                style: { backgroundColor: 'white' },
            }}
        />
    );
};

const VideosList = (props) => {
    const { videos, selectedVolume, setSelectedVolume } = useStudio();
    const navigate = useNavigate();
    const [viewMode, setViewMode] = React.useState('list'); // 'thumbnails', 'list'

    const handleChangeViewMode = (event, nextView) => {
        setViewMode(nextView);
    };

    const [openNewVideo, setOpenNewVideo] = React.useState(false);
    const addButton = (
        <Fab size={'medium'} color="primary" aria-label="add" onClick={() => setOpenNewVideo(true)}>
            <AddIcon />
        </Fab>
    )

    const toggleViews = (
        <ToggleButtonGroup exclusive value={viewMode} onChange={handleChangeViewMode}>
            <ToggleButton value="list" aria-label="list">
                <ViewListIcon />
            </ToggleButton>
            <ToggleButton value="thumbnails" aria-label="thumbnails">
                <ViewModuleIcon />
            </ToggleButton>
            {/*
                <ToggleButton value="quilt" aria-label="quilt">
                    <ViewQuiltIcon />
                </ToggleButton>
            */}
        </ToggleButtonGroup>
    );


    const [search, setSearch] = useState('');
    const handleOnSearch = (value) => {
        console.log('search', value);
        setSearch(value);
    }

    const searchBar = (
        <SearchField value={search} onSearch={handleOnSearch} />
    );
    // const [selectedVolume, setSelectedVolume] = React.useState('df69d326-5ced-419e-8cfd-a7d5b7732ec9');
    // const [selectedVolume, setSelectedVolume] = React.useState(null);
    // const { selectedVolume, setSelectedVolume } = useItemEditor();

    const rows = useMemo(() => {
        if (!videos)
            return [];
        let vids = videos ?? [];

        // handle search
        if (search) {
            vids = vids?.filter(video => video.title.toLowerCase().includes(search.toLowerCase()));
        }

        // handle filters
        if (selectedVolume && selectedVolume !== 'null') {
            vids = vids.filter(video => video.volume_id === selectedVolume);
        }

        return vids.map((video, index) => {
            return {
                ...video,
                // id: video.id,
                // title: video.title,
                // slug: video.slug,
            };
        });
    }, [search, videos, selectedVolume])

    // build the content
    const content = useMemo(() => {
        const onRowClick = (params, event, details) => {
            console.log('row clicked', params, event, details);
            navigate(`/videos/${params.id}`);
        }

        if (viewMode === 'list') {
            if (rows)
                return (
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        onRowClick={onRowClick}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'title', sort: 'asc' }],
                            },
                        }}
                    />
                );
        } else if (viewMode === 'thumbnails') {
            return (
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {videos?.map(video => (
                        <Grid item xs={2} sm={4} md={4} key={video.id}>
                            <VideoCard
                                video={video}
                                showDelete={false}
                                className={'cursor-pointer hover:shadow-lg hover:scale-105 transition-all duration-100 ease-in-out active:scale-100'}
                                onClick={() => navigate(`/videos/${video.id}`)}
                            />
                        </Grid>
                    ))}
                </Grid>

            );
        }
        return null;
    }, [viewMode, rows]);


    return (
        <PageContainer
            title={'Videos'}
            actions={[
                searchBar,
                toggleViews,
                <VolumeDropdown selectedVolume={selectedVolume} onChangeVolume={setSelectedVolume} />,
                addButton
            ]}
            paths={[
                { title: 'Home', href: '/', },
                { title: 'Studio', href: '/studio/' },
            ]}
        >

            <div className={'p-4 w-full h-full'}>
                {content}
            </div>

            <NewVideoDialog
                open={openNewVideo}
                onClose={() => setOpenNewVideo(false)}
            />
        </PageContainer>
    );
};


export default VideosList;
