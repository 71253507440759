import React, { useCallback, useMemo } from 'react';
import tw from 'twin.macro';
import { useDropzone } from "react-dropzone";
import apiClient from "../../../context/APIClient";
import InputText from "./FormControls";
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import { Box, Button, IconButton } from "@mui/material";
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';

const QuestionImage = ({ quiz_id, question, choice, updateQuestion, updateChoice }) => {
    const [showURLInput, setShowURLInput] = React.useState(false);
    // console.log('quiz_id', quiz_id);

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        // console.log(acceptedFiles);
        // console.log('quiz_id', quiz_id);
        acceptedFiles.map((file) => {
            console.log(file);
            const formData = new FormData();
            formData.append("file", file);

            if (choice) {
                if( choice.new ) {
                    formData.append('choice_id', 'new');
                } else {
                    formData.append('choice_id', choice.id);
                }
            }
            if (question) {
                formData.append('question_id', question.id);
            }
            if (quiz_id)
                formData.append('quiz_id', quiz_id);

            // formData.append("tags", `codeinfuse, medium, gist`);
            // formData.append("upload_preset", "pvhilzh7"); // Replace the preset name with your own
            // formData.append("api_key", "1234567"); // Replace API key with your own Cloudinary key
            // formData.append("timestamp", (Date.now() / 1000) | 0);

            // Make an AJAX upload request using Axios (replace Cloudinary URL below with your own)
            return apiClient.put("/api/quiz_image/", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Disposition": `attachment; filename="${file.name}"`
                },
            })
                .then(response => {
                    const data = response.data;
                    console.log(data);
                    const { url, choice_id, question_id } = data;
                    if (choice) {
                        if( choice.new && choice_id ) {
                            updateChoice({ id: choice.id, choice_text: url, content_image: true });
                        } else {
                            updateChoice({ id: choice.id, choice_text: url, content_image: true });
                        }
                    } else if (question) {
                        updateQuestion({ id: question.id, image_url: url });
                    }
                })
        });
        // api.all(uploaders).then(() => {
        //     // ... perform after upload is successful operation
        //     console.log("Files uploaded")
        // });

    }, [choice, question, quiz_id, updateChoice, updateQuestion]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    let image_url = useMemo(() => {
        if (choice) {
            return choice.choice_text;
        }
        else if (question) {
            return question.image_url;
        }
        return null;
    }, [choice, question]);

    return (
        <div className={'relative border-2 border-solid rounded-lg w-full cursor-pointer'}>
            <div
                className={'w-full min-h-[56px] flex justify-center items-center '}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                {image_url ?
                    <img
                        src={image_url}
                        width={'auto'}
                        height={'100%'}
                        // className={'max-h-[250px] my-4'}
                        className={'max-h-[250px] border-2 border-gray-400 border-solid'}
                    />
                    :
                    <div className={'text-[#999999] bg-[#eeeeee] hover:bg-[#dddddd] active:bg-[#ffffff] font-bold text-xl min-h-[56px] w-full h-full flex justify-center items-center'}>
                        {isDragActive ? "Drop here" : "Drag images here, or click to upload."}
                    </div>
                }
            </div>
            <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
                {question?.image_url &&
                    <IconButton
                        // sx={{ position: 'absolute', right: 0, top: 0 }}
                        onClick={() => updateQuestion({ id: question.id, image_url: null })}
                    >
                        <DeleteSharpIcon />
                    </IconButton>}
                <IconButton
                    // sx={{ position: 'absolute', right: 0, top: 0 }}
                    onClick={() => setShowURLInput(!showURLInput)}
                >
                    <ModeEditSharpIcon />
                </IconButton>
            </Box>

            {showURLInput &&
                <div className={'flex justify-center items-center m-2'}>
                    <InputText
                        id='image_url'
                        label='URL'
                        name='image_url'
                        // value={quiz?.title}
                        // onChange={handleChangeValue}
                        value={choice ? choice.choice_text : question?.image_url}
                        onChange={(e) => {
                            if (choice) {
                                updateChoice({ id: choice.id, choice_text: e.target.value, content_image: true })
                            } else {
                                updateQuestion({ id: question.id, image_url: e.target.value })
                            }
                        }}
                    />
                </div>
            }
        </div>
    );
};

export default QuestionImage;
