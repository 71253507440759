import React from 'react';
import { Avatar, Button, Card, CardContent, CardHeader, Checkbox, Fab, Paper, Radio, Stack, Typography } from "@mui/material";
import { getLevelBackground, getLevelColor, getLevelName, QUESTION_TYPE_MULTIPLE_CHOICE, QUESTION_TYPE_MULTIPLE_SELECTION, QUIZ_BACKGROUNDS } from "./constants";
import RichText from "../../../Components/RichText/RichText";
import { Edit } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { QUESTION_TYPE_MULTIPLE_SELECT } from "./QuestionSettingsDialog";


export const AnswerKeyQuestion = ({ question, index, hideEdit }) => {

    let instructionText = null;
    if (question?.instruction_text) {
        // instructionText = `(${question?.instruction_text})`;
        instructionText = question?.instruction_text;
    } else if (question.type === QUESTION_TYPE_MULTIPLE_SELECT) {
        instructionText = '(Select all options that apply.)';
    }


    return (
        <Card variant={'blank'} sx={{}}>

            <CardHeader
                // avatar={<Avatar variant={'rounded'} sx={{ bgcolor: getLevelBackground(question.level) }}>{index + 1}</Avatar>}
                avatar={
                    <Typography variant={'h4'}>
                        {index + 1}.
                    </Typography>
                }
                title={
                    <Typography variant={'h4'} sx={{ fontSize: 26, fontFamily: 'Helvetica' }}>
                        <RichText text={question.question_text} />

                        {question.image_url && <div className={''}>
                            <img
                                src={question.image_url}
                                className={'my-1 max-w-[160px]'}
                            />
                        </div>}

                        {instructionText && <div className={'my-1'}>
                            <Typography
                                variant={'p'}
                                sx={{ fontSize: 20, fontFamily: 'Helvetica' }}
                            >
                                {instructionText}
                            </Typography>
                        </div>}

                    </Typography>
                }
            />

            <CardContent>
                <>
                    {question.choices.map((choice, index) => {
                        let decorator = null;
                        if (question.type === QUESTION_TYPE_MULTIPLE_CHOICE) {
                            decorator = (
                                <Radio checked={choice.correct} />
                            )
                        } else if (question.type === QUESTION_TYPE_MULTIPLE_SELECTION) {
                            decorator = (
                                <Checkbox checked={choice.correct} />
                            )
                        }
                        return (
                            <div key={choice.id} className={'flex flex-row items-center gap-4'}>
                                {/*
                            <Avatar
                                sx={{
                                    bgColor: 'transparent',
                                    // bgcolor: 'red'
                                }}
                            >
                                {String.fromCharCode(65 + index)}
                            </Avatar>
                            */}
                                {decorator}

                                <Typography
                                    variant='h6'
                                    sx={{
                                        // bgColor: 'transparent',
                                        // bgcolor: 'red'
                                    }}
                                >
                                    {String.fromCharCode(65 + index)})
                                </Typography>

                                {choice.content_image ?
                                    <img
                                        src={choice.choice_text}
                                        className={'max-h-[160px] max-w-[200px] aspect-square'}
                                    />
                                    :
                                    <Typography variant={'body1'} sx={{ fontSize: 20, fontFamily: 'Helvetica' }}>
                                        <RichText text={choice.choice_text} />
                                    </Typography>}
                            </div>
                        );
                    })}
                </>
                {/*
                <div key={question.id}>
                    <div className={'font-bold text-gray-600'}>{index + 1}. {question.question_text}</div>
                    <div className={'font-bold text-gray-600'}>Answer: {question.choices[question.answer].choice}</div>
                </div>
                */}
            </CardContent>

        </Card>
    );
};


const QuizAnswerKeys = ({ quiz, hideEdit }) => {
    const navigate = useNavigate();
    // group questions by level
    const questionsByLevel = quiz.questions.reduce((acc, question) => {
        if (!acc[question.level]) {
            acc[question.level] = [];
        }
        acc[question.level].push(question);
        return acc;
    }, {});

    // sort the questions by question_index
    Object.keys(questionsByLevel).forEach((level) => {
        questionsByLevel[level].sort((a, b) => {
            return a.question_index - b.question_index;
        });
    });

    // get a list of levels
    const levels = Object.keys(questionsByLevel).map((level) => {
        return {
            level: parseInt(level),
            questions: questionsByLevel[level]
        };
    });
    // console.log("levels", levels);
    return (
        <Stack spacing={2} className={'-bg-blue-200 w-full'}>
            {levels.map((level, index) => {
                return (
                    <Card key={level.level} sx={{ width: '100%' }}>
                        <CardHeader
                            // title={`${getLevelName(level.level)} Level `}
                            title={
                                <Typography variant={'h5'}>
                                    {getLevelName(level.level)} Level
                                </Typography>
                            }
                            sx={{
                                backgroundColor: QUIZ_BACKGROUNDS[level.level],
                            }}
                            action={
                                <Stack direction={'row'} spacing={2}>

                                    {/*
                                        <Typography variant={'h4'}>
                                            {getLevelName(level.level)} Level
                                        </Typography>
                                        */}
                                    {(
                                        !hideEdit
                                    ) && <>
                                        <Button variant={'outlined'} color={'primary'}>
                                            Preview
                                        </Button>
                                        <Button variant={'outlined'} color={'primary'}>
                                            Edit
                                        </Button>
                                    </>
                                    }
                                </Stack>

                            }
                            // avatar={
                            //     <Avatar sx={{ bgcolor: 'black' }}>
                            //         {index + 1}
                            //     </Avatar>
                            // }
                        />
                        <CardContent>
                            {/*
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    Level {index + 1}
                                </Typography>
*/}
                            {/*
                            <Typography variant="h5" component="div">
                                {Q}
                            </Typography>
                            */}
                            <Stack spacing={2}>
                                {level.questions.map((question, index) => {
                                    return (
                                        <div style={{ position: 'relative' }}>
                                            {/*  Float a button on the upper right
                                                */}
                                            {/*     ((!hideEdit) && <Fab
                                                onClick={() => navigate(`/quiz/${quiz.id}/question/${question.id}`)}
                                                size='small'
                                                color="info"
                                                aria-label="edit"
                                                sx={{ position: 'absolute', top: -10, right: -10 }}
                                            >
                                                <Edit />
                                            </Fab>)
*/}

                                            <AnswerKeyQuestion
                                                key={question.id}
                                                question={question}
                                                index={index}
                                                hideEdit={hideEdit}
                                            />

                                            {(
                                                index < (
                                                    level.questions.length - 1
                                                )
                                            ) && <hr />}
                                        </div>
                                    )
                                })}
                            </Stack>

                        </CardContent>
                    </Card>
                );
            })}
            {/*
            <Stack spacing={2}>
                {quiz.questions.map((question, index) => {
                    return (
                        <div key={index}>
                            <div className={'font-bold text-gray-600'}>{question.question_index}. {question.question}</div>
                            <div className={'font-bold text-gray-600'}>Answer: {question.choices[question.answer].choice}</div>
                        </div>
                    );
                })}
            </Stack>
            */}

        </Stack>
    );
};


export default QuizAnswerKeys;
