import { formatRelative } from "date-fns";
import React from "react";

export const titleColumn = (props = {}) => ({
    field: 'title',
    headerName: 'Title',
    flex: 1,
    align: 'left',
    ...props,
});

export const imageColumn = (props = {}) => ({
    field: 'url_thumbnail',
    headerName: 'Thumbnail',
    renderCell: (params) => {
        return (
            <img src={params.value} alt={params.value} style={{ width: '100%' }} />
        );
    },
    ...props,
});

export const createdColumn = (props = {}) => ({
    field: 'created',
    headerName: 'Created',
    sortable: true,
    width: 200,
    renderCell: (params) => {
        try {
            return formatRelative(new Date(params?.value), new Date())
        } catch (error) {
            console.log('error', error);
            return 'error';
        }
    },
    ...props,
});

export const updatedColumn = (props = {}) => ({
    field: 'updated',
    headerName: 'Updated',
    sortable: true,
    width: 200,
    renderCell: (params) => {
        try {
            return formatRelative(new Date(params?.value), new Date())
        } catch (error) {
            console.log('error', error);
            return 'error';
        }
    },
    ...props,
});