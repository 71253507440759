import React, { useState } from 'react';
import { Button, Card, CardContent, CardHeader, Grid, Stack, Typography } from "@mui/material";
import DeleteLessonDialog from "../../StudioContainer/Dialogs/DeleteLessonDialog";
import LessonListDialog from "../../StudioContainer/Dialogs/AddExistingLessonDialog";
import AddLessonsGroupDialog from "../../StudioContainer/Dialogs/AddLessonsGroupDialog";
import InputText from "../../Quizzes/QuizEditor/FormControls";

const GroupEditor = ({ lessonGroup, updateLessonGroup }) => {
    const [openNewLesson, setOpenNewLesson] = useState(false)
    const [openExistingLesson, setOpenExistingLesson] = useState(false)
    const [openDeleteLesson, setOpenDeleteLesson] = useState(false)
    const [deleteLessonGroup, setDeleteLessonGroup] = useState(false)

    const [createLesson, setCreate] = useState(false)

    const handleChangeValue = (event) => {
        let { name, value } = event.target;
        console.log('handleChangeValue', name, value);
        // updateLessonGroup({ id: lessonGroup.id, [name]: value });
    };

    console.log('!!!! EDIT GROUP', lessonGroup?.title, lessonGroup?.id);

    return (
        <Grid id={lessonGroup.id} container rowSpacing={0} columnSpacing={0}>
            <Grid item xs={12} >
                <Card sx={{ m: 2 }}>
                    <CardHeader title='Lesson Group' />
                    <CardContent>
                        <Stack spacing={2}>
                            <InputText
                                id='title'
                                name='title'
                                label='Title'
                                onChange={handleChangeValue}
                                value={lessonGroup?.title ?? '<empty>'}
                            />
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card sx={{ m: 2 }}>
                    <CardContent>
                        <Stack spacing={2}>
                            <Button fullWidth variant='outlined' color='primary' size='small' onClick={() => setOpenNewLesson(true)}>
                                Add Group
                            </Button>
                            <Button
                                fullWidth
                                variant='outlined'
                                color='primary'
                                size='small'
                                onClick={() => {
                                    setOpenExistingLesson(true);

                                }}
                            >
                                Add Video
                            </Button>
                            <Button
                                fullWidth
                                variant='outlined'
                                color='warning'
                                size='small'
                                onClick={() => {
                                    setOpenDeleteLesson(true);
                                    setDeleteLessonGroup(true)

                                }}
                            >
                                Delete Group
                            </Button>
                            <Typography variant='body2'>
                                ID: {lessonGroup?.id}
                            </Typography>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <AddLessonsGroupDialog open={openNewLesson} onClose={() => setOpenNewLesson(false)} courseId={lessonGroup.course} parentId={lessonGroup.id} createLesson={createLesson} />
            <LessonListDialog open={openExistingLesson} onClose={() => setOpenExistingLesson(false)} groupLessons={lessonGroup.lessons} courseId={lessonGroup.course} parentId={lessonGroup.id} />
            <DeleteLessonDialog open={openDeleteLesson} onClose={() => setOpenDeleteLesson(false)} lessonGroup={lessonGroup} deleteLessonGroup={deleteLessonGroup} />


        </Grid>
    );
};

export default GroupEditor;
