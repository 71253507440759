import React from 'react';
import tw, { css, styled } from 'twin.macro';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, FormControlLabel, Checkbox, Stack, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import apiClient from "../../../context/APIClient";
import { useNavigate } from "react-router-dom";
import DropdownFilter from "../../../Admin/Accounts/DropdownFilter";
import { useStudio } from "../../Context/StudioContext";
import { useItemEditor } from "../StudioContainer";

const AddCourseDialog = ({ open, onClose }) => {
    const { register, handleSubmit, trigger, formState: { errors }, control } = useForm();

    const handleClose = () => {
        onClose();
    }
    const navigate = useNavigate();

    const onSubmit = data => {
        console.log("🚀 ~ file: AddCourseDialog.js:17 ~ onSubmit ~ data:", data)
        const payload = { title: data.name, course_type: { parent: data.parent, teacher: data.teacher } }
        apiClient.post('/api/admin/courses/', payload)
            .then((response) => {
                console.log(response);
                const { data } = response;
                navigate(`/studio/library/${data.id}`)
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const showError = (error, text = null) => {
        if (error) {
            // console.log(error);
            let message = text;
            if (!message) {
                if (error.type === 'required') message = 'This field is required';
                else message = error.message;
            }

            return (
                <div className='error-container text-red-500'>
                    <p className='error-tag'>{`* ${message}`}</p>
                </div>
            );
        }
        return null;
    };
    const { volumes} = useItemEditor();
    const volumeList = volumes?.map(volume => [volume.id, volume.title]) ?? [];
    const [volume, setVolume] = React.useState(null);

    console.log('volumeList',volumeList);
    console.log('volume', volume);

    return (
        <Dialog onClose={handleClose} open={open} maxWidth={"sm"} fullWidth={true}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Create New Course</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Stack spacing={3} sx={{ paddingTop: '10px' }}>
                            <TextField
                                autoFocus
                                fullWidth
                                // onChange={onChange}
                                // value={value}
                                label={"Name"}
                                {...register("name", { required: true })}
                            />
                            {showError(errors.name)}
                        </Stack>
                    </DialogContentText>

                    <DialogContentText>
                        <div className='mt-6 text-black'>Please select the volume:</div>
                        <DropdownFilter
                            className={'w-full m-0'}
                            // label={'Volume'}
                            items={volumeList}
                            onChange={setVolume}
                        />
                    </DialogContentText>
                    <DialogContentText>
                        <div className='mt-6 text-black'>Please select what account type This course is for:</div>
                        <div className='flex'>
                            <label className='flex mr-4'>
                                <input
                                    type='checkbox'
                                    {...register("teacher", { required: true })}
                                />
                                <div className='text-black ml-2'>Teacher</div>

                            </label>
                            <label className='flex'>
                                <input
                                    type='checkbox'
                                    {...register("parent", { required: true })}
                                />
                                <div className='text-black ml-2'>Parent</div>
                            </label>
                        </div>
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button type="button" color="primary" variant="outlined" size="small" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" variant="contained" size="small" onClick={() => trigger()}>
                        Create
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default AddCourseDialog;