import React, { useMemo, useState } from 'react';
import tw from 'twin.macro';
import { Button, Stepper, Step, StepLabel } from '@material-ui/core';

const WizardContainer = tw.div`flex flex-col items-stretch  w-full  min-h-[80vh]`;

export const StepContainer = tw.div`flex flex-col items-stretch justify-center flex-grow p-4 text-lg`;

const MultiStepWizard = ({ steps }) => {
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const currentStep = useMemo(() => {
        if (!steps)
            return null;
        return steps[activeStep % steps.length];
    }, [activeStep, steps]);

    const hideSteps = currentStep?.hideSteps ?? false;
    const hideHeader = currentStep?.hideHeader ?? false;
    const StepContent = currentStep?.content;
    const isFirstStep = activeStep === 0;
    const isLastStep = activeStep === steps.length - 1;

    return (
        <WizardContainer>

            {!hideHeader && <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step) => (
                    <Step key={step.label}>
                        <StepLabel>{step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>}

            <StepContent className={'w-full'} activeStep={activeStep} setActiveStep={setActiveStep} />

            {!hideSteps && <div className={'w-full items-end justify-end gap-4 my-4 flex flex-row '}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleBack}
                    disabled={isFirstStep}
                >
                    Back
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={isLastStep}
                >
                    {isLastStep ? 'Finish' : 'Next'}
                </Button>
            </div>}
        </WizardContainer>
    );
};

export default MultiStepWizard;
