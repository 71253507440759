import React, { useMemo } from 'react';
import tw, { css } from 'twin.macro';
import ChoiceItem from './ChoiceItem';

const GridContainer = tw.div`
    w-full
    grid 
    grid-cols-1 
    auto-rows-fr
    gap-[20px]
    font[3rem]
`;

const OneColumnGrid = tw(GridContainer)`
    sm:grid-cols-1
`;

const TwoColumnGrid = tw(GridContainer)`
    sm:grid-cols-2
`;

const gridLayouts = {
    'grid-1-col': OneColumnGrid,
    'grid-2-col': TwoColumnGrid
};

const ChoicesGrid = (props) => {
    // render the choices
    const gridItems = useMemo(() => {
        const { question, choiceColor, enableAnswerButtons, buttonState, onClickChoice, audioButtonClicked, answerKey } = props;
        if (!question?.choices)
            return null;
        // check if buttons are disabled
        const handleClickChoice = (index) => {
            if (enableAnswerButtons && onClickChoice)
                onClickChoice(index);
        };
        return question.choices.map((choice, i) => {
            return (
                <ChoiceItem
                    key={choice.id}
                    id={`question-choice-${i}`}
                    index={i}
                    choice={choice}
                    question={question}
                    buttonState={buttonState}
                    enableAnswerButtons={enableAnswerButtons}
                    audioButtonClicked={audioButtonClicked}
                    choiceColor={choiceColor}
                    onClickChoice={handleClickChoice}
                    answerKey={answerKey}
                />
            );
        });
    }, [props]);

    if (!props.question)
        return null;

    // get the layout
    let ChoiceLayout = GridContainer;
    if (props.question?.layout) {
        ChoiceLayout = gridLayouts[props.question.layout] ?? GridContainer;
    }

    return (
        <ChoiceLayout
            key={`${props.question?.id}-${props.question?.layout}`}
            className={`choices-layout--${props.question?.layout}`}
            data-cy='choices-layout'
            data-layout={props.question?.layout}
        >
            {gridItems}
        </ChoiceLayout>
    );
};

export default ChoicesGrid;
