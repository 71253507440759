import CourseListContainer from "./CourseListContainer";
import CourseEditorContainer from "./CourseEditorContainer";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

const CourseEditor = {
    slug: "groupings",
    name: "Groupings",
    namePlural: "Groupings",
    api: '/api/admin/courses/',
    key: 'courses',
    ListContainer: CourseListContainer,
    EditorContainer: CourseEditorContainer,
    icon: <LibraryBooksIcon />,

    columns: [
        // {
        //     name: 'id',
        //     label: 'ID',
        // },
        {
            name: 'title',
            label: 'Title',
            flex: 2,
        },
        {
            name: 'slug',
            label: 'Slug',
            flex: 1,
        },
        {
            name: 'course_type',
            label: 'Type',
            // flex: 1,
        },
        // {
        //     name: 'volume',
        //     label: 'Volume',
        //     align: 'center',
        //     flex: 3,
        // },
        // {
        //     name: 'description',
        //     label: 'Description',
        //     align: 'center',
        //     flex: 3,
        // },
    ]
}

export default CourseEditor

