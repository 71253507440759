import React, { useState, useEffect, useMemo } from 'react';
import { useGetAPI } from "../../context/api";
import { DataGrid } from '@mui/x-data-grid';
import { Box, Card, Fab, Modal, Typography, Button } from "@mui/material";
import { PageContainer } from "../../Home/PageContainer";
import AddIcon from "@material-ui/icons/Add";
import { useNavigate } from "react-router-dom";
import NewPlaylistDialog from './Dialogs/NewPlaylistDialog';
import { useTopics } from './TopicsEditorProvider';


const PlaylistContainer = () => {
    const navigate = useNavigate();
    const [openNewPlaylist, setOpenNewPlaylist] = React.useState(false);

    const { data: topics } = useGetAPI('/api/assignment_topics/');
    const { data: videoLibrary } = useGetAPI('/api/video_files/');



    // filter data that a children of data
    const [stateRows, setRows] = useState([])
    const [expandRowIds, setIds] = useState([])

    useEffect(() => {
        let filterTopics = topics?.filter(topics => topics.parent === null)
        if (topics) setRows(filterTopics)
    }, [topics]);



    // create the rows
    const rows = useMemo(() => {
        return stateRows?.map((t, index) => {
            // check if the topics have sub topics
            let sub_topic = topics.find(topic => topic.parent === t.id)
            return {
                index: index,
                id: t.id,
                title: t.name,
                sub_topic: sub_topic ? 'yes' : '',
                expand: '',
                parent: t.parent,
                topic: true,
                playlists: t?.playlists
            };
        });
    }, [stateRows, topics]);

    const columns = [
        {
            field: 'expand',
            headerName: '',
            width: 5,
            cellClassName: 'expand-icon font-bold ',
            renderCell: (params) => {
                // console.log('render cell', params, expandRowIds);
                const disableClick = (e) => {
                    e.stopPropagation();

                }
                const onClick = (e) => {
                    e.stopPropagation();
                    // add or remove the id from the expandRowIds
                    if (expandRowIds.includes(params.id)) {
                        setIds(expandRowIds.filter(id => id !== params.id))
                    } else {
                        setIds([...expandRowIds, params.id])
                    }
                };
                // console.log('render cell', params);
                const { row } = params;
                if (!row.topic) {
                    // console.log('render cell w parent', params);
                    return null;
                }
                let transform = 'rotate(180deg)';
                if (expandRowIds.includes(params.id)) {
                    transform = 'rotate(0deg)';
                }

                return (
                    <div className='w-full h-full' onClick={disableClick}>
                        <button onClick={onClick}>
                            <div
                                className='transition-transform duration-100 expand-icon flex justify-center items-center w-full w-[30px] h-[30px] m-auto mt-3 '
                                style={{ transform }}
                            >
                                <svg width="30" height="30" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.7763 1.7017C8.66129 1.7017 2.08281 8.28019 2.08281 16.3952C2.08281 24.5101 8.66129 31.0886 16.7763 31.0886C24.8912 31.0886 31.4697 24.5101 31.4697 16.3952C31.4697 8.28019 24.8912 1.7017 16.7763 1.7017Z" stroke="#1A1717" stroke-width="2.93869" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M24.7207 19.1157L16.8842 11.2792L9.04768 19.1157" stroke="#1A1717" stroke-width="2.93869" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>


                            </div>
                        </button>
                    </div>

                );
            }
        },
        {
            field: 'title',
            headerName: 'Title',
            width: 300,
            cellClassName: 'font-bold cursor-pointer select-none active:bg-gray-300',
            valueGetter: (params) => {
                if (params.row.topic) {
                    return params.row.title;
                }
                return '';
            }
        },

        {
            field: 'sub_topic',
            headerName: 'Sub Topic',
            width: 150,
            cellClassName: 'font-bold '
        },
        {
            field: 'video_title',
            headerName: 'Video',
            width: 250,
            cellClassName: 'font-bold '
        },
        {
            field: 'lowest_grade_level',
            headerName: 'Lowest Grade',
            width: 150,
            type: 'number',
            cellClassName: 'font-bold '
        },
        {
            field: 'highest_grade_level',
            headerName: 'Highest Grade',
            type: 'number',
            width: 150,
            cellClassName: 'font-bold '
        },

    ];

    const finalRows = useMemo(() => {
        const finalRows = [];
        rows.forEach(row => {
            finalRows.push(row);

            if (row.sub_topic === 'yes') {
                const foundSubTopic = topics.filter(topic => topic.parent === expandRowIds[0])
                // if the row is expanded
                if (foundSubTopic) {
                    let playlists = foundSubTopic.map(playlist => playlist.playlists[0])
                    let videosList = playlists.reduce((newList, playlist) => {
                        playlist.videos.forEach(v => {
                            let video = videoLibrary.find(video => video.id === v);
                            newList.push({
                                id: video.id,
                                title: video.title,
                                highest_grade_level: playlist.highest_grade_level,
                                lowest_grade_level: playlist.lowest_grade_level,
                            })
                        })
                        return newList
                    }, [])

                    videosList.forEach(video => {
                        finalRows.push({
                            id: `${video.id}+${Math.floor(Math.random() * 100000)}`,
                            parent: row.id,
                            sub_topic: '',
                            expanded_row: 'expanded_row',
                            video_title: video.title,
                            lowest_grade_level: video.lowest_grade_level,
                            highest_grade_level: video.highest_grade_level
                        });
                    })
                }
            }
            // if the row is expanded
            const found = expandRowIds.find(id => id === row.id);
            if (found) {
                let playlists = topics.find(d => d.id === row.id).playlists;
                let videosList = playlists.reduce((newList, playlist) => {
                    playlist.videos.forEach(v => {
                        let video = videoLibrary.find(video => video.id === v);
                        newList.push({
                            id: video.id,
                            title: video.title,
                            highest_grade_level: playlist.highest_grade_level,
                            lowest_grade_level: playlist.lowest_grade_level,
                        })
                    })
                    return newList
                }, [])

                videosList.forEach(video => {
                    finalRows.push({
                        id: `${video.id}+${Math.floor(Math.random() * 100000)}`,
                        parent: row.id,
                        sub_topic: '',
                        expanded_row: 'expanded_row',
                        video_title: video.title,
                        lowest_grade_level: video.lowest_grade_level,
                        highest_grade_level: video.highest_grade_level
                    });
                })
            }
        });
        return finalRows;
    }, [rows, expandRowIds]);

    const onRowClick = (params, event, details) => {
        if (params.row.expanded_row === 'expanded_row') return null;
        else navigate(`/playlist/${params.id}`);
    }

    const addButton = (
        <Fab color="primary" aria-label="add" onClick={() => setOpenNewPlaylist(true)}>
            <AddIcon />
        </Fab>
    );
    return (
        <PageContainer title={'Topics'} paths={[]} actions={[addButton]}>
            <Card className={'flex-grow h-full'}>
                {rows && <DataGrid
                    autoHeight={true}
                    rows={finalRows}
                    columns={columns}
                    onRowClick={onRowClick}
                    getRowClassName={(params) => {
                        return params.row.expanded_row === 'expanded_row' ? "highlight" : "";
                    }}
                    sx={{
                        ".highlight": {
                            bgcolor: "#bfc2c7",
                            "&:hover": {
                                bgcolor: "#bfc2c7",
                            },
                            ".MuiDataGrid-row.Mui-selected": {
                                bgcolor: "#bfc2c7",
                            },
                        },
                    }}

                />}
            </Card>
            <NewPlaylistDialog open={openNewPlaylist} onClose={() => setOpenNewPlaylist(false)} />
        </PageContainer>
    );
};

export default PlaylistContainer;
