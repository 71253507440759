import React, { useMemo } from 'react';
import tw, { css, styled } from 'twin.macro';
import DropdownFilter from "../../Admin/Accounts/DropdownFilter";
import { useGetAPI } from "../../context/api";
import useSWR from "swr";

const VolumeDropdown = ({ selectedVolume, onChangeVolume }) => {
    const { data } = useSWR('/api/volumes/');

    const items = useMemo(() => {
        const volumes = data?.data?.map(volume => {
            return [volume.id, volume.title];
        }) ?? [];
        return [[null, 'All'], ...volumes];
    }, [data]);

    const handleChangeVolume = (value) => {
        console.log('handleChangeVolume', value);
        if (onChangeVolume) {
            onChangeVolume(value);
        }
    }

    return (
        <DropdownFilter
            label={'Volume'}
            items={items}
            onChange={handleChangeVolume}
            initialSelected={selectedVolume}
            showNone={false}
        />
    );
};

export default VolumeDropdown;
