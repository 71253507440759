import React from "react";
import tw, { styled } from "twin.macro";
import { Button, Card, CardActions, CardContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import { useQuizEditor } from "./QuizEditorProvider";
import { QUIZ_LEVEL_CHOICES } from "./constants";
import { useNavigate, useMatch } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const truncateText = (text, length) => {
    if (text?.length > length) {
        return text?.substring(0, length) + '...';
    }
    return text;
}
/*
selected: <div> MuiSelect-select MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input
list: <li>      MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root
     */

const QuestionDropdownContainer = styled.div`
    .MuiSelect-select {
        .question-text {
            display: none;
        }

        .question-level {
            min-width: 0;
        }

        .question-number {
            min-width: 0;
        }
    }

    .MuiMenuItem-root {
    }
`;

export const QuestionsDropdown = (props) => {
    const navigate = useNavigate();

    const { quiz, questionId } = useQuizEditor();
    const handleChangeQuestion = (event) => {
        navigate(`/quiz/${quiz.id}/question/${event.target.value}`);
    };
    return (
        <QuestionDropdownContainer>
            <FormControl variant={"standard"} className={props.className}>
                {/*<InputLabel id="question-toolbar-question-dropdown-label">Question</InputLabel>*/}
                <Select
                    id="question-toolbar-question-dropdown"
                    labelId="question-toolbar-question-dropdown-label"
                    // label="Question"
                    value={questionId}
                    onChange={handleChangeQuestion}
                >
                    {quiz?.questions?.map(question => {
                        const isSelected = question.id === questionId;
                        return <MenuItem key={question.id} value={question.id}>
                            <div className={'flex flex-row gap-2'}>
                                <div className={'-bg-red-100 flex flex-row gap-1 font-[600] text-lg'}>
                                    <div className={'question-level min-w-[80px] flex flex-row items-center justify-end'}>
                                        {QUIZ_LEVEL_CHOICES[question.level][1]}
                                    </div>
                                    <div className={'question-number min-w-[36px] flex flex-row items-center justify-end'}>
                                        #{question.question_index + 1}
                                    </div>
                                </div>

                                <div className={'question-text text-base font-[400] flex flex-row items-center bg-red-2200 overflow-ellipsis'}>
                                    {truncateText(question.question_text, 80)}
                                </div>
                            </div>

                        </MenuItem>;
                    })}
                </Select>
            </FormControl>
        </QuestionDropdownContainer>
    );
};

const QuizDropdownContainer = styled.div`
    .MuiSelect-select {
        .question-text {
            display: none;
        }

        .question-level {
            min-width: 0;
        }

        .question-number {
            min-width: 0;
        }
    }

    .MuiMenuItem-root {
    }
`;

export const QuizzesDropdown = (props) => {
    const { allQuizzes, quizId } = useQuizEditor();
    const navigate = useNavigate();

    const handleChangeQuiz = (event) => {
        navigate(`/quiz/${event.target.value}`);
    };

    return (
        <QuizDropdownContainer>
            <FormControl variant={"standard"}>
                {/*<InputLabel id="question-toolbar-quiz-dropdown-label">Quiz</InputLabel>*/}
                <Select
                    id="question-toolbar-quiz-dropdown"
                    labelId="question-toolbar-quiz-dropdown-label"
                    // label="Quiz"
                    value={quizId}
                    onChange={handleChangeQuiz}
                >
                    {allQuizzes?.map(quiz => (
                        <MenuItem key={quiz.id} value={quiz.id}>{quiz.title}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </QuizDropdownContainer>
    );
};


export const QuestionEditorToolbar = ({ onSave, disableSave, showSidebar, setShowSidebar }) => {
    // get the current quiz
    const navigate = useNavigate();
    // const match = useMatch();
    // console.log(match);
    const { allQuizzes, quizId, questionId, quiz, question } = useQuizEditor();
    // console.log(allQuizzes);
    // get the list of quizzes
    const handleChangeQuestion = (event) => {
        navigate(`/quiz/${quizId}/question/${event.target.value}`);
        navigate(`/quiz/${quizId}/question/${event.target.value}`);
    }
    const handleChangeQuiz = (event) => {
        navigate(`/quiz/${event.target.value}/question/${event.target.value}`);
    }

    const handleNextQuestion = () => {
        const index = quiz.questions.findIndex(q => q.id === questionId);
        if (index < quiz.questions.length - 1) {
            navigate(`/quiz/${quizId}/question/${quiz.questions[index + 1].id}`);
        }
    }

    const handlePreviousQuestion = () => {
        const index = quiz.questions.findIndex(q => q.id === questionId);
        if (index > 0) {
            navigate(`/quiz/${quizId}/question/${quiz.questions[index - 1].id}`);
        }
    }

    return <Grid item xs={12}>
        <Card sx={{ mb: 0 }}>
            <CardActions className={"bg-blue-100"}>
                <Grid
                    justifyContent="space-between"
                    container
                    alignItems="center"
                    // spacing={24}
                >
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <FormControl variant={"outlined"} sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="question-toolbar-quiz-dropdown-label">Quiz</InputLabel>
                                    <Select
                                        id="question-toolbar-quiz-dropdown"
                                        labelId="question-toolbar-quiz-dropdown-label"
                                        label="Quiz"
                                        value={quizId}
                                        onChange={handleChangeQuiz}
                                    >
                                        {allQuizzes?.map(quiz => (
                                            <MenuItem key={quiz.id} value={quiz.id}>{quiz.title}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item>
                                <FormControl variant={"outlined"} sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="question-toolbar-question-dropdown-label">Question</InputLabel>
                                    <Select
                                        id="question-toolbar-question-dropdown"
                                        labelId="question-toolbar-question-dropdown-label"
                                        label="Question"
                                        value={questionId}
                                        onChange={handleChangeQuestion}
                                    >
                                        {quiz?.questions?.map(question => {
                                            return <MenuItem key={question.id} value={question.id}>
                                                <div className={'flex flex-row gap-2'}>
                                                    <div className={'-bg-red-100 flex flex-row gap-1 font-[600] text-lg'}>
                                                        <div className={'min-w-[80px] flex flex-row items-center justify-end'}>
                                                            {QUIZ_LEVEL_CHOICES[question.level][1]}
                                                        </div>
                                                        <div className={'min-w-[36px] flex flex-row items-center justify-end'}>
                                                            #{question.question_index + 1}
                                                        </div>
                                                    </div>

                                                    <div className={'text-base font-[400] flex flex-row items-center bg-red-2200 overflow-ellipsis'}>
                                                        {truncateText(question.question_text, 80)}
                                                        {/*{question.question_text}*/}
                                                    </div>
                                                </div>

                                            </MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>


                    </Grid>


                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item>
                                <div className={'flex flex-row justify-center items-center h-full'}>
                                    <IconButton
                                        onClick={handlePreviousQuestion}
                                    >
                                        <NavigateBeforeIcon />
                                    </IconButton>

                                    <IconButton
                                        onClick={handleNextQuestion}
                                    >
                                        <NavigateNextIcon />
                                    </IconButton>
                                </div>
                            </Grid>
                            {/*
                            <Grid item>
                                <Button size="large" variant={'outlined'} sx2={{ height: '56px' }} color={'primary'}>
                                    Take Quiz
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={() => setShowSidebar( (value) => !value )} size="large" variant={'outlined'} sx2={{ height: '56px' }} color={'info'}>
                                    {showSidebar ? 'Hide Preview' : 'Show Preview'}
                                </Button>
                            </Grid>
*/}
                            <Grid item>
                                <Button size="large" variant={'outlined'} sx2={{ height: '56px' }} color={'success'} onClick={onSave} disabled={disableSave}>
                                    Save
                                </Button>
                            </Grid>


                        </Grid>


                    </Grid>
                </Grid>

            </CardActions>
        </Card>
    </Grid>;
};

export default QuestionEditorToolbar;
