import React from 'react';
import { Grid, Paper, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    root: {
        padding: '1rem',
        width: '100%',
    },
    form: {
        // width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
});


const FormContainer = ({ onSubmit, children, elevation }) => {
    const classes = useStyles();
    return (
        <Grid container>
            <Paper  className={classes.root} elevation={elevation ?? 3}>
                <form onSubmit={onSubmit} className={classes.form}>
                    <Stack spacing={2}>
                        {children}
                    </Stack>
                </form>
            </Paper>
        </Grid>
    );
};


export default FormContainer;
