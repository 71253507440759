import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, Grid, Stack, Button, FormControlLabel, Checkbox } from "@mui/material";
import InputText from "../Quizzes/QuizEditor/FormControls";
import AddLessonsGroupDialog from '../StudioContainer/Dialogs/AddLessonsGroupDialog';
import ConfirmDialog from "../Quizzes/QuizEditor/ConfirmDialog";
import apiClient from "../../context/APIClient";
import { useNavigate } from "react-router-dom";
import { useStudio } from "../Context/StudioContext";

const CourseEditorContainer = ({ course }) => {
    const [openNewLesson, setOpenNewLesson] = useState(false)
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [checkedTeacher, setCheckedTeacher] = useState(false);
    const [checkedParent, setCheckedParent] = useState(false);


    useEffect(() => {
        if (course.course_type?.teacher) setCheckedTeacher(true);
        if (course.course_type?.parent) setCheckedParent(true);
    }, [])

    const navigate = useNavigate();
    const { mutateCourses } = useStudio();

    const handleChangeValue = (event) => {
        let { name, value } = event.target;
        console.log('handleChangeValue', name, value);
        // updateChoice({ ...question, [name]: value });
    };

    const handleDelete = () => {
        // delete the course and change the url
        apiClient.delete(`/api/admin/courses/${course.id}`)
            .then(response => {
                mutateCourses()
                    .then(() => {
                        navigate('/studio/groupings');
                    })
            })
            .catch(error => {
                console.log(error);
                alert("There was an error deleting the course.")
            })
    }

    const handleChangeCheckBoxTeacher = (event) => {
        const payload = { course_type: { parent: course.course_type?.parent, teacher: event.target.checked } }
        apiClient.patch(`/api/admin/courses/${course.id}/`, payload)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
        setCheckedTeacher(event.target.checked);
    };

    const handleChangeCheckBoxParent = (event) => {
        const payload = { course_type: { teacher: course.course_type?.teacher, parent: event.target.checked } }
        apiClient.patch(`/api/admin/courses/${course.id}/`, payload)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
        setCheckedParent(event.target.checked);
    };


    return (
        <Grid container rowSpacing={1} columnSpacing={1}>

            <Grid item xs={12} md={8} lg={9} xl={9}>
                <Card sx={{ m: 1 }}>
                    <CardHeader title='Group' />
                    <CardContent>
                        <Stack spacing={1}>
                            <InputText onChange={handleChangeValue} id='title' name='title' value={course?.title} label='Title' />
                        </Stack>
                        <div className='mt-6 text-black'>Course Type</div>
                        <FormControlLabel control={<Checkbox
                            checked={checkedTeacher}
                            onChange={handleChangeCheckBoxTeacher}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />} label="Teacher" />
                        <FormControlLabel control={<Checkbox
                            checked={checkedParent}
                            onChange={handleChangeCheckBoxParent}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />} label="Parent" />
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12} md={4} lg={2} xl={2}>
                <Card sx={{ m: 1 }}>
                    <CardContent>
                        <Stack spacing={1}>
                            <Button
                                fullWidth
                                variant='outlined'
                                color='primary'
                                size='small'
                                onClick={() => setOpenNewLesson(true)}
                            >
                                New Lesson Group
                            </Button>

                            <Button
                                fullWidth
                                variant='outlined'
                                color='warning'
                                size='small'
                                onClick={() => setOpenConfirmDelete(true)}
                            >
                                Delete Group
                            </Button>
                        </Stack>
                    </CardContent>
                </Card>
            </Grid>
            <AddLessonsGroupDialog open={openNewLesson} onClose={() => setOpenNewLesson(false)} courseId={course.id} />

            <ConfirmDialog
                open={openConfirmDelete}
                setOpen={setOpenConfirmDelete}
                onConfirm={handleDelete}
                title={'Delete Course'}
                message={'Are you sure you want to delete this course?'}
            />
        </Grid>
    );

};


export default CourseEditorContainer;
