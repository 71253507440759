import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import ToolContainer from "./ToolContainer";
import PropertiesContainer from "./PropertiesContainer";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tab-panel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const TabContainer = ({ tabs, selectedTab, setSelectedTab }) => {
    // const [selectedTab, setSelectedTab] = useState(0);

    function handleTabChange(event, newValue) {
        console.log('tab change');
        setSelectedTab(newValue);
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `tab-panel-${index}`,
        };
    }

    return (
        <Paper sx={{ margin: '10px', width: '25%' }}>


            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example">
                {tabs.map((tab, index) => (<Tab key={tab.id} label={tab.label} {...a11yProps(index)} />))}
            </Tabs>

            {tabs.map((tab, index) => (<TabPanel value={selectedTab} index={index}>
                    {tab.component}
                </TabPanel>
            ))}

        </Paper>
    );
};


export default TabContainer;
