import React, { useCallback, useMemo, useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { useNavigate } from "react-router-dom";
import LibraryEditor from "./Tabs/LibraryEditor";
import { useStudio } from "../Context/StudioContext";
import { useDevTool } from "../../context/DevTool";
import DropdownFilter from "../../Admin/Accounts/DropdownFilter";
import { useParams } from "react-router-dom";
import { useItemEditor } from "../StudioContainer/StudioContainer";
import { Breadcrumbs, Button, ButtonGroup, Link, Tab, Tabs, Typography } from "@mui/material";
import LibrarySettings from "./Tabs/LibrarySettings";
import { v4 as uuidv4 } from "uuid";
import NewGroupDialog from "./NewGroupDialog";
import apiClient from "../../context/APIClient";
import AddLessonsGroupDialog from "../StudioContainer/Dialogs/AddLessonsGroupDialog";
import LessonListDialog from "../StudioContainer/Dialogs/AddExistingLessonDialog";
import AddVideoDialog from './AddVideoDialog';
import LibrarySettingsDialog from "./LibrarySettingsDialog";
import LibraryTreeView from "./Tabs/NewLibraryTreeView";
import CourseTableView from "./CourseTableView";
import LibraryTableView from "./LibraryTableView";
import LibraryDataView from "./Tabs/LibraryDataView";

export const TestCatalog = {
    "catalog": {
        "name": "Master Catalog",
        "description": "Master Catalog",
    },

    "attributes": {
        "test": {
            "name": "test",
            "description": "test",
            "type": "test",
            "data": {
                "test": "test"
            }
        }
    },

    "values": {
        "test": {
            "url": "https://test.com",
            "id": "000",
            "type": "Course",
        }
    },
    "items": [
        {
            "id": "000",
            "name": "test",
            "type": "Course",
            // "description": "",
            // "item_id": "test",
            // "item_slug": "test",
            // "attributes": {
            //     "test": "test"
            // }
        }
    ],
}

const LibraryEditorContainer = () => {
    const navigate = useNavigate();
    const studio = useStudio();
    const { volumes } = useItemEditor();
    const { libraryId } = useParams();
    const { courses, mutateCourses } = studio;
    // const course = courses.find(course => course.slug === 'course-by-grade');
    const course = useMemo(() => {
        console.log("COURSES DID UPDATE");
        return courses.find(course => course.id === libraryId)
    }, [libraryId, courses]);

    console.log("!!! Course groups:", course?.groups.map(group => group.id));

    const volume = useMemo(() => volumes?.find(volume => volume.id === course?.volume_id), [course?.volume_id, volumes]);

    // ui state
    const [tab, setTab] = React.useState('Workspace');
    const [openNewGroup, setOpenNewGroup] = React.useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [openExistingLesson, setOpenExistingLesson] = useState(false)
    const [showLibrarySettings, setShowLibrarySettings] = useState(false);
    const [showVideosPanel, setShowVideosPanel] = useState(false);
    const [showSettingsPanel, setShowSettingsPanel] = useState(false);
    // const [openDeleteLesson, setOpenDeleteLesson] = useState(false)
    // const [deleteLessonGroup, setDeleteLessonGroup] = useState(false)
    useDevTool('Library Editor Container', { course, studio, volume });

    // const [library, setLibrary] = React.useState(null);
    // const onChangeLibrary = (value) => {
    //     console.log('onChangeLibrary', value);
    // };
    // const courseItems = useMemo(() => {
    //     return courses?.map(course => {
    //         return [course.id, course.title];
    //     });
    // }, [courses]);
    const createNewGroup = useCallback((parent, title) => {

        // post the data to the server
        let payload = { title, course: course.id }
        if (parent) {
            payload.parent = parent.id;
        }

        apiClient.post('/api/admin/lesson_groups/', payload)
            .then((response) => {
                console.log(response);
                // mutateCourses();
                // handleClose();
                const { data } = response;
                console.log('new group', data);
                if (data) {
                    const newCourses = courses?.map(course => {
                        // const uniqueId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                        // const uniqueId = uuidv4();
                        if (course.id === libraryId) {
                            return {
                                ...course,
                                groups: [
                                    ...course.groups,
                                    data
                                    // {
                                    //     new: true,
                                    //     id: uniqueId,
                                    //     course: course.id,
                                    //     parent: parent?.id ?? null,
                                    //     title: title,
                                    //     index: 0,
                                    //     icon: null,
                                    //     lessons: []
                                    // }
                                ]
                            }
                        }
                        return course;
                    });
                    console.log('!!!!!! mutateCourses', newCourses, mutateCourses);
                    mutateCourses(newCourses, false);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        console.log('handleNewGroup');
    }, [courses, libraryId, mutateCourses]);

    const addVideoToGroup = useCallback((groupId, lessonId) => {
        // post the data to the server
        console.log('add video to group', groupId, lessonId);

        // get the group
        const group = course.groups.find(group => group.id === groupId);
        if (!group) {
            console.error('Could not find group', groupId);
            return;
        }
        console.log('found group', group);
        console.log('adding video', lessonId);

        setOpenExistingLesson(false);

        // temporary update the group
        const newCourses = courses?.map(course => {
            if (course.id === libraryId) {
                return {
                    ...course,
                    groups: [...course.groups.map(g => g.id === group.id ? ({ ...g, lessons: [...g.lessons, lessonId], saving: true }) : g)]
                }
            }
            return course;
        });
        console.log('!!!!!! mutateCourses', newCourses, mutateCourses);
        mutateCourses(newCourses, false);


        let payload = { lessons: [...group.lessons, lessonId] }
        apiClient.patch(`/api/admin/lesson_groups/${group.id}/`, payload)
            .then((response) => {
                console.log(response);
                // mutateLessons();
                // mutateCourses();
                const { data } = response;
                console.log('new group', data);
                if (data) {
                    const newCourses = courses?.map(course => {
                        if (course.id === libraryId) {
                            return {
                                ...course,
                                groups: [...course.groups.map(group => group.id === data.id ? data : group)]
                            }
                        }
                        return course;
                    });
                    console.log('!!!!!! mutateCourses', newCourses, mutateCourses);
                    mutateCourses(newCourses, false);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        // apiClient.post('/api/admin/lesson_groups/', payload)
        //     .then((response) => {
        //         console.log(response);
        //         // mutateCourses();
        //         // handleClose();
        //         const { data } = response;
        //         console.log('new group', data);
        //         if( data ) {
        //             const newCourses = courses?.map(course => {
        //                 // const uniqueId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        //                 const uniqueId = uuidv4();
        //                 if (course.id === libraryId) {
        //                     return {
        //                         ...course,
        //                         groups: [
        //                             ...course.groups,
        //                             data
        //                             // {
        //                             //     new: true,
        //                             //     id: uniqueId,
        //                             //     course: course.id,
        //                             //     parent: parent?.id ?? null,
        //                             //     title: title,
        //                             //     index: 0,
        //                             //     icon: null,
        //                             //     lessons: []
        //                             // }
        //                         ]
        //                     }
        //                 }
        //                 return course;
        //             });
        //             console.log('mutateCourses', newCourses, mutateCourses);
        //             mutateCourses(newCourses, false);
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
        console.log('handleNewGroup');
    }, [courses, libraryId, mutateCourses]);

    const removeVideoFromGroup = useCallback((groupId, lessonId) => {
        // post the data to the server
        console.log('remove video from group', groupId, lessonId);

        // get the group
        const group = course.groups.find(group => group.id === groupId);
        if (!group) {
            console.error('Could not find group', groupId);
            return;
        }
        console.log('found group', group);
        console.log('removing video', lessonId);

        setOpenExistingLesson(false);

        // temporary update the group
        const newCourses = courses?.map(course => {
            if (course.id === libraryId) {
                return {
                    ...course,
                    // exclude the lesson
                    groups: [...course.groups.map(g => g.id === group.id ? ({ ...g, lessons: [...g.lessons.filter(l => l !== lessonId)], saving: true }) : g)]
                }
            }
            return course;
        });
        console.log('!!!!!! mutate',newCourses);
        mutateCourses(newCourses, false);

        let payload = { lessons: [...group.lessons.filter(l => l !== lessonId)] }
        apiClient.patch(`/api/admin/lesson_groups/${group.id}/`, payload)
            .then((response) => {
                console.log(response);
                // mutateLessons();
                // mutateCourses();
                const { data } = response;
                console.log('new group', data);
                if (data) {
                    const newCourses = courses?.map(course => {
                        if (course.id === libraryId) {
                            return {
                                ...course,
                                groups: [...course.groups.map(group => group.id === data.id ? data : group)]
                            }
                        }
                        return course;
                    });
                    console.log('!!!!!! mutateCourses', newCourses, mutateCourses);
                    mutateCourses(newCourses, false);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        // apiClient.post('/api/admin/lesson_groups/', payload)
        //     .then((response) => {
        //         console.log(response);
        //         // mutateCourses();
        //         // handleClose();
        //         const { data } = response;
        //         console.log('new group', data);
        //         if( data ) {
        //             const newCourses = courses?.map(course => {
        //                 // const uniqueId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        //                 const uniqueId = uuidv4();
        //                 if (course.id === libraryId) {
        //                     return {
        //                         ...course,
        //                         groups: [
        //                             ...course.groups,
        //                             data
        //                             // {
        //                             //     new: true,
        //                             //     id: uniqueId,
        //                             //     course: course.id,
        //                             //     parent: parent?.id ?? null,
        //                             //     title: title,
        //                             //     index: 0,
        //                             //     icon: null,
        //                             //     lessons: []
        //                             // }
        //                         ]
        //                     }
        //                 }
        //                 return course;
        //             });
        //             console.log('mutateCourses', newCourses, mutateCourses);
        //             mutateCourses(newCourses, false);
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
        console.log('handleNewGroup');
    }, [courses, libraryId, mutateCourses]);

    // add a group to a course
    const handleNewGroup = () => {
        setOpenNewGroup(true);
        // createNewGroup(null, "New Group");
    };

    const handleRefresh = () => {
        // mutateCourses();
    };

    const handleCreateNewGroup = (data) => {
        console.log('handleCreateNewGroup', data);
        createNewGroup(null, data.name);
        setOpenNewGroup(false);
    }

    // add a video to a group
    const handleAddVideo = (group) => {
        console.log('add video', group);
        setSelectedGroup(group);
        setOpenExistingLesson(true);
        // createNewGroup(null, "New Group");
    };

    const handleAddVideoToGroup = (video) => {
        addVideoToGroup(selectedGroup, video);
    }

    const handleSave = () => {
        console.log('saving course', course);

        const payload = {
            course_type: course.course_type ?? {},
            groups: course.groups?.map(group => {
                if (group.new) {
                    delete group['id'];
                    delete group['new'];
                    return group;
                }
                return group;
            }),
            lessons: course.lessons
        };

        apiClient.patch(`/api/admin/courses/${course.id}/`, payload)
            .then((response) => {
                console.log(response);
                const { data } = response;
                // navigate(`/studio/course/${data.id}`)
            })
            .catch((error) => {
                console.log(error);
            });

    };
    const [selectedItem, setSelectedItem] = useState(null);

    const onSelectItem = (it) => {
        const { itemType, item } = it;

        console.log('!!! onSelectItem', it);
        setSelectedItem(it);

        if (itemType === 'Group' || itemType === 'Parent Group') {
            // find the real group
            console.log("!!! GROUP:", item);
            const group = course.groups.find(group => group.id === item.id);
            setSelectedGroup(item.id);
        } else if (itemType === 'Lesson') {
            setSelectedGroup(null);
            // handleAddVideo(item);
        } else if (itemType === 'Course') {
            setSelectedGroup(null);
        } else {
            console.error('Unknown item type', itemType);
        }
    }
    console.log('!!!! TOP SELECTION:', selectedItem);

    return (
        <div className='w-full h-full bg-[transparent] flex flex-col'>

            <div className='Toolbar select-none flex flex-col w-full bg-[white] drop-shadow-lg px-10 py-1'>

                <div className={'flex flex-row w-full justify-between items-between'}>

                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href={'/studio'} onClick={(e) => {
                            e.preventDefault();
                            navigate('/studio');
                        }}>
                            <Typography color="inherit">
                                {'Studio'}
                            </Typography>
                        </Link>
                        <Link underline="hover" color="inherit" href={'/studio/library'} onClick={(e) => {
                            e.preventDefault();
                            navigate('/studio/library');
                        }}>
                            <Typography color="inherit">
                                {'Library'}
                            </Typography>
                        </Link>

                        <Typography variant='h5' color="inherit">
                            {course?.title}
                        </Typography>

                    </Breadcrumbs>

                    <div className={'py-0 shrink-0 px-4 flex flex-row items-center gap-4'}>
                        <Button
                            variant='outlined'
                            color='primary'
                            size='small'
                            onClick={() => setShowLibrarySettings(true)}
                        >
                            Settings
                        </Button>
                        <Button
                            variant='outlined'
                            color='primary'
                            size='small'
                            onClick={handleNewGroup}
                        >
                            Add Group
                        </Button>
                        <Button
                            disabled={selectedItem === null}
                            variant='outlined'
                            color='primary'
                            size='small'
                            onClick={() => handleAddVideo(selectedItem?.id)}
                        >
                            Add Video
                        </Button>
                        <Button
                            disabled={selectedItem === null}
                            variant='outlined'
                            color='warning'
                            size='small'
                            // onClick={handleDeleteGroup}
                        >
                            Delete Group
                        </Button>
                        {/*
                        <Button
                            variant='outlined'
                            color='primary'
                            size='small'
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                        */}
                    </div>

                </div>

                <div className={'hidden flex-row w-full justify-between items-between'}>

                    <div className={'flex flex-col py-1 shrink-0 px-4 flex flex-row items-center gap-1'}>
                        <div className={'py-0 w-full'}>
                            Library: <span className={'font-bold'}>{course?.title}</span>
                        </div>

                        <div className={'py-0 w-full'}>
                            Volume: <span className={'font-bold'}>{volume?.title ?? course?.volume}</span>
                        </div>
                    </div>

                    <div className={'flex flex-row grow w-full'} />

                    <div className={'py-0 shrink-0 px-4 flex flex-row items-center gap-4'}>

                        <ButtonGroup variant="" aria-label="outlined primary button group">

                            <Button
                                variant={showVideosPanel ? 'contained' : 'outlined'}
                                // size='small'
                                onClick={() => setShowVideosPanel(value => !value)}
                            >
                                Videos
                            </Button>

                            <Button
                                variant={showSettingsPanel ? 'contained' : 'outlined'}
                                // size='small'
                                onClick={() => setShowSettingsPanel(value => !value)}
                            >
                                Settings
                            </Button>
                        </ButtonGroup>
                    </div>

                </div>

                <Tabs
                    value={tab}
                    onChange={(event, newValue) => setTab(newValue)}
                    aria-label="Video Tabs"
                >
                    <Tab
                        key={'Workspace'}
                        label={'Workspace'}
                        value={'Workspace'}
                        aria-controls={`tab-panel-Workspace`}
                    />
                    <Tab
                        key={'Tree'}
                        label={'Tree'}
                        value={'Tree'}
                        aria-controls={`tab-panel-Tree`}
                    />
                    <Tab
                        key={'Table'}
                        label={'Table'}
                        value={'Table'}
                        aria-controls={`tab-panel-Table`}
                    />
                    <Tab
                        key={'Settings'}
                        label={'Settings'}
                        value={'Settings'}
                        aria-controls={`tab-panel-Settings`}
                    />
                    <Tab
                        key={'Data'}
                        label={'Data'}
                        value={'Data'}
                        aria-controls={`tab-panel-Data`}
                    />
                </Tabs>
            </div>

            <div className={'w-full flex-grow flex flex-row bg-[transparent] overflow-auto bg-[re'}>
                {tab === 'Tree' && <LibraryTreeView course={course} onSelectItem={onSelectItem} selectedItem={selectedItem} />}
                {tab === 'Workspace' && (
                    <LibraryEditor
                        course={course}
                        createNewGroup={createNewGroup}
                        addVideo={handleAddVideo}
                        removeVideo={removeVideoFromGroup}
                        showVideosPanel={showVideosPanel}
                        showSettingsPanel={showSettingsPanel}
                        onSelectItem={onSelectItem}
                        selectedItem={selectedItem}
                    />
                )}
                {tab === 'Table' && <LibraryTableView course={course} />}
                {tab === 'Settings' && <LibrarySettings course={course} />}
                {tab === 'Data' && <LibraryDataView course={course} />}
            </div>

            <NewGroupDialog open={openNewGroup} onClose={() => setOpenNewGroup(false)} onSuccess={handleCreateNewGroup} />

            <AddVideoDialog
                open={openExistingLesson}
                onClose={() => setOpenExistingLesson(false)}
                volumeId={course?.volume_id}
                onSuccess={handleAddVideoToGroup}
                // groupLessons={lessonGroup.lessons}
                // courseId={lessonGroup.course}
                // parentId={lessonGroup.id}
            />

            {course && <LibrarySettingsDialog
                course={course}
                open={showLibrarySettings}
                onClose={() => setShowLibrarySettings(false)}
            />}


            {/*<AddLessonsGroupDialog open={true} onClose={() => setOpenNewGroup(false)} courseId={course?.id} />*/}
            {/*<AddLessonsGroupDialog open={openNewLesson} onClose={() => setOpenNewLesson(false)} courseId={course.id} />*/}

        </div>
    );
};

export default LibraryEditorContainer;
