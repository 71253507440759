import React, { useMemo, createContext, useCallback, useContext, useEffect, useState } from 'react';
import 'twin.macro';
// import { useNavigate } from 'react-router-dom';
import apiClient from "./APIClient";
import { useDevTool } from "./DevTool";
import uniqid from "uniqid";
import { useTabIdentifier } from "./Sessions";
// import { handleAPIError } from 'utils/api-client';
// import { formatNetworkError, handleNetworkError } from '../../lib/axios';

export const AuthContext = createContext(null);

// configure client for API calls
const AuthProvider = (props) => {
    // const navigate = useNavigate();
    // const { setErrorMessage } = useError();

    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [initialStore, setInitialStore] = useState({});
    const tabId = useTabIdentifier();

    // const [csrfToken, setCSRFToken] = useState(null);
    useDevTool('auth', { isLoggedIn, isLoggingIn, initialStore, tabId });

    // get the session
    useEffect(() => {
        getSession()
            .then((response) => {
                console.log('[AuthProvider] initial getSession success', response);
            })
            .catch((error) => {
                console.log('[AuthProvider] initial getSession error', error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSession = () => {
        // console.log('[Auth] getSession');
        return apiClient
            .get('/api/session/', { withCredentials: true })
            .then(({ data }) => {
                console.log('session response:', data);
                if (data?.isAuthenticated) {
                    console.log('Auth Session: Currently logged in, checking user');
                    return apiClient.get('/api/me/')
                        .then(({ data }) => {
                            console.log('Auth Session: User:', data);

                            // validate the user - only staff users are allowed
                            if (!data?.user?.is_staff) {
                                // user is not staff, disallow login
                                // logout the user
                                return apiClient('/api/logout')
                                    .then(({ data }) => {
                                        // redirecting to login will reload the page
                                        // setIsLoggedIn(false);
                                        window.location.href = '/login';
                                        setIsLoggedIn(false);
                                        return false;
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        window.location.href = '/login';
                                        setIsLoggedIn(false);
                                        return false;
                                    });
                            }

                            console.log('Auth Session: Currently logged in, checking user');
                            setIsLoggedIn(true);
                            setInitialStore(data);
                            return true;
                        })
                        .catch((err) => {
                            console.log('Error getting user', err);
                            // setErrorMessage(formatNetworkError(err));
                            // handleNetworkError(err);
                        });
                    // });
                } else {
                    console.log('Auth Session: Not logged in');
                    setIsLoggedIn(false);
                    return false;
                }
            })
            .catch((err) => {
                console.log('Error getting session', err);
                // setErrorMessage(formatNetworkError(err));
                // handleNetworkError(err);
            });
    };

    // logout of the app
    const logout = useCallback(() => {
        console.log('[AuthProvider] logout');
        apiClient('/api/logout')
            .then(({ data }) => {
                // redirecting to login will reload the page
                // setIsLoggedIn(false);
                window.location.href = '/login';
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    // login to the app, async function returns the initial payload or false
    const login = useCallback(async (username, password) => {
        try {
            // console.log('Logging in', username);
            setIsLoggingIn(true);

            // clear the login flag during login,
            // to prevent the app from logging back out due to the URL '/login'
            // console.log('setLoggedIn: null');
            // setIsLoggedIn(null);

            // perform a login
            const response = await apiClient.post(
                '/api/login/',
                { username, password }
                // {
                //     withCredentials: true,
                //     headers: {
                //         'Content-Type': 'application/json'
                //         // 'X-CSRFToken': csrfToken
                //     }
                // }
            );
            console.log('Login successful', response);

            // login successful, get the payload
            const { data: payload } = response;
            console.log('Initial Data:', payload);

            // store the initial payload - need to get the payload into the user provider
            setInitialStore(payload);

            // clear the login flag during login,
            // to prevent the app from logging back out due to the URL '/login'
            // console.log('setLoggedIn: null');
            setIsLoggedIn(null);
            // redirect to change the URL before changing isLoggedIn state
            // check for redirect
            let newPath = '/';
            if (window.location.search.startsWith('?next='))
                newPath = decodeURIComponent(window.location.search.slice(6));
            console.log('new path', newPath);
            //!! navigate(newPath);
            window.history.replaceState({}, document.title, newPath);
            // console.log('Redirecting to app', newPath);

            // login complete
            // console.log('setIsLoggingIn false');
            setIsLoggingIn(false);
            // console.log('setIsLoggedIn true');
            setIsLoggedIn(true);
            return true;
        } catch (error) {
            // login failure
            console.log('Login error', error);
            // handleAPIError(error);

            // set not logged in
            setIsLoggingIn(false);
            setIsLoggedIn(false);

            // if (error.response) {
            //     // The request was made and the server responded with a status code that falls out of the range of 2xx
            //     console.log(error.response.data);
            //     console.log(error.response.status);
            //     console.log(error.response.headers);
            // }

            throw error;
        }
    }, []);

    // build the context
    const context = useMemo(() => {
        return {
            login,
            logout,
            isLoggedIn,
            isLoggingIn, //: isLoggedIn === null,
            initialStore
        };
    }, [initialStore, isLoggedIn, isLoggingIn, login, logout]);

    // first render, don't mount anything until we check any existing credentials
    if (isLoggedIn === null) {
        // still checking if our credentials are valid
        return null;
        // return <div tw='background[green] h-full w-full' />;
    }

    // console.log('[AuthContext]', context);
    return <AuthContext.Provider value={context}>{props.children}</AuthContext.Provider>;
};

export function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}

// useAuth with auto login
export function useAuthLogin(username, password) {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuthLogin must be used within an AuthProvider');
    }

    useEffect(() => {
        console.log('[useAuthLogin] initial login()');
        if (username && password) {
            const { login } = context;
            login(username, password);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username, password]);

    return context;
}

export default AuthProvider;
