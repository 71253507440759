import QuizSharpIcon from "@mui/icons-material/QuizSharp";
import VideoLibrarySharpIcon from "@mui/icons-material/VideoLibrarySharp";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { AccountTreeSharp, CollectionsBookmarkSharp, FeaturedPlayListSharp, ImageSharp, WorkSharp, WorkspacesOutlined, WorkspacesSharp } from "@material-ui/icons";
import { WorkHistorySharp, WorkspacePremiumRounded } from "@mui/icons-material";

export const root_menu_items = [
    // {
    //     title: 'Admin',
    //     path: '/admin',
    //     icon: <FeaturedPlayListSharp />,
    // },
    {
        title: 'Studio',
        path: '/studio',
        icon: <FeaturedPlayListSharp />,
    },
    // {
    //     title: 'Server Admin',
    //     url: 'http://localhost:8000/admin/',
    //     icon: <FeaturedPlayListSharp />,
    // },
    // {
    //     title: 'Operations',
    //     path: '/ops',
    //     icon: <FeaturedPlayListSharp />,
    // },
];

export const admin_menu_items = [
    {
        title: 'Console',
        url: '/admin/console',
        icon: <FeaturedPlayListSharp />,
    },
    {
        title: 'Account Creation',
        url: '/admin/create-account',
        icon: <FeaturedPlayListSharp />,
    },
    {
        title: 'Accounts',
        url: '/admin/accounts',
        icon: <FeaturedPlayListSharp />,
    },
];
