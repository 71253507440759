import React from 'react';
import ChatStream from "./ChatStream/ChatStream";
import tw from 'twin.macro';
import { Card, Container, Grid } from "@mui/material";

const chat_models = [
    // 'gpt-4',
    // 'gpt-4-0314',
    // 'gpt-4-32k',
    // 'gpt-4-32k-0314',
    'gpt-3.5-turbo',
    'gpt-3.5-turbo-0301'
]

const completion_models = [
    'text-davinci-003',
    'text-davinci-002',
    'text-curie-001',
    'text-babbage-001',
    'text-ada-001',
    'davinci',
    'curie',
    'babbage',
    'ada'
]

const ChatGPT = (props) => {
    return (
        <Container maxWidth={false} className=' bg-red-100- h-full' spacing={2}>
            <Grid container spacing={2} className={' h-full bg-orange-300-'}>
                <Grid item xs={12} className={' h-full bg-orange-300-'}>
                    <ChatStream  model={'gpt-4'} />
                </Grid>
            </Grid>
        </Container>
        /*
                <div className={'w-full h-full bg-red-300 flex overflow-hidden '}>
                    <ChatStream  model={'gpt-4'}/>
                </div>
        */
    );
};


export default ChatGPT;
