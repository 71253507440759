import React, { useState, useEffect, useCallback } from 'react';
import { Outlet, useParams, useNavigate } from "react-router-dom";
import { useGetAPI } from "../../context/api";
import { Backdrop, CircularProgress } from "@mui/material";
import apiClient from '../../context/APIClient';


export const TopicsContext = React.createContext([]);

const TopicsEditorProvider = (props) => {
    const { topicId } = useParams();
    const navigate = useNavigate();
    const { loading, error, data } = useGetAPI('/api/assignment_topics/');


    const [topics, setTopics] = useState([])

    useEffect(() => {
        if (data) setTopics(data)
    }, [data]);

    const fetchTopics = () => {
        return apiClient.get('/api/assignment_topics/')
            .then(({ data }) => {
                setTopics(data)
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const updateGradeLevel = (gradeLevel, type, playlistId, parentId) => {
        setTopics(topics.map(topic => {
            if (topic.parent === parentId) {
                return {
                    ...topic,
                    playlists: topic.playlists.map(playlist => {
                        return playlist.id === playlistId ? { ...playlist, id: playlistId, [type]: gradeLevel } : playlist
                    })
                }
            }
            if (topic.id === topicId) {
                return {
                    ...topic,
                    playlists: topic.playlists.map(playlist => {
                        return playlist.id === playlistId ? { ...playlist, id: playlistId, [type]: gradeLevel } : playlist
                    })
                }
            }
            else return topic
        }))

    }
    const updateName = (name, playlistId, parentId) => {
        setTopics(topics.map(topic => {
            if (topic.parent === parentId) {
                return {
                    ...topic,
                    playlists: topic.playlists.map(playlist => {
                        return playlist.id === playlistId ? { ...playlist, id: playlistId, name: name } : playlist
                    })
                }
            }
            if (topic.id === topicId) {
                return {
                    ...topic,
                    playlists: topic.playlists.map(playlist => {
                        return playlist.id === playlistId ? { ...playlist, id: playlistId, name: name } : playlist

                    })
                }
            }
            else return topic
        }))
    }

    const savePlaylist = () => {
        let payload = topics.find(topic => topic.id === topicId);
        console.log("🚀 ~ file: TopicsEditorProvider.js:66 ~ savePlaylist ~ payload:", payload)
        apiClient.patch(`/api/admin/topics/${topicId}/`, payload)
            .then((response) => {
                navigate('/playlists/');
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const savePlaylistAndContinueEditing = () => {
        let payload = topics.find(topic => topic.id === topicId);
        console.log("🚀 ~ file: TopicsEditorProvider.js:66 ~ savePlaylist ~ payload:", payload)
        apiClient.patch(`/api/admin/topics/${topicId}/`, payload)
            .then((response) => {
                fetchTopics();
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const value = { loading, topics: topics, updateName, updateGradeLevel, savePlaylist, savePlaylistAndContinueEditing, fetchTopics }
    // handle loading states
    if (loading)
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    if (error)
        return <div>Error: {error.message}</div>;
    if (!data)
        return <div>No data...</div>;

    if (error) return <div>Error: {error}</div>;

    return (
        <TopicsContext.Provider value={value}>
            <Outlet />
        </TopicsContext.Provider>
    );
};

export function useTopics() {
    const context = React.useContext(TopicsContext);
    if (context === undefined) {
        throw new Error(`useVideos must be used within a VideosContainer`)
    }
    return context
}

export default TopicsEditorProvider;