import React, { useState } from "react";

export function DynamoEditor() {
    const [questions, setQuestions] = useState([]);
    const [loopStart, setLoopStart] = useState(0);
    const [loopEnd, setLoopEnd] = useState(0);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);

    function addQuestion() {
        setQuestions([
            ...questions,
            {
                question: "",
                answers: ["", "", "", ""],
                correctAnswerIndex: 0,
            },
        ]);
        setCurrentQuestionIndex(questions.length);
    }

    function removeQuestion(index) {
        const newQuestions = [...questions];
        newQuestions.splice(index, 1);
        setQuestions(newQuestions);
        setCurrentQuestionIndex(-1);
    }

    function handleQuestionChange(event, index) {
        const newQuestions = [...questions];
        newQuestions[index].question = event.target.value;
        setQuestions(newQuestions);
    }

    function handleAnswerChange(event, questionIndex, answerIndex) {
        const newQuestions = [...questions];
        newQuestions[questionIndex].answers[answerIndex] = event.target.value;
        setQuestions(newQuestions);
    }

    function handleCorrectAnswerChange(event, questionIndex) {
        const newQuestions = [...questions];
        newQuestions[questionIndex].correctAnswerIndex = event.target.value;
        setQuestions(newQuestions);
    }

    function handleLoopStartChange(event) {
        setLoopStart(parseInt(event.target.value));
    }

    function handleLoopEndChange(event) {
        setLoopEnd(parseInt(event.target.value));
    }

    function handleQuestionSelect(index) {
        setCurrentQuestionIndex(index);
    }

    return (
        <div>
            <div>
                <h2>Questions</h2>
                <ul>
                    {questions.map((question, index) => (
                        <li key={index}>
                            <button onClick={() => removeQuestion(index)}>Remove</button>
                            <button onClick={() => handleQuestionSelect(index)}>
                                {index === currentQuestionIndex ? "▼" : "►"} Question {index +
                                1}
                            </button>
                        </li>
                    ))}
                    <li>
                        <button onClick={addQuestion}>Add Question</button>
                    </li>
                </ul>
            </div>
            <div>
                <h2>Video Player</h2>
                <label>
                    Loop Start Time (in seconds):
                    <input type="number" value={loopStart} onChange={handleLoopStartChange} />
                </label>
                <br />
                <label>
                    Loop End Time (in seconds):
                    <input type="number" value={loopEnd} onChange={handleLoopEndChange} />
                </label>
                <br />
                <video src="your_video_url_here.mp4" loop />
            </div>
            {currentQuestionIndex !== -1 && (
                <div>
                    <h2>Question {currentQuestionIndex + 1}</h2>
                    <label>
                        Question:
                        <input
                            type="text"
                            value={questions[currentQuestionIndex].question}
                            onChange={(event) =>
                                handleQuestionChange(event, currentQuestionIndex)
                            }
                        />
                    </label>
                    <br />
                    <label>
                        Answer 1:
                        <input
                            type="text"
                            value={questions[currentQuestionIndex].answers[0]}
                            onChange={(event) =>
                                handleAnswerChange(event, currentQuestionIndex, 0)
                            }
                        />
                    </label>
                    <br />
                    <label>
                        Answer 2:
                        <input
                            type="text"
                            value={questions[currentQuestionIndex].answers[1]}
                            onChange={(event) =>
                                handleAnswerChange(event, currentQuestionIndex, 1)
                            }
                        />
                    </label>
                    <br />
                    <label>
                        Answer 3:
                        <input
                            type="text"
                            value={questions[currentQuestionIndex].answers[2]}
                            onChange={(event) =>
                                handleAnswerChange(event, currentQuestionIndex, 2)
                            }
                        />
                    </label>
                    <br />
                    <label>
                        Answer 4:
                        <input
                            type="text"
                            value={questions[currentQuestionIndex].answers[3]}
                            onChange={(event) =>
                                handleAnswerChange(event, currentQuestionIndex, 3)
                            }
                        />
                    </label>
                    <br />
                    <label>
                        Correct Answer:
                        <select
                            value={questions[currentQuestionIndex].correctAnswerIndex}
                            onChange={(event) =>
                                handleCorrectAnswerChange(event, currentQuestionIndex)
                            }
                        >
                            <option value={0}>Answer 1</option>
                            <option value={1}>Answer 2</option>
                            <option value={2}>Answer 3</option>
                            <option value={3}>Answer 4</option>
                        </select>
                    </label>
                </div>
            )}
        </div>
    );
}
