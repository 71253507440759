import React from 'react';
import { Dialog, DialogActions, Button, DialogTitle } from "@mui/material";
import apiClient from '../../../context/APIClient';
import { useStudio } from '../../Context/StudioContext';

const DeleteLessonDialog = ({ open, onClose, lessonGroup, deleteLessonGroup, lesson }) => {

    const studio = useStudio();
    const { mutateCourses, mutateLessons, lessonGroups } = studio;

    const handleClose = () => {
        onClose();
    }

    const onSubmit = () => {
        if (deleteLessonGroup) {
            apiClient.delete(`/api/admin/lesson_groups/${lessonGroup.id}/`)
                .then((response) => {
                    mutateLessons();
                    mutateCourses();
                    handleClose();
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        if (!deleteLessonGroup) {
            // find lesson group attached to lesson id
            let foundGroup = lessonGroups.find(lg => lg.id === lessonGroup)
            let removeLesson = foundGroup?.lessons.filter(ids => ids !== lesson.id)
            const payload = { lessons: removeLesson }
            apiClient.patch(`/api/admin/lesson_groups/${lessonGroup}/`, payload)
                .then((response) => {
                    mutateLessons();
                    mutateCourses();
                    handleClose();
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={handleClose}
            aria-labelledby="lessons-dialog"
        >
            <DialogTitle>This will remove <span className='text-[#1976d2]'>{lessonGroup?.title ?? lesson?.title}</span> as a lesson group</DialogTitle>
            <DialogActions>
                <Button type="button" color="primary" variant="outlined" size="small" onClick={onClose}>
                    Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" size="small" className='ml-4' onClick={() => onSubmit()}>
                    Delete
                </Button>
            </DialogActions >
        </Dialog>
    );
};

export default DeleteLessonDialog