import React, { useCallback, useState } from 'react';
import { Button, FormControl, Grid, InputLabel, OutlinedInput, Box, IconButton } from "@mui/material";
import { useDropzone } from "react-dropzone";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import apiClient from '../../../context/APIClient';
import { useStudio } from '../../Context/StudioContext';


const LyricsPanel = ({ video, updateVideo }) => {

    const [lyrics, setLyrics] = useState(video.lyrics)

    const studio = useStudio();
    const { mutateVideos } = studio;
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        console.log(acceptedFiles);
        acceptedFiles.map((file) => {
            const formData = new FormData();
            formData.append("file", file);
            formData.append('video_id', video.id);
            console.log('<LyricsPanel> uploading lyrics to video', video);
            return apiClient.put("/api/upload_lyrics/", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Disposition": `attachment; filename="${file.name}"`
                },
            })
                .then(response => {
                    const data = response.data;
                    console.log('<LyricsPanel> upload complete:', data);
                    if (video)
                        updateVideo({ lyrics_file: data.path });
                })
                .catch( error => {
                    console.log('<LyricsPanel> upload failed:', error);
                    console.log(error);
                })
        });
    }, [updateVideo, video]);

    const onHandleLyricChange = (event) => {
        let { value } = event.target;
        setLyrics(value)
    }

    const saveLyrics = () => {
        console.log('<LyricsPanel> save lyrics text');
        apiClient.patch(`/api/video/${video.id}/`, { lyrics: lyrics })
            .then((response) => {
                console.log('<LyricsPanel> save completed', response.data);
                mutateVideos()
            })
            .catch((error) => {
                console.log('<LyricsPanel> save failed', error);
                console.log(error);
            });
    }

    /*
    <div className={'flex justify-center items-center m-2'}>
        <InputText
            id='lyrics_file'
            label='File'
            name='lyrics_file'
            value={video?.lyrics_file}
            onChange={(e) => {
                if (video) {
                    updateVideo({ id: video.id, url_thumbnail: e.target.value })
                }
            }}
        />
    </div>
    */
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
    return (
        <Grid container>

            <div className={'w-full relative border-2 border-solid rounded-lg cursor-pointer overflow-hidden my-5'}>
                <div
                    className='min-h-[26px] flex justify-center items-center'
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    {video?.lyrics_file ?
                        <div
                            // onClick={(event) => event.stopPropagation()}
                            className={'flex justify-center items-center m-2 min-h-[60px]'}>
                            <a
                                onClick={(event) => event.stopPropagation()}
                                className={'underline'} href={`https://public.muzology.com/lyrics/${video?.lyrics_file}`}
                                target='_blank'
                            >
                                {video?.lyrics_file}
                            </a>
                        </div>
                        /*
                            <div className={'text-[#999999] bg-[#eeeeee] hover:bg-[#dddddd] active:bg-[#ffffff] font-bold text-xl min-h-[60px] w-full h-full flex justify-center items-center px-10'}>
                            </div>
                        */
                        :
                        <div className={'text-[#999999] bg-[#eeeeee] hover:bg-[#dddddd] active:bg-[#ffffff] font-bold text-xl min-h-[60px] w-full h-full flex justify-center items-center px-10'}>
                            {isDragActive ? "Drop here" : "Drag PDF here, or click to upload."}
                        </div>
                    }
                </div>
                <Box sx={{ position: 'absolute', right: 0, top: 0, display: 'flex', flexDirection: 'column' }}>
                    <IconButton
                        disabled={video?.lyrics_file ? false : true}
                        sx={{ position: 'absolute', right: 0, top: 0 }}
                        onClick={() => updateVideo({ lyrics_file: null })}
                    >
                        <DeleteSharpIcon />
                    </IconButton>
                </Box>
            </div>


            <Grid item xs={12}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">Lyrics Text</InputLabel>
                    <OutlinedInput
                        multiline
                        id="outlined-adornment-amount"
                        value={lyrics}
                        minRows={10}
                        // rows={10}
                        onChange={onHandleLyricChange}
                        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        label="Lyrics Text"
                    />
                </FormControl>
            </Grid>
            <Grid container justifyContent="flex-end">
                <Button size="large" color="primary" variant="contained" onClick={saveLyrics}>
                    Save
                </Button>
                {/*
                <Button size="small" color="error" variant="contained">
                    Cancel
                </Button>
                */}

            </Grid>
        </Grid>
    );
};


export default LyricsPanel;
