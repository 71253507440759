import React, { useMemo } from 'react';
import { Button, Container, Grid } from "@mui/material";
import { useNavigate, Outlet } from "react-router-dom";
import { studio_menu_items } from "./studio_menu_items";
import { PageContainer } from "../Home/PageContainer";
import MenuGrid from "../Home/MenuGrid";
import { useDevTool } from "../context/DevTool";

const Context = React.createContext();

const HomeContainer = (
    {
        title = null,
        actions = [],
        menu_items = studio_menu_items,
        initialBreadcrumbs = [],
        rootTitle,
        rootPath,
        children,
        maxWidth = 'lg',
    }
) => {
    const navigate = useNavigate();

    const [breadcrumbs, setBreadcrumbs] = React.useState([]);

    const value = useMemo(() => (
        { breadcrumbs, setBreadcrumbs }
    ), [breadcrumbs, setBreadcrumbs]);

    const allBreadcrumbs = [...initialBreadcrumbs, ...breadcrumbs];

    const pageTitle = breadcrumbs.length > 0 ? breadcrumbs[0].title : title;

    useDevTool('HomeContainer', {title, breadcrumbs, initialBreadcrumbs, menu_items, allBreadcrumbs});

    return (
        <Context.Provider value={value}>
            <PageContainer
                maxWidth={maxWidth}
                title={title}
                actions={actions}
                rootTitle={rootTitle}
                rootPath={rootPath}
                paths={initialBreadcrumbs}
                breadcrumbs={breadcrumbs}
            >
                <Container sx={{ padding: '2em' }} maxWidth={'xl'}>
                    <Outlet />

                    {menu_items && <MenuGrid menu_items={menu_items} />}

                    {children}
                </Container>
            </PageContainer>
        </Context.Provider>
    );
};

export function useBreadcrumbs() {
    const context = React.useContext(Context);
    if (context === undefined) {
        throw new Error('useBreadcrumbs must be used within a HomeContainer');
    }
    return context;
}

export function useBreadcrumb(title, url) {
    const { breadcrumbs, setBreadcrumbs } = useBreadcrumbs();

    React.useEffect(() => {
        if (title && url) {
            setBreadcrumbs([{ title, url }]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, url]);

    return { breadcrumbs, setBreadcrumbs };
}

export default HomeContainer;
