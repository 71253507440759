import React from 'react';
import HealthCheckPanel from "../../Panels/HealthCheck/HealthCheckPanel";
import { Grid, Stack, Typography } from "@mui/material";
import { useDevTool } from "../../../context/DevTool";
import apiClient from "../../../context/APIClient";
import { QUESTION_TYPE_MULTIPLE_CHOICE, QUESTION_TYPE_MULTIPLE_SELECTION } from "../../Quizzes/QuizEditor/constants";
import Panel from "../../../Components/Dashboard/Panel";
import { addStatusRow } from "../../Quizzes/QuizEditor/HealthCheck";

const VideoHealthCheck = ({ video }) => {
    const [checks, setChecks] = React.useState([]);
    const [isValid, setIsValid] = React.useState(video?.healthcheck_valid);
    useDevTool('checks', checks);

    React.useEffect(() => {
        apiClient.get(video ? `/api/video/${video.id}/check/?force=true` : null)
            .then(response => {
                console.log('response', response);
                const { valid, checks } = response.data;
                setIsValid(valid);
                setChecks(checks);
            })
            .catch(error => {
                console.log('error', error);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container rowSpacing={4} spacing={1}>
            <Grid item xs={12}>
                <Stack spacing={2}>
                    <Typography variant={'h4'}>{isValid ? <div className="m-auto bg-green-200 text-green-700 px-2 py-1 rounded">Health Check: PASS</div> :
                        <div className="m-auto  bg-red-200 text-red-700 px-2 py-1 rounded">Health Check: FAIL</div>}</Typography>

                    <Panel title={'Health Checks'}>
                        <Stack spacing={0}>
                            <table className="table-auto w-full">
                                <tbody>
                                {checks.map(check => {
                                    console.log(check);
                                    return addStatusRow(check.title, check.valid, check.errors)
                                })}
                                </tbody>
                            </table>
                        </Stack>
                    </Panel>

                </Stack>
            </Grid>
        </Grid>
    );

    // return (
    //     <Grid container>
    //         <Grid item xs={12}>
    //             <HealthCheckPanel>
    //                 <h1>
    //                     Video Health Check
    //                 </h1>
    //                 {props.children}
    //             </HealthCheckPanel>
    //         </Grid>
    //     </Grid>
    // );
};


export default VideoHealthCheck;
