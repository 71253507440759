import React from 'react';
import Panel from "../../../../Components/Dashboard/Panel";
import { Stack, Grid } from "@mui/material";

const QuizPublishTab = ({ quiz }) => {

    React.useEffect( () => {
        // begin a check for the quiz



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                Publish {quiz.title}
            </Grid>
            <Panel title={'Publishing'}>
                <Stack spacing={0}>
                    <table className="table-auto w-full">
                        <tr>
                            <td className="px-4 py-2 w-0 ">
                                            <span className="m-auto bg-green-200 text-green-700 px-2 py-1 rounded">
                                                YES
                                            </span>
                            </td>
                            <td className="px-4 py-2 ">
                                Published
                            </td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 ">
                                            <span className="m-auto  bg-red-200 text-red-700 px-2 py-1 rounded">
                                                YES
                                            </span>
                            </td>
                            <td className="px-4 py-2 ">
                                Valid
                            </td>
                        </tr>

                    </table>
                </Stack>
            </Panel>

        </Grid>
    );
};

export default QuizPublishTab;