import React, { useState } from 'react';
import { useLocalStorage } from "./LocalStorage";
// import { useNavigate } from "react-router-dom";
import { useDevTool } from "./DevTool";
import api from './APIClient';
import {useAuth} from "./AuthProvider";

export const UserContext = React.createContext({ user: null, setUser: null });

const UserProvider = (props) => {
    const [user, setUser] = useState(null);
    const [account, setAccount] = useState(null);
    // const [user, setUser] = useLocalStorage('auth', null);
    // const navigate = useNavigate()
    const auth = useAuth();
    useDevTool('user', user);


    React.useEffect( () => {
        if( auth.isLoggedIn ) {
            setUser(auth.initialStore.user);
            setAccount(auth.initialStore.account);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isLoggedIn, auth.initialStore]);

    return (
        <UserContext.Provider value={{ user, setUser, account }}>
            {props.children}
        </UserContext.Provider>
    );
};

export function useUser() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error(`useUser must be used within a UserProvider`)
    }
    return context
}

export default UserProvider;
