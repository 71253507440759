import { useMemo } from "react";

export const getRootGroups = (course) => course?.groups?.filter(group => !group.parent) ?? [];

// , [allLessons, course?.groups, rootGroups, lessonPaletteData])


export const createTreeData = (course, treeData=null, allLessons= null) => {
    const rootGroups = getRootGroups(course);

    const tree = treeData ?? {

        root: {
            id: 'root',
            index: 'root',
            itemType: 'Root',
            isFolder: true,
            children: ['course'],
            data: 'Root',
            title: 'Root',
            item: null
        },

        course: {
            id: 'course',
            index: 'course',
            itemType: 'Course',
            isFolder: true,
            children: [],
            data: 'Course',
            title: 'Course',
            item: course
        }
    };

    const vs = {}

    const addChildGroups = (groupId, tree, treeId, viewState, depth = 1) => {

        // find the children of this group
        const childGroups = course?.groups?.filter(childGroup => childGroup.parent === groupId) ?? [];
        childGroups.forEach(childGroup => {
            tree[childGroup.id] = {
                id: childGroup.id,
                index: childGroup.id,
                parentId: groupId,
                itemType: 'Group',
                isFolder: true,
                children: [],
                title: childGroup.title,
                data: childGroup.title,
                item: childGroup,
                depth: depth
            };

            // add the child group to the parent group
            if (tree[groupId]) {
                tree[groupId].children.push(childGroup.id);
            }

            viewState[treeId]?.expandedItems.push(childGroup.id);

            // viewState = { ...viewState, [groupId]: {
            //     focusedItem: childGroup.id,
            //     selectedItems: [childGroup.id],
            //     expandedItems: [childGroup.id],
            // }};
            // viewState[groupId]?.expandedItems.push(childGroup.id);

            // add the group's lessons
            childGroup?.lessons?.forEach(lessonId => {
                // console.log('adding lesson', lessonId);
                const lesson = allLessons?.find(lesson => lesson.id === lessonId);
                // console.log('lesson', lesson);
                if (!lesson)
                    return null;

                const lessonNodeId = `group-${childGroup.id}-lesson-${lesson.id}`;
                // console.log('lessonNodeId', lessonNodeId);
                tree[lessonNodeId] = {
                    id: lessonNodeId,
                    index: lessonNodeId,
                    parentId: childGroup.id,
                    itemType: 'Lesson',
                    isFolder: false,
                    children: [],
                    title: lesson.title,
                    data: lesson.title,
                    item: lesson
                }
                tree[childGroup.id].children.push(lessonNodeId);
                // vs[group.id].expandedItems.push(lessonNodeId);
            });

            // add the child group's children recursively
            console.log('add child groups for', childGroup);
            addChildGroups(childGroup.id, tree, treeId, viewState, depth + 1);
        });

    };

    // find the top level groups
    rootGroups.forEach(group => {
        // create the root container for this tree
        /*
        const nodeId = `container-${group.id}`;
        tree[nodeId] = {
            id: nodeId,
            index: nodeId,
            itemType: 'Group Container',
            isFolder: true,
            children: [group.id],
            title: 'container: ' + group.title,
            data: group.title,
            item: group,
            depth: -1
        };
        tree['course'].children.push(nodeId);
        */

        // the parent group item
        tree[group.id] = {
            id: group.id,
            index: group.id,
            itemType: 'Parent Group',
            isFolder: true,
            children: [],
            title: group.title,
            data: group.title,
            item: group,
            depth: 0
        };
        tree['course'].children.push(group.id);

        // create the view state
        // vs[group.id] = {
        //     focusedItem: group.id,
        //     selectedItems: [group.id],
        //     expandedItems: [group.id, nodeId],
        // }

        // add the children
        console.log('add root child groups for', group);
        addChildGroups(group.id, tree, group.id, vs, 1);
        // setViewState(vs);
    });

    // // setup the lesson palette
    // tree['lesson-palette'] = {
    //     id: 'lesson-palette',
    //     index: 'lesson-palette',
    //     itemType: 'Lesson Palette',
    //     isFolder: true,
    //     children: [],
    //     title: 'Lesson Palette',
    // };
    //
    // allLessons?.forEach(lesson => {
    //     // console.log('lesson', lesson, course?.volume_id, lesson.volume_id);
    //     if (course?.volume_id && lesson.volume_id !== course?.volume_id) {
    //         // console.log('skipping lesson', lesson.id, lesson.title, lesson.volume_id, course?.volume_id);
    //         return;
    //     }
    //     const lessonNodeId = `lesson-palette-lesson-${lesson.id}`;
    //     tree[lessonNodeId] = {
    //         id: lessonNodeId,
    //         index: lessonNodeId,
    //         itemType: 'Lesson',
    //         isFolder: false,
    //         children: [],
    //         title: lesson.title,
    //         data: lesson.title,
    //         item: lesson
    //     }
    //     tree['lesson-palette'].children.push(lessonNodeId);
    // });
    return tree;
};

