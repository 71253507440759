import React, { useMemo } from 'react';
import tw, { css } from 'twin.macro';
import { InlineMath } from 'react-katex';
// import styled from "styled-components/macro";
// import RichText from '../../pages/core/RichText';
// import ReactKaTeX from '../../pages/util/Katex';
import logo from './logo/muzology-logo-bw.png'
import { styled } from "twin.macro";
import RichText from "../../../Components/RichText/RichText";
// require('./Quiz.css');


const PrintPageStyle = styled.div`
    position: relative;
`;

const PrintQuizQuestion = styled.div`
    @media print {
        break-inside: avoid;
    }
`;

const CornerBlock = styled.div`
    width: 38%;
    display: block;
    /* height: 111px; */
    border-bottom-right-radius: 150px
`;

const Question = styled.div`
    padding: 10px 0 2px 30px;
    display: flex
`;

const Number = styled.div`
    margin-right: 7px;
    font-size: 12px;

    /* for circling correct answers */
    //border: 1px solid red;

    border-radius: 50%
`

const selectQuizType = (quizLevel) => {
    if (quizLevel === 0) {
        return "Silver Challenge"
    } else if (quizLevel === 1) {
        return "Gold Challenge"
    } else if (quizLevel === 2) {
        return "Platinum Challenge"
    } else {
        return "Answer Key"
    }
}

const selectQuestions = (quizLevel, questions) => {
    return questions?.filter(question => question.level === quizLevel);
    // if (quizNumber === 1) {
    //     return questions.slice(0, 10)
    // }
    // else if (quizNumber === 2) {
    //     return questions.slice(10, 20)
    // } else {
    //     return questions.slice(20, 30)
    // }
}

const LETTERS = [...Array(26)].map((_, i) => String.fromCharCode(65 + i));

const QuestionBlock = (props) => {
    // create an array of all uppercase letters
    let question = props.question
    let letterCount = -1
    let questionNumber = props.questionNumber % 10 + 1
    let questionMargin = question.question_text.includes("<math>") ? "-0.5px" : ""
    let level = parseInt(props.level)
    let questionText = props.question.question_text
    return (
        <PrintQuizQuestion style={{ paddingBottom: "25px" }}>
            <div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', "display": "flex" }}>
                    <div style={{ paddingRight: '7px', fontSize: "14px" }}>{questionNumber + "."}</div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <RichText text={questionText} style={{ fontSize: "14px", marginTop: questionMargin }} />
                        {question.image_url === "" ? "" :
                            <img style={{ maxWidth: '100%', maxHeight: '250px', minHeight: '60px', marginTop: "15px", width: "265px" }} src={question.image_url} />}
                    </div>
                </div>
                {(
                    level === 3 || question.type === 3
                ) ? <div style={{ height: "70px" }} /> :
                    question?.choices?.map(choice => {
                        letterCount += 1
                        let content = null;
                        if (choice.content_math) {
                            content = <InlineMath math={choice.choice_text} />;
                        } else if (choice.content_image) {
                            content = <img
                                style={{ maxWidth: '100%', maxHeight: '250px', minHeight: '60px' }}
                                src={choice.choice_text}
                                alt={`choice ${LETTERS[letterCount]}`}
                            />;
                        } else {
                            content = <RichText text={choice.choice_text} />;
                        }

                        let numberStyle = {};
                        if( choice.correct ) {
                            numberStyle = {
                                fontWeight: 'bold',
                                // backgroundColor: '#FEEA50',
                                border: '2px solid black',
                            };
                        }

                        return (
                            <Question>
                                <Number
                                    className='w-[24px] h-[24px] flex justify-center items-center '
                                    style={numberStyle}
                                >
                                    {LETTERS[letterCount] + "."}
                                </Number>
                                {content}
                            </Question>
                        );

                    })}
                {questionNumber === 10 ? <hr className={'mt-8'} /> : ""}
            </div>
        </PrintQuizQuestion>
    )


}

const QuestionAnswer = (props) => {
    let question = props.question
    let letterCount = -1
    let questionNumber = (
        props.questionNumber % 10
    ) + 1
    // let questionMargin = question?.question_text?.includes("<math>") ? "-0.5px" : ""
    // let level = parseInt(props.level)
    // let questionText = props.question.question_text
    // let correctChoices = question.choices.filter( choice => choice.correct );
    // console.log(question);

    return (
        <PrintQuizQuestion style={{ paddingBottom: "25px" }}>
            <div>
                <div style={{ paddingLeft: '10px', paddingRight: '10px', "display": "flex" }}>
                    <div style={{ paddingRight: '7px', fontSize: "14px" }}>{questionNumber + "."}</div>
                </div>
                {
                    question?.choices?.map(choice => {
                        letterCount += 1

                        if (!choice.correct) {
                            return null;
                        }

                        if (question.type === 3) {
                            return (
                                <Question>
                                    <RichText text={choice.choice_text} />
                                </Question>
                            )
                        } else {
                            return (
                                <Question>
                                    <Number>{LETTERS[letterCount] + "."}</Number>
                                </Question>
                            )
                        }


                        if (choice.content_math) {
                            return (
                                <Question>
                                    <Number>{LETTERS[letterCount] + "."}</Number>
                                    <InlineMath math={choice.choice_text} />
                                </Question>
                            )
                        } else if (choice.content_image) {
                            return (
                                <Question>
                                    <Number>{LETTERS[letterCount] + "."}</Number>
                                    <img style={{ maxWidth: '100%', maxHeight: '250px', minHeight: '60px' }} src={choice.choice_text} />
                                </Question>
                            )
                        } else {
                            return (
                                <Question>
                                    <Number>{LETTERS[letterCount] + "."}</Number>
                                    <RichText text={choice.choice_text} />
                                </Question>
                            )

                        }
                    })}
                {questionNumber === 10 ? <hr className={'mt-8'} /> : ""}
            </div>
        </PrintQuizQuestion>
    )

}

const RenderQuiz = (props) => {
    // console.log('print worksheet:', props.quiz);
    // console.log('level:', props.level);
    let title = props.quiz.title
    // let questions = props.quiz.questions || []
    // questions = props.level !== -1 ? selectQuestions(props.level, props.quiz.questions) : questions
    const questions = useMemo(() => {
        const allQuestions = props.quiz?.questions ?? [];
        if (props.level === -1)
            return allQuestions;
        else
            return selectQuestions(props.level, allQuestions);
    }, [props.level, props.quiz]);

    // title = title.slice(5)
    let questionNumber = -1
    let qType = selectQuizType(props.level)

    // console.log('questions:', questions);

    // print the answer key
    if (props.answer_key) {
        return (
            <PrintPageStyle>
                <div style={{ display: "flex", position: "relative" }}>
                    <CornerBlock className="logo">
                        <img style={{ padding: "20px 0 0 0", width: "85%" }} src={logo} />
                    </CornerBlock>
                    <div style={{ padding: "0 0 0 150px" }}>
                        <h1 style={{ paddingTop: "20px" }}>{title}</h1>
                        <h3 style={{ marginTop: "20px" }}>{selectQuizType(props.level)} ANSWER KEY</h3>
                    </div>
                </div>

                <div className={'print-quiz-question'} style={{ paddingTop: "25px", position: "relative" }}>
                    {questions?.map((question, index) => {
                        const questionNumber = index;
                        // questionNumber += 1
                        // {
                        //     (questionNumber + 1) % 10 === 1 ? level += 1 : level = level
                        // }
                        return (
                            <QuestionAnswer className="q" question={question} questionNumber={questionNumber} level={props.level} />
                        )
                    })}
                </div>

            </PrintPageStyle>
        );
    }

    let currentLevel = 0;
    return (
        <PrintPageStyle>
            <div style={{ display: "flex", position: "relative" }}>
                <CornerBlock className="logo">

                    <img style={{ padding: "20px 0 0 0", width: "85%" }} src={logo} />
                </CornerBlock>
                <div style={{ padding: "0 0 0 150px" }}>
                    <h1 style={{ paddingTop: "20px" }}>{title}</h1>
                    <h3 style={{ marginTop: "20px" }}>{selectQuizType(props.level)} ___________________</h3>
                </div>
            </div>

            <div className={'print-quiz-question'} style={{ paddingTop: "25px", position: "relative" }}>
                {questions?.map(question => {
                    questionNumber += 1;
                    {
                        (
                            questionNumber + 1
                        ) % 10 === 1 ? currentLevel += 1 : currentLevel = currentLevel
                    }
                    return (
                        <QuestionBlock className="q" question={question} questionNumber={questionNumber} level={props.level} />
                    )
                })}
            </div>

        </PrintPageStyle>
    );
};

// class RenderQuiz extends React.Component {
//     selectQuizType = (quizNumber) => {
//         if (quizNumber == 1) {
//             return "Silver Challenge"
//         }
//         else if (quizNumber == 2) {
//             return "Gold Challenge"
//         } else {
//             return "Blue Challenge"
//         }
//     }
//     selectQuestions = (quizNumber, questions) => {
//         if (quizNumber == 1) {
//             return questions.slice(0, 10)
//         }
//         else if (quizNumber == 2) {
//             return questions.slice(10, 20)
//         } else {
//             return questions.slice(20, 30)
//         }
//     }
//     render() {
//         let title = this.props.quiz.title
//         let questions = this.props.quiz.questions || []
//         questions = this.props.level != -1 ? this.selectQuestions(this.props.level, this.props.quiz.questions) : questions
//         title = title.slice(5)
//         let questionNumber = -1
//         let qType = this.selectQuizType(this.props.level)
//         let level = 0
//
//         // print the answer key
//         if( this.props.answer_key ) {
//             return (
//                 <PrintPageStyle>
//                     <div style={{ display: "flex", position: "relative" }}>
//                         <CornerBlock className="logo">
//                             <img style={{ padding: "20px 0 0 0", width: "85%" }} src={logo} />
//                         </CornerBlock>
//                         <div style={{ padding: "0 0 0 150px" }}>
//                             <h1 style={{ paddingTop: "20px" }}>{title}</h1>
//                             <h3 style={{ marginTop: "20px" }}>{qType} ANSWER KEY</h3>
//                         </div>
//                     </div>
//
//                     <div className={'print-quiz-question'} style={{ paddingTop: "25px", position: "relative"  }}>
//                         {questions.map(question => {
//                             questionNumber += 1
//                             { (questionNumber + 1) % 10 === 1 ? level += 1 : level = level }
//                             return (<QuestionAnswer className="q" question={question} questionNumber={questionNumber} level={this.props.level} />)
//                         })}
//                     </div>
//
//                 </PrintPageStyle>
//             );
//         }
//
//
//         return (
//             <PrintPageStyle>
//                 <div style={{ display: "flex", position: "relative" }}>
//                     <CornerBlock className="logo">
//
//                         <img style={{ padding: "20px 0 0 0", width: "85%" }} src={logo} />
//                     </CornerBlock>
//                     <div style={{ padding: "0 0 0 150px" }}>
//                         <h1 style={{ paddingTop: "20px" }}>{title}</h1>
//                         <h3 style={{ marginTop: "20px" }}>{qType} ___________________</h3>
//                     </div>
//                 </div>
//
//                 <div className={'print-quiz-question'} style={{ paddingTop: "25px", position: "relative"  }}>
//                     {questions.map(question => {
//                         questionNumber += 1
//                         { (questionNumber + 1) % 10 === 1 ? level += 1 : level = level }
//                         return (<QuestionBlock className="q" question={question} questionNumber={questionNumber} level={this.props.level} />)
//                     })}
//                 </div>
//
//             </PrintPageStyle>
//         );
//     }
// }

export default RenderQuiz;
