import React from 'react';
import { FormControl, InputLabel, OutlinedInput, Paper, Table, TableRow, TableCell, TableHead, TableBody, TableContainer, Grid, Stack } from "@mui/material";
import BitmovinPlayer from "../../../Components/Bitmovin";
import { createSourceConfig } from "../../../Components/Bitmovin/BitmovinPlayer";

const VideoPanel = ({ video }) => {
    console.log('VideoPanel', video);
    if( !video )
        return null;
    return (
        <div>
            <BitmovinPlayer
                source={createSourceConfig(video)}
                // aria-label={`video player for ${video.title}`}
                video={video}
                // user={user}
                // assignment={assignment}
                // key={sessionId}
                // className='sticky-video'
                autoplay={false}
                controls={true}
                // sources={sources}
                // poster={createVideoPoster(video?.url_thumbnail ?? '')}
                // metaData={metaData}
                // onReady={onVideoStart}
                // onPlay={onVideoPlay}
                // onEnd={onVideoEnd}
                // postVideoSessionRanges={postVideoSessionRanges}
            />
        </div>
        );

    //     <Grid container>
    //         <Grid item xs={12} lg={12}>
    //
    //             {/*
    //             <ReactPlayer
    //                 url={source?.public_url}
    //                 width="640"
    //                 height="360"
    //                 controls
    //             />
    //             */}
    //         </Grid>
    //
    //         <Grid item xs={12} lg={4}>
    //             <Stack spacing={2}>
    //                 {/*<div>upload: {uploadId}</div>*/}
    //                 {/*<div>video: {video?.title}</div>*/}
    //                 {/*<pre>{JSON.stringify(data?.data, null, 4)}</pre>*/}
    //                 {/*<pre>{JSON.stringify(video, null, 4)}</pre>*/}
    //             </Stack>
    //         </Grid>
    //
    //         <Grid item xs={12}>
    //             <Stack spacing={2}>
    //                 {/*<div>upload: {uploadId}</div>*/}
    //                 {/*<div>video: {video?.title}</div>*/}
    //                 {/*<pre>{JSON.stringify(video, null, 4)}</pre>*/}
    //                 <pre>{JSON.stringify(video, null, 4)}</pre>
    //             </Stack>
    //         </Grid>
    //     </Grid>
    // );
    // return (
    //     <div className={'mt-6'}>
    //
    //         <TableContainer component={Paper}>
    //             <Table aria-label="simple table">
    //                 <TableHead>
    //                     <TableRow>
    //                         <TableCell>Key</TableCell>
    //                         <TableCell>Value</TableCell>
    //                     </TableRow>
    //                 </TableHead>
    //                 <TableBody>
    //                     <TableRow>
    //                         <TableCell>ID</TableCell>
    //                         <TableCell>{video.id}</TableCell>
    //                     </TableRow>
    //                     <TableRow>
    //                         <TableCell>Title</TableCell>
    //                         <TableCell>{video.title}</TableCell>
    //                     </TableRow>
    //                     <TableRow>
    //                         <TableCell>Description</TableCell>
    //                         <TableCell>{video.description}</TableCell>
    //                     </TableRow>
    //                     <TableRow>
    //                         <TableCell>Thumbnail</TableCell>
    //                         <TableCell>{video.url_thumbnail}</TableCell>
    //                     </TableRow>
    //                     <TableRow>
    //                         <TableCell>Duration</TableCell>
    //                         <TableCell>{video.duration}</TableCell>
    //                     </TableRow>
    //                     <TableRow>
    //                         <TableCell>DASH</TableCell>
    //                         <TableCell>{
    //                             // strip the https://videos prefix
    //                             video.url_dash.replace(/^https:\/\/videos.muzology.com\//, '')
    //                         }</TableCell>
    //                     </TableRow>
    //                     <TableRow>
    //                         <TableCell>HLS</TableCell>
    //                         <TableCell>{
    //                             // strip the https://videos prefix
    //                             video.url_hls.replace(/^https:\/\/videos.muzology.com\//, '')
    //                         }</TableCell>
    //                     </TableRow>
    //                     <TableRow>
    //                         <TableCell>Lesson</TableCell>
    //                         <TableCell>{video.lesson_id}</TableCell>
    //                     </TableRow>
    //                     <TableRow>
    //                         <TableCell>Quiz</TableCell>
    //                         <TableCell>{video.quiz_id}</TableCell>
    //                     </TableRow>
    //                 </TableBody>
    //
    //             </Table>
    //         </TableContainer>
    //         <FormControl fullWidth sx={{ mb: 2 }}>
    //             <InputLabel htmlFor="outlined-adornment-dash">DASH</InputLabel>
    //             <OutlinedInput
    //                 id="outlined-adornment-dash"
    //                 value={video.url_dash}
    //                 // onChange={handleChange('amount')}
    //                 // startAdornment={<InputAdornment position="start">$</InputAdornment>}
    //                 label="DASH"
    //             />
    //         </FormControl>
    //         <FormControl fullWidth sx={{ mb: 2 }}>
    //             <InputLabel htmlFor="outlined-adornment-hls">HLS</InputLabel>
    //             <OutlinedInput
    //                 id="outlined-adornment-hls"
    //                 value={video.url_hls}
    //                 // onChange={handleChange('amount')}
    //                 // startAdornment={<InputAdornment position="start">$</InputAdornment>}
    //                 label="HLS"
    //             />
    //         </FormControl>
    //         <FormControl fullWidth sx={{ mb: 2 }}>
    //             <InputLabel htmlFor="outlined-adornment-webm">WEBM</InputLabel>
    //             <OutlinedInput
    //                 id="outlined-adornment-webm"
    //                 value={video.url_webm}
    //                 // onChange={handleChange('amount')}
    //                 // startAdornment={<InputAdornment position="start">$</InputAdornment>}
    //                 label="WEBM"
    //             />
    //         </FormControl>
    //         <FormControl fullWidth sx={{ mb: 2 }}>
    //             <InputLabel htmlFor="outlined-adornment-amount">MP4</InputLabel>
    //             <OutlinedInput
    //                 id="outlined-adornment-amount"
    //                 value={video.url_mp4}
    //                 // onChange={handleChange('amount')}
    //                 // startAdornment={<InputAdornment position="start">$</InputAdornment>}
    //                 label="Title"
    //             />
    //         </FormControl>
    //     </div>
    // );
};


export default VideoPanel;
