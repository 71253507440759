import React, { useMemo } from 'react';
import { Outlet, Route } from "react-router-dom";
import { PageContainer } from "../../Home/PageContainer";
import { Button, Container, Grid } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import AccountTemplates from "./AccountTemplates";
import AccountCreation from "./AccountCreation";

function TabPanel({ children, index, value, ...other }) {
    const selected = value === index;
    return (
        <div
            id={`vertical-tabpanel-${index}`}
            role='tabpanel'
            aria-labelledby={`vertical-tab-${index}`}
            className={`flex flex-grow w-full flex-col h-full ${selected ? '' : 'hidden'}`}
            {...other}
        >
            {selected && (
                <div className={'flex items-stretch justify-stretch'}>
                    {children}
                </div>
            )}
        </div>
    );
}

const Context = React.createContext();

const AccountCreationContainer = (props) => {
    const [breadcrumbs, setBreadcrumbs] = React.useState([]);

    function a11yProps(index, allowStep) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
            className: 'h-full bg-green'
        };
    }

    const [allowStepIndex, setAllowStepIndex] = React.useState(0);
    const [stepIndex, setStepIndex] = React.useState(0);
    const [selectedType, setSelectedType] = React.useState(null);
    const [selectedTemplate, setSelectedTemplate] = React.useState(null);

    const handleChangeStep = (event, newValue) => {
        setStepIndex(newValue);
    };

    const steps = React.useMemo(() => {

        const handleSelectType = (template) => {
            setSelectedType(template['id']);
            setSelectedTemplate(template);
            setAllowStepIndex(100);
            setStepIndex(1);
        }

        const stepTemplates = [
            {
                id: 'account-type',
                label: 'Account Type',
                component: <AccountTemplates onSelect={handleSelectType} />
            },
            {
                id: 'account-details',
                label: 'Account Details',
                component: <AccountCreation  />
            },
            {
                id: 'account-settings',
                label: 'Account Settings',
                component: <div>Account Settings</div>
            },
            {
                id: 'parent-subscription',
                label: 'Parent Subscription',
                component: <AccountCreation  />
            },
            {
                id: 'demo-account',
                label: 'Demo Account',
                component: <AccountCreation  />
            },
        ];

        let steps = [];
        switch (selectedType) {
            case 'school':
                steps = [stepTemplates[0], stepTemplates[1], stepTemplates[2]];
                break;
            case 'teacher':
                steps = [stepTemplates[0], stepTemplates[1], stepTemplates[2]];
                break;
            case 'parent':
                steps = [stepTemplates[0], stepTemplates[1], stepTemplates[2], stepTemplates[4]];
                break;
            case 'parent-demo':
                steps = [stepTemplates[0], stepTemplates[1], stepTemplates[2], stepTemplates[3]];
                break;
            case 'teacher-demo':
                steps = [stepTemplates[0], stepTemplates[1], stepTemplates[2], stepTemplates[3]];
                break;
            default:
                steps = stepTemplates;
        }
        steps = steps.slice(0, allowStepIndex + 1);
        return steps;

    }, [allowStepIndex, selectedType]);

    const value = useMemo(() => (
        { breadcrumbs, setBreadcrumbs }
    ), [breadcrumbs, setBreadcrumbs]);

    return (
        <Context.Provider value={value}>
            <PageContainer
                title={"Account Creation"}
                actions={[]}
                // rootTitle={'Admin'}
                // rootPath={'/admin'}
                paths={[
                    { href: '/', title: 'Home' },
                    { href: '/admin/', title: 'Admin' },
                    { href: '/admin/create-account/', title: 'Create Account' },
                    ...breadcrumbs
                ]}
            >
                <Container sx={{ padding: '2em' }}>
                    <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        // columns={{ xs: 4, sm: 8, md: 12 }}
                        justify="left"
                    >
                        <Grid item xs={12} sm={2} md={2}>
                            <Tabs
                                orientation="vertical"
                                // variant="scrollable"
                                value={stepIndex}
                                onChange={handleChangeStep}
                                aria-label="Account Creation Steps"
                                sx={{
                                    borderRight: 1,
                                    borderColor: 'divider',
                                    height: '100%',
                                }}
                            >
                                {steps.map((step, index) => {
                                    return (
                                        <Tab
                                            key={step.id}
                                            label={step.label}
                                            {...a11yProps(index, allowStepIndex >= index)}

                                        />
                                    );
                                })}

                            </Tabs>
                        </Grid>

                        <Grid item xs={12} sm={10} md={10}>
                            {steps.map((step, index) => {
                                return (
                                    <TabPanel
                                        key={step.id}
                                        value={stepIndex}
                                        index={index}
                                    >
                                        {step.component}
                                    </TabPanel>
                                );
                            })}
                            {/*
                            <Outlet />
                            */}
                        </Grid>
                    </Grid>
                </Container>
            </PageContainer>

        </Context.Provider>
    );
};

export function useBreadcrumbs() {
    const context = React.useContext(Context);
    if (context === undefined) {
        throw new Error('useBreadcrumbs must be used within a BreadcrumbsProvider');
    }
    return context;
}

export default AccountCreationContainer;
