import React from 'react';
import { useDropzone } from "react-dropzone";
import apiClient from "../../context/APIClient";

const uploadToS3 = (file, url, onUploadProgress) => {
    const options = {
        withCredentials: false,
        // onUploadProgress,
        // headers: {
        //     'Content-Type': file.type,
        // },
        // baseURL: '',
    };
    console.log('post to s3:', url, file);
    return apiClient.put(url, file, options)
        .then(response => {
            if (response.status === 200) {
                return url;
            }
        });
}

const UploadProgress = ({ progress }) => {
    return (
        <div className="progress">
            Uploading:
            <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${progress}%` }}
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
            >
                {progress}%
            </div>
        </div>
    );
};

const VideoUpload = (props) => {
        const [isUploading, setIsUploading] = React.useState(false);
        const [uploadProgress, setUploadProgress] = React.useState(0);
        const [size, setSize] = React.useState(0);
        const [selectedFile, setSelectedFile] = React.useState(null);

        const onUploadProgress = event => {
            console.log('progress:', event, event.loaded, event.total);
            const progress = (event.loaded / event.total) * 100;
            setUploadProgress(progress);
        }

        function onDrop(acceptedFiles) {
            // setSelectedFile(acceptedFiles[0]);
            if (acceptedFiles.length > 0)
                uploadFile(acceptedFiles[0]);
        }

        const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
            onDrop,
            maxFiles: 1,
            multiple: false
        });

        function uploadFile(file) {
            console.log(file);
            setIsUploading(true);
            console.log(file.name);
            const filename = file.name;
            const payload = {
                filename: filename,
            };
            apiClient.post('/api/video_upload/', payload)
                .then((response) => {
                    const { data } = response;
                    console.log("Uploading to S3 endpoint", data.url);
                    // upload file to pre-signed s3 url
                    setUploadProgress(0);
                    setSize(file.size);
                    // apiClient.put(url, files[i], onUploadProgress)

                    console.log("uploadToS3:", data.url);
                    uploadToS3(file, data.url, onUploadProgress).then(r => {
                        console.log("uploadedToS3");
                        console.log(r);
                        setIsUploading(false);
                        // navigate(`/videos/${data.id}`)
                    });
                })
                .catch((error) => {
                    console.log(error);
                });

            // const req = request.post('/upload')
            // files.forEach(file => {
            //     req.attach(file.name, file)
            // })
            // req.end(callback)
        }

        const files = acceptedFiles.map(file => (
            <li key={file.path}>
                {file.path} - {file.size} bytes
            </li>
        ));

        return (
            <section className="container">
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <div className={'text-[#999999] bg-[#eeeeee] hover:bg-[#dddddd] active:bg-[#ffffff] font-bold text-xl min-h-[56px] w-full h-full flex justify-center items-center'}>
                        {isUploading && (
                            <div className="progress">
                                Uploading: <UploadProgress progress={uploadProgress} />
                            </div>)}
                        {!isUploading && (isDragActive ? "Drop here" : files.length > 0 ? files : "Drag 'n' drop a video file here, or click to select")}
                    </div>
                </div>
                {/*
                <aside>
                    <h4>Files</h4>
                    <ul>{files}</ul>
                </aside>
                */}

            </section>
        );
    }
;


export default VideoUpload;
