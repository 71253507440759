import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

const QuizDetails = ({ quiz }) => {
    return (
        <div className={'w-full'}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>{quiz.id}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Slug</TableCell>
                        <TableCell>{quiz.slug}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Created</TableCell>
                        <TableCell>{quiz.created}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Last Updated</TableCell>
                        <TableCell>{quiz.updated}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};


export default QuizDetails;
