import React from 'react';
import { styled } from "twin.macro";

const ErrorContainer = styled.div`
    margin-top: 10px;

    .error-tag {
        font-family: Avenir, serif;
        font-weight: 900;
        font-style: italic;
        font-size: 18px;
        color: #fa2a2a;
        margin-bottom: 10px;
    }
`;
export const ErrorMessage = (name, formErrors) => {
    const error = formErrors[name];
    if (error) {
        return (
            <ErrorContainer>
                <p className='error-tag'>{error}</p>
            </ErrorContainer>
        );
    }
    return null;
};

export const showError = (error, text = null) => {
    if (error) {
        // console.log(error);
        let message = text;
        if (!message) {
            if (error.type === 'required') message = 'This field is required';
            else message = error.message;
        }

        return (
            <ErrorContainer className='error-container'>
                <p className='error-tag'>{`* ${message}`}</p>
            </ErrorContainer>
        );
    }
    return null;
};

