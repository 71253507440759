export const GRADE_LEVEL_CHOICES = [
    [0, 'Kindergarten'],
    [1, '1'],
    [2, '2'],
    [3, '3'],
    [4, '4'],
    [5, '5'],
    [6, '6'],
    [7, '7'],
    [8, '8'],
    [9, '9'],
    [10, '10'],
    [11, '11'],
    [12, '12'],
]

