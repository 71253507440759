import React from 'react';
import tw, { css, styled } from 'twin.macro';
import { createBrowserRouter, Route, RouterProvider, Routes, useRoutes } from "react-router-dom";
import ShareQuizContainer from "../Studio/Quizzes/QuizEditor/Share/ShareQuizContainer";
import WorksheetView from "../Studio/Quizzes/Worksheet/Worksheet";
import Login from "../Login/Login";

let router = createBrowserRouter([
    {
        path: "/share/:quiz_id",
        element: <ShareQuizContainer />
    },
    {
        path: "/quiz/worksheet",
        element: <WorksheetView />
    },
    {
        path: "*",
        element: <Login />
    }
]);

const UnauthenticatedApp = (props) => {

    return (
        <RouterProvider router={router} />
    )
};

export default UnauthenticatedApp;
