import * as React from 'react';

function SvgAudioIcon(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.383 3.075A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0 9.97 9.97 0 012.93 7.07 9.969 9.969 0 01-2.93 7.071 1 1 0 01-1.414-1.414A7.97 7.97 0 0017 10a7.97 7.97 0 00-2.343-5.657 1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.984 5.984 0 0115 10a5.984 5.984 0 01-1.757 4.242 1 1 0 11-1.415-1.415A3.983 3.983 0 0013 10a3.982 3.982 0 00-1.172-2.828 1 1 0 010-1.415z'
                fill='currentColor'
            />
        </svg>
    );
}

export default SvgAudioIcon;
