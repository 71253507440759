import React from 'react';

const CourseListContainer = (props) => {
    return (
        <div>
            course list
        </div>
    );
};


export default CourseListContainer;
