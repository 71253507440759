import React from 'react';
import { styled } from "twin.macro";
import { Typography } from "@mui/material";
import * as PropTypes from "prop-types";
import QuizQuestionView from "../../Studio/Quizzes/QuizEditor/QuestionEditor/Chat/Views/QuizQuestionView";

const MessageContainer = styled(Typography)(({ $self }) => (
    {
        alignSelf: $self ? 'flex-end' : 'flex-start',
        maxWidth: '70%',
        padding: '0.5rem 1rem',
        margin: '0.5rem',
        borderRadius: '20px',
        background: $self ? '#3f51b5' : '#f0f0f0',
        color: $self ? '#ffffff' : '#000000',
    }
));


const CodeMessage = ({ code, author }) => {
    console.log(`code: '${code}'`);
    console.log(code[0])
    console.log(code[1])
    console.log(code[2])
    console.log(code[3])
    console.log(parseInt(code[4]))
    console.log(code[5])
    console.log(code[6])
    // remove 'json ' from the beginning of the code
    if (code.startsWith('json\n')) {
        code = code.substring(5);
        console.log('removed json', code);
    }

    // convert the code to a JSON object
    try {
        const item = JSON.parse(code);
        console.log('item', item);
        // pretty print the JSON object
        // code = JSON.stringify(item, null, 4);
        // code = JSON.stringify(item, null, 4);
        code = (
            <QuizQuestionView
                key={item.id}
                question={item}
                index={item.question_index}
            />
        )
    } catch (e) {
        console.log('error parsing json', e);
        code = 'Error parsing JSON: ' + e + '\n' + code;
    }

    return (
        <MessageContainer $self={author}>
            {code}
        </MessageContainer>
    )
};

const TextMessage = ({ text, author }) => {
    return (
        <MessageContainer $self={author}>
            {text}
        </MessageContainer>
    )
};

const Message = ({ text, self }) => {
    // split the text into sections so we can embed ``` code fences.
    if (text) {
        const sections = text?.split('```');
        console.log(sections);
        if (sections?.length > 1) {
            // need to 'cut out' sections that are code fences.
            // collect each section as a Message component, and then return a Stack of Message components.
            const messages = [];
            for (let i = 0; i < sections.length; i++) {
                const section = sections[i];
                if (i % 2 === 0) {
                    // even sections are text
                    messages.push(<TextMessage author={self} text={section}/>);
                } else {
                    // odd sections are code fences
                    messages.push(<CodeMessage author={self} code={section} />);
                }
            }
            return messages;
        }
    }

    return (
        <MessageContainer $self={self}>
            {text}
        </MessageContainer>
    );
};


export default Message;
