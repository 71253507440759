import React, { useMemo } from 'react';

import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../../Home/PageContainer";
import { Avatar, Button, Fab, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import AddIcon from '@material-ui/icons/Add';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { useStudio } from "../Context/StudioContext";
import AddLessonsGroupDialog from '../StudioContainer/Dialogs/AddLessonsGroupDialog';

const columns = [
    {
        field: 'url_thumbnail',
        headerName: 'Thumbnail',
        renderCell: (params) => {
            return (
                <img src={params.value} alt={params.value} style={{ width: '100%' }} />
            );
        }
    },
    { field: 'title', headerName: 'Title', flex: 1, align: 'left' },
];

const LessonsList = (props) => {
    const studio = useStudio();
    const { lessons, mutateCourses, mutateLessons } = studio;

    const navigate = useNavigate();
    const [view, setView] = React.useState('thumbnails');
    // const classes = useStyles();
    const handleChange = (event, nextView) => {
        setView(nextView);
    };

    const rows = useMemo(() => {
        return lessons?.map((lesson, index) => {
            return {
                ...lesson,
                // id: video.id,
                // title: video.title,
                // slug: video.slug,
            };
        });
    }, [lessons])

    const [openNewLesson, setOpenNewLesson] = React.useState(false);


    const addButton = (
        <Fab size={'medium'} color="primary" aria-label="add" onClick={() => setOpenNewLesson(true)}>
            <AddIcon />
        </Fab>
    )

    const toggleViews = (
        <ToggleButtonGroup exclusive value={view} onChange={handleChange}>
            <ToggleButton value="list" aria-label="list">
                <ViewListIcon />
            </ToggleButton>
            <ToggleButton value="thumbnails" aria-label="thumbnails">
                <ViewModuleIcon />
            </ToggleButton>
            {/*
                    <ToggleButton value="quilt" aria-label="quilt">
                        <ViewQuiltIcon />
                    </ToggleButton>
                    */}
        </ToggleButtonGroup>
    );

    const content = useMemo(() => {
        const onRowClick = (params, event, details) => {
            console.log('row clicked', params, event, details);
            navigate(`/lessons/${params.id}`);
        }

        // if (view === 'list') {
        //     if (rows)
        return (
            <DataGrid
                rows={rows}
                columns={columns}
                onRowClick={onRowClick}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'title', sort: 'asc' }],
                    },
                }}
            />
        );
        // } else if (view === 'thumbnails') {
        //     return (
        //         <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        //             {videos?.map(video => (
        //                 <Grid item xs={2} sm={4} md={4} key={video.id}>
        //                     <VideoCard
        //                         video={video}
        //                         showDelete={false}
        //                         className={'cursor-pointer hover:shadow-lg hover:scale-105 transition-all duration-100 ease-in-out active:scale-100'}
        //                         onClick={() => navigate(`/videos/${video.id}`)}
        //                     />
        //                 </Grid>
        //             ))}
        //         </Grid>

        //     );
        // }
        // return null;
    }, [view, rows]);
    return (
        <PageContainer
            title={'Lessons'}
            actions={[addButton]}
            paths={[
                { href: '/', title: 'Home' },
                { href: '/studio/', title: 'Studio' },
            ]}
            // maxWidth={'xl'}
        >

            <div className={'p-4 w-full h-full'}>
                {content}
            </div>
            <AddLessonsGroupDialog open={openNewLesson} onClose={() => setOpenNewLesson(false)} createLesson={true} />

        </PageContainer>
    );
};


export default LessonsList;
