import React from 'react';
import { Container, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PanelPaper = styled(Paper)(({ theme }) => ({
    overflowY:'auto',
    flexGrow:'1',
    padding: '10px',
    backgroundColor: 'white',
    // boxShadow: '1px 1px 2px #000000',
    border: '1px solid lightgray',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    // padding: theme.spacing(1),
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
}));


export const PanelPaper2 = ({ children }) => {
    return (
        <Paper className={'p-2'} style={{
            overflowY:'auto',
            flexGrow:'1',
            padding: '10px',
            backgroundColor: 'white',
            // boxShadow: '1px 1px 2px #000000',
            border: '1px solid lightgray',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
        }}>
            {children}
        </Paper>
    )
}


const PanelView = ({ children }) => {
    return (
        <Container maxWidth={null} style={{ display: 'flex', alignItems: 'stretch' }}>
            <div className='my-5 p-[10px] w-full flex grow' style={{ alignItems: 'stretch' }}>
                <PanelPaper>{children}</PanelPaper>
            </div>
        </Container>
    );
};


export default PanelView;
