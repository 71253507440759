import React from 'react';
import MenuGrid from "../../../Components/MenuGrid/MenuGrid";
import { account_variants } from "../variants";

const AccountVariantGrid = ({ setVariant, setActiveStep, activeStep }) => {
    return (
        <MenuGrid
            items={account_variants}
            onSelected={(item, index) => {
                setVariant(item);
                setActiveStep(activeStep + 1);
            }}
        />
    );
};

export default AccountVariantGrid;
