import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageContainer } from "../../Home/PageContainer";
import { useVideo, useVideos } from "./VideosContainer";
import { useParams, useSearchParams, unstable_usePrompt as usePrompt, useNavigate } from "react-router-dom";
import { Button, FormControl, Grid, Input, InputAdornment, InputLabel, OutlinedInput, Paper, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import VideoInfoCard from "./VideoInfoCard";
import VideoDetails from "./panels/VideoDetails";
import Box from "@mui/material/Box";
import VideoPanel from "./panels/VideoPanel";
import ThumbnailPanel from "./panels/ThumbnailPanel";
import LyricsPanel from "./panels/LyricsPanel";
import { useDevTool } from "../../context/DevTool";
import apiClient from "../../context/APIClient";
import VideoInfo from "./panels/VideoInfo";
import { useGetAPI } from "../../context/api";
import DropboxChooser from "./DropboxChooser";

import VideoHealthCheck from "./panels/VideoHealthCheck";
import SourceVideos from "./panels/SourceVideos";
import FilesPanel from "./panels/FilesPanel";
import { useStudio } from "../Context/StudioContext";


function TabPanel(props) {
    const { children, selected, value, index, ...other } = props;
    const isSelected = selected === value;
    return (
        <div
            role="tabpanel"
            hidden={!isSelected}
            id={`tab-panel-${index}`}
            aria-labelledby={`tab-${index}`}
            className={'flex-grow h-full'}
            {...other}
        >
            {isSelected && (
                <Box sx={{ p: 2, background: '', height: '100%', position: 'relative' }}>
                    {children}
                    {/*
                    <Typography>
                    </Typography>
                    <div className={'absolute inset-4 bg-red pb-10 flex items-stretch justify-stretch'}>
                        {children}
                    </div>
                    */}
                </Box>
            )}
        </div>
    );
}

function useQuiz(quizId) {
    console.log('[useQuiz]', quizId)
    const { data: quiz } = useGetAPI(quizId && `/api/quiz/${quizId}/`);
    console.log('[useQuiz] Downloaded quiz', quiz);
    return quiz;
}

const VideoEditor = () => {
    const navigate = useNavigate();
    const { videoId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const uploadId = useMemo(() => searchParams ? searchParams.get('uploadId') : null, [searchParams]);
    const {lessons, mutateVideos} = useStudio();

    // the original video and our working copy
    const originalVideo = useVideo(videoId);
    const [video, setVideo] = useState(null);

    // flags
    const [isDirty, setIsDirty] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    // grab the lesson and the quiz for this video
    const quiz = useQuiz(video?.quiz_id)

    const lesson = useMemo( () => {
        if( video?.lesson_id ) {
            return lessons?.find(lesson => lesson.id === video.lesson_id);
        }
        return null;
    }, [video, lessons]);

    console.log("<VideoEditor> quiz", quiz);
    console.log("<VideoEditor> lesson", lesson);
    useDevTool('Video Editor', { video, quiz, lesson, isDirty, originalVideo });
    useEffect(() => {
        if (originalVideo) {
            console.log("<VideoEditor> setting video", originalVideo);
            setVideo(originalVideo);
            setIsDirty(false);
        }
    }, [originalVideo]);

    usePrompt({
        when: isDirty,
        message: "You have unsaved changes. Are you sure you want to leave?",
    });

    // update the working copy
    const updateVideo = (update) => {
        console.log('updateVideo', update);
        setVideo(value => (
            { ...value, ...update }
        ));
        setIsDirty(true);
    }
    // const updateLesson = (update) => {
    //     console.log('updateVideo', update);
    //     setLesson(value => (
    //         { ...value, ...update }
    //     ));
    //     // setIsDirty(true);
    // }


    // handle tab change
    const handleTabChange = (event, value) => {
        setSearchParams({ tab: value });
    };

    const handleSelectUpload = useCallback((upload) => {
        console.log('handleSelectUpload', upload);
        console.log('tab', searchParams.get('tab'));
        if (upload?.id) {
            setSearchParams({ tab: searchParams.get('tab'), uploadId: upload.id });
        } else {
            setSearchParams({ tab: searchParams.get('tab') });
        }
        // updateVideo({ upload });
    }, [searchParams, setSearchParams]);

    // all the tabs
    const tabs = [
        {
            id: 'info',
            label: 'General Info',
            component: <VideoInfo
                video={video}
                lesson={lesson}
                quiz={quiz}
                updateVideo={updateVideo}
                errors={formErrors}
            />
        },
        {
            id: 'video',
            label: 'Video',
            component: <VideoPanel video={video} updateVideo={updateVideo} />

        },
        {
            id: 'thumbnail',
            label: 'Thumbnail',
            component: <ThumbnailPanel video={video} updateVideo={updateVideo} />

        },
        {
            id: 'lyrics',
            label: 'Lyrics',
            component: <LyricsPanel video={video} updateVideo={updateVideo} />
        },
        {
            id: 'source_videos',
            label: 'Source Videos',
            component: <SourceVideos onSelectUpload={handleSelectUpload} video={video} updateVideo={updateVideo} uploadId={uploadId} />
        },
        {
            id: 'healthcheck',
            label: 'Health Check',
            component: <VideoHealthCheck video={video} />
        },
        // {
        //     id: 'files',
        //     label: 'Files',
        //     component: <FilesPanel onSelectUpload={handleSelectUpload} video={video} updateVideo={updateVideo} uploadId={uploadId} />
        // },
        {
            id: 'details',
            label: 'Details',
            component: <VideoDetails
                video={video}
                lesson={lesson}
                quiz={quiz}
                updateVideo={updateVideo}
            />
        }
    ];

    // the current tab
    const tab = useMemo(() => {
        const tab = searchParams.get('tab');
        return tab ?? 'info';
    }, [searchParams]);
    console.log('tab', tab);

    useDevTool('video', video);
    console.log(videoId, video);

    function handleSave(stayOnPage = false) {
        setLoading(true);
        setFormErrors({});
        apiClient.patch(`/api/video/${video?.id}/`, video)
            .then((response) => {
                const { data } = response;
                setVideo(data);
                mutateVideos();
                setIsDirty(false);
                setLoading(false);
                if (!stayOnPage) {
                    navigate('/videos/');
                }
            })
            .catch((error) => {
                console.log("error saving video");
                console.dir(error);
                if (error.response.status === 400) {
                    // form validation error
                    const { data } = error.response;
                    console.log(data);
                    setFormErrors(data);
                    // setVideo(value => ({ ...value, ...data }));
                }
                setLoading(false);
            });
    }

    const handleSuccess = (files) => {
        console.log('handleSuccess', files);
        const file = files[0];
        // updateVideo({ video_url: file.link });
    }

    function deleteVideo() {
        // if (confirm('Are you sure you want to delete this video?')) {
        //     apiClient.delete(`/api/video/${video?.id}/`)
        //         .then((response) => {
        //             console.log('delete response', response);
        //             window.location = '/videos/';
        //         })
        //         .catch((error) => {
        //             console.log("error deleting video");
        //             console.dir(error);
        //         });
        // }
    }

    // if (!video) return "no video";
    return (
        <PageContainer
            key={video?.id}
            loading={loading}
            maxWidth={'xl'}
            title={video?.title ?? video?.title ?? video?.id ?? ''}
            paths={[
                { title: 'Home', href: '/', },
                { title: 'Studio', href: '/studio/' },
                { title: 'Videos', href: '/studio/videos/' },
            ]}

        >
            <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={9}>
                    <Paper>
                        {video && <>
                            <Tabs
                                value={tab}
                                onChange={handleTabChange}
                                aria-label="Video Tabs"
                            >
                                {tabs.map((t, index) => (
                                    <Tab key={t.id} label={t.label} value={t.id} aria-controls={`tab-panel-${index}`} />
                                ))}
                            </Tabs>

                            {tabs.map((t, index) => (
                                <TabPanel key={t.id} selected={tab} value={t.id} index={index}>
                                    {t.component}
                                </TabPanel>
                            ))}
                        </>}


                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Stack spacing={2}>

                        {/*
                        <Button onClick={handleDuplicate} disabled={false} variant='outlined' size='small' color='primary'>
                            Duplicate Quiz
                        </Button>
                        <Button onClick={handleConfirmShare} disabled={false} variant='outlined' size='small' color='primary'>
                            Share Quiz
                        </Button>
                        */}

                        {/*
                        <Button onClick={() => handleSave()} disabled={!isDirty} variant='outlined' size='small' color='primary'>
                            Save
                        </Button>
                        */}

                        <Button onClick={() => handleSave(true)} disabled={!isDirty} variant='outlined' size='small' color='primary'>
                            Save
                        </Button>

                        {/*
                        <DropboxChooser
                            appKey="w7l7sbxwq4gyquq"
                            success={handleSuccess}
                            multiselect={false}
                            extensions={['.mp4', '.mov', '.avi', '.wmv', '.flv', '.mpg', '.mpeg', '.m4v', '.webm', '.mkv']}
                            // linkType="preview"
                            linkText="direct"
                        >
                            <Button className={'w-full'} variant='outlined' size='small' color='primary'>
                                Select a video from Dropbox
                            </Button>
                        </DropboxChooser>
                        */}


                        <Button onClick={deleteVideo} variant='outlined' size='small' color='error'>
                            Delete
                        </Button>

                        {isDirty && <Typography variant="body2" color="error" className={'m-auto text-center'}>*** UNSAVED CHANGES ***</Typography>}

                        {video?.url_thumbnail && <img
                            src={video.url_thumbnail}
                            alt="video thumbnail"
                            className={'w-full'}
                        />}


                        {/*<VideoUploader video={video}/>*/}

                    </Stack>

                </Grid>

                {/*
                <Grid item xs={12}>
                    <VideoInfoCard video={video} />
                    <Paper sx={{ padding: '10px', width: '100%' }}>
                        <h1>{video?.title}</h1>
                        <p>{video?.slug}</p>
                    </Paper>
                </Grid>
                    */}
            </Grid>
        </PageContainer>
    );
};


export default VideoEditor;
