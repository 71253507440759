import React from 'react';
import tw, { css, styled } from 'twin.macro';
import FileTable from "./FileTable";
import VolumeDropdown from "../StudioContainer/VolumeDropdown";
import { PageContainer } from "../../Home/PageContainer";
import { useGetAPI } from "../../context/api";
import { useDevTool } from "../../context/DevTool";

const UploadList = ({ children }) => {
    const { data } = useGetAPI('/api/uploads/');
    // useDevTool('files', data);
    // return null;
    return (
        <PageContainer
            maxWidth={'xl'}
            title={'Videos'}
            actions={[
                // searchBar,
                // toggleViews,
                // <VolumeDropdown selectedVolume={selectedVolume} onChangeVolume={setSelectedVolume} />,
                // addButton
            ]}
            paths={[
                { title: 'Home', href: '/', },
                { title: 'Studio', href: '/studio/' },
            ]}
        >

            <div className={'p-4 w-full h-full'}>
                <FileTable files={data} />
            </div>

        </PageContainer>
    )
    return (
        <div tw='w-full flex flex-col'>
            <div tw='w-full flex flex-row'>
                top row
            </div>
            <div tw='w-full flex flex-row flex-grow'>
                <FileTable />
            </div>
        </div>
    );
};

export default UploadList;
