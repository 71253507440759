import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, InputLabel, MenuItem, Modal, Select, Stack, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetAPI } from "../../../context/api";
import { VideoCard } from '../../../Components';
import apiClient from "../../../context/APIClient";
import { useTopics } from '../TopicsEditorProvider';
import { useStudio } from '../../Context/StudioContext';

const AddVideoDialog = ({ open, onClose, playlistId, lesson, lessonId }) => {

    const { data } = useGetAPI('/api/video_files/');
    const { fetchTopics } = useTopics();
    const studio = useStudio();
    const { mutateVideos, mutateLessons, mutateCourses } = studio;

    const videoLibrary = data;
    const [videoList, setVideoList] = useState([]);
    const [videoId, setId] = useState('')
    const handleClose = () => {
        onClose();
    }
    const navigate = useNavigate();

    const handleClick = (id) => {
        if (videoList.includes(id)) {
            setVideoList(videoList.filter((item) => item !== id));
        } else {
            setVideoList([...videoList, id]);
        }
    }

    const handleChange = (event) => {
        setId(event.target.value);
    }

    const onSubmit = () => {
        if (lesson) {
            let data = { video: videoId }
            apiClient.patch(`/api/admin/lessons/${lessonId}/`, data)
                .then((response) => {
                    console.log(response);
                    mutateVideos();
                    mutateLessons();
                    mutateCourses();
                    handleClose();
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        let data = { videos: videoList }
        apiClient.patch(`/api/admin/playlist_definitions/${playlistId}/`, data)
            .then((response) => {
                console.log(response);
                fetchTopics();
                handleClose();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const showError = (error, text = null) => {
        if (error) {
            // console.log(error);
            let message = text;
            if (!message) {
                if (error.type === 'required') message = 'This field is required';
                else message = error.message;
            }

            return (
                <div className='error-container text-red-500'>
                    <p className='error-tag'>{`* ${message}`}</p>
                </div>
            );
        }
        return null;
    };
    // console.log(errors);
    return (
        <Dialog onClose={handleClose} open={open} maxWidth={"lg"} fullWidth={true}>
            <DialogTitle>Select which videos you would like to add </DialogTitle>
            <DialogContent >
                <Grid container spacing={3}>
                    {videoLibrary?.map(video => (
                        <Grid item key={video.id} xs={12} sm={12} md={6} lg={4} xl={4}>
                            <VideoCard
                                className={'select-none cursor-pointer hover:shadow-xl active:shadow-sm'}
                                video={video}
                                videoSelection={lesson ? false : true}
                                handleClick={handleClick}
                                handleChange={handleChange}
                                videoList={videoList}
                                lesson={true}
                                videoId={videoId}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button type="button" color="primary" variant="outlined" size="small" onClick={onClose}>
                    Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" size="small" onClick={() => onSubmit()}>
                    Add
                </Button>
            </DialogActions>

        </Dialog>
    );
};

export default AddVideoDialog;