import React from 'react';
import { Button, FormControl, FormLabel, Grid, InputLabel, OutlinedInput, Stack } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import InputText, { InputSwitch } from "../../Quizzes/QuizEditor/FormControls";
import ThumbnailImage from "../ThumbnailImage";
import { showError } from "../../Quizzes/ErrorMessage";
import { useGetAPI } from "../../../context/api";
import LessonInputField from "../LessonInputField";
import QuizInputField from "../../Lessons/QuizInputField";

const VideoDetails = ({ video, lesson, quiz, updateVideo }) => {
    const errors = {};
    const linkComponent = (props) => {
        return (
            <div {...props}>
                hello
            </div>);
    }
    const updateQuiz = (quiz_id) => {
        console.log('updateQuiz', quiz_id);
        updateVideo({ quiz_id });
    }

    return (
        <Grid container rowSpacing={1} columnSpacing={1}>
            {/*
            <Grid item xs={12}>

                {video.url_thumbnail && <img
                    src={video.url_thumbnail}
                    alt="video thumbnail"
                    className={'h-[100px]'}
                />}
                <CardMedia
                    component="img"
                    // height="40"
                    image={video.url_thumbnail}
                    alt="video thumbnail"
                />
            </Grid>
            */}
            <Grid item xs={12}>
                <Stack spacing={2}>

                    <hr />

                    <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-id">ID</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-id"
                            readOnly={true}
                            value={video.id}
                            // onChange={(ev) => updateVideo({ slug: ev.target.value })}
                            // onChange={handleChange('amount')}
                            // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="ID"
                        />
                    </FormControl>
                    {showError(errors.title, errors.title)}

                    <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-adornment-slug">Slug</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-slug"
                            value={video.slug}
                            onChange={(ev) => updateVideo({ slug: ev.target.value })}
                            // onChange={handleChange('amount')}
                            // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Slug"
                        />
                    </FormControl>
                    {showError(errors.title, errors.title)}

                    <InputText id='copyright_year' name='copyright_year' label="Copyright Year" value={video.copyright_year} onChange={(ev) => updateVideo({ copyright_year: ev.target.value })} />
                    {showError(errors.copyright_year, errors.copyright_year)}

                    <hr />

                    <LessonInputField lessonId={video.lesson_id} updateVideo={updateVideo} />

                    <QuizInputField quizId={lesson?.quiz_id} updateQuiz={updateQuiz} />

                    <hr />
                    <FormLabel component="legend">Thumbnail</FormLabel>
                    <div className={'mb-4 flex'}>
                        <ThumbnailImage
                            video={video}
                            // updateVideo={updateVideo}
                            containerClassName={video.url_thumbnail ? 'px-[0px] py-[0px] overflow-hidden' : ''}
                            className={'h-[200px]'}
                        />
                    </div>
                    <hr />
                    <FormLabel component="legend">Video</FormLabel>

                    <InputText id='url_dash' name='url_dash' label="DASH URL" value={video.url_dash} onChange={(ev) => updateVideo({ url_dash: ev.target.value })} />
                    <InputText id='url_hls' name='url_hls' label="HLS URL" value={video.url_hls} onChange={(ev) => updateVideo({ url_hls: ev.target.value })} />

                    {/*
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">Description</InputLabel>
                    <OutlinedInput
                        multiline
                        id="outlined-adornment-amount"
                        value={video.description}
                        rows={4}
                        // onChange={handleChange('amount')}
                        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        label="Description"
                    />
                </FormControl>
                */}

                    {/*
                    <InputText id='slug' name='slug' label="Slug" value={video.slug} />
                    <InputText id='notes' name='notes' label="Notes" value={video.notes} multiline={true} minRows={2} />
                    */}
                </Stack>
            </Grid>

        </Grid>

    );
};


export default VideoDetails;
