import React, { useState } from 'react';
import { Grid, FormControl, InputLabel, OutlinedInput, Stack } from "@mui/material";

const QuestionNotes = ({ question, updateQuestion }) => {
    const [notes, setNotes] = useState(question?.notes ?? '');

    const handleChange = (event) => {
        console.log('handleChange', event.target.name, event.target.value);
        // if (updateQuestion) {
        //     updateQuestion({ [event.target.name]: event.target.value });
        // }
    }
    const handleBlur = (event) => {
        console.log('onBlur', event.target.name, event.target.value);
        if (updateQuestion) {
            updateQuestion({ [event.target.name]: event.target.value });
        }
    }
    return (
        <Grid item xs={12} className={'bg-red-400- flex-grow'}>
            <Stack spacing={2}>
                <FormControl fullWidth variant={'outlined'} color={'info'}>
                    <InputLabel id="question-notes-label">Notes</InputLabel>
                    <OutlinedInput
                        name="notes"
                        id="question-notes"
                        // color={'primary'}
                        multiline
                        minRows={20}
                        label="Notes"
                        defaultValue={question?.notes ?? ''}
                        // value={question?.notes}
                        onBlur={handleBlur}
                        // onChange={handleChange}
                    />
                </FormControl>
            </Stack>
        </Grid>
    );
};


export default QuestionNotes;
