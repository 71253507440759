import React from 'react';
import { Dialog, DialogContent, List, ListItem, ListItemButton, ListItemText, DialogTitle } from "@mui/material";
import apiClient from '../../../context/APIClient';
import { useGetAPI } from '../../../context/api';
import { useStudio } from '../../Context/StudioContext';

const LessonListDialog = ({ open, onClose, groupLessons, parentId }) => {

    const { data: lessons } = useGetAPI(`/api/lessons/`);
    const studio = useStudio();
    const { mutateCourses, mutateLessons } = studio;
    const handleClose = () => {
        onClose();
    }

    const onSubmitLesson = (lessonId) => {
        const payload = { lessons: [...groupLessons, lessonId] }
        apiClient.patch(`/api/admin/lesson_groups/${parentId}/`, payload)
            .then((response) => {
                console.log(response);
                mutateLessons();
                mutateCourses();
                handleClose();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={handleClose}
            aria-labelledby="lessons-dialog"
        >
            <DialogTitle id="lessons-dialog">Existing Lessons</DialogTitle>
            <DialogContent>
                <List>
                    {lessons?.map((lesson) => (
                        <ListItem disablePadding key={lesson.id}>
                            <ListItemButton
                                // selected={lessonId === video.id}
                                onClick={() => onSubmitLesson(lesson.id)}
                            >
                                <ListItemText primary={`${lesson.title} (${lesson.volume_title})`} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
};

export default LessonListDialog