import React, { useEffect, useMemo, useRef } from 'react';
import tw, { styled }  from 'twin.macro';
import AudioButton from '../AudioButton/AudioButton';
// import { getQuizAudioURL } from 'app/model/muzology';

// import { QUIZ_SHOW_AUDIO_CLIPS } from 'features';
// import { QUESTION_TYPE_FREE_ENTRY } from 'modules/quiz/constants.js';
// import tw, { styled } from 'twin.macro';
// import Input from '../FreeEntry/Input';
// import Keyboard from '../FreeEntry/Keyboard';

// import QuestionTypeFactory from '../QuestionTypes/factory';
// import { QUESTION_TYPE_FREE_ENTRY } from '../QuestionTypes/constants';
import ChoicesGrid from './ChoicesGrid.js';
import QuestionContents from './QuestionContents';
import { QUESTION_TYPE_FREE_ENTRY } from "../../Studio/Quizzes/QuizEditor/constants";

const QUIZ_SHOW_AUDIO_CLIPS = false;

// language=LESS
const Container = styled.div`
    &.question-container {
        flex-grow: 0;

        display: flex;
        flex-direction: column;

        user-select: none;

        //.audio-button {
        //    background: none;
        //    border: none;
        //    //border-radius: 50%;
        //    height: 32px;
        //    width: 32px;
        //}

        .question-contents {
            line-height: normal;

            .question-text {
                //text-align: left;
                //font-family: KaTeX_Main, serif;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 18px;
                font-weight: 800;
                color: #fff;
                @media only screen and (min-width: 500px) {
                    font-size: 20px;
                }
            }
        }

        .choice-audio-button {
            height: 32px;
            width: 32px;
            position: absolute;
            right: 0; // attach to the right side of the choice wrapper

            .audio-button-icon {
                height: 32px;
            }
        }

        .answer-button {
            margin: 10px;
            line-height: normal;

            flex-basis: calc(50% - 20px);

            position: relative;
        }

        .free-entry-contents {
            display: flex;
            flex-direction: column;
            color: black;
        }
    }

    .free-entry {
        background: white;
        font-family: 'Source Sans Pro', serif;
        width: 100%;
        height: 200px;
    }

    .choice-row {
        background: red;
        display: flex;
        flex-direction: row;
        flex-basis: 50%;
    }

    .grid-row {
        margin: 10px;
        line-height: normal;

        flex-basis: calc(50% - 20px);

        position: relative;
        grid-auto-rows: 1fr;

        .answer-button {
            margin: 10px;
            line-height: normal;

            flex-basis: calc(50% - 20px);

            position: relative;
        }
    }
`;

function useQuestionPlugin(pluginType) {
    return useMemo(() => {
        if (pluginType) {
            // const questionTypeFactory = new QuestionTypeFactory();
            // return questionTypeFactory.get(pluginType);
            return null;
        }
        return null;
    }, [pluginType]);
}

const Question = (props) => {
    // console.log('Question', props.question);
    // const [questionPlugin, setQuestionPlugin] = React.useState(null);
    // console.log('answerData', props.answerData);
    const inputElement = useRef(null);
    const questionAudio = useRef(null);

    // create the plugin for this question type
    // useEffect(() => {
    //     // console.log('getting custom question type', props.question.type);
    //     const questionTypeFactory = new QuestionTypeFactory();
    //     const plugin = questionTypeFactory.get(props.question.type);
    //     if (plugin) {
    //         // console.log('rendering custom question type', props.question.type, plugin);
    //         // setQuestionPlugin(plugin);
    //         // plugin.mount(props.question, props.context, props.onFreeEntryChanged);
    //         return () => {
    //             // console.log('unmounting custom question type', props.question.type, plugin);
    //             // plugin?.unmount(props.question, props.context, props.onFreeEntryChanged);
    //             // setQuestionPlugin(null);
    //         };
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    // const plugin = useMemo(() => {
    //     // console.log('getting custom question type', props.question.type);
    //     const questionTypeFactory = new QuestionTypeFactory();
    //     return questionTypeFactory.get(props.question.type);
    // }, [props.question.type]);

    // get a plugin for this question type
    const questionPlugin = useQuestionPlugin(props.question?.type);

    // get the components from the plugin
    // const { questionInputComponent } = questionPlugin || {};
    // const InputComponent = questionInputComponent ? questionInputComponent() : null;

    const handleClickChoice = (index) => {
        // check if buttons are disabled
        if (!props.enableAnswerButtons) return;
        if (props.onClickChoice) props.onClickChoice(index);
    };

    const audioButtonClicked = (index) => {
        if (index == null) {
            if (questionAudio) questionAudio.stop();
        }
    };

    const onFreeEntryChanged = (text) => {
        // console.log('free entry:', text);
        // put the entry into redux
        // TODO: this may cause a performance hit - move to local state, but will need to handle return key
        if (props.onFreeEntryChanged) props.onFreeEntryChanged(text);
    };

    const onFreeEntryKey = (key) => {
        // console.log('   New key: ', key);

        let char = key.key;
        if (key.unicode) {
            char = String.fromCharCode(key.unicode);
        }

        // put the character in the text input
        if (inputElement.current) {
            inputElement.current.insertCharacter(char);
        }
    };

    const renderFreeEntryInput = () => {
        console.log('rendering free entry input', props.question.type, props.question.attributes);
        const hideKeyboard = props.question.type === QUESTION_TYPE_FREE_ENTRY && props.question.attributes?.hide_keyboard;
        const singleLineInput = props.question.type === QUESTION_TYPE_FREE_ENTRY && props.question.attributes?.single_line_text;
        // make free entry disabled unless the state is using_answering
        const disabled = props.context?.state !== 'user_answering';
        return (
            <div className='free-entry-contents'>
                {/*
                <Input
                    ref={inputElement}
                    key={props.question.id}
                    text={props.text}
                    onChanged={onFreeEntryChanged}
                    disabled={disabled}
                    singleLine={singleLineInput}
                    question={props.question}
                    context={props.context}
                />

                {!hideKeyboard && (<Keyboard onKey={disabled ? null : onFreeEntryKey} />)}
                */}
                text box and keyboard...
            </div>
        );
    };

    const inputPanel = useMemo(() => {
        // render the plugin if it exists
        if (questionPlugin) {

            // render the input component
            // if (InputComponent) {
            //     return (
            //         <InputComponent
            //             question={props.question}
            //             context={props.context}
            //             onFreeEntryChanged={props.onFreeEntryChanged}
            //             setAnswerData={props.setAnswerData}
            //         />
            //     );
            // }

            // ask the plugin to render the input
            const render = questionPlugin.render(
                props.question,
                props.context,
                props.onFreeEntryChanged,
                props.setAnswerData,
                props.enableSubmitButton
            );
            if (render) {
                return render;
            }
        }

        // render free entry
        if (props.question?.type === QUESTION_TYPE_FREE_ENTRY) {
            return renderFreeEntryInput();
        }

        // render the default choice layout
        return (
            <ChoicesGrid
                question={props.question}
                buttonState={props.buttonState}
                choiceColor={props.choiceColor}
                enableAnswerButtons={props.enableAnswerButtons}
                onClickChoice={handleClickChoice}
            />
        );
    }, [handleClickChoice, props.buttonState, props.choiceColor, props.context, props.enableAnswerButtons, props.enableSubmitButton, props.onFreeEntryChanged, props.question, props.setAnswerData, questionPlugin, renderFreeEntryInput]);

    // console.log('CustomQuestionType', CustomQuestionType);
    // console.log('props.question.type', props.question.type);
    if (!props.question)
        return null;

    return (
        <Container
            choiceColor={props.choiceColor}
            className={`question-container ${props.className}}`}
            data-question-id={props.question.question_id}
        >
            <QuestionContents
                question={props.question}
                questionIndex={props.questionIndex}
                // audioSample={QUIZ_SHOW_AUDIO_CLIPS && getQuizAudioURL(props.question.question_id)}
            />

            <div className='px-5 pt-7 sm:pt-2 answer-section'>
                {inputPanel}
            </div>

        </Container>
    );
};

export default Question;
