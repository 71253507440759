import React from 'react';
import { Container, Paper } from "@mui/material";
import CourseTableView from "./CourseTableView";
import PanelView from "./PanelView";

const LibraryTableView = ({ course }) => {
    return (

        <PanelView>
            <CourseTableView course={course} />
        </PanelView>
    );
    // return (
    //     <Container maxWidth={null} style={{ display: 'flex', alignItems: 'stretch' }}>
    //         <div
    //             className='my-5 p-[10px] w-full flex grow'
    //             style={{
    //                 alignItems: 'stretch',
    //                 // backgroundColor: 'white',
    //                 // border: '1px solid lightgray',
    //                 // boxShadow: 'inset 1px 1px 2px #000000'
    //             }}
    //         >
    //             <Paper className={'p-2'} style={{
    //                 flexGrow:'1',
    //                 padding: '10px',
    //                 backgroundColor: 'white',
    //                 // boxShadow: '1px 1px 2px #000000',
    //                 border: '1px solid lightgray'
    //             }}>
    //                 <CourseTableView course={course} />
    //
    //             </Paper>
    //         </div>
    //     </Container>
    // );
};


export default LibraryTableView;
