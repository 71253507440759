import React, { useMemo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { QUIZ_LEVELS } from "../constants";
import Panel from "../../../../Components/Dashboard/Panel";

const QuizImageAccordion = ({ image, quiz, question, choice }) => {

    // collect all the items for this question
    const items = [];
    if (image && quiz) {
        if (quiz.image_url && image.url === quiz.image_url) {
            items.push(quiz);
        }
    }

    // return (
    //     <Table>
    //         <TableBody>
    //             {image.map((item, index) => {
    //                 <TableRow>
    //                     <TableCell>
    //                         ID
    //                     </TableCell>
    //                     <TableCell>
    //                         {quiz.id}
    //                     </TableCell>
    //                 </TableRow>
    //             })}
    //         </TableBody>
    //     </Table>
    // )

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <div className={'flex flex-row items-center'}>
                    <img src={image.url} alt={image.alt} className={'max-h-[100px]'} />
                    <div className={'ml-2'}>
                        <Typography>Quiz Level {question?.level} - {QUIZ_LEVELS[question?.level]}</Typography>
                        <Typography>Question {question?.question_index}</Typography>
                        {image.type === 'choice' && <Typography>Choice {choice?.choice_index}</Typography>}
                    </div>
                </div>

            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Paper variant="outlined">
                            <img src={image.url} alt={image.alt} className={''} />
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper variant="outlined">
                            <div className={'w-[50%] flex-grow'}>
                                <div>URL: {image.url}</div>
                                <div>Res: ? x ?</div>
                                <div>Size: 0 bytes</div>
                                <div>Question ID: {image.question.id}</div>
                                <div>Question Index: {image.question.question_index}</div>
                                {image.type === 'choice' && <div>Choice Index: {image.choice.choice_index}</div>}
                                <div>Type: {image.type}</div>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const QuizImageRow = ({ image }) => {
    return (
        <div className={'flex'}>

            <div className={'w-[25%] flex-grow '}>
                <img src={image.url} className='max-h-[300px]' alt={null} />
            </div>

            <div className={'w-[50%] flex-grow'}>
                <div>URL: {image.url}</div>
                <div>Res: ? x ?</div>
                <div>Size: 0 bytes</div>
                <div>Question ID: {image.question.id}</div>
                <div>Question Index: {image.question.question_index}</div>
                {image.type === 'choice' && <div>Choice Index: {image.choice.choice_index}</div>}
                <div>Type: {image.type}</div>
            </div>

        </div>
    );
};

const QuizImages = ({ quiz }) => {
        console.log('QuizImages', quiz);
        const images = useMemo(() => {
            if (!quiz)
                return [];
            console.log('QuizImages useMemo', quiz)
            return quiz.questions.reduce((acc, question) => {
                console.log(' + question', question.id, question.image_url);
                if (question.image_url) {
                    console.log('question', question);
                    acc.push({
                        id: question.id,
                        type: 'question',
                        question: question,
                        index: question.question_index,
                        url: question.image_url,
                    });
                }
                return question.choices.reduce((acc, choice) => {
                    console.log(' + choice', choice.id, choice.content_image);
                    if (choice.content_image) {
                        console.log('    choice', choice);
                        acc.push({
                            id: choice.id,
                            type: 'choice',
                            question: question,
                            choice: choice,
                            index: choice.choice_index,
                            url: choice.choice_text,
                        });
                        // acc.push(choice.choice_text);
                    } else if (choice.image_url) {
                        console.log('      choice', choice);
                        acc.push({
                            id: choice.id,
                            type: 'choice',
                            question: question,
                            choice: choice,
                            index: choice.choice_index,
                            url: choice.image_url,
                        });
                    }
                    return acc;
                }, acc);
            }, []);
        }, [quiz]);

        console.log("all images", images);
        return (
            <Grid container>

                {/* <Grid item xs={12}>
                    {images.map((image, index) => {
                        return (
                            <Grid key={image.id} container>
                                <Grid item xs={12}>
                                    {image.type === 'question' && <Typography>Question {image.question.question_index}</Typography>}
                                    {image.type === 'choice' && <Typography>Choice {image.choice.choice_index}</Typography>}
                                </Grid>

                                <Grid item xs={4}>
                                    <Paper sx={{ margin: 'auto' }} variant="outlined" elevation={3}>
                                        <img src={image.url} className={'max-h-[100px]'} />
                                    </Paper>


                                    <div className={'bg-red-600 flex align-center justify-center'}>

                                        <Paper sx={{ margin: 'auto' }} variant="outlined" elevation={3}>
                                            paper
                                        </Paper>

                                    </div>


                                </Grid>
                                <Grid item xs={8}>
                                    <Paper sx={{ margin: 'auto' }} variant="outlined" elevation={3}>
                                        {image.url}
                                    </Paper>
                                </Grid>
                            </Grid>
                        )
                    })};
                </Grid>*/}

                {/*
                <Grid item xs={12}>
                    {images.map((image, index) => {
                        return (
                            <Grid item xs={12}>
                                <QuizImageAccordion image={image} />
                            </Grid>
                        )
                    })};
                </Grid>
                */}

                <Grid item xs={12}>
                    <Panel title={'Images'}>


                    {images.map((image, index) => {
                    return (
                        <div key={image.id} className={''}>
                            <QuizImageRow image={image} />
                            <hr className={'w-full'} />
                        </div>
                    );
                })}
                    </Panel>
                </Grid>
            </Grid>
        );
    }
;


export default QuizImages;
