import QuizSharpIcon from "@mui/icons-material/QuizSharp";
import VideoLibrarySharpIcon from "@mui/icons-material/VideoLibrarySharp";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { AccountTreeSharp, CollectionsBookmarkSharp, FeaturedPlayListSharp, ImageSharp, WorkSharp, WorkspacesOutlined, WorkspacesSharp } from "@material-ui/icons";
import { WorkHistorySharp, WorkspacePremiumRounded } from "@mui/icons-material";

export const studio_menu_items = [
    {
        title: 'Video Library',
        description: 'Create and manage video libraries',
        url: '/studio/library',
        icon: <QuizSharpIcon />,
    },
    {
        title: 'Videos',
        description: 'Create and manage videos',
        url: '/studio/videos',
        icon: <VideoLibrarySharpIcon />,
    },
    {
        title: 'Video Uploads',
        description: 'Mange uploaded files',
        url: '/studio/uploads',
        icon: <QuizSharpIcon />,
    },
    {
        title: 'Quizzes',
        description: 'Create and manage quizzes',
        url: '/studio/quizzes',
        icon: <QuizSharpIcon />,
    },
    {
        title: 'Lessons',
        url: '/studio/lessons',
        icon: <CollectionsBookmarkSharp />,
    },
    {
        title: 'Groupings',
        url: '/studio/groupings',
        icon: <LibraryBooksIcon />,
    },
];

export const studio_secondary_menu_items = [
    {
        title: 'Images',
        url: '/studio/images',
        icon: <ImageSharp />,
    },
    {
        title: 'Projects',
        url: '/studio/projects',
        icon: <AccountTreeSharp />,
    },
    {
        title: 'Playlists',
        url: '/studio/playlists',
        icon: <FeaturedPlayListSharp />,
    }
];
