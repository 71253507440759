// import { CHOICE_CORRECT, CHOICE_INCORRECT, CHOICE_SELECTED, CHOICE_UNSELECTED } from 'modules/quiz/constants';

// export const choiceStateClassNames = {
//     [CHOICE_UNSELECTED]: 'normal',
//     [CHOICE_SELECTED]: 'selected',
//     [CHOICE_INCORRECT]: 'incorrect',
//     [CHOICE_CORRECT]: 'correct'
// };

export const QuizChoiceLayouts = [
    {
        name: '1 Column',
        id: 'grid-1-col',
    },
    {
        name: '2 Columns',
        id: 'grid-2-col',
    }
];
