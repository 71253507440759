import React from 'react';
import { TextField, Stack, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, Grid, Container, Divider, DialogContentText } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { useStudio } from "../../Context/StudioContext";
import PanelView from "../PanelView";
import DropdownFilter from "../../../Admin/Accounts/DropdownFilter";
import { useItemEditor } from "../../StudioContainer/StudioContainer";

const LibrarySettings = ({ course }) => {
    const { lessons } = useStudio();
    // get lessons for each lesson_id in the course
    const groups = course?.groups ?? [];
    const items = course?.lessons?.map(lesson => lessons.find(l => l.id === lesson)).filter(l => l) ?? [];
    const listStyle = {
        margin: '20px 0',
        padding: '10px',
        backgroundColor: '#f7f7f7', // Light grey background for the list section
        borderRadius: '5px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)' // subtle shadow for depth
    };

    const { volumes} = useItemEditor();
    const volumeList = volumes?.map(volume => [volume.id, volume.title]) ?? [];
    const [volumeId, setVolumeId] = React.useState(course?.volume_id ?? 'Vol');

    console.log('volumeId',volumeId);
    const handleSetVolume = (id) => {
        console.log('handleSetVolume', id);
        setVolumeId(id);
    }

    return (
        <PanelView>
            <Typography variant="h4">Course Catalog</Typography>
            <form noValidate autoComplete="off">

                <Stack spacing={2}>
                    <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={course?.title ?? ''}
                    />

                    <DropdownFilter
                        className={'w-full m-0 my-4'}
                        label={'Volume'}
                        items={volumeList}
                        initialSelected={volumeId ?? ''}
                        onChange={handleSetVolume}
                    />

                    <div spacing={2}>
                        <Typography variant="subtitle1">Groups</Typography>
                        <Paper style={listStyle}>
                            <List>
                                {groups?.map(folder => (
                                    <ListItem key={folder.id}>
                                        <ListItemIcon>
                                            <FolderIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={folder.title} />
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </div>

                    {/*<Divider variant="middle" />*/}

                    <div spacing={2}>
                        <Typography variant="subtitle1">Videos</Typography>
                        <Paper style={listStyle}>
                            <Typography variant="subtitle1">Videos</Typography>
                            <List>
                                {items?.filter(l => l).map(video => (
                                    <ListItem key={video?.id}>
                                        <ListItemIcon>
                                            <VideoLibraryIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={video?.title} />
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </div>

                </Stack>



            </form>
        </PanelView>
    );
    return (
        <Container maxWidth="lg" className='mt-0' spacing={2}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper style={{ padding: 16 }}>
                        <Typography variant="h4">Course Catalog</Typography>
                        <form noValidate autoComplete="off">
                            <TextField
                                label="Name"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={course?.title}
                            />




                            <Typography variant="subtitle1">Groups</Typography>

                            <Paper style={listStyle}>
                                <List>
                                    {groups?.map(folder => (
                                        <ListItem key={folder.id}>
                                            <ListItemIcon>
                                                <FolderIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={folder.title} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>

                            <Divider variant="middle" />

                            <Paper style={listStyle}>
                                <Typography variant="subtitle1">Videos</Typography>
                            <List>
                                {items?.filter(l => l).map(video => (
                                    <ListItem key={video?.id}>
                                        <ListItemIcon>
                                            <VideoLibraryIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={video?.title} />
                                    </ListItem>
                                ))}
                            </List>
                            </Paper>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </Container>

    );
};


const LibrarySettings2 = (props) => {
    // {...register("name", { required: true })}
    return (
        <div>
            <div className={'flex flex-row justify-between items-center'}>

                <Stack spacing={3} sx={{ paddingTop: '10px' }}>
                    <TextField
                        autoFocus
                        fullWidth
                        // onChange={onChange}
                        // value={value}
                        label={"Name"}
                    />
                    {/*{showError(errors.name)}*/}
                </Stack>

            </div>
        </div>
    );
};


export default LibrarySettings;
