import React from 'react';

import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card, CardActions } from "@mui/material";

export function VideoInfoCard({ video }) {
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardMedia
                component="img"
                height="140"
                image={video.url_thumbnail}
                alt="video thumbnail"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {video.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {video.description}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small">Action</Button>
                <Button size="large">Action</Button>
                {/*
                <Button size="small">Share</Button>
                <Button size="small">Learn More</Button>
                */}
            </CardActions>
        </Card>
    );
}

export default VideoInfoCard;
