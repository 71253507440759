import React from 'react';
import { FormControl, FormControlLabel, InputLabel, OutlinedInput, Switch } from "@mui/material";

export const InputText = ({ id, name, label, value, onChange, sx, ...props  }) => {
    return (
        <FormControl fullWidth sx={sx}>
            <InputLabel id={`${id}-label`}>{label}</InputLabel>
            <OutlinedInput
                id={id}
                // labelId={`${id}-label`}
                label={label}
                name={name}
                value={value ?? ''}
                onChange={onChange}
                {...props}
            />
        </FormControl>
    );
};

export const InputSwitch = ({ id, name, label, defaultChecked, value, onChange, ...props }) => {
    return (
        <FormControlLabel
            control={
                <Switch
                    id={id}
                    name={name}
                    // defaultChecked={defaultChecked}
                    checked={value}
                    onChange={onChange}
                />
            }
            label={label}
            {...props}
        />
    )
    // return (
    //     <FormControl
    //         fullWidth
    //     >
    //         <InputLabel id={`${id}-label`}>{label}</InputLabel>
    //         <OutlinedInput
    //             id={id}
    //             labelId={`${id}-label`}
    //             label={label}
    //             name={name}
    //             value={value}
    //             onChange={onChange}
    //             {...props}
    //         />
    //     </FormControl>
    // );
};


export default InputText;