/**
 * Created by jasonduncan on 7/13/18.
 */
import React, { useCallback, useMemo } from 'react';
import tw, { css, styled } from 'twin.macro';

// import Page from 'components/Page';
import SubmitButton from './SubmitButton';
import Question from './Question';
import { IncorrectMessage } from './IncorrectMessage';

const QuestionPageContainer = styled.div`
    align-items: stretch;
    position: relative;
    padding: 30px 20px 40px;
    background: linear-gradient(127.46deg, rgb(45, 5, 105) 13.37%, rgb(76, 63, 193) 71.65%);


    .page-container {
        display: flex;
        flex-direction: column;
    }

    .question-page-contents {
        .question-section {
            //background:red;
        }

        .answer-section {
            /* background: #7569d6; */
            @media only screen and (min-width: 1200px) {
                // 1200 and over
            }
            @media only screen and (max-width: 1199px) and (min-width: 992px) {
                // 992 - 1199
            }
            @media only screen and (max-width: 991px) and (min-width: 768px) {
                // 768 - 992
                padding-right: 40px;
                padding-left: 40px;
            }
            @media only screen and (max-width: 767px) and (min-width: 401px) {
                // 767 - 401
                padding-right: 20px;
                padding-left: 20px;
            }
            @media only screen and (max-width: 400px) {
                // 400 and lower
                padding-right: 10px;
                padding-left: 10px;
            }
        }

        .submit-section {
            /* padding-top: 30px; */
            padding-bottom: 20px;
            max-width: 921px;
            display: flex;
            justify-content: center;
            //background: #7569d6;
            border-radius: 0px 0px 20px 20px;
            margin: auto;

            .button {
                width: 200px;
                background-color: #8e71e9;
            }
        }
    }

    .question-text {
        font-family: KaTeX_Main, serif;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.72);

        //margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const QuestionPage = (props) => {
    // get properties
    const {
        question,
        className,
        style,
        // buttonState,
        // choiceColor,
        // freeEntryTextChanged,
        // setAnswerData,
        // answerData,
        // enableSubmitButton,
        // submitQuestionAnswer,
        // setQuestionChoice
    } = props;

    // get the button text & allow it to be overridden
    const buttonText = "CHECK";
    const handleButtonClick = null;

    // check if this question failed
    let thisQuestionFailed = false;
    // console.log('context', context);
    // get special error message for multiple select - if the question is incorrect
    let errorMessage = null;

    // create the page

    return (
        <QuestionPageContainer className={`question-page ${className ?? ''}`} style={style ?? { }}>
            <div className='question-page-contents p-0' >
                <div
                    className='question-section p-0 paddingBottom[50px] flex items-stretch flex-col flex-grow w-full sm:maxWidth[1024px] m-[0.5rem auto 0] m-[5rem auto 0]'
                >
                    <Question
                        className='background[blue]'
                        // context={context}
                        // enableAnswerButtons={context.enable_answer_buttons}
                        // questionIndex={context.question_index}
                        question={question}
                        // buttonState={buttonState}
                        // choiceColor={choiceColor}

                        // onClickChoice={setQuestionChoice}
                        // submitQuestionAnswer={submitQuestionAnswer}
                        // setAnswerData={setAnswerData}
                        // answerData={answerData}
                        // enableSubmitButton={enableSubmitButton}
                        // these are linked...
                        // onFreeEntryChanged={freeEntryTextChanged}
                        // text={context && context.response ? context.response.free_entry : null}
                    />

                    {errorMessage && <div className='relative w-full mt-2'>
                        {errorMessage}
                    </div>}


                </div>

                <div className='submit-section'>
                    <SubmitButton
                        title={buttonText}
                        // disabled={!context.enable_submit_button}
                        onClick={handleButtonClick}
                        className={buttonText === 'NEXT' && 'aqua'}
                    />
                </div>

                {props.children}
            </div>

            {/*
            <div className='hidden sm:block absolute bottom-0 left-0'>
                <img src={SplicedRecords(level)} alt={`level ${level}`} />
            </div>
            */}
        </QuestionPageContainer>
    );
};

export default QuestionPage;
