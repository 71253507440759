import React, { useRef } from 'react';
import 'twin.macro';
import { AudioIcon } from '../../assets/icons';

const AudioButton = ({ audioSample, children, className, tabIndex }) => {
    const audioElement = useRef();

    const handleClick = () => {
        if (audioElement.current) {
            if (audioElement.current.paused) {
                audioElement.current.play();
            } else {
                audioElement.current.pause();
            }
        }
    };
    // console.log('audioSample', audioSample);
    return (
        <button
            tabIndex={tabIndex ?? 1000}
            className={`flex justify-center items-center select-none text-white text-xl cursor-pointer hover:color[#cccccc] active:color[#bbbbbb] audio-button ${className}`}
            onClick={handleClick}
        >
            {children}

            <AudioIcon />

            <audio
                ref={audioElement}
                className='audio-player-node'
                autoPlay={false}
                src={audioSample}
            />
        </button>
    );
};

AudioButton.defaultProps = {
    audioSample: null, // "http://public.muzology.com/quiz-audio/56f950cd-b2a9-4aaf-be4e-147fea5c678a.mp3",
    onClick: null,
    className: null,
    style: null
};

export default AudioButton;
