import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { TableHead } from "@mui/material";
import MaterialTable from "@material-table/core";
import { useDevTool } from "../../context/DevTool";

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const AccountsTable = ({ rows = [], total, setPage, page, rowsPerPage, setRowsPerPage, tableRef }) => {
    // console.log(rowsPerPage, rows.length);
    // Avoid a layout jump when reaching the last page with empty rows.
    // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const emptyRows = Math.max(0, rowsPerPage - rows.length);
    // console.log('empty=', emptyRows);
    // console.log('rows=',rows);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event, b) => {
        // console.log(event, b);
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };
    /*
        {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row) => (
     */

    const sortedRows = rows?.toSorted((a, b) => {
        const aID = a['id'];
        const bID = b['id'];
        if (aID > bID) return 1;
        if (aID < bID) return -1;
        return 0;
    });

    const pageRows = (
        rowsPerPage > 0 ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedRows
    );

    useDevTool('AccountsTable', { total, rows, sortedRows });

    // return (
    //     <div style={{ width: "100%", background: 'red' }}>
    //         <MaterialTable
    //             tableRef={tableRef}
    //             style={{ width: "100%" }}
    //             columns={[
    //                 { title: "ID", field: "id" },
    //                 { title: "Name", field: "name" },
    //                 { title: "Classification", field: "account_classification_name" },
    //                 { title: "Active", field: "active" },
    //                 // { title: "Soyadı", field: "surname" },
    //                 // { title: "Doğum Yılı", field: "birthYear", type: "numeric" },
    //                 // {
    //                 //     title: "Doğum Yeri",
    //                 //     field: "birthCity",
    //                 //     lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
    //                 // },
    //             ]}
    //             data={rows ?? []}
    //             title="Accounts"
    //             onRowsPerPageChange={setRowsPerPage}
    //             onPageChange={setPage}
    //         />
    //     </div>
    // );
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        {/* identity */}
                        <TableCell>#</TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>

                        {/* type */}
                        <TableCell>Type</TableCell>
                        <TableCell align="left">Variant</TableCell>

                        {/* state */}
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Active</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pageRows.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                                {rows.indexOf(row) + 1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.id}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.created}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.email}
                            </TableCell>

                            <TableCell component="th" scope="row">
                                {row.account_type === 10 ? "PARENT" : "TEACHER"}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="left">
                                {row.account_variant}
                            </TableCell>

                            <TableCell style={{ width: 160 }} align="left">
                                {row.status}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="left">
                                {row.active ? "ACTIVE" : "INACTIVE"}
                            </TableCell>

                        </TableRow>
                    ))}

                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            colSpan={3}
                            count={total}
                            page={page}
                            rowsPerPageOptions={[5, 10, 25, 50, { label: 'All', value: -1 }]}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            SelectProps={{ inputProps: { 'aria-label': 'rows per page' }, native: true }}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

export default AccountsTable;

