import React from 'react';
import { useGetAPI } from "../context/api";
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";

const ProjectItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const ProjectCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    // color: '#fff',

}));

const ProjectsContainer = (props) => {
    const { loading, error, data } = useGetAPI('/api/projects/');
    const navigate = useNavigate();
    console.log(data);
    return (
        <Container sx={{ paddingTop: '2em' }}>
            <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                justify="left"
            >
                {data?.map((project, index) => (
                    <Grid item xs={2} sm={4} md={4} key={index}>
                        <ProjectCard onClick={() => navigate(`/project/${project.project_id}`)}>
                            <CardActionArea >
                                {/*
                            <CardMedia
                                component="img"
                                height="140"
                                image="/static/images/cards/contemplative-reptile.jpg"
                                alt="green iguana"
                            />
                            */}
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Project {index + 1}
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        {project.name}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        {project.created}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary">
                                    Open
                                </Button>
                                <Button size="small" color="primary">
                                    Duplicate
                                </Button>
                                <Button size="small">
                                    Delete
                                </Button>
                            </CardActions>
                        </ProjectCard>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};


export default ProjectsContainer;
