import React, { useCallback } from 'react';
import { useDropzone } from "react-dropzone";
import { Box, IconButton } from "@mui/material";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import InputText from "../Quizzes/QuizEditor/FormControls";
import apiClient from "../../context/APIClient";

const ThumbnailImage = ({ video, updateVideo, className, containerClassName, ...props }) => {

    const [showURLInput, setShowURLInput] = React.useState(false);

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        console.log(acceptedFiles);
        acceptedFiles.map((file) => {
            console.log(file);
            const formData = new FormData();
            formData.append("file", file);
            formData.append('video_id', video.id);
            console.log('upload image to video', video);
            return apiClient.put("/api/quiz_image/", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Disposition": `attachment; filename="${file.name}"`
                },
            })
                .then(response => {
                    const data = response.data;
                    console.log(data);
                    if (video)
                        updateVideo({ url_thumbnail: data.url });
                })
                .catch(error => {
                    console.log(error);
                    alert("There was an error uploading the file. Please contact tech.");
                });
        });
        // api.all(uploaders).then(() => {
        //     // ... perform after upload is successful operation
        //     console.log("Files uploaded")
        // });

    }, [updateVideo, video]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <div className={'w-full relative border-2 border-solid rounded-lg cursor-pointer overflow-hidden'}>
            <div
                className={'min-h-[56px] flex justify-center items-center ' + containerClassName}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                {video?.url_thumbnail ?
                    <img
                        src={video?.url_thumbnail}
                        width={'auto'}
                        height={'100%'}
                        className={className}
                        // className={'max-h-[250px] my-4'}
                    />
                    :
                    <div className={'text-[#999999] bg-[#eeeeee] hover:bg-[#dddddd] active:bg-[#ffffff] font-bold text-xl min-h-[100px] w-full h-full flex justify-center items-center px-10'}>
                        {isDragActive ? "Drop here" : "Drag images here, or click to upload."}
                    </div>
                }
            </div>
            <Box sx={{ position: 'absolute', right: 0, top: 0, display: 'flex', flexDirection: 'column' }}>
                <IconButton
                    // sx={{ position: 'absolute', right: 0, top: 0 }}
                    onClick={() => setShowURLInput(!showURLInput)}
                >
                    <ModeEditSharpIcon />
                </IconButton>
                <IconButton
                    disabled={video?.url_thumbnail ? false : true}
                    // sx={{ position: 'absolute', right: 0, top: 0 }}
                    onClick={() => updateVideo({ url_thumbnail: null })}
                >
                    <DeleteSharpIcon />
                </IconButton>
            </Box>

            {showURLInput &&
                <div className={'flex justify-center items-center m-2'}>
                    <InputText
                        id='url_thumbnail'
                        label='URL'
                        name='url_thumbnail'
                        value={video?.url_thumbnail}
                        onChange={(e) => {
                            if (video) {
                                updateVideo({ id: video.id, url_thumbnail: e.target.value })
                            }
                        }}
                    />
                </div>
            }
        </div>
    );
};

export default ThumbnailImage;
