import React from 'react';
import { Button, Grid, Stack } from "@mui/material";
import Divider from "@material-ui/core/Divider";
import SourceVideoDetails from "../SourceVideos/SourceVideoDetails";

const SourceVideo = ({ video, uploadId, sourceVideos, onSelectUpload }) => {
    const sourceVideo = sourceVideos?.find(video => video.id === uploadId);
    return (
        <Stack spacing={2}>
            {/* back button */}
            <Grid item>
                <Button variant="outlined" onClick={() => onSelectUpload(null)}>Back</Button>
            </Grid>
            <Divider />
            <SourceVideoDetails
                video={video}
                uploadId={uploadId}
                sourceVideo={sourceVideo}
                setUploadId={onSelectUpload}
            />

            {/*
                <Grid container>
                    <Grid item xs={12}>

                    </Grid>
                </Grid>
                */}
        </Stack>
    );
};

export default SourceVideo;
