import React from 'react';
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";

const AdminLayout = (props) => {
    return (
        <div id={'main-layout'} className={'h-screen flex flex-col'}>
            <Navbar title={"Muzology Admin"}/>
            <div id={'main-contents'} className={'flex flex-grow bg-red-200- bg-gray-50'}>
                {props.children}
                <Outlet />
            </div>
        </div>
    );
};

export default AdminLayout;
