import React from 'react';
import ChatStream from "./ChatStream/ChatStream";
import { Container, Grid } from "@mui/material";

const AiWorkspace = (props) => {
    return (
        <Container maxWidth={'lg'} className='h-full' spacing={2}>
            <Grid container spacing={2} className={' h-full bg-orange-300-'}>
                <Grid item xs={12} className={' h-full bg-orange-300-'}>
                    <ChatStream  model={'gpt-4'} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default AiWorkspace;