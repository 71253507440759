import React, { useState } from 'react';
import { Button, Container, Grid, Stack, TextField } from "@mui/material";
import apiClient from "../../context/APIClient";

const GenerateImage = (props) => {
    const [query, setQuery] = useState(null);
    const [responses, setResponses] = useState([]);

    const onChange = (event) => {
        setQuery(event.target.value);
    }

    const postQuery = () => {
        apiClient.post('/chatgpt/', { 'prompt': query })
            .then(response => {
                console.log(response);
                const {data} = response;
            });
    };

    return (
        <Container>
            <Stack spacing={2}>

                <TextField
                    variant='outlined'
                    // label='Description'
                    multiline
                    minRows={2}
                    fullWidth
                    onChange={onChange}
                    value={query}
                />

                <Button onClick={postQuery}>
                    Submit
                </Button>
            </Stack>
        </Container>
    );
};


export default GenerateImage;
