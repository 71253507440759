import React from 'react';
import tw from 'twin.macro';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useCreateAccountDialog } from "./CreateAccount/CreateAccountDialog";
import { Card, CardContent, CardHeader, Container, Grid, InputAdornment, Stack, TextField } from "@mui/material";
import DashboardCard from "../Components/Dashboard/DashboardCard";
import IconTextList from "../Components/Dashboard/IconTextList";
import { studio_menu_items } from "../Studio/studio_menu_items";
import SearchField from "../Components/SearchField/SearchField";

const data = [
    { name: 'Jan', users: 400 },
    { name: 'Feb', users: 300 },
    { name: 'Mar', users: 200 },
    { name: 'Apr', users: 278 },
    { name: 'May', users: 189 },
    { name: 'Jun', users: 239 },
    { name: 'Jul', users: 345 },
    { name: 'Aug', users: 200 },
    { name: 'Sep', users: 300 },
    { name: 'Oct', users: 400 },
    { name: 'Nov', users: 200 },
    { name: 'Dec', users: 278 },
];

const studio_items = [
    { name: 'Quizzes', }
]
const SearchInput = () => {
    return (
        <div tw="relative w-full max-w-md mx-auto">
            <div tw="absolute inset-y-0 left-0 flex items-center pl-2">
                <SearchIcon />
            </div>
            <InputBase
                tw="pl-8 pr-2 py-1 text-black bg-white border border-gray-300 rounded-md"
                placeholder="Search…"
            />
        </div>
    );
};

const AdminConsoleHome = () => {
    const { show: showCreateAccount, Dialog: CreateAccountDialog } = useCreateAccountDialog();

    return (
        <Container>

            <Stack spacing={4} marginTop={2}>
                <SearchField />

                <TextField
                    // label="Search"
                    backgroundColor="white"
                    bgcolor="white"
                    variant="outlined"
                    fullWidth
                    inputProps={{ style: { backgroundColor: 'white' } }}
                    InputProps={{
                        style: { backgroundColor: 'white' },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />

                <Typography variant="h4" marginTop={0} marginBottom={0}>
                    Admin Console
                </Typography>

                <DashboardCard title="Manage Accounts">
                    <IconTextList />
                </DashboardCard>

                <DashboardCard title="Studio">
                    <IconTextList items={studio_menu_items} />
                </DashboardCard>

            </Stack>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title="Manage Accounts"
                            titleTypographyProps={{ variant: 'h6' }}
                            className={'bg-[#fafafa]'}
                        />
                        <Divider />
                        <CardContent className={''}>
                            <List>
                                <ListItem button>
                                    <ListItemText primary="Dashboard" />
                                </ListItem>
                                <ListItem button>
                                    <ListItemText primary="Users" />
                                </ListItem>
                                <ListItem button>
                                    <ListItemText primary="Settings" />
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                </Grid>


                <Grid item xs={12}>
                    <Card>
                        <Typography variant="h6">
                            Menu Commands
                        </Typography>
                        <List>
                            <ListItem button>
                                <ListItemText primary="Dashboard" />
                            </ListItem>
                            <ListItem button>
                                <ListItemText primary="Users" />
                            </ListItem>
                            <ListItem button>
                                <ListItemText primary="Settings" />
                            </ListItem>
                        </List>
                    </Card>
                </Grid>
            </Grid>

            <Paper tw="w-1/4 h-full mr-4 p-4">
                <Typography variant="h6">
                    Menu Commands
                </Typography>
                <List>
                    <ListItem button>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="Users" />
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="Settings" />
                    </ListItem>
                </List>
            </Paper>
            <Card>
                <div tw="w-3/4">
                    <Typography variant="h6" tw="mb-4">
                        Basic User Metrics
                    </Typography>
                    <LineChart
                        width={730}
                        height={250}
                        data={data}
                        tw="mx-auto"
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="users" stroke="#8884d8" />
                    </LineChart>
                </div>
            </Card>
        </Container>
    );

    return (
        <div tw="flex flex-col h-full w-full">


            {/*            <AppBar position="static">
                <Toolbar tw="flex justify-between">
                    <Typography variant="h6">
                        Admin Console
                    </Typography>
                    <div tw="relative">
                        <div tw="absolute inset-y-0 left-0 flex items-center pl-2">
                            <SearchIcon />
                        </div>
                        <InputBase
                            tw="pl-8 pr-2 py-1 text-white bg-gray-700 rounded-md"
                            placeholder="Search…"
                        />
                    </div>
                </Toolbar>
            </AppBar>*/}

            <div tw="flex flex-1 p-4">
                <Paper tw="w-1/4 h-full mr-4 p-4">
                    <Typography variant="h6">
                        Menu Commands
                    </Typography>
                    <List>
                        <ListItem button>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                        <ListItem button>
                            <ListItemText primary="Users" />
                        </ListItem>
                        <ListItem button>
                            <ListItemText primary="Settings" />
                        </ListItem>
                    </List>
                </Paper>


            </div>
        </div>
    );
};

export default AdminConsoleHome;
