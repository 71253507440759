import React from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { Typography } from "@mui/material";

const CourseTableView = ({ course }) => {
    const rows = course?.groups ?? [];
    console.log('rows', rows);

    const columns = [
        {
            field: 'title',
            headerName: 'Title',
            width: 200,
            editable: true,
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 200,
        },
        // {
        //     field: 'slug',
        //     headerName: 'Slug',
        //     width: 200,
        //     editable: true,
        // },
        // {
        //     field: 'created',
        //     headerName: 'Created',
        //     width: 200,
        //     editable: true,
        // },
        // {
        //     field: 'updated',
        //     headerName: 'Updated',
        //     width: 200,
        //     editable: true,
        // },
    ]

    return (
        <div className={'h-full w-full flex items-stretch flex-col'}>
            <div className={'pb-1 mb-2'}>
                <Typography variant={'h6'}>Groups</Typography>
            </div>

            <div className={'h-full w-full'}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    // onRowClick={onRowClick}
                    // autoHeight={true}
                    // hideFooter={true}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'title', sort: 'asc' }],
                        },
                    }}
                />
                {/*
            <Typography variant={'h6'}>Videos</Typography>
            <DataGrid
                rows={rows}
                columns={columns}
                // onRowClick={onRowClick}
                autoHeight={true}
                // hideFooter={true}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'title', sort: 'asc' }],
                    },
                }}
            />
            */}
            </div>
        </div>
    );
};


export default CourseTableView;
