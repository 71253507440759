import React from 'react';
import { useCreateAccountDialog } from "./CreateAccount/CreateAccountDialog";
import useDialog from "../Components/Dialog/Dialog";
import { Button, Typography } from "@mui/material";
import AdminMenuGrid from "./CreateAccount/AdminMenuGrid";
import AdminConsoleHome from "./AdminConsoleHome";

const ManagementContainer = (props) => {
    const { showDialog, DialogWrapper } = useDialog();
    const { show: showCreateAccount, Dialog: CreateAccountDialog } = useCreateAccountDialog();
    return (
        <div className={'w-full h-full  bg-[#f0f0f0]'}>
            {/*
            <div className={'w-full h-[100px] bg-green-800'}>
                hello
            </div>
            */}
            <div>
            </div>
            <AdminConsoleHome />
        </div>
    );

    return (
        <div className={'w-full h-full p-10'}>
            <Typography variant={'h4'}>
                Management Console
            </Typography>

            <Button variant="contained" color="primary" onClick={showCreateAccount}>
                Create Account
            </Button>
            {/*
            <Button variant="contained" color="primary" onClick={showDialog}>
                Show Dialog
            </Button>
            */}
            {/*
            <AdminMenuGrid/>
            */}

            <CreateAccountDialog />

            <DialogWrapper title="Sample Dialog">
                <p>Dialog content goes here.</p>
            </DialogWrapper>
        </div>
    );
};


export default ManagementContainer;
