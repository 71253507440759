import React from 'react';
import tw from 'twin.macro';
import QuestionImage from "./QuestionImage";

const QuestionChoiceImage = (props) => {
    return (
        <div className={'w-full min-h-[56px] flex justify-center items-center '}>
            <QuestionImage
                key={props.choice?.id}
                choice={props.choice}
                question={props.question}
                quiz_id={props.quiz_id}
                updateChoice={props.updateChoice}
            />
            {/*
            {props.choice?.choice_text ?
                <img
                    src={props.choice?.choice_text}
                    width={'auto'}
                    height={'100%'}
                    className={'max-h-[250px] border-[1px solid black]'}
                />
                :
                <QuestionImage
                    choice={props.choice}
                    quiz_id={props.quiz_id}
                    updateChoice={props.updateChoice}
                />
            }
            */}
        </div>
    );
};

export default QuestionChoiceImage;
