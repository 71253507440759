(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("katex"), require("katex/dist/katex.css"));
	else if(typeof define === 'function' && define.amd)
		define("KatexEditor", ["katex", "katex/dist/katex.css"], factory);
	else if(typeof exports === 'object')
		exports["KatexEditor"] = factory(require("katex"), require("katex/dist/katex.css"));
	else
		root["KatexEditor"] = factory(root["katex"], root["katex/dist/katex.css"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_katex__, __WEBPACK_EXTERNAL_MODULE_katex_dist_katex_css__) {
return 