import React from 'react';
import ItemList from "./ItemList";

const ItemListContainer = (props) => {
    return (
            <ItemList/>
    );
};


export default ItemListContainer;
