import React, { useState, useEffect, useMemo } from 'react';
import uniqid from "uniqid";

const Context = React.createContext();

export function useTabIdentifier() {
    // const [tabId, setTabId] = useState(null);
    // useEffect(() => {
    //     // Check if a tab ID already exists in this tab's session storage.
    //     let existingTabId = sessionStorage.getItem('tabId');
    //
    //     if (existingTabId) {
    //         // If an ID already exists, use it.
    //         setTabId(existingTabId);
    //     } else {
    //         // If no ID exists, generate a new one and store it.
    //         let newTabId = uniqid()
    //         sessionStorage.setItem('tabId', newTabId);
    //         setTabId(newTabId);
    //     }
    // }, []);

    return useMemo(() => {
        // Check if a tab ID already exists in this tab's session storage.
        let existingTabId = sessionStorage.getItem('tabId');
        if (existingTabId) {
            // If an ID already exists, use it.
            console.log('existingTabId:', existingTabId);
            return existingTabId;
        } else {
            // If no ID exists, generate a new one and store it.
            let newTabId = uniqid()
            sessionStorage.setItem('tabId', newTabId);
            console.log('newTabId:', newTabId);
            return newTabId;
        }
    }, []);
}


export const SessionProvider = (props) => {
    const tabId = useTabIdentifier();
    console.log('tabId:', tabId);
    return (
        <Context.Provider value={{ tabId }}>
            {props.children}
        </Context.Provider>
    );
};

export default SessionProvider;
