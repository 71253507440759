import React from 'react';

const Panel = ({ children, title }) => {
    return (
        <div className={'bg-gray-100 border-[1px] border-solid border-gray-300 rounded overflow-clip'}>
            <div className={'p-1 px-4 border-b-[1px] border-solid border-gray-300 select-none'}>
                {title}
            </div>
            <div className={'p-2 bg-white'}>
                {children}
            </div>
        </div>
    );
};

export default Panel;