import React, { useMemo } from 'react';
import useSWR from "swr";
import apiClient, { apiFetcher } from "../../context/APIClient";
import { useDevTool } from "../../context/DevTool";

const Context = React.createContext();


const StudioContext = (props) => {
    const { data: lessons, mutate: mutateLessons, isValidating: isLessonsValidating } = useSWR('/api/admin/lessons/', apiFetcher)
    const { data: courses, mutate: mutateCourses, isValidating: isCoursesValidating } = useSWR('/api/admin/courses/', apiFetcher)
    const { data: videos, mutate: mutateVideos, isValidating: isVideosValidating } = useSWR('/api/video/', apiFetcher)
    const [selectedVolume, setSelectedVolume] = React.useState('df69d326-5ced-419e-8cfd-a7d5b7732ec9');

    console.log('+ courses', courses);

    const value = useMemo(() => {
        console.log('*** studio context ***');
        console.log('courses', courses);
        console.log('lessons', lessons);

        const lessonGroups = courses?.reduce((acc, course) => {
            acc.push(...course.groups)
            return acc;
        }, []);

        return {
            lessonGroups: lessonGroups ?? [],
            videos: videos ?? [],
            lessons: lessons ?? [],
            courses: courses ?? [],
            mutateLessons,
            mutateCourses,
            mutateVideos,
            selectedVolume,
            setSelectedVolume
        }
    }, [courses, lessons, mutateCourses, mutateLessons, mutateVideos, selectedVolume, videos]);
    useDevTool('studio', value);
    console.log('value:',value);

    // const videos = data?.data ?? [];
    // const value = {
    //     videos: videos?.data?.data ?? [],
    //     lessons: lessons?.data ?? [],
    //     courses: courses?.data?.data ?? [],
    //     // mutate: mutate, 
    // }

    // if (loading) {
    //     console.log("*** studio context loading *** ");
    //     return "Loading";
    // }

    React.useEffect( () => {
        console.log('!!!!!! COURSES:', courses);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courses]);

    const loading = useMemo(() => {
        return isLessonsValidating || isVideosValidating || isCoursesValidating;
    }, [isCoursesValidating, isLessonsValidating, isVideosValidating]);
    const error = useMemo(() => {
        return videos?.error || lessons?.error || null;
    }, [videos, lessons]);
    return (
        <Context.Provider value={value}>
            {props.children}
            {error && <div className={'absolute top-0 w-full h-[70px] flex justify-center items-center'}>
                <div className={'bg-red-500 font-bold text-lg text-white px-20 mx-auto py-2 px-32 flex justify-center items-center'}>
                    Error: {error}
                </div>
            </div>}
            {loading && <div className={'absolute top-0 w-full h-[70px] flex justify-center items-center'}>
                <div className={'bg-green-500 font-bold text-lg text-white px-20 mx-auto py-2 px-32 flex justify-center items-center'}>
                    Loading
                </div>
            </div>}
        </Context.Provider>
    );
};

export function useStudio() {
    const context = React.useContext(Context);
    if (context === undefined) {
        throw new Error(`useStudio must be used within a StudioContext`)
    }
    console.log('!!!! useStudio:', context);
    return context
}

export function useCreateLesson() {
    const { mutateCourses, mutateLessons } = useStudio();
    return ({ title = '', lesson_group = null }) => {
        const payload = { title, lesson_group }
        return apiClient.post('/api/admin/lessons/', payload)
            .then((response) => {
                // console.log(response);

                mutateLessons();
                mutateCourses();
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

function useLesson(lessonId) {
    const { lessons } = useStudio();
    return lessons?.find(lesson => lesson.id === lessonId);
}

export default StudioContext;
