import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import { Player } from "@remotion/player";

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import PropertiesContainer from "./PropertiesContainer";
import ToolContainer from "./ToolContainer";
import { useGetAPI } from "../context/api";
import api from '../context/APIClient';
import { VideoEditorComponent } from "./Video/Video";
import { useDevTool } from "../context/DevTool";
import LayerList from "./LayerList";
import Slider from "../demo/Slider";
import { useDropzone } from "react-dropzone";
import { useProject } from "./ProjectEditor";
import { useLayers, useLayerActions } from "./ProjectWorkspaceProvider";
import { AddText, UploadImage } from "./tools/Tools";
import ProjectExplorer from "../Projects/ProjectExplorer";
import { useKey, useKeyPress, useMeasure } from "react-use";
import SketchContainer from "./Sketch/SketchContainer";
import { Tab, Tabs } from "@mui/material";
import TabContainer from "./TabContainer";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const VideoEditorContainer = (props) => {
    const project = useProject();
    const layers = useLayers();
    const { addLayer, updateLayer } = useLayerActions();

    const [selectedLayerId, setSelectedLayerId] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);

    const setSelectedLayer = (layer) => {
        if (!layer) {
            setSelectedLayerId(null);
            setSelectedTab(0)
        } else {
            setSelectedLayerId(layer.id);
            setSelectedTab(1)
        }
    }

    const selectedLayer = useMemo(() => {
        return layers?.find(layer => layer.id === selectedLayerId) ?? null;
    }, [layers, selectedLayerId]);

    // console.log('project', project);
    // console.log('layers', layers);

    // const loadProject = (project) => {
    //     console.log("loading project", project);
    //
    //     const fps = project.fps ?? 30;
    //     const seconds = project.duration ?? ((60 * 3) + 18);
    //     const frames = fps * seconds;
    //
    //     const proj = {
    //         ...project,
    //
    //         fps,
    //         duration: seconds,
    //         frames,
    //
    //         width: 1920,
    //         height: 1080
    //     }
    //
    //     setProject(proj);
    // }

    // const { loading, error } = useGetAPI(`/api/projects/${projectId}/`, { setData: loadProject });

    const playerRef = useRef(null);
    // selection
    const [currentFrame, setCurrentFrame] = useState(0);

    // useDevTool('layers', layers);
    // useDevTool('project', project);
    useDevTool('layer', selectedLayer);

    const fps = 30.0;
    const frames = ((60 * 3) + 18) * fps;

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        console.log(acceptedFiles);
        acceptedFiles.map((file) => {
            console.log(file);

            const formData = new FormData();
            formData.append("file", file);
            // formData.append('project_id', project.project_id);
            // formData.append("tags", `codeinfuse, medium, gist`);
            // formData.append("upload_preset", "pvhilzh7"); // Replace the preset name with your own
            // formData.append("api_key", "1234567"); // Replace API key with your own Cloudinary key
            // formData.append("timestamp", (Date.now() / 1000) | 0);

            // Make an AJAX upload request using Axios
            return api
                .post("/api/project_file/?project_id=" + project.project_id, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        // "X-Requested-With": "XMLHttpRequest",
                        // "Content-Disposition": `attachment; filename="${file.name}"`
                    },
                })
                .then(response => {
                    const data = response.data;
                    console.log('uploaded', response);
                    // const fileURL = data.secure_url // You should store this URL for future references in your app
                    console.log(data);
                    // items.push(data);
                    onClickTool({ name: 'image', file: data?.file?.file });
                })
                .catch(err => {
                    console.log("Error uploading image", err);
                })

        });

        // api.all(uploaders).then(() => {
        //     // ... perform after upload is successful operation
        //     console.log("Files uploaded")
        // });

    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
    });

    // const layers = [
    //     {
    //         text: "Hello World",
    //         x: 100,
    //         y: 100,
    //         width: 100,
    //         height: 100,
    //         background: 'yellow',
    //         color: 'red',
    //         fontSize: '124px',
    //         fontFamily: 'sans-serif',
    //         textAlign: 'center',
    //
    //         timeStart: 0,
    //         timeEnd: 90,
    //     }
    // ]


    const onClickTool = useCallback((tool) => {
        // add a layer at the current time
        let time = 0;
        let frame = 0;


        if (playerRef.current) {
            console.log('player ref', playerRef.current);
            frame = playerRef.current.getCurrentFrame();
            time = frame / 30.0;
        }

        // console.log("TIME", time);

        // create the new layer
        const newLayer = {
            id: uuidv4(),
            type: 'text',
            // text: "Hello World",
            // text: `Placed at frame ${frame} / ${time}s`,
            text: `Frame ${frame}`,
            x: 100,
            y: 100,
            width: 100,
            height: 100,
            background: 'yellow',
            color: 'red',
            fontSize: '60px',
            fontFamily: 'sans-serif',
            textAlign: 'center',
            timeStart: frame / 30.0,
            timeEnd: (20),
            frameStart: frame,
            frameCount: 20 * 30, // 20 seconds
        }

        console.log('clicked tool', tool);
        if (tool) {
            const { name, file } = tool;
            if (name === 'image' && file) {
                // this is an image
                newLayer.type = 'image';
                newLayer.src = file;
                // file: "https://dynamo-project-files.s3.amazonaws.com/project_files/kid-4.png"
                // name: "image"
            }
        }


        addLayer(newLayer);
        // setLayers(layers => [...layers, newLayer]);
        setSelectedLayer(newLayer);

    }, []);

    const onSetCurrentFrame = (frame) => {
        setCurrentFrame(frame);
        if (playerRef.current)
            playerRef.current.seekTo(frame);
    }

    // console.log('layers', layers);

    const setLayerValue = (layer, key, value) => {
        console.log("SET LAYER VALUE", layer, key, value);

        // find the layer
        const curLayer = layers.find(l => l.id === layer.id);
        if (curLayer) {

            // create the new layer
            const newLayer = {
                ...curLayer,
                [key]: value
            };

            // update the layers
            updateLayer(newLayer);
            // setLayers(layers => layers.map(l => l.id === layer.id ? newLayer : l));

            // make it the selected layer
            setSelectedLayer(newLayer);
        }

        // if (selectedLayer.id === layer_.id) {
        //     console.log('new value', key, value)

        // }

        // const setLayerValue = (key, value) => {
        //     console.log('setLayerValue', key, value);
        //     setLayer(layer => ({ ...layer, [key]: value }));

        // setSelectedLayer(layer => ({ ...layer, [key]: value }));
    }

    const tools = [
        {
            id: "text",
            component: <AddText />
        },
        {
            id: "image",
            component: <UploadImage />
        }
    ]

    useEffect(() => {
        if (!playerRef.current) {
            return;
        }
        // playerRef.current.addEventListener("play", () => {
        //     console.log("playing");
        // });
        // playerRef.current.addEventListener("ratechange", () => {
        //     console.log("ratechange");
        // });
        // playerRef.current.addEventListener("pause", () => {
        //     console.log("pausing");
        // });

        // See below for difference between `seeked` and `timeupdate`
        playerRef.current.addEventListener("seeked", (e) => {
            // console.log("seeked to " + e.detail.frame);
            setCurrentFrame(e.detail.frame);
        });

        playerRef.current.addEventListener("timeupdate", (e) => {
            // console.log("time has updated to " + e.detail.frame);
            setCurrentFrame(e.detail.frame);
        });

        // playerRef.current.addEventListener("ended", (e) => {
        //     console.log("ended");
        // });
        // playerRef.current.addEventListener("error", (e) => {
        //     console.log("error", e.detail.error);
        // });
        // playerRef.current.addEventListener("fullscreenchange", (e) => {
        //     console.log("fullscreenchange", e.detail.isFullscreen);
        // });
    }, []);

    // get the player dimensions
    const [ref, { width }] = useMeasure();
    const scale = width / 1920;
    // console.log("width", width, scale );

    const isSpacePressed = useKeyPress(' ');
    // const predicate = (event) => event.key === 'a';
    // const isPressed = useKeyPress(predicate);
    // Shift+S toggles state viewer
    const predicate = (event) => {
        // const value = event.key === 's' && event.shiftKey;
        // return event.key === 's' && event.ctrlKey;
        console.log(event.key);
        return event.key === ' ' || event.key === 'z';
    };

    const onTogglePlay = () => {
        console.log('toggle play');
        if (playerRef.current) {
            if (playerRef.current.isPlaying()) {
                playerRef.current.pause();
            } else {
                playerRef.current.play();
            }
        }
    };

    useKey(predicate, onTogglePlay, { event: 'keydown' });

    useMemo(() => {

        window.addEventListener('keydown', function (e) {
            if (e.keyCode == 32 && e.target == document.body) {
                e.preventDefault();
                onTogglePlay();
            }
        });

    }, [])

    const videoURL = project?.data?.video?.url;

    // tabs
    const tabs = [
        {
            id: "tools",
            label: "Tools",
            component: <ToolContainer
                onClickTool={onClickTool}
                tools={tools}
            />
        },
        {
            id: "properties",
            label: "Properties",
            component: <PropertiesContainer
                layer={selectedLayer}
                setLayerValue={(key, value) => setLayerValue(selectedLayer, key, value)}
            />
        },
        {
            id: "sketch",
            label: "Sketch",
        }
    ]


    return (
        <div className="flex flex-grow flex-col">
            <div {...props} className={'w-full flex flex-row '}>

                {/*
                <ProjectExplorer project={project} />
                */}

                <div ref={ref} className={'flex-grow w-1/2 relative border-black border-2'}>

                    <div {...getRootProps()}>

                        <input {...getInputProps()} />

                        {isDragActive && <div className={'absolute inset-0 bg-opacity-50 bg-green-300 z-10 flex justify-center items-center'}>
                            <h1 className={'text-2xl text-center'}>Drop the files here ...</h1>
                        </div>}

                        {/*
                        {isDragActive ? <p>Drop the files here ...</p> : <p>Upload new item</p>}
                        {isDragActive ? <p>Drop the files here ...</p> : null}
                        */}


                        <Player
                            ref={playerRef}
                            // className={'w-full h-full bg-amber-600'}
                            component={VideoEditorComponent}
                            compositionWidth={1920}
                            compositionHeight={1080}

                            fps={project?.data?.video?.fps ?? 30}
                            durationInFrames={parseInt(project?.data?.video?.frames ?? 1)}

                            controls={true}
                            clickToPlay={false}

                            style={{
                                background: "green",
                                width: "100%",
                                margin: "auto"
                            }}

                            inputProps={{
                                videoURL,
                                layers,
                                scale,
                                setSelectedLayer,
                                selectedLayer
                            }}
                            // durationInFrames={Math.ceil(30 * project.songDuration)}
                        />

                        {/*
                        <SketchContainer />
                        */}

                    </div>
                </div>

                <TabContainer
                    tabs={tabs}

                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}


                    // layer={selectedLayer}
                    // setLayerValue={(key, value) => setLayerValue(selectedLayer, key, value)}
                    // onClickTool={onClickTool}
                    // tools={tools}

                    // toolsContainer={<ToolContainer
                    //     onClickTool={onClickTool}
                    //     tools={tools}
                    // />}
                    //
                    // propertiesContainer={<PropertiesContainer
                    //     layer={selectedLayer}
                    //     setLayerValue={(key, value) => setLayerValue(selectedLayer, key, value)}
                    // />}
                />
                {/*

                <Paper sx={{ margin: '10px' }}>

                    <ToolContainer
                        onClickTool={onClickTool}
                        tools={tools}
                    />

                    <PropertiesContainer
                        layer={selectedLayer}
                        setLayerValue={(key, value) => setLayerValue(selectedLayer, key, value)}
                    />

                </Paper>
*/}

                {/*
            <div className={'flex-grow bg-red-800 height[100%]'}>

                <h1>right side</h1>

            </div>
            */}

            </div>

            <LayerList
                project={project}
                layers={layers ?? []}

                durationInFrames={frames}
                frame={currentFrame}
                setCurrentFrame={onSetCurrentFrame}
                onSelectLayer={setSelectedLayer}
                setLayerValue={setLayerValue}
            />

            <div className={'flex-grow bg-neutral-200'}>

                {/*
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Item>
                            xs=12
                        </Item>
                    </Grid>
                </Grid>
                */}

            </div>
        </div>
    );
};


export default VideoEditorContainer;
