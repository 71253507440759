import React from 'react';
import { Dialog, DialogContent, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useGetAPI } from "../../../../context/api";

const QuizListDialog = ({ open, setOpen, quizId, onSelectQuiz }) => {
    const handleSelectQuiz = (event, id) => {
        // console.log('handleSelectQuiz', id);
        if (onSelectQuiz)
            onSelectQuiz(id);
    };

    const { data: quizzes } = useGetAPI(`/api/quizzes/`);
    // const swr = useSWR('/api/quizzes/');
    // console.log('QuizListDialog', quizzes);
    // const { loading, error, data } = swr
    if (!quizzes) return <div>Loading...</div>;

    // if(!quizzes) {
    //     if (loading) return <div>Loading...</div>;
    //     if (error) return <div>Error loading quizzes</div>;
    //     quizzes = data?.quizzes;
    // }

    // console.log('QuizListDialog', quizzes)

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="quizzes-dialog"
        >
            <DialogTitle id="quizzes-dialog">Quizzes</DialogTitle>
            <DialogContent>
                <List>
                    {quizzes?.map((quiz) => (
                        <ListItem disablePadding key={quiz.id}>
                            <ListItemButton
                                selected={quizId === quiz.id}
                                onClick={(event) => handleSelectQuiz(event, quiz.id)}
                            >
                                <ListItemText primary={quiz.title} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
};

export default QuizListDialog;
