import React from 'react';
import { useParams } from "react-router-dom";
import RenderQuiz from "./QuizPage";
import { useGetAPI } from "../../../context/api";

const WorksheetView = (props) => {
    const { quizId } = useParams();
    const { loading, error, data } = useGetAPI(`/api/fullquizzes/${quizId}/`);

    if (loading || !data)
        return 'Loading...';
    const level = 0;
    const answerKey = false;

    return (
        <div>
            {/*
            <div>{loading}</div>
            <div>{quizId}</div>
            <div>{level}</div>
            <div>{answerKey}</div>
            <div>{JSON.stringify(data)}</div>
            <div>{JSON.stringify(data?.questions)}</div>
            <div>{JSON.stringify(error)}</div>
            */}
            <RenderQuiz
                quiz={data}
                level={2}
                answer_key={answerKey}
            />
        </div>
    );
};

export default WorksheetView;
