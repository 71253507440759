import React, { useCallback } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputText, { InputSwitch } from "./FormControls";
import { FormControl, FormGroup, FormLabel, Grid, InputAdornment, Stack, Table, TableCell, TableBody, TableHead, TableRow, Typography } from "@mui/material";
import { useDevTool } from "../../../context/DevTool";
import apiClient from "../../../context/APIClient"
import useSWR from "swr";

export const QUESTION_TYPE_MULTIPLE_CHOICE = 0;
export const QUESTION_TYPE_MULTIPLE_SELECT = 1;
export const QUESTION_TYPE_TRUE_FALSE = 2;
export const QUESTION_TYPE_FREE_ENTRY = 3;

const QuestionSettingsDialog = ({ question, updateQuestion, open, setOpen, onConfirm, onDelete }) => {
    useDevTool('question settings', question);

    const [questionData, setQuestionData] = React.useState(null);

    React.useEffect(() => {
        setQuestionData(question);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateQuestionData = (question) => {
        console.log('updateQuestionData', question);
        setQuestionData((value) => {
            return { ...value, ...question }
        });
        // setQuestionDirty(true);
    }

    const handleChangeValue = useCallback((event) => {
        let { name, value } = event.target;
        // console.log('handleChangeValue', name, value);
        updateQuestionData({ [name]: value });
    }, []);

    const handleChangeChecked = useCallback((event) => {
        let { name, checked } = event.target;
        console.log('handleChangeChecked', name, checked);
        updateQuestionData({ [name]: checked });
    }, [])

    // const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
    const [tts, setTTS] = React.useState(null);
    const handleConvertToTTS = () => {
        let text = questionData?.question_text;
        if (questionData?.instruction_text) {
            text += '\n' + questionData?.instruction_text;
        } else if (question.type === QUESTION_TYPE_MULTIPLE_SELECT) {
            text += '\n(Select all options that apply.)';
        }

        // call the server
        const prompt = `You are a converter from rich text that may include representations of math in katex/latex to an english description.\n` +
            `You will be given text, any representation of math equation will be wrapped in <math>...</math> tags \n` +
            'If the text contains a blank like _____ say the word "blank" in its place.\n' +
            'All of the input text is in english and will not need to be translated, but the sound needs to sound like english.\n' +
            'The text you generate will be used for TTS (text to speech) and should be in english.\n' +
            'We will use that text to generate text to speech for the purpose of accesability.\n' +
            `Do not output anything but the english translation of the string sutable for TTS.\n` +
            `Do not add any new words or change any words. If it is math or asking a question, do not solve the question or equation, simply repeat exactly what it is as spoken text.\n` +
            'If the text is a mathmatical fraction, use terms such as "two thirds" instead of "two divided by three" or "two over three" ' +
            'this text may or may not contain katex/math, if not just return the text as is.\n' +
            'Output one answer and stop.\n\n' +
            `What is the english translation of: "${text}"\n\n`;
        // `For example, if the string is: "What is <math>\frac{1}{5}</math>?" then you would output: What is one fifth?\n` +
        // setLoading(true);
        apiClient.post('/chatgpt/', { 'prompt': prompt })
            .then(response => {
                const { data } = response;
                const output = data.choices[0].message?.content;
                // setLoading(false);
                updateQuestionData({ audio_tts_override: output });
            })
            .catch(error => {
                console.log(error);
                // setLoading(false);
            });
    };
    const handleSave = () => {
        updateQuestion(questionData);
    };

    const { data: audioConfig } = useSWR('/api/quiz/audio/?question_id=' + question?.id);
    // console.log('audioConfig', audioConfig);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="settings-dialog"
        >
            <DialogTitle id="settings-dialog">Question Settings</DialogTitle>
            <DialogContent>
                <form className={'w-full'}>
                    <Stack spacing={2}>
                        <FormLabel component="legend">Contents</FormLabel>
                        <Stack spacing={2}>
                            <FormGroup>
                                <InputText
                                    id='choice_text'
                                    name='choice_text'
                                    value={questionData?.question_text}
                                    label='Text'
                                    onChange={handleChangeValue}
                                    endAdornment={(
                                        <InputAdornment position="start">
                                            <Stack spacing={0} direction={'row'}>
                                                <Button variant="outlined" color="primary" onClick={handleConvertToTTS}>
                                                    Generate TTS
                                                </Button>
                                                {/*
                                                <IconButton>
                                                    <SettingsIcon htmlColor='#888888' />
                                                </IconButton>
                                                */}
                                                {/*{!questionData?.image_url && <IconButton onClick={() => setShowImage(!showImage)}>*/}
                                                {/*    <ImageIcon htmlColor='#888888' />*/}
                                                {/*</IconButton>}*/}
                                            </Stack>
                                        </InputAdornment>
                                    )}
                                />
                            </FormGroup>

                            <FormGroup>
                                {/*
                                <InputText
                                    id='audio_tts_override'
                                    name='audio_tts_override'
                                    value={questionData?.audio_tts_override ?? ""}
                                    label='TTS Audio Override'
                                    onChange={handleChangeValue}
                                />
                                */}
                                <InputText
                                    id='audio_tts_override'
                                    label='TTS Override'
                                    name='audio_tts_override'
                                    value={questionData?.audio_tts_override ?? ""}
                                    onChange={handleChangeValue}
                                />

                            </FormGroup>

                            <FormGroup>
                                <InputText
                                    id='image_url'
                                    label='Image'
                                    name='image_url'
                                    value={questionData?.image_url}
                                    onChange={handleChangeValue}
                                />

                                {/*
                                <InputSwitch
                                    id='last_position'
                                    label='Last Position'
                                    name='last_position'
                                    value={questionData.last_position}
                                    onChange={handleChangeChecked}
                                />
*/}
                            </FormGroup>
                            <InputText
                                id='question_index'
                                name='question_index'
                                label='Index'
                                value={questionData?.question_index}
                                onChange={handleChangeValue}
                            />
                            {/*
                            <InputText
                                id='choice_index'
                                name='choice_index'
                                value={choice.choice_index}
                                label='Index'
                                onChange={handleChangeValue}
                            />
                            */}
                            <InputSwitch
                                id='warmup'
                                name='warmup'
                                label='Warmup Question'
                                checked={questionData?.warmup}
                                onChange={handleChangeChecked}
                            />


                        </Stack>

                        <FormLabel component="legend">Text To Speech</FormLabel>

                        <FormControl component="fieldset" variant="filled">

                        </FormControl>

                        <Stack spacing={2}>
                            <InputText
                                id='audio'
                                label='Audio URL'
                                name='audio'
                                value={questionData?.audio}
                                onChange={handleChangeValue}
                            />

                        </Stack>
                        {/*
                        <Button
                            variant="outlined"
                            onClick={() => setConfirmDeleteOpen(true)}
                            color="warning"
                        >
                            Delete
                        </Button>
                        */}
                    </Stack>


                </form>
                {/*
                <div>
                    <pre>{JSON.stringify(audioConfig, null, 2)}</pre>
                </div>
                */}
                {audioConfig?.data && <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Typography variant={'h6'}>Audio</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Date Generated</TableCell>
                            <TableCell>{audioConfig?.data.audio_generated_date}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>URL</TableCell>
                            <TableCell>{audioConfig?.data.audio_url}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Source Question Text</TableCell>
                            <TableCell>{audioConfig?.data.audio_source_text}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Source TTS Override</TableCell>
                            <TableCell>{audioConfig?.data.audio_tts_override}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Text Requested</TableCell>
                            <TableCell>{audioConfig?.data.audio_text}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>}
            </DialogContent>
            {/*
            <Grid item xs={12}>
            </Grid>
            */}
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => setOpen(false)}
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => {
                        setOpen(false);
                        handleSave();
                        if (onConfirm) {
                            onConfirm();
                        }
                    }}
                    color="success"
                >
                    Save
                </Button>
            </DialogActions>


        </Dialog>
    );
};

export default QuestionSettingsDialog;
