import React from 'react';
import ProjectEditor, { useProject } from "./ProjectEditor";
import VideoEditorContainer from "./VideoEditorContainer";
import { useParams } from "react-router-dom";
import ReactJson from "react-json-view";

const ProjectEditorContainer = (props) => {
    const { projectId } = useParams();
    const project = useProject();
    return (
        <ProjectEditor projectId={projectId}>
            <ReactJson
                name='Project'
                src={project}
                collapsed={1}
            />
            {/*<VideoEditorContainer />*/}
        </ProjectEditor>
    );
};


export default ProjectEditorContainer;
