import React from "react";
import { blockTypes } from "./constants";
import { RenderInlineStyles } from "./inlineStyle";
import { RenderCustomStyles } from "./customStyles";
import { styled } from "twin.macro";
import { insert } from "./math";

// eslint-disable-next-line no-undef
const ToolbarContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    //min-height: 48px;
    //padding: 5px 7px;
    padding: 2px;
    border-radius: 2px 2px 0 0;
    border: 1px solid #e1e1e1;
    margin: -6px -6px 8px;
    //box-shadow: 0px 0px 3px 1px rgba(15, 15, 15, 0.17);
`;

export default class Toolbar extends React.Component {
    render() {
        // console.log("EditorState: TOOLBAR", this.props.editorState);
        return (
            <ToolbarContainer>
                <RenderInlineStyles
                    editorState={this.props.editorState}
                    updateEditorState={this.props.updateEditorState}
                />
                <RenderCustomStyles
                    editorState={this.props.editorState}
                    updateEditorState={this.props.updateEditorState}
                />
                <button onClick={() => insert(this.props.editorState, this.props.updateEditorState)}>Math</button>
            </ToolbarContainer>
        );
    }
}
