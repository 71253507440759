export const account_variants = [
    { id: 'teacher-trial', label: 'Teacher Trial' },
    { id: 'teacher-subscription', label: 'Teacher Subscription' },
    { id: 'parent-trial', label: 'Parent Trial' },
    { id: 'parent-subscription', label: 'Parent Subscription' },
    { id: 'school-trial', label: 'School Trial' },
    { id: 'school-subscription', label: 'School Subscription' },
    { id: 'step-up-trial', label: 'Step-Up Trial' },
    { id: 'tanoshi-trial', label: 'Tanoshi Trial' },
];

