import React, { useMemo } from 'react';
import tw, { css, styled } from 'twin.macro';
import {
    ControlledTreeEnvironment,
    StaticTreeDataProvider,
    Tree,
    UncontrolledTreeEnvironment
} from "react-complex-tree";
import { useDevTool } from "../../context/DevTool";
import "react-complex-tree/lib/style-modern.css";
import { v4 as uuidv4 } from "uuid";

const TreeStyle = styled.div`
  .rct-tree-item-li {
    font-size: 1rem;
  }
`;

const LessonGroupTree = ({ course, group }) => {
    const treeId = group?.id ?? 'lesson-group-tree';
    const [treeData, setTreeData] = React.useState({});
    const [expandedItems, setExpandedItems] = React.useState(['groupContainer']);

    React.useEffect( () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const tree = {
            root: {
                id: 'root',
                itemType: 'Root',
                isFolder: true,
                children: ['groupContainer'],
                data: 'Root',
                title: 'Root',
                item: null
            }
        };

        // find the top level groups
        if( group ) {
            tree[group.id] = {
                id: group.id,
                itemType: 'Group',
                isFolder: true,
                children: [],
                title: group.title,
                data: group.title,
                item: group
            };
            tree['root'].children.push(group.id);

            // find the children of this group
            const childGroups = course?.groups?.filter(childGroup => childGroup.parent === group.id) ?? [];
            childGroups.forEach(childGroup => {
                tree[childGroup.id] = {
                    id: childGroup.id,
                    itemType: 'Group',
                    isFolder: true,
                    children: [],
                    title: childGroup.title,
                    data: childGroup.title,
                    item: childGroup
                };
                tree[group.id].children.push(childGroup.id);
            });
        }
        setTreeData(tree);
        if( !expandedItems ) {
            setExpandedItems(['groupContainer']);
        }
    }, [course, expandedItems, group]);

    useDevTool('LessonGroupTree', { course, treeData });
    const uniqueId = uuidv4();
    console.log(uniqueId, treeData);
    return (
        <TreeStyle key={uniqueId} className='w-full h-full bg-[white] flex flex-col '>
            <ControlledTreeEnvironment
                key={uniqueId}
                items={treeData}
                dataProvider={
                    new StaticTreeDataProvider(treeData, (item, title) => ({
                        ...item,
                        title
                    }))
                }
                getItemTitle={item => item?.title}
                // canDragAndDrop={true}
                // canDropOnFolder={true}
                // canReorderItems={true}
                // onDrop={(items, target) => {
                //     const findTargetInTree = loadedTree[target.targetItem];
                //     const item = items[0];
                //     if (findTargetInTree?.itemType === 'LessonGroup') {
                //
                //         if (item.itemType === 'Lesson') {
                //             handleUpdateLessons(findTargetInTree, item)
                //         }
                //         if (item.itemType === 'LessonGroup') {
                //             handleUpdateLessonGroup(findTargetInTree, item)
                //         }
                //         if (target.targetType === 'between-items') {
                //             if (item.itemType === 'Lesson') {
                //                 handleUpdateLessons(findTargetInTree, item, target.childIndex)
                //             }
                //         }
                //     }
                //
                //
                // }}
                // canDropAt={(items, target) => {
                //     const findTargetInTree = loadedTree[target.targetItem];
                //     const findTargetParent = loadedTree[target.parentItem]
                //     const itemType = items[0].itemType;
                //     if (target.targetType === 'between-items') {
                //         if (itemType === 'Lesson' && findTargetParent?.itemType === 'Course') return false
                //         if (itemType === 'Lesson' && findTargetParent?.itemType === 'Root') return false
                //         if (itemType === 'LessonGroup' && findTargetInTree?.itemType === 'Root') return false
                //     }
                //     if (itemType === 'Lesson' && findTargetInTree?.itemType === 'Course') return false
                //     if (itemType === 'LessonGroup' && findTargetInTree?.itemType === 'Lesson') return false
                //
                //     else return true;
                //
                // }}
                // onSelectItems={(item) => onSelected(loadedTree[item[0]])}
                viewState={{
                    'lesson-tree': {
                        focusedItem: 'groupContainer',
                        selectedItems: ['groupContainer'],
                        expandedItems: ['groupContainer'],
                    },
                }}
            >
                <Tree
                    className={'w-full h-full bg-[orange]'}
                    treeId='lesson-tree'
                    rootItem="root"
                    treeLabel={"Tree Label"}
                />
            </ControlledTreeEnvironment>
        </TreeStyle>
    );
};

export default LessonGroupTree;
