import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, InputLabel, MenuItem, Modal, Select, Stack, TextField } from "@mui/material";
import apiClient from "../../../context/APIClient";
import Checkbox from '@mui/material/Checkbox';
import { purple } from '@mui/material/colors';
import { useGetAPI } from "../../../context/api";
import Radio from '@mui/material/Radio';

const AddQuizDialog = ({ open, onClose, lessonId }) => {

    const { data: quizData } = useGetAPI('/api/quizzes/');
    const { data: lessons } = useGetAPI('/api/lessons/');

    const [quizId, setQuizId] = useState(false);
    let filteredQuizData = quizData && lessons ? quizData.filter(quiz => !lessons.find(l => l.quiz_id === quiz.id)) : [];

    const handleClose = () => {
        onClose();
    }

    const handleChange = (event) => {
        setQuizId(event.target.value);
    };

    const onSubmit = () => {
        let data = { quiz: quizId }
        apiClient.patch(`/api/admin/lessons/${lessonId}/`, data)
            .then((response) => {
                console.log(response);
                handleClose();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const showError = (error, text = null) => {
        if (error) {
            // console.log(error);
            let message = text;
            if (!message) {
                if (error.type === 'required') message = 'This field is required';
                else message = error.message;
            }

            return (
                <div className='error-container text-red-500'>
                    <p className='error-tag'>{`* ${message}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <Dialog onClose={handleClose} open={open} maxWidth={"lg"} fullWidth={true}>
            <DialogTitle>Select which quiz you would like to add </DialogTitle>
            <DialogContent >
                <Grid container spacing={3}>
                    {filteredQuizData?.map(quiz => (
                        <Grid item key={quiz.id} xs={12} sm={12} md={6} lg={4} xl={4}>
                            <div>
                                <div className='h-[60px] select-none text-left text-lg text-[white] bg-[#1976d2] p-5 flex justify-between items-center'>
                                    <div className={'h-full justify-center items-center flex'}>
                                        {quiz?.title}
                                    </div>
                                    <div className='cursor-pointer'>
                                        <Radio
                                            checked={quiz.id === quizId}
                                            onChange={handleChange}
                                            value={quiz.id}
                                            name="radio-buttons"
                                            sx={{
                                                color: purple[50],
                                                '&.Mui-checked': {
                                                    color: purple[50],
                                                },
                                            }}
                                        />

                                    </div>
                                </div>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button type="button" color="primary" variant="outlined" size="small" onClick={onClose}>
                    Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" size="small" onClick={() => onSubmit()}>
                    Add
                </Button>
            </DialogActions>

        </Dialog>
    );
};

export default AddQuizDialog;