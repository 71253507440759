import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Autocomplete } from "@material-ui/core";
import apiClient from "../../context/APIClient";

const SearchField = () => {
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (inputValue) {
            apiClient.get(`/search?q=${inputValue}`)
            // Replace this with your API call
            fetch(`https://api.example.com/search?q=${inputValue}`)
                .then((response) => response.json())
                .then((data) => setOptions(data.results));
        }
    }, [inputValue]);

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.title}
            style={{ width: 300 }}
            freeSolo
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Search"
                    variant="outlined"
                    onChange={(e) => setInputValue(e.target.value)}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        style: { backgroundColor: 'white' },
                    }}
                />
            )}
        />
    );
};

export default SearchField;
