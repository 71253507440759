import React, { useCallback } from 'react';
import { Card, CardActionArea, Container, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useDropzone } from "react-dropzone";

const Item = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const ToolContainer = ({ tools, onClickTool }) => {

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        console.log(acceptedFiles);
        acceptedFiles.map((file) => {
            console.log(file);

            const formData = new FormData();
            formData.append("file", file);
            // formData.append('project_id', project_id);

            // onClick();
            // onClickTool({ name: 'image', data: formData });

            // formData.append("tags", `codeinfuse, medium, gist`);
            // formData.append("upload_preset", "pvhilzh7"); // Replace the preset name with your own
            // formData.append("api_key", "1234567"); // Replace API key with your own Cloudinary key
            // formData.append("timestamp", (Date.now() / 1000) | 0);
            /*
            // Make an AJAX upload request using Axios (replace Cloudinary URL below with your own)
            return api.put("/api/project_file/?project_id=" + props.project_id, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Disposition": `attachment; filename="${file.name}"`
                },
            }).then(response => {
                const data = response.data;
                // const fileURL = data.secure_url // You should store this URL for future references in your app
                console.log(data);
                items.push(data);
            })
            */

        });

        // api.all(uploaders).then(() => {
        //     // ... perform after upload is successful operation
        //     console.log("Files uploaded")
        // });

    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        noClick: true
    });

    return (
        <div className={'flex-grow relative'} {...getRootProps()}>
            <input {...getInputProps()} />

            {isDragActive && <div className={'absolute inset-0 bg-opacity-50 bg-green-300 z-10 flex justify-center items-center'}>
                <h1 className={'text-2xl text-center'}>Drop the files here ...</h1>
            </div>}

            {/*<h1 className="text-3xl font-bold underline text-center">TOOL PALETTE</h1>*/}
            <Container>
                <Grid
                    justify="center"
                    wrap="wrap"
                    width={'100%'}
                    container
                    spacing={2}
                    marginTop={0}
                    style={{ background: 'transparent' }}
                >
                    {tools.map((item, i) => (
                        <Grid xs={6} item key={item.id} sx={{ height: '100px', background: 'transparent' }}>
                            <Item>
                                <CardActionArea>
                                    {item.component}
                                </CardActionArea>
                            </Item>
                        </Grid>))}
                </Grid>
            </Container>
        </div>
    );
};


export default ToolContainer;
