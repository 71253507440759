import React from "react";
import tw, { css, styled } from "twin.macro";
import { Backdrop, Breadcrumbs, CircularProgress, Container, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";

export const PageBreadcrumbs = ({ paths, title, rootTitle, rootPath  }) => {
    const navigate = useNavigate();

    // const isRoot = !paths || paths?.length === 0;
    const isRoot = title === null;
    // console.log('isroot', isRoot, paths);
    if (isRoot ) {
        if( !rootTitle )
            return null;
        return (
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href={rootPath ?? '/studio'} onClick={(e) => {
                    e.preventDefault();
                    navigate(rootPath ?? '/studio');
                }}>
                    <Typography variant='h5' color="inherit">
                        {rootTitle ?? 'Studio'}
                    </Typography>
                </Link>
            </Breadcrumbs>
        )
    }

    return (
        <Breadcrumbs aria-label="breadcrumb">

            {(rootTitle && rootPath) && <Link  underline="hover" color="inherit" href={rootPath} onClick={(e) => {
                e.preventDefault();
                navigate(rootPath);
            }}>
                <Typography color="inherit">
                    {rootTitle}
                </Typography>
                {/*
                <Typography variant='h5' color="inherit">
                    {rootTitle}
                </Typography>
                <Typography color="inherit">Studio</Typography>
                */}
            </Link>}

            {paths?.map((path, index) => {
                return (
                    <Link
                        key={path.title}
                        className={'cursor-pointer'}
                        underline="hover"
                        color="inherit"
                        href={path.href}
                        as={'a'}
                        onClick={(e) => {
                            navigate(path.href);
                            e.preventDefault();
                        }}
                    >
                        <Typography color="inherit">
                            {path.title}
                        </Typography>
                    </Link>
                );
            })}

            {title && <Typography variant={'h5'} color="text.primary">{title}</Typography>}
        </Breadcrumbs>
    );
}
export const PageContainer = ({ actions, children, maxWidth, paths, title, hideBreadcrumbs = false, loading = false, rootTitle, rootPath }) => {
    return (
        <div className={'p-[10px] h-full flex-grow'}>
            <Grid container spacing={2} className={'h-full basis-full flex-grow flex flex-col'}>
                <Grid className={'h-full basis-full flex-grow flex flex-col'}>
                    {!hideBreadcrumbs && <Container maxWidth={false}>
                        <Grid item xs={12} className={'basis-0 flex-grow-0 flex flex-row justify-between'}>

                            <PageBreadcrumbs
                                title={title}
                                paths={paths}
                                rootTitle={rootTitle}
                                rootPath={rootPath}
                            />

                            <div className={'flex-grow flex flex-row items-center justify-end gap-4'}>
                                {actions}
                            </div>

                        </Grid>
                    </Container>}
                    <Container maxWidth={maxWidth ?? 'lg'} sx={{ padding: '0', height: '100%', background: '' }}>
                        {children}
                        {/*
                        <div className={'flex-grow bg-red-200'}>
                        </div>
                        <Grid className={'w-full basis-full flex-grow flex flex-col h-full m-0 bg-green-200'}>
                            <div className={'flex-grow bg-red-200'}>
                            </div>
                        </Grid>
                        */}
                        {/*
                        <Grid container className={'bg-red-100-'}>
                        </Grid>
                        */}
                    </Container>
                </Grid>
            </Grid>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}