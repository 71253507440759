import React, { useMemo, useState } from 'react';
import tw from 'twin.macro';
import MultiStepWizard, { StepContainer } from "../../Components/Wizard/MultiStepWizard";
import { Button, Stepper, Stack, StepLabel } from '@material-ui/core';
import { CardContent, Container, Paper, Typography } from "@mui/material";
import MenuGrid from "../../Components/MenuGrid/MenuGrid";
import AccountVariantGrid from "./steps/AccountVariantGrid";
import AccountInfoForm from "./steps/AccountInfoForm";


const CreateAccountWizard = () => {
    const [variant, setVariant] = useState(null);

    const handleSetVariant = (variant) => {
        setVariant(variant);
        setActiveStep(1);
    }

    const steps = useMemo(() => {
        const Step1 = (props) => <StepContainer tw={'justify-items-stretch items-stretch'}>
            {/*
            <Typography variant={'h4'}>
                Select Account Type
            </Typography>
            <br />
            */}
            <AccountVariantGrid {...props} setVariant={setVariant} />
        </StepContainer>;

        const Step2 = (props) => <StepContainer>
            <AccountInfoForm {...props} variant={variant} />
        </StepContainer>;

        const Step3 = (props) => <StepContainer>
            Step 3 Content
        </StepContainer>;

        return [
            { label: 'Account Type', content: Step1, hideSteps: true, hideHeader: true },
            { label: 'Account Info', content: Step2 },
            { label: 'Details', content: Step3 },
        ];

    }, [variant]);

    const [activeStep, setActiveStep] = useState(0);

    return (
        <MultiStepWizard
            steps={steps}
            // setActiveStep={setActiveStep}
            // activeStep={activeStep}
        />
    );

    // return (
    //     <Container maxWidth={'lg'} className={'w-full'}>
    //         <Paper
    //             variant="elevation"
    //             elevation={2}
    //             // className="w-full"
    //         >
    //             <CardContent>
    //                 <MultiStepWizard steps={steps} />
    //             </CardContent>
    //         </Paper>
    //     </Container>
    // );
};

export default CreateAccountWizard;
