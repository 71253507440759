import React, { useMemo } from 'react';
import apiClient from "../../../context/APIClient";
import { useDevTool } from "../../../context/DevTool";
import { StaticTreeDataProvider, Tree, UncontrolledTreeEnvironment } from "react-complex-tree";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

const createPath = (item, nodes) => {
    // crawl the parent nodes and add them to the path
    const path = [item.id];
    let node = item;
    while (node.parent) {
        const parent = nodes[node.parent];
        if (!parent)
            break;
        path.push(parent.id);
        node = parent;
    }

    // reverse the path and combine with '/'
    return path.reverse().join('/');
}

const reduceItem = (acc, item) => {
    console.log('reduceItem', item, acc);

    acc[item.id] = {
        ...item,
        index: item.id,
        // path: '/studio',
        path: createPath(item, acc),
        // children: [],
        children: [...item.children?.map(child => child.id)],
        // isFolder: item.children?.length > 0,
        isFolder: true,
        canMove: false,
        canRename: false,
    };

    // push this into the studio if it is not a child
    if (!item.parent)
        acc.studio.children.push(item.id);

    // reduce the children
    acc = item.children?.reduce(reduceItem, acc) ?? acc;

    // else
    //     acc[item.parent].children.push(item.id);

    // reduce the children
    // return item.children.reduce(reduceItem, acc);
    return acc;
    // return acc;

    // if (!item.parent)
    // acc['studio'].children.push(item.id);
    // acc['studio-root'].children.push(item.id);
    // only add children to the course child if it is not a parent
    // find that parent and add it as a child to the obj
    // const childGroups = course?.groups?.filter((g) => g.parent === group.id) ?? [];
    // const childGroupIds = childGroups.map(g => g.id)
    // // const lessonIds = group.lessons.map(lessonId => `${group.id}:${lessonId}`)
    //
    // // add the lessons for this group
    // acc = group.lessons?.reduce((acc, lessonId) => {
    //     // get the lesson object
    //     const lesson = course?.lesson_objects.find(lesson => lesson.id === lessonId);
    //     if (!lesson)
    //         return acc;
    //
    //     // create a pseudo id
    //     const pseudoLessonId = `${group.id}:${lessonId}`;
    //     acc[pseudoLessonId] = {
    //         itemType: 'Lesson',
    //         index: pseudoLessonId,
    //         isFolder: false,
    //         data: lesson.title,
    //         lessonGroup: group.id,
    //         item: lesson
    //     };
    //     acc[group.id].children.push(pseudoLessonId)
    //     return acc;
    // }, acc);
    //
    // return acc;
};

export const StudioTree = ({ roots, onSelected }) => {
    const navigate = useNavigate();
    // start the tree
    // reduce course groups and add the root obj
    const loadedTree = useMemo(() => {
        if (!roots)
            return null;

        // create an item for each LessonGroup
        // return roots.reduce()
        return roots.reduce(reduceItem, {
            root: {
                index: 'root',
                itemType: 'Root',
                children: ['studio'],
                title: 'root',
                data: 'Root item',
                item: null
            },
            studio: {
                title: 'Studio',
                itemType: 'Studio',
                index: 'studio',
                id: 'studio',
                isFolder: true,
                children: [],
                data: 'Studio',
                path: '/studio',
                item: null
            },
        });

    }, [roots]);

    // React.useEffect( () => {
    //
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedItems]);

    const handleSelected = (selectedItems) => {
        console.log('selected', selectedItems);
        if(!selectedItems || selectedItems.length === 0)
            return;

        const item = loadedTree[selectedItems[0]];
        if (onSelected)
            onSelected(item);
        if (item.path)
            navigate(item.path);
    }

    useDevTool('StudioTree', { roots, loadedTree });
    console.log('loadedTree', loadedTree);
    return (
        <UncontrolledTreeEnvironment
            dataProvider={new StaticTreeDataProvider(loadedTree, (item, title) => ({ ...item, title }))}
            getItemTitle={item => item.title}
            // canDragAndDrop={true}
            // canDropOnFolder={true}
            // canReorderItems={true}
            // viewState={{}}

            onSelectItems={handleSelected}
            defaultInteractionMode={'double-click-item-to-expand'}
            viewState={{
                'studio-tree': {
                    focusedItem: 'studio',
                    selectedItems: ['studio'],
                    // expandedItems: ['studio'],
                    // expandedItems: ["891dafea-8e12-4187-93d1-e869b036d2a3"],
                },
            }}
        >

            <Tree treeId="studio-tree" rootItem="root" treeLabel="Studio" />
        </UncontrolledTreeEnvironment>
    );
}

export default StudioTree;
