import React, { useMemo } from 'react';
import { styled } from "twin.macro";
import ItemTreeNew from './ItemTreeNew';

const TreeStyle = styled.div`
    .rct-tree-item-li {
        font-size: 1rem;
    }
`;


const CourseTree = ({ course, className, selected, onSelected }) => {
    // const { itemTypeDef, items, item, columns, itemType } = useItemEditor();

    const handleSelected = (item) => {
        onSelected(item);
    }

    if( !course ) {
        return "Loading course";
    }

    return (
        <TreeStyle>
            <ItemTreeNew course={course} onSelected={handleSelected} />
        </TreeStyle>
    );
};

export default CourseTree;