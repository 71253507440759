import React from 'react';

const DynamoPlayer = (props) => {
    return (
        <div>

        </div>
    );
};


export default DynamoPlayer;
