import React from 'react';
import Draft, { EditorState, DefaultDraftBlockRenderMap, convertFromHTML } from "draft-js";
import Editor from '@draft-js-plugins/editor';

import "draft-js/dist/Draft.css";
import Immutable from "immutable";

import createKaTeXPlugin from 'draft-js-katex-plugin';
// import createKaTeXPlugin from './draftjs-katex';
import katex from 'katex'

const kaTeXPlugin = createKaTeXPlugin({ katex });

const { InsertButton } = kaTeXPlugin;

const TextEditorDraft = ({ value }) => {
    const [editorState, setEditorState] = React.useState(() =>
        EditorState.createWithText(value ?? "Hello world!"),
    );

    React.useEffect(() => {
        console.log('TextEditorDraft useEffect');
        // setEditorState(EditorState.createWithText(value || "Hello world!"));
        // setEditorState(EditorState.createWithContent(Draft.convertFromRaw(value)));
        // if (value !== editorState.toString()) {
        setEditorState(EditorState.createWithText(value ?? "Empty"));
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);


    const editor = React.useRef(null);

    function focusEditor() {
        editor.current.focus();
    }

    const mapWithButton = Immutable.Map({
        button: {
            element: "button"
        }
    }).merge(DefaultDraftBlockRenderMap);

    const htmlContent = convertFromHTML(value, undefined, mapWithButton);

    function myBlockRenderer(contentBlock) {
        console.log('myBlockRenderer', contentBlock);
        const type = contentBlock.getType();
        if (type === "button") {
            return {
                component: () => {
                    return (
                        <button onClick={() => console.log("doesn't seem to work :(")}>
                            {contentBlock.getText()}
                        </button>
                    );
                },
                editable: false,
            };
        }
    }

    return (
        <div>
            <div>
                <InsertButton />
            </div>

            <div
                style={{ fontFamily: "Menlo", border: "1px solid black", minHeight: "6em", cursor: "text" }}
                onClick={focusEditor}
            >
                <Editor
                    plugins={[kaTeXPlugin]}
                    ref={editor}
                    editorState={editorState}
                    onChange={setEditorState}
                    placeholder="Question text"
                    customBlockRenderFunc={myBlockRenderer}
                />
            </div>

        </div>
    );
};

export default TextEditorDraft;