import React from 'react';
import { Box, LinearProgress, Typography } from "@mui/material";

const UploadProgress = ({ progress }) => {
    const pct = parseInt(progress * 100) / 100.0;
    console.log('progress:', progress, pct);
    return (
        <Box width="100%" display="flex" alignItems="center" p={1}>
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="white">
                    {`${Math.round(progress,)}%`}
                </Typography>
            </Box>
        </Box>
    )
    return (
        <div className="progress flex gap-2 bg-black">
            Uploading:
            <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${progress}%` }}
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
            >
                {progress}%
            </div>
        </div>
    );
};


export default UploadProgress;
