import React from 'react';
import ReactJson from "react-json-view";
import PanelView from "../PanelView";

const LibraryDataView = (course) => {
    return (
        <PanelView>
            <ReactJson
                // style={{ flexGrow: '1', background: 'red', height: '100%' }}
                name='State'
                src={{ course }}
                collapsed={4}
            />
        </PanelView>
    );
};


export default LibraryDataView;
