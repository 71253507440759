import React, { useEffect } from 'react';
import { useCallback, useMemo, useState } from "react";
import { UncontrolledTreeEnvironment, Tree, StaticTreeDataProvider } from 'react-complex-tree';
import "react-complex-tree/lib/style-modern.css";
import apiClient from '../../context/APIClient';
import { useDevTool } from "../../context/DevTool";

export const ItemTree = ({ root, roots, className, onSelected, course }) => {

    // start the tree
    // reduce course groups and add the root obj
    const loadedTree = useMemo(() => {
        if (!course)
            return null;

        // create an item for each LessonGroup
        const tree = course?.groups?.reduce((acc, group) => {
            // if the course group has parent
            // find that parent and add it as a child to the obj
            const childGroups = course?.groups?.filter((g) => g.parent === group.id) ?? [];
            const childGroupIds = childGroups.map(g => g.id)
            // const lessonIds = group.lessons.map(lessonId => `${group.id}:${lessonId}`)
            acc[group.id] = {
                itemType: 'LessonGroup',
                index: group.id,
                isFolder: true,
                children: [...childGroupIds],
                data: group.title,
                item: group
            };

            // add the lessons for this group
            acc = group.lessons?.reduce((acc, lessonId) => {
                // get the lesson object
                const lesson = course?.lesson_objects.find(lesson => lesson.id === lessonId);
                if (!lesson)
                    return acc;

                // create a pseudo id
                const pseudoLessonId = `${group.id}:${lessonId}`;
                acc[pseudoLessonId] = {
                    itemType: 'Lesson',
                    index: pseudoLessonId,
                    isFolder: false,
                    data: lesson.title,
                    lessonGroup: group.id,
                    item: lesson
                };
                acc[group.id].children.push(pseudoLessonId)
                return acc;
            }, acc);

            //     // let lessonChildren = [];
            //     // if (lesson.quiz_id) lessonChildren.push(lesson.quiz_id)
            //     // if (lesson.video) lessonChildren.push(lesson.video)
            //     // construct a pseudo-id from the group & lesson
            //     return acc;
            // }, acc);

            // only add children to the course child if it is not a parent
            if (!group.parent)
                acc[course.id].children.push(group.id);
            return acc;
        }, {
            root: {
                index: 'root',
                itemType: 'Root',
                children: [course.id],
                title: 'root',
                data: 'Root item',
                item: null
            },
            [course.id]: {
                itemType: 'Course',
                index: course.id,
                id: course.id,
                isFolder: true,
                children: [],
                data: course.title,
                item: course
            },
        });

        // course?.lesson_objects?.reduce((acc, lesson) => {
        //     let lessonChildren = [];
        //     if (lesson.quiz_id) lessonChildren.push(lesson.quiz_id)
        //     if (lesson.video) lessonChildren.push(lesson.video)
        //     acc[lesson.id] = {
        //         ...lesson,
        //         itemType: 'Lesson',
        //         index: lesson.id,
        //         isFolder: true,
        //         children: lessonChildren,
        //         data: lesson.title,
        //     };
        //     return acc;
        // }, tree);
        // course?.video_objects.reduce((acc, video) => {
        //     Object.values(tree).forEach(item => {
        //         item.children.forEach(child => {
        //             if (child === video.id) {
        //                 acc[video.id] = {
        //                     ...video,
        //                     itemType: 'Video',
        //                     index: video.id,
        //                     isFolder: false,
        //                     children: [],
        //                     data: video.title,
        //                 };
        //             }
        //         })
        //     });
        //     return acc;
        // }, tree)
        return tree;
    }, [course]);


    const insert = (arr, index, newItem) => [
        // part of the array before the specified index
        ...arr.slice(0, index),
        // inserted item
        newItem,
        // part of the array after the specified index
        ...arr.slice(index)
    ]

    const handleUpdateLessons = (lessonGroup, lesson, childIndex) => {
        if (childIndex) {
            const lesson = insert(lessonGroup.item.lessons, lesson.item.id, childIndex)
            const payload = { lessons: lesson }
            apiClient.patch(`/api/admin/lesson_groups/${lessonGroup.item.id}/`, payload)
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });
            return
        }
        Object.values(loadedTree).forEach(item => {
            if (item?.item?.lessons) {
                let foundChild = item?.item?.lessons.find(ids => ids === lesson.item.id);
                if (foundChild) {
                    let removeLesson = item?.item?.lessons.filter(ids => ids !== lesson.item.id)
                    const payload = { lessons: removeLesson }
                    apiClient.patch(`/api/admin/lesson_groups/${item.item.id}/`, payload)
                        .then((response) => {
                            const payload = { lessons: [...lessonGroup.item.lessons, lesson.item.id] }
                            apiClient.patch(`/api/admin/lesson_groups/${lessonGroup.item.id}/`, payload)
                                .then((response) => {
                                    console.log(response);
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }
        })
    }

    const handleUpdateLessonGroup = (lessonGroupParent, lessonGroup) => {
        const payload = { parent: lessonGroupParent.id }
        apiClient.patch(`/api/admin/lesson_groups/${lessonGroup.id}/`, payload)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useDevTool('course', course);
    useDevTool('loadedTree', loadedTree);

    // console.log('tree course', course?.id, course);

    return (
        <UncontrolledTreeEnvironment

            dataProvider={new StaticTreeDataProvider(loadedTree, (item, data) => ({ ...item, data }))}
            getItemTitle={item => item.data}
            canDragAndDrop={true}
            canDropOnFolder={true}
            canReorderItems={true}
            onDrop={(items, target) => {
                const findTargetInTree = loadedTree[target.targetItem];
                const item = items[0];
                if (findTargetInTree?.itemType === 'LessonGroup') {

                    if (item.itemType === 'Lesson') {
                        handleUpdateLessons(findTargetInTree, item)
                    }
                    if (item.itemType === 'LessonGroup') {
                        handleUpdateLessonGroup(findTargetInTree, item)
                    }
                    if (target.targetType === 'between-items') {
                        if (item.itemType === 'Lesson') {
                            handleUpdateLessons(findTargetInTree, item, target.childIndex)
                        }
                    }
                }


            }}
            canDropAt={(items, target) => {
                const findTargetInTree = loadedTree[target.targetItem];
                const findTargetParent = loadedTree[target.parentItem]
                const itemType = items[0].itemType;
                if (target.targetType === 'between-items') {
                    if (itemType === 'Lesson' && findTargetParent?.itemType === 'Course') return false
                    if (itemType === 'Lesson' && findTargetParent?.itemType === 'Root') return false
                    if (itemType === 'LessonGroup' && findTargetInTree?.itemType === 'Root') return false
                }
                if (itemType === 'Lesson' && findTargetInTree?.itemType === 'Course') return false
                if (itemType === 'LessonGroup' && findTargetInTree?.itemType === 'Lesson') return false

                else return true;

            }}
            onSelectItems={(item) => onSelected(loadedTree[item[0]])}
            viewState={{
                ['tree-2']: {
                    focusedItem: course?.id,
                    selectedItems: [course?.id],
                    expandedItems: [course?.id],
                    // expandedItems: ["891dafea-8e12-4187-93d1-e869b036d2a3"],
                },
            }}
        >
            <Tree treeId="tree-2" rootItem="root" treeLabel="Tree Example" />
        </UncontrolledTreeEnvironment>
    );
}

export default ItemTree;