import React from 'react';

const VideoTile = ({ video, onClick }) => {
    // get the image
    if( !video ) return null;
    let image = video?.url_thumbnail ?? video?.thumbnail ?? video?.image;
    if (image === '/static/images/muzology_thumbnail.png') {
        image = video?.image;
    }

    console.log('video: ', image, video );

    return (
        <button
            className={'p-2 text-left text-base bg-[white] flex flex-row items-center gap-2'}
            onClick={onClick}
        >
            <div
                className={'bg-[red] h-[50px] aspect-video'}
            >
                <img
                    className={'bg-[green] h-[50px] aspect-video'}
                    src={image}
                    width={'auto'}
                    height={'50px'}
                />
            </div>
            {video?.title}
        </button>
    );
};


export default VideoTile;
