import React from 'react';
import { Button, ButtonGroup, FormControl, Grid, MenuItem, Select, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Panel from "../../../../Components/Dashboard/Panel";
import QuizPreviewClient from "../QuizPreviewClient";
import { useNavigate } from "react-router-dom";
import { useQuizEditor } from "../QuizEditorProvider";
import { QUIZ_LEVEL_CHOICES } from "../constants";
import { styled } from "twin.macro";

const QuestionDropdownContainer = styled.div`
    .MuiSelect-select {
        .question-text {
            display: none;
        }

        .question-level {
            min-width: 0;
        }

        .question-number {
            min-width: 0;
        }
    }

    .MuiMenuItem-root {
    }
`;

const truncateText = (text, length) => {
    if (text?.length > length) {
        return text?.substring(0, length) + '...';
    }
    return text;
}

export const QuestionsDropdown = ({ onSelect, className, quiz, questionId }) => {
    // const navigate = useNavigate();
    // const { quiz, questionId } = useQuizEditor();
    const [selectedQuestionId, setSelectedQuestionId] = React.useState(questionId);

    const handleChangeQuestion = (event) => {
        if (onSelect) {
            onSelect(event.target.value);
        }
        setSelectedQuestionId(event.target.value);
        // navigate(`/quiz/${quiz.id}/question/${event.target.value}`);
    };

    return (
        <QuestionDropdownContainer>
            <FormControl variant={"standard"} className={className}>
                {/*<InputLabel id="question-toolbar-question-dropdown-label">Question</InputLabel>*/}
                <Select
                    id="question-toolbar-question-dropdown"
                    labelId="question-toolbar-question-dropdown-label"
                    // label="Question"
                    value={questionId}
                    onChange={handleChangeQuestion}
                >
                    {quiz?.questions?.map(question => {
                        const isSelected = question.id === questionId;
                        return <MenuItem key={question.id} value={question.id}>
                            <div className={'flex flex-row gap-2'}>
                                <div className={'-bg-red-100 flex flex-row gap-1 font-[600] text-lg'}>
                                    <div className={'question-level min-w-[80px] flex flex-row items-center justify-end'}>
                                        {QUIZ_LEVEL_CHOICES[question.level][1]}
                                    </div>
                                    <div className={'question-number min-w-[36px] flex flex-row items-center justify-end'}>
                                        #{question.question_index + 1}
                                    </div>
                                </div>

                                <div className={'question-text text-base font-[400] flex flex-row items-center bg-red-2200 overflow-ellipsis'}>
                                    {truncateText(question.question_text, 80)}
                                </div>
                            </div>

                        </MenuItem>;
                    })}
                </Select>
            </FormControl>
        </QuestionDropdownContainer>
    );
};


const QuizPreviewsPanel = ({ quiz }) => {
    console.log('QuizPreviewsPanel', quiz);
    const [level, setLevel] = React.useState(0);
    const [questionId, setQuestionId] = React.useState(null);

    React.useEffect(() => {
        if (quiz?.questions[0].id) {
            setQuestionId(quiz?.questions[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // filter unique levels
    const levels = quiz.questions.reduce((acc, question) => {
        if (!acc.includes(question.level)) {
            acc.push(question.level);
        }
        return acc;
    }, []);

    const handleSetLevel = (event) => {
        console.log('handleSetLevel', event.target.value);
        setLevel(event.target.value);
    }

    const handleQuestionIdChange = (event) => {
        console.log('handleQuestionIdChange', event.target.value);
        setQuestionId(event.target.value);
    }

    return (
        <Grid container spacing={2}>
            <Grid item >
                <ToggleButtonGroup
                    exclusive={true}
                    value={level}
                    onChange={handleSetLevel}
                    aria-label="question level"
                >
                    {levels.map((level) => {
                        return (
                            <ToggleButton value={`${level}`} aria-label="left aligned">
                                Level {level + 1}
                            </ToggleButton>
                        );
                    })}
                </ToggleButtonGroup>
            </Grid>

            <Grid item >
                <ToggleButtonGroup
                    exclusive={true}
                    value={questionId}
                    onChange={handleQuestionIdChange}
                    aria-label="question index"
                >
                    {quiz?.questions.filter(q => q.level == level).map((question) => {
                        return (
                            <ToggleButton value={question.id} aria-label="left aligned">
                                Q #{question.question_index + 1}
                            </ToggleButton>
                        );
                    })}
                </ToggleButtonGroup>
            </Grid>

{/*
            <Grid item xs={12}>
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    {levels.map((level) => {
                        return (
                            <Button key={level} variant='outlined' selected={true}>
                                {level}
                            </Button>
                        );
                    })}
                </ButtonGroup>
            </Grid>
*/}
            <Grid item>
                <QuestionsDropdown
                    quiz={quiz}
                    questionId={questionId}
                    onSelect={setQuestionId}
                />
            </Grid>

            <Grid item xs={12} lg={6}>
            </Grid>

            <Grid item xs={12}>
                <Stack spacing={0}>


                    <QuizPreviewClient
                        quizId={quiz.id}
                        questionId={quiz?.questions[0].id}
                    />

                </Stack>
            </Grid>
        </Grid>

    );
};


export default QuizPreviewsPanel;
