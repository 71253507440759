import React, { useMemo } from 'react';
import tw, { css, styled } from 'twin.macro';
import { DataGrid, GridActionsCellItem, renderBooleanCell } from "@mui/x-data-grid";
import Tooltip from '@mui/material/Tooltip';

import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import apiClient from "../../context/APIClient";
import { CircularProgress } from "@mui/material";


const QuizTable = ({ quizzes, onClickQuiz }) => {
    // columns
    const pageSize = 100;
    const columns = [
        {
            id: 'index',
            // field: 'index',
            // width: 50,
            headerName: '#',
            width: 90,
            sortable: false,
            disableReorder: true,
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1
            //
            //     valueGetter: (params, gridCellParams) => {
            //         console.log('params', params);
            //         console.log('gridCellParams', gridCellParams);
            //         const rowIndex = (gridCellParams.rowIndex) + 1; // Current row index in the page
            //         const currentPage = gridCellParams.pagination.page; // Current page number
            //         const lineNumber = (currentPage * pageSize) + rowIndex; // Calculate line number
            //         return <span>{lineNumber}</span>;
            //     }
        },
        // { field: 'id', headerName: 'ID', width: 150 },
        {
            field: 'title',
            headerName: 'Title',
            sortable: true,
            flex: 1,
            cellClassName: 'font-bold cursor-pointer select-none active:bg-gray-300'
        },
        {
            field: 'quiz_type',
            headerName: 'Type',
            sortable: true,
            // width: 150
        },
        // {
        //     field: 'question_count',
        //     headerName: 'Questions',
        //     width: 150,
        //     valueGetter: (params) => {
        //         return params.row.quiz?.questions?.length || '?';
        //     }
        // },
        // { field: 'col2', headerName: 'Column 2', width: 150 },
        // {
        //     field: 'actions',
        //     headerName: 'Actions',
        //     // flex: 1,
        //     width: 200,
        //     cellClassName: 'font-bold cursor-pointer select-none -bg-blue-200 active:bg-gray-300',
        //     renderCell: (params) => {
        //         return (
        //             <Stack direction="row" spacing={2} sx={{ margin: 'auto' }}
        //                    onClick={(event) => {
        //                        event.stopPropagation();
        //                    }}
        //             >
        //                 <Fab
        //                     color="primary"
        //                     aria-label="edit"
        //                     size='small'
        //                     onClick={(event) => {
        //                         // event.stopPropagation();
        //                         console.log(params.value);
        //                         // navigate(`/quiz/${params.value}`);
        //                         // onRowClick(params, event, params.value);
        //                     }}
        //                 >
        //                     <Edit />
        //                 </Fab>
        //                 <Fab
        //                     color="info"
        //                     aria-label="duplicate"
        //                     size='small'
        //                     onClick={(event) => {
        //                         event.stopPropagation();
        //                         console.log(params.value);
        //                         // navigate(`/quiz/${params.value}`);
        //                         // onRowClick(params, event, params.value);
        //                     }}
        //                 >
        //                     <ContentCopyOutlined />
        //                 </Fab>
        //
        //                 <Fab
        //                     color="warning"
        //                     aria-label="delete"
        //                     size='small'
        //                     onClick={(event) => {
        //                         event.stopPropagation();
        //                     }}
        //                 >
        //                     <Delete />
        //                 </Fab>
        //             </Stack>
        //         )
        //     }
        // },
        {
            field: 'created',
            headerName: 'Created',
            flex: 0.5,
            sortable: true,
            type: 'dateTime',
            // width: 150
            // valueGetter: ({ value }) => value && new Date(value),
            renderCell: (params) => {
                // console.log(params);
                const quiz = params.row.quiz;
                if (!quiz) return '';
                // console.log(quiz.created);
                // return quiz.created;
                // return formatRelative(quiz.created, new Date());
                const created = new Date(quiz.created)
                const relative = formatRelative(created, new Date())
                // const formatted = created.format('dddd MMMM Do YYYY HH:mm:ss Z');
                const formatted = created.toDateString();

                return (
                    <Tooltip title={formatted}>
                        <span className="table-cell-trucate">
                            {relative}
                        </span>
                    </Tooltip>
                );

                // return moment(quiz.created).fromNow();
                // return quiz.created.format('dddd MMMM Do YYYY HH:mm:ss Z');
            },

            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                const date1 = new Date(v1 || 0);
                const date2 = new Date(v2 || 0);
                return date1 - date2;
            }
        },
        {
            field: 'updated',
            headerName: 'Updated',
            sortable: true,
            flex: 0.5,
            // sortable: true,
            // width: 150
            // valueGetter: (params) => {
            //     const quiz = params.row.quiz;
            //     if (!quiz) return '';
            //     return formatRelative(new Date(quiz.updated), new Date())
            // },
            renderCell: (params) => {
                // console.log(params);
                const quiz = params.row.quiz;
                if (!quiz) return '';
                // console.log(quiz.created);
                // return quiz.created;
                // return formatRelative(quiz.created, new Date());
                const updated = new Date(quiz.updated)
                const relative = formatRelative(updated, new Date())
                // const formatted = created.format('dddd MMMM Do YYYY HH:mm:ss Z');
                const formatted = updated.toDateString();

                return (
                    <Tooltip title={formatted}>
                        <span className="table-cell-trucate">
                            {relative}
                        </span>
                    </Tooltip>
                );

                // return moment(quiz.created).fromNow();
                // return quiz.created.format('dddd MMMM Do YYYY HH:mm:ss Z');
            },
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                const date1 = new Date(v1 || 0);
                const date2 = new Date(v2 || 0);
                return date1 - date2;
            }

        },
        {
            field: 'healthcheck',
            headerName: 'Health Check',
            sortable: true,
            // flex: 0.5,
            valueGetter: (params) => {
                const healthCheck = params.row.healthcheck_valid
                if (healthCheck?.valid === true)
                    return 'PASS';
                if (healthCheck?.valid === false)
                    return 'FAIL';
                return '?';
                // console.log(healthCheck);
                // console.log(healthCheck.status);
                // console.log(healthCheck.status === 'ok');
            },

            renderCell: (params) => {
                let value = null;
                const healthCheck = params.row.healthcheck_valid;
                if (healthCheck === true)
                    value = true;
                else if (healthCheck === false)
                    value = false;

                // if (!healthCheck) {
                //     return (
                //         <div className={'flex justify-center w-full'}>
                //             <CircularProgress color="inherit"  size={'1rem'}/>
                //         </div>
                //     )
                // }
                // console.log(healthCheck);
                // console.log(healthCheck.status);
                // console.log(healthCheck.status === 'ok');
                return (
                    <span className="table-cell-trucate text-center w-full">
                        {value === null ? <span className={`justify-center w-full px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800`}>
                            unknown
                        </span> : (
                            (value === true) ? <span className={`justify-center w-full px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800`}>
                            PASS
                        </span> :
                                <span className={`justify-center w-full px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800`}>
                            FAIL
                        </span>
                        )}
                    </span>
                );
            }
        },
        {
            field: 'is_live',
            headerName: 'Is Live',
            sortable: true,
            // flex: 0.5,
            renderCell: (params) => {
                if (params.value) {
                    return (
                        <span className="table-cell-trucate text-center w-full">
                            <span className={`justify-center w-full px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800`}>
                                LIVE
                            </span>
                        </span>
                    );
                } else {
                    return (
                        <span className="table-cell-trucate text-center w-full">
                            <span className={`justify-center w-full px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800`}>
                                NO
                            </span>
                        </span>
                    );
                }
            },
        },
        {
            field: 'Actions',
            type: 'actions',
            getActions: (params) => [
            ]
        }
    ];

    const onRowClick = (params, event, details) => {
        console.log('row clicked', params, event, details);
        if (onClickQuiz)
            onClickQuiz(params.row.quiz);
    }

    const [healthChecks, setHealthChecks] = React.useState({});
    const cancelControllers = React.useRef({});

    // React.useEffect(() => {
    //     const fetchHealthChecks = async () => {
    //         const newHealthChecks = {};
    //
    //         for (const quiz of quizzes) {
    //             const controller = new AbortController();
    //             cancelControllers.current[quiz.id] = controller;
    //
    //             try {
    //                 const response = await apiClient.get(`/api/quiz/${quiz.id}/check/`, { signal: controller.signal });
    //                 newHealthChecks[quiz.id] = response.data;
    //                 setHealthChecks(prev => ({ ...prev, [quiz.id]: response.data }))
    //                 console.log('quiz health check', response.data);
    //             } catch (error) {
    //                 if (error.name === 'AbortError') {
    //                     console.log('Request was aborted for quiz', quiz.id);
    //                 } else {
    //                     console.log('quiz health check error', error);
    //                 }
    //             }
    //
    //             delete cancelControllers.current[quiz.id];
    //         }
    //         // setHealthChecks(prev => ({ ...prev, ...newHealthChecks }));
    //     };
    //     fetchHealthChecks();
    //     return () => {
    //         Object.values(cancelControllers.current).forEach(controller => controller.abort());
    //     }
    //
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [quizzes]);


    /*
    const [healthChecks, setHealthChecks] = React.useState({});
    const cancelControllers = React.useRef({});

    React.useEffect(() => {
        // fetch the health check for each quiz
        quizzes.forEach(quiz => {
            const controller = new AbortController();
            cancelControllers.current[quiz.id] = controller;
            apiClient.get(`/api/quiz/${quiz.id}/check/`, { signal: controller.signal })
                .then(response => {
                    delete cancelControllers.current[quiz.id];
                    const { data } = response;
                    console.log('quiz health check', data);
                    setHealthChecks(prev => {
                        return {
                            ...prev,
                            [quiz.id]: data
                        }
                    });
                })
                .catch(error => {
                    delete cancelControllers.current[quiz.id];
                    console.log('quiz health check error', error);
                });
        });
        // cancel any requests that are in progress
        return () => {
            Object.values(cancelControllers.current).forEach(controller => controller.abort());
            // console.log('canceling health check requests');
            // apiClient.cancelAllRequests();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quizzes]);
    */

    const rows = useMemo(() => {
        return quizzes.map((quiz, index) => {
            // console.log(quiz);
            const healthCheck = healthChecks[quiz.id];
            return {
                ...quiz,
                healthcheck: healthCheck
            };
        });
    }, [quizzes, healthChecks]);

    return (
        <div tw=''>
            <DataGrid
                autoHeight={true}
                rows={rows}
                columns={columns}
                onRowClick={onRowClick}
                getRowId={(row) => row.id}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'updated', sort: 'desc' }],
                    }
                }}
            />
        </div>
    );
};

export default QuizTable;
