import React, { useState, useMemo } from 'react';
import InputText from "../Quizzes/QuizEditor/FormControls";
import { useGetAPI } from "../../context/api";
import { Dialog, DialogContent, Button, InputAdornment, List, ListItem, ListItemButton, ListItemText, Chip } from "@mui/material";
import { UncontrolledTreeEnvironment, Tree, StaticTreeDataProvider } from 'react-complex-tree';
import "react-complex-tree/lib/style-modern.css";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useNavigate } from "react-router-dom";
import { useStudio } from "../Context/StudioContext";
import { useItemEditor } from '../StudioContainer/StudioContainer';

import apiClient from '../../context/APIClient';
import { useDevTool } from "../../context/DevTool";


const LessonListDialog = ({ open, setOpen, lessonTitle, selected, lessonId, mutateLessons, mutateCourses }) => {
    const title = selected?.item?.title

    const addLesson = (lessonId) => {
        const payload = { lessons: [...selected?.item?.lessons, lessonId] }
        apiClient.patch(`/api/admin/lesson_groups/${selected?.item?.id}/`, payload)
            .then((response) => {
                console.log(response);
                mutateLessons();
                mutateCourses();
                setOpen(false)
            })
            .catch((error) => {
                console.log(error);
            });

    };

    let content = (
        <div className='py-2'>
            <div className='text-lg mb-5'>This will add <span className='text-[#4d93d0]'>{lessonTitle}</span> to the Lesson Group <span className='text-[#4d93d0]'>{title}</span></div>
            <Button
                variant='outlined'
                color='primary'
                size='small'
                onClick={() => addLesson(lessonId)}
            > Confirm</Button>
        </div>

    )

    if (selected?.itemType === 'Course') {
        content = <div>You can not add a lesson to a Course</div>
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="lessons-dialog"
        >
            <DialogContent>
                {content}

            </DialogContent>
        </Dialog>
    );
};


const LessonGroupField = ({ lessonId }) => {
    // console.log('render lesson group field');
    const studio = useStudio();
    // useDevTool('LessonGroupField', studio);
    const { lessons, lessonGroups, courses, mutateLessons, mutateCourses } = studio;
    const [showTreeDialog, setShowTreeDialog] = useState(false);
    const lesson = lessons?.find(lesson => lesson.id === lessonId);
    const lessonGroupsContainingSomeLesson = lessonGroups?.filter(lg => lg.lessons?.some(l => l === lesson?.id))
    const [selected, setSelected] = useState(null)

    const removeFromLessonGroup = (item) => {
        let removeLesson = item?.lessons.filter(ids => ids !== lessonId)
        const payload = { lessons: removeLesson }
        apiClient.patch(`/api/admin/lesson_groups/${item.id}/`, payload)
            .then((response) => {
                console.log(response);
                mutateLessons();
                mutateCourses();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const loadedTree = useMemo(() => {
        if (!courses)
            return null;

        // create an item for each course
        const tree = courses.reduce((acc, course) => {
            // console.log("🚀 ~ file: LessonGroupField.js:137 ~ tree ~ course:", course)
            const filterGroups = course.groups.filter(group => !group.parent)
            const courseGroups = filterGroups.map(group => group.id)
            // console.log("🚀 ~ file: LessonGroupField.js:102 ~ courseGroups ~ courseGroups:", courseGroups)
            acc[course.id] = {
                itemType: 'Course',
                index: course.id,
                isFolder: true,
                children: courseGroups,
                data: course.title,
                item: course
            };
            acc['root'].children.push(course.id)

            // create an item for each lesson group
            acc = course.groups.reduce((acc, group) => {
                // if the course group has parent
                // find that parent and add it as a child to the obj
                const childGroups = course?.groups?.filter((g) => g.parent === group.id) ?? [];
                const childGroupIds = childGroups.map(g => g.id)
                const isFolder = childGroupIds.length > 0 ? true : false;
                acc[group.id] = {
                    itemType: 'LessonGroup',
                    index: group.id,
                    isFolder: isFolder,
                    children: [...childGroupIds],
                    data: group.title,
                    item: group
                };
                return acc;
            }, acc);

            return acc;
        }, {
            root: {
                index: 'root',
                itemType: 'Root',
                children: [],
                title: 'root',
                data: 'Root item',
                item: null
            }
        });
        return tree;
    }, [courses]);
    // console.log("🚀 ~ file: LessonGroupField.js:139 ~ loadedTree ~ loadedTree:", loadedTree)


    if (!loadedTree)
        return null;

    const getGroupNodes = (group) => {
        if (!group)
            return [];
        let nodes = [];
        // if the group has a parent
        if (!group.parent) {
            if (group.course) {
                const course = courses.find(c => c.id === group.course);
                if (course) {
                    nodes.push({
                        itemType: 'Course',
                        index: course.id,
                        name: course.title,
                        isFolder: true,
                        children: course.groups,
                        data: course.title,
                        item: course
                    });
                }
            }
        } else {
            // find the parent
            const parent = lessonGroups.find(lg => lg.id === group.parent);
            if (parent) {
                nodes = getGroupNodes(parent);
            }
        }

        let node = {
            itemType: 'LessonGroup',
            index: group.id,
            name: group.title,
            isFolder: true,
            children: group.children,
            data: group.title,
            item: group
        };

        return [...nodes, node];
    }

    const createChips = () => {
        // const getParentName = (parentId) => {
        return lessonGroupsContainingSomeLesson.map((item, index) => {
            const nodes = getGroupNodes(item);
            const name = nodes.map(n => n.name).join(' / ');
            return (
                <div className='m-2' key={index}>
                    <Chip label={name} variant="outlined" onDelete={() => removeFromLessonGroup(item)} />
                </div>
            );
        })
    }

    return (
        <>
            <div className='text-[#373a3f]'>Lesson Groups</div>
            <div className='w-full flex flex-col flex-wrap items-start'>
                {createChips()}
            </div>
            <div>
                <Button
                    variant='outlined'
                    color='primary'
                    size='small'
                    onClick={() => setShowTreeDialog(true)}
                > Add Lesson to Lesson Groups</Button>
            </div>
            <div>
                <UncontrolledTreeEnvironment
                    dataProvider={new StaticTreeDataProvider(loadedTree, (item, data) => ({ ...item, data }))}
                    getItemTitle={item => item.data}
                    viewState={{}}
                    onSelectItems={(item) => setSelected(loadedTree[item[0]])}
                >
                    <Tree treeId="tree-2" rootItem="root" treeLabel="Tree Example" />
                </UncontrolledTreeEnvironment>
            </div>
            <LessonListDialog
                open={showTreeDialog}
                setOpen={setShowTreeDialog}
                lessonTitle={lesson?.title}
                selected={selected}
                lessonId={lessonId}
                mutateLessons={mutateLessons}
                mutateCourses={mutateCourses}
            />
        </>
    );
};


export default LessonGroupField;
