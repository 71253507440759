import React from 'react';
import { Card, CardContent, CardHeader } from "@mui/material";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    root: {
        // padding: '1rem',
        // width: '100%',
    },
    header: {
        backgroundColor: '#fafafa',
        // padding: '1rem',
        // width: '100%',
    },
    content: {
        // width: '100%',
        // display: 'flex',
        // flexDirection: 'column',
    },
});

const DashboardCard = ({ title, children }) => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardHeader
                title={title}
                titleTypographyProps={{ variant: 'h6' }}
                className={classes.header}
            />

            <Divider />

            <CardContent className={classes.content}>
                {children}
            </CardContent>
        </Card>
    );
};


export default DashboardCard;
