import React, { useState } from 'react';
import { PageContainer } from "../../Home/PageContainer";
import { useTopics } from './TopicsEditorProvider';
import { Card, Grid, Button, Stack, Select, FormControl, MenuItem, InputLabel } from "@mui/material";
import AddIcon from "@material-ui/icons/Add";
import { useParams } from "react-router-dom";
import { useGetAPI } from "../../context/api";
import { VideoCard } from '../../Components/index';
import NewSubPlaylistDialog from './Dialogs/NewSubPlaylistDialog';
import AddVideoDialog from './Dialogs/AddVideoDialog';
import InputText from "../Quizzes/QuizEditor/FormControls";
import { GRADE_LEVEL_CHOICES } from './constants';
import apiClient from '../../context/APIClient';
import DeletePlaylistDialog from './Dialogs/DeletePlaylistDialog';

const PlaylistEditorContainer = () => {
    const [openAddVideo, setOpenAddVideo] = useState(false);
    const [openSubPlaylist, setOpenSubPlaylist] = useState(false);
    const [openDeletePlaylist, setOpenDeletePlaylist] = useState(false);
    const [deletePlaylistId, setDeletePlaylistId] = useState(null);
    const [playlistId, setPlaylistId] = useState(null)
    const [playlistName, setPlaylistName] = useState(null)

    const { topics, updateGradeLevel, updateName, savePlaylist, savePlaylistAndContinueEditing, fetchTopics } = useTopics();
    const { topicId } = useParams();
    let playlist = topics?.find(playlist => playlist.id === topicId);
    let subPlaylists = topics?.filter(playlist => playlist.parent === topicId);
    const { data } = useGetAPI('/api/video_files/');
    const videoLibrary = data;


    const onChange = (event, playlistId, type, parentId) => {
        if (type === 'name') updateName(event.target.value, playlistId, parentId)
        else updateGradeLevel(event.target.value, type, playlistId, parentId)
    }

    const addPlaylist = () => {
        let data = { topic_id: playlist.id, name: playlist.name }
        apiClient.post('/api/admin/playlist_definitions/', data)
            .then((response) => {
                console.log(response);
                fetchTopics();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const createCards = () => {
        if (subPlaylists.length > 0) {
            return subPlaylists.map(playlist => {
                console.log("🚀 ~ file: PlaylistEditorContainer.js:51 ~ createCards ~ playlist:", playlist)
                return (
                    <Card className={'flex-grow py-5 px-5 mt-4 min-h-[100px] bg-[#f1f1f1]'}>
                        <div className=''>{playlist.name}</div>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {playlist.playlists[0].videos.map(video => (
                                <Grid item xs={2} sm={4} md={4} key={video.id}>
                                    <VideoCard video={videoLibrary?.find(v => v.id === video)} playlistId={playlist.id} />
                                </Grid>
                            ))}
                        </Grid>
                        <div className='mt-4 '>
                            <Button variant={'outlined'} onClick={() => {
                                setPlaylistId(playlist.playlists[0].id);
                                setPlaylistName(playlist.playlists[0].name);
                                setOpenAddVideo(true);
                            }} startIcon={<AddIcon />}>
                                Add Video
                            </Button>
                        </div>
                        <form className='mt-4 pt-4'>
                            <Stack spacing={2} >
                                <InputText
                                    id='title'
                                    label='Name'
                                    name='title'
                                    value={playlist?.name}
                                    onChange={e => onChange(e, playlist.id, 'name', playlist.parent)}

                                />
                                <FormControl fullWidth>
                                    <InputLabel id="lowest_grade_level" shrink={true}>Lowest Grade Level</InputLabel>
                                    <Select
                                        id="grade-level"
                                        labelId="grade-level-label"
                                        label="Lowest Grade Level"
                                        name='lowest_grade_level'
                                        value={String(playlist?.lowest_grade_level)}
                                        onChange={e => onChange(e, playlist.id, 'lowest_grade_level', playlist.parent)}
                                    >
                                        <MenuItem value={'-1'}>&nbsp;</MenuItem>
                                        {GRADE_LEVEL_CHOICES.map((item) => (<MenuItem value={String(item[0])}>{item[1]}</MenuItem>))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="highest_grade_level" shrink={true}>Highest Grade Level</InputLabel>
                                    <Select
                                        id="grade-level"
                                        labelId="grade-level-label"
                                        label="Highest Grade Level"
                                        name='highest_grade_level'
                                        value={String(playlist?.highest_grade_level)}
                                        onChange={e => onChange(e, playlist.id, 'highest_grade_level', playlist.parent)}
                                    >
                                        <MenuItem value={'-1'}>&nbsp;</MenuItem>
                                        {GRADE_LEVEL_CHOICES.map((item) => (<MenuItem value={String(item[0])}>{item[1]}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </form>
                        <div className='mt-4'>
                            <Button variant='outlined' size='small' color='error' onClick={() => {
                                setOpenDeletePlaylist(true);
                                setDeletePlaylistId(playlist.playlists[0].id);
                            }}>
                                Delete Playlist
                            </Button>
                        </div>
                    </Card>
                )

            })
        }
        return playlist?.playlists?.map(playlist => {
            return (
                <Card className={'flex-grow py-5 px-5 mt-4 bg-[#f1f1f1]'}>
                    <Grid container>
                        <Grid item xs={12} md={8} lg={9} xl={9}>

                            <div className=''>{playlist.name}</div>
                            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                {playlist?.videos?.map(video => (
                                    <Grid item xs={2} sm={4} md={4} key={video.id}>
                                        <VideoCard video={videoLibrary?.find(v => v.id === video)} playlistId={playlist.id} showDelete={true} />
                                    </Grid>
                                ))}
                            </Grid>
                            <div className='mt-4 pt-4'>
                                <Button variant={'outlined'} onClick={() => {
                                    setPlaylistId(playlist.id);
                                    setPlaylistName(playlist.name);
                                    setOpenAddVideo(true);
                                }} startIcon={<AddIcon />}>
                                    Add Video
                                </Button>
                            </div>
                            <form className='mt-4 pt-4'>
                                <Stack spacing={2} >
                                    <InputText
                                        id='title'
                                        label='Name'
                                        name='title'
                                        value={playlist?.name}
                                        onChange={e => onChange(e, playlist.id, 'name')}

                                    />
                                    <FormControl fullWidth>
                                        <InputLabel id="lowest_grade_level" shrink={true}> Lowest Grade Level</InputLabel>
                                        <Select
                                            id="grade-level"
                                            labelId="grade-level-label"
                                            label="Lowest Grade Level"
                                            name='quiz_type'
                                            value={String(playlist?.lowest_grade_level)}
                                            onChange={e => onChange(e, playlist.id, 'lowest_grade_level')}

                                        >
                                            {GRADE_LEVEL_CHOICES.map((item) => (<MenuItem value={String(item[0])}>{item[1]}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel id="highest_grade_level" shrink={true}>Highest Grade Level</InputLabel>
                                        <Select
                                            id="grade-level"
                                            labelId="grade-level-label"
                                            label="Highest Grade Level"
                                            name='quiz_type'
                                            value={String(playlist?.highest_grade_level)}
                                            onChange={e => onChange(e, playlist.id, 'highest_grade_level')}

                                        >
                                            {GRADE_LEVEL_CHOICES.map((item) => (<MenuItem value={String(item[0])}>{item[1]}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                            </form>
                        </Grid>
                    </Grid>
                    <div className='mt-4'>
                        <Button variant='outlined' size='small' color='error' onClick={() => {
                            setOpenDeletePlaylist(true);
                            setDeletePlaylistId(playlist.id);
                        }}>
                            Delete Playlist
                        </Button>
                    </div>


                </Card>
            )

        })
    }



    return (
        <PageContainer
            title={playlist?.name}
            paths={[{ href: '/playlists/', title: 'Topics' }]}
        >

            <div className='flex justify-end'>
                <Stack spacing={2}>
                    {subPlaylists.length === 0 &&
                        <Button variant={'outlined'} onClick={addPlaylist} startIcon={<AddIcon />}>
                            Add Playlist
                        </Button>
                    }
                    {subPlaylists.length > 0 &&
                        <Button variant={'outlined'} onClick={() => setOpenSubPlaylist(true)} startIcon={<AddIcon />}>
                            Add SubTopics Playlist
                        </Button>
                    }

                </Stack>
                <Grid item xs={12} md={4} lg={3} xl={3} className='pl-0 md:pl-5'>
                    <Stack spacing={2}>
                        <Button variant='outlined' size='small' color='success' onClick={savePlaylist} className='min-h-[36px]'>
                            Save
                        </Button>
                        <Button variant='outlined' size='small' color='primary' onClick={savePlaylistAndContinueEditing} className='min-h-[36px]'>
                            Save And Continue Editing
                        </Button>

                    </Stack>
                </Grid>


            </div>

            {createCards()}
            <NewSubPlaylistDialog open={openSubPlaylist} subTopic={true} onClose={() => setOpenSubPlaylist(false)} topicId={topicId} />
            <AddVideoDialog open={openAddVideo} onClose={() => setOpenAddVideo(false)} playlistId={playlistId} playlistName={playlistName} />
            <DeletePlaylistDialog open={openDeletePlaylist} onClose={() => setOpenDeletePlaylist(false)} playlistId={deletePlaylistId} />

        </PageContainer>
    );
};

export default PlaylistEditorContainer;