import React, { useState } from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Dialog } from "@mui/material";
import CreateAccountWizard from "./CreateAccountWizard";
import { Button, DialogActions } from "@material-ui/core";

const CreateAccountDialog = ({ open, setOpen }) => {
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="create-account-dialog"
            maxWidth={"lg"}
            fullWidth={true}
        >
            <DialogTitle id="create-account-dialog">
                Create New Account
            </DialogTitle>

            <DialogContent>
                <CreateAccountWizard />
            </DialogContent>

        </Dialog>
    );
};

export const useCreateAccountDialog = () => {
    const [isOpen, setIsOpen] = useState(false);

    const showDialog = () => setIsOpen(true);
    const hideDialog = () => setIsOpen(false);

    const DialogWrapper = (props) => (<CreateAccountDialog open={isOpen} setOpen={setIsOpen} />);

    return { show: showDialog, hide: hideDialog, Dialog: DialogWrapper };
};

export default CreateAccountDialog;
