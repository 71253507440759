import React from 'react';
import { AppBar, Avatar, Button, Grid, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Navbar = (props) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    // const open = false;
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleLogout = () => {
        navigate('/logout');
    }
    const handleProfile = () => {
        navigate('/profile');
    }

    return (
        <AppBar position="static" alignitems="center" color="primary" className={'flex-grow-0'}>
            <Toolbar>
                <Grid container justify="center" wrap="wrap">
                    <Grid item>
                        <Typography variant="h6">
                            <a href={'/'}>{props.title ?? "Muzology Studio"}</a>
                        </Typography>
                    </Grid>
                </Grid>
                <Button
                    variant=""
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <Avatar>
                        {/*<AccountCircleIcon />*/}
                    </Avatar>
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleProfile}>Profile</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
                {/*
                <Button
                    variant="contained"
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <Avatar>
                        user
                        <AccountCircleIcon />
                    </Avatar>
                </Button>
                <Menu
                    id="basic-menu"
                    // anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                </Menu>
                */}
            </Toolbar>
        </AppBar>

    );
};


export default Navbar;
