import React, {useCallback, useEffect, useState} from 'react';
import {Outlet, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useGetAPI} from "../../../context/api";
import {QUESTION_TYPE_MULTIPLE_CHOICE, QUESTION_TYPE_MULTIPLE_SELECTION} from "./constants";
import {useDevTool} from "../../../context/DevTool";
import apiClient from "../../../context/APIClient";
import {Backdrop, CircularProgress} from "@mui/material";
import {v4 as uuidv4} from "uuid";
import * as uuid from "uuid";

const Context = React.createContext(null);

export const createNewQuestion = (quiz, level) => {
    if (!quiz)
        return null;
    // get all the questions for this level
    const questions = quiz.questions.filter(question => question.level === level);
    // get the highest question index
    const maxIndex = questions.reduce((max, question) => Math.max(max, question.question_index), 0);
    // console.log('new max index', maxIndex, questions);
    // create a new question with the next index
    const newQuestion = {
        new: true,
        id: uuid.v4(),
        quiz_id: quiz.id,
        question_text: '',
        question_index: maxIndex + 1,
        level: level,
        type: QUESTION_TYPE_MULTIPLE_CHOICE,
        choices: [
            {new: true, id: uuidv4(), choice_index: 1, choice_text: '', correct: false, content_image: false},
            {new: true, id: uuidv4(), choice_index: 2, choice_text: '', correct: false, content_image: false},
            {new: true, id: uuidv4(), choice_index: 3, choice_text: '', correct: false, content_image: false},
            {new: true, id: uuidv4(), choice_index: 4, choice_text: '', correct: false, content_image: false},
        ],
    };

    return newQuestion;
}

const QuizEditorProvider = (props) => {
    const {quizId, questionId} = useParams();
    const {data: allQuizzes} = useGetAPI(`/api/quizzes/`);
    const {loading, error, data} = useGetAPI(`/api/fullquizzes/${quizId}/`);

    const [quiz, setQuiz] = useState(null);
    const [question, setQuestion] = useState(null);

    const [quizDirty, setQuizDirty] = useState(false);
    const [isQuestionDirty, setIsQuestionDirty] = useState(false);
    const navigate = useNavigate();
    const [busy, setBusy] = useState(false);
    const newQuestion = questionId === 'create';
    const [searchParams] = useSearchParams();
    const {level} = searchParams;
    // const [saving, setSaving] = useState(false);
    // console.log('level', level, searchParams);
    // load the quiz whenever data changes
    useEffect(() => {
        if (data) {
            // make a copy of the data
            console.log('loading quiz', data);
            setQuiz(JSON.parse(JSON.stringify(data)));
        }
    }, [data]);

    const createNewQuestion2 = (quiz, level = 0) => {
        // create the question
        const question = createNewQuestion(quiz, level);
        console.log('new question:', question);
        // flag the question as new
        question.new = true;

        let questions = quiz.questions;

        // add the new question to the list
        questions = [...questions, question];

        // update the quiz
        setQuiz((quiz) => {
            return {...quiz, questions};
        });

        // set this as the current question
        setQuestion(question);

        // flag the quiz as dirty
        setIsQuestionDirty(true);

        // navigate to the question editor
        // navigate(`/quiz/${quizId}/question/${question.id}`);
        navigate(`/quiz/${quizId}/question/create?id=${question.id}`);
    }

    // load the question when the questionId changes
    useEffect(() => {
        if (quiz) {
            let question = null;
            if (questionId === 'create') {
                console.log("* CREATING A NEW QUESTION (id is create)");
                // create a new question
                question = createNewQuestion(quiz, level ?? 0);
                // setIsQuestionDirty(true);
                // console.log("new question: ", question);
                // navigate(`/quiz/${quizId}/question/${question.id}`);
                // navigate(`/quiz/${quizId}/question/${question.id}`);
            } else {
                // find the question based on the questionId
                question = quiz?.questions?.find((question) => question.id === questionId);
            }
            setQuestion(question);
        }
    }, [level, questionId, quiz]);

    // create a new question
    // const createQuestion = useCallback((level) => {
    //     const question = createNewQuestion(quiz, level);
    //     setIsQuestionDirty(true);
    //     return question;
    // }, []);

    const updateChoice = useCallback((choice, flagAsDirty = true) => {
        console.log("Update choice:", choice, question);
        let q = question;
        if( !q ) {
            // find the question that owns this choice
            q = quiz.questions.find((question) => {
                return question.choices.find((c) => c.id === choice.id);
            });
        }

        // find choice.id in question.choices
        // if found, replace it
        // setQuiz((quiz) => {
        //     const questions = quiz.questions.map((q) => {
        //         if (q.id === question.id) {
        //             const choices = q.choices.map((c) => {
        //                 if (c.id === choice.id)
        //                     return { ...c, ...choice };
        //                 return c;
        //             });
        //             return { ...q, choices };
        //         }
        //         return q;
        //     });
        //
        //     return { ...quiz, questions };
        // });

        setQuestion((question) => {
            // question.question_text = 'new text';
            // question.choices = [];
            // console.log('new question', question);
            // return question;

            let choices = question.choices;
            // console.log('update question choice', question.type);
            if (question.type === QUESTION_TYPE_MULTIPLE_CHOICE && choice.correct !== undefined) {
                // if this is a multiple choice question, then only one choice can be correct
                // so we need to set all other choices to incorrect
                // choices = choices.map((c) => {
                //     if (c.id === choice.id)
                //         return { ...c, correct: choice.correct };
                //     return { ...c, correct: false };
                // });
                console.log('multiple choice', choices);
            } else if (question.type === QUESTION_TYPE_MULTIPLE_SELECTION && choice.correct !== undefined) {
                // if this is a multiple choice question, then only one choice can be correct
                // so we need to set all other choices to incorrect
                // choices = choices.map((c) => {
                //     return { ...c, correct: false };
                // });
                // console.log('multiple select', choices);
            }
            // console.log('merging choice', choice, choices);
            // merge the choice into the existing choice
            choices = choices.map((c) => {
                if (c.id === choice.id) {
                    // merge the choice into the existing choice
                    return {...c, ...choice};
                }
                // if (question.type === QUESTION_TYPE_MULTIPLE_CHOICE)
                //     return { ...c, correct: false };
                return c;
            });

            if (question.type === QUESTION_TYPE_MULTIPLE_CHOICE) {
                // if this choice was correct, then set all other choices to incorrect
                if (choice.correct) {
                    choices = choices.map((c) => {
                        if (c.id === choice.id)
                            return c;
                        return {...c, correct: false};
                    });
                }
            }

            // console.log('new choices', choices);
            // question.question_text = 'new text';
            // question.choices = choices;
            // console.log('updated question', question, choices);
            return {...question, choices};
        });
        if (flagAsDirty)
            setIsQuestionDirty(true);
    }, [question])

    const updateQuiz = (updates, flagAsDirty = true) => {
        console.log('updateQuiz', quiz, updates);
        setQuiz((quiz) => {
            return {...quiz, ...updates};
        });
        if (flagAsDirty)
            setQuizDirty(true);
    }

    const updateQuestion = (question, flagAsDirty = true) => {
        // console.log('updateQuestion', question);
        setQuestion((value) => {
            return {...value, ...question}
        });
        if (flagAsDirty)
            setIsQuestionDirty(true);

        // if( question.id === null ) {
        //     setQuestion(question);
        // } else {
        //     setQuiz((quiz) => {
        //         const questions = quiz.questions.map((q) => {
        //             if (q.id === question.id) {
        //                 return { ...q, ...question };
        //             }
        //             return q;
        //         });
        //         return { ...quiz, questions };
        //     });
        // }
        //
        // setQuiz((quiz) => {
        //     const questions = quiz.questions.map((q) => {
        //         if (q.id === question.id) {
        //             return { ...q, ...question };
        //         }
        //         return q;
        //     });
        //     return { ...quiz, questions };
        // });
    }

    const saveQuiz = useCallback(() => {
        // if the question is dirty, merge it into the quiz
        // make a clone of the quiz
        let data = JSON.parse(JSON.stringify(quiz));
        console.log('save quiz', data);
        if (question) {
            // remove IDs from new choices
            const questionCopy = {...question};
            questionCopy.choices = question.choices.map((choice) => {
                if (choice.new) {
                    delete choice.id;
                    delete choice.new;
                }
                return choice;
            });

            if (questionCopy.new) {
                // add the question to the quiz
                delete questionCopy.id;
                console.log('adding question', questionCopy);
                const questions = data.questions.concat(questionCopy);
                data = {...data, questions};
            } else if (isQuestionDirty) {
                // merge the question into the quiz
                const questions = data.questions.map((q) => {
                    if (q.id === question.id) {
                        return {...q, ...question};
                    }
                    return q;
                });
                data = {...data, questions};
            }
        }

        // post the quiz
        console.log('saving data');
        setBusy(true);
        return apiClient.patch(`/api/quiz/${quizId}/`, data)
            .then((response) => {
                console.log('saved quiz', response);
                setBusy(false);
                setQuizDirty(false);
            })
            .catch((error) => {
                setBusy(false);
                console.log('error saving quiz', error);
            });
    }, [newQuestion, question, isQuestionDirty, quiz, quizId]);

    const saveQuestion = useCallback(() => {
        console.log('* save question:', question);

        // make a clone of the question
        const data = JSON.parse(JSON.stringify(question));

        // remove IDs from new choices
        data.choices = data.choices.map((choice) => {
            if (choice.new) {
                delete choice.id;
                delete choice.new;
            }
            return choice;
        });

        console.log('saving question data', data);

        const originalId = data.id;
        if (data.new) {
            // remove the question ID
            delete data.id;
        }

        // post the quiz
        setBusy(true);
        return apiClient.patch('/api/quiz/question/', {...data, quiz_id: quizId})
            .then((response) => {
                console.log('saved question', response);
                const {data} = response;

                let questions = quiz.questions;
                if (question.id === null) {
                    // just saved a new question - so add it to the list
                    questions = [...questions, data];
                } else if (question.new) {
                    // this was a new question just saved - so replace the question by ID
                    questions = quiz.questions.map((q) => {
                        if (q.id === originalId) {
                            return data;
                        }
                        return q;
                    });

                } else {
                    // replace the question by ID
                    questions = quiz.questions.map((q) => {
                        if (q.id === question.id) {
                            return data;
                        }
                        return q;
                    });
                }

                setQuiz((quiz) => {
                    return {...quiz, questions};
                });
                setIsQuestionDirty(false);
                setBusy(false);
            })
            .catch((error) => {
                console.log('error saving question', error);
                setBusy(false);
            });
    }, [question, quiz, quizId]);

    const deleteQuestion = useCallback(() => {
        // delete the current question
        if (questionId === 'create') {
            // cancel creating a new question
            setIsQuestionDirty(false);
            return navigate(`/quiz/${quizId}`);
        } else {
            // delete the question
            return apiClient.delete(`/api/quiz/question/?question_id=${questionId}`)
                .then((response) => {
                    console.log('deleted question', response);
                    // remove the question from the quiz
                    const questions = quiz.questions.filter((q) => q.id !== questionId);
                    setQuiz((quiz) => {
                        return {...quiz, questions};
                    });
                })
                .catch((error) => {
                    console.log('error deleting question', error);
                    navigate(`/quiz/${quizId}`);
                });
        }
    }, [navigate, questionId, quiz?.questions, quizId]);

// console.log('question', question);
    const value = {
        allQuizzes,

        loading,
        error,

        // quiz editor
        quizId,
        quiz: quiz,
        quizDirty,
        updateQuiz,

        saveQuiz,
        saveQuestion,

        // question editor
        questionId,
        newQuestion,
        question: question,
        isQuestionDirty: isQuestionDirty,
        updateQuestion,
        deleteQuestion,
        modifyQuestionChoice: updateChoice,
        updateChoice,
        createNewQuestion: createNewQuestion2,
    }
    useDevTool('Quiz Editor', value);

// const router = useRouter();
// if (!router.isReady)
//     return null;

// handle loading states
// if (loading)
//     console.log("*** QuizEditorProvider loading");
//     return (
//         <Backdrop
//             sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
//             open={true}
//         >
//             <CircularProgress color="inherit"/>
//         </Backdrop>
//     );

    if (error) {
        console.log('*** QuizEditorProvider error', error);
        return <div>Error: {error.message}</div>;
    }

    if (!data || !quiz) {
        console.log('*** QuizEditorProvider no data');
        //return <div>No data...</div>;
    }
    if (questionId && !question) {
        console.log('*** QuizEditorProvider no question');
        // return <div>Loading question...</div>;
    }

    return (
        <Context.Provider value={value}>
            {/*<Loading open={busy} />*/}
            <Backdrop
                sx={{color: '#ffffff22', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={busy}
            >
                <CircularProgress color="inherit" variant='indeterminate'/>
            </Backdrop>

            <Outlet/>
        </Context.Provider>
    );
};

export function useQuizEditor() {
    const context = React.useContext(Context);
    if (context === undefined) {
        throw new Error(`useQuizEditor must be used within a QuizEditorProvider`)
    }
    return context
}

export function useDeleteQuestions(callback, deps) {
    const context = React.useContext(Context);
    if (context === undefined) {
        throw new Error(`useDeleteQuestions must be used within a QuizEditorProvider`)
    }

    return useCallback((questionIds) => {
        return new Promise((resolve, reject) => {
            const tasks = questionIds.map((questionId) => {
                return apiClient.delete(`/api/quiz/question/?question_id=${questionId}`);
            });

            Promise.all(tasks)
                .then(() => {
                    // Remove the questions from the quiz
                    const questions = context.quiz.questions.filter((q) => !questionIds.includes(q.id));
                    context.updateQuiz({questions});

                    if (callback) {
                        callback();
                    }

                    resolve();
                })
                .catch((error) => {
                    console.error('An error occurred:', error);
                    // Handle the error according to your needs
                    reject(error);
                });
        });

    }, [callback, context]);
}

export default QuizEditorProvider;
