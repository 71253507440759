import axios from "axios";
import { MUZOLOGY_API_URL } from "../config";

export const apiClient = axios.create({
    baseURL: MUZOLOGY_API_URL,
    withCredentials: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    headers: { 'Content-Type': 'application/json' }
});

// standard api fetcher for all useSwr calls
export const apiFetcher = (url) => {
    // console.debug('[api fetcher] get', url);
    return apiClient.get(url, { withCredentials: true })
        .then(response => {
            // console.debug(`[api fetcher] complete ${url}`, response?.data);
            // console.log(`[apiFetcher] ${response.config?.method} ${response.config?.url}`, response.data);
            // console.dir(response);
            return response.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
    // .catch(err => {
    //     handleNetworkError(err);
    // });
    // .then((response) => response.data)
    // .catch(err => {
    //     console.error(`[api fetcher] Error fetching ${url}: ${err}`);
    // });
};


export default apiClient;
