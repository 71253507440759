import React, {useMemo, useState} from 'react';
import InputText from "../Quizzes/QuizEditor/FormControls";
import {useGetAPI} from "../../context/api";
import {
    Dialog,
    DialogContent,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemText
} from "@mui/material";
import {Add, Clear, Delete, Edit, Visibility, VisibilityOff} from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import {useNavigate} from "react-router-dom";
import {useStudio} from "../Context/StudioContext";
import apiClient from '../../context/APIClient';

const linkComponent = ({className, ...props}) => {
    return (
        <div className={`select-none ${className} underline underline-offset-4 cursor-pointer`} {...props} >
            {props.value}
        </div>
    )
}

const LessonListDialog = ({open, setOpen, onSelectVideo, videos}) => {

    const handleSelectVideo = (event, id) => {
        if (onSelectVideo)
            onSelectVideo(id);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="lessons-dialog"
        >
            <DialogTitle id="lessons-dialog">Videos</DialogTitle>
            <DialogContent>
                <List>
                    {videos?.map((video) => (
                        <ListItem disablePadding key={video.id}>
                            <ListItemButton
                                // selected={lessonId === video.id}
                                onClick={(event) => handleSelectVideo(event, video.id)}
                            >
                                <div className='max-w-[150px]'>
                                    <img className='aspect-video bg-[cyan]' src={video?.url_thumbnail} alt={'video thumbnail'}/>
                                </div>

                                <ListItemText primary={video.title}/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
};


const VideoInputField = ({videoId, updateVideo, excludeLessonId = null, excludeUsed = true}) => {

    // const { data: videoLibrary } = useGetAPI('/api/video_files/');

    const navigate = useNavigate();
    const {videos: allVideos} = useStudio();
    const selectedVideo = allVideos?.find(video => video.id === videoId);

    // filter the list of videos to show
    const videos = useMemo(() => {
        if (excludeLessonId || excludeUsed || videoId) {
            return allVideos?.filter(video => {
                if (excludeLessonId && video.lesson_id === excludeLessonId)
                    return false;
                if (videoId && video.id === videoId)
                    return false;
                return !(excludeUsed && video.lesson_id);
            });
        }
        return allVideos;
    }, [allVideos, excludeLessonId, excludeUsed, videoId]);

    const onClickEdit = () => { if (videoId) navigate(`/studio/videos/${videoId}`); };
    const onClickRemove = () => { updateVideo({video: null}); };

    const endAdornment = (
        <InputAdornment position="end">
            <IconButton
                disabled={!videoId}
                aria-label="edit video"
                onClick={onClickEdit}
                // edge="end"
            >
                <Edit/>
            </IconButton>

            <IconButton
                aria-label="create video"
                onClick={() => setShowVideoDialog(true)}
                edge="end"
            >
                <Add/>
            </IconButton>

            <IconButton
                aria-label="remove lesson"
                onClick={onClickRemove}
                edge="end"
            >
                <Clear/>
            </IconButton>
        </InputAdornment>
    );

    const [showVideoDialog, setShowVideoDialog] = React.useState(false);


    return (
        <>
            <InputText
                id='video'
                name='video'
                label="Video"
                value={selectedVideo?.title ?? videoId ?? ''}
                readOnly={true}
                // InputLabelProps={{ shrink: true }}
                endAdornment={endAdornment}
                // startAdornment={ <InputAdornment position="start">{lesson?.id}</InputAdornment>}
                InputProps={{
                    startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    endAdornment: endAdornment,
                    // inputComponent: linkComponent,
                }}
                inputComponent={linkComponent}
                inputProps={{
                    onClick: () => {
                        setShowVideoDialog(true);
                    },
                    // inputComponent: linkComponent,
                    // startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    endAdornment: endAdornment,
                    url: `/studio/videos/${videoId}/`
                }}
            />

            <LessonListDialog
                open={showVideoDialog}
                setOpen={setShowVideoDialog}
                videos={videos}
                // lessons={videos}
                // videoId={lessonId}
                onSelectVideo={(id) => {
                    updateVideo({video: id});
                    setShowVideoDialog(false);
                }}
            />
        </>
    );
};


export default VideoInputField;
