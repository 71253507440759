import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

const useDialog = () => {
    const [isOpen, setIsOpen] = useState(false);

    const showDialog = () => setIsOpen(true);
    const hideDialog = () => setIsOpen(false);

    const DialogWrapper = ({ title, children, actions }) => (
        <Dialog open={isOpen} onClose={hideDialog}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>{children}</DialogContent>
            {actions ? (
                <DialogActions>{actions}</DialogActions>
            ) : (
                <DialogActions>
                    <Button onClick={hideDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );

    return { showDialog, hideDialog, DialogWrapper };
};

export default useDialog;
