import React, { useEffect, useState } from 'react';
import { Alert, Snackbar, Dialog, FormGroup, Stack, TextField, DialogActions, Button } from "@mui/material";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CreateAccountWizard from "../../../Manage/CreateAccount/CreateAccountWizard";
import UploadProgress from "./UploadProgress";
import InputText, { InputSwitch } from "../../Quizzes/QuizEditor/FormControls";
import { useForm } from "react-hook-form";

// function Alert(props) {
//     return <Alert elevation={6} variant="filled" {...props} />;
// }
function humanFileSize(size) {
    var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return +(
        (
            size / Math.pow(1024, i)
        ).toFixed(2)
    ) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

const UploadVideoDialog = ({ uploading, uploadSuccess, progress, file, error }) => {
    const [open, setOpen] = useState(false);
    const { register, handleSubmit, trigger, setError, formState: { errors }, control } = useForm();

    // open the dialog when uploading starts
    useEffect(() => {
        if (uploading && !open) {
            setOpen(true);
        }
    }, [open, uploading]);

    const handleClose = () => {
        // setOpen(false);
    }

    const handleSave = () => {
        // save the form
        trigger();
    }

    const handleCancel = () => {
        // cancel any upload in progress
        // or tell the server to delete it
        setOpen(false);
    }

    const handleSaveForm = (data) => {
        // save the form
    }

    return (
        <Dialog
            open={open}
            // onClose={handleClose}
            aria-labelledby="create-account-dialog"
            maxWidth={"lg"}
            fullWidth={true}
        >
            <DialogTitle id="create-account-dialog">
                Upload Video
            </DialogTitle>

            <DialogContent>
                <form onSubmit={handleSubmit(handleSaveForm)}>
                    <Stack spacing={2} sx={{ width: '100%' }}>
                        {error && <Alert severity="error">{error}</Alert>}

                        <InputText
                            // id='name'
                            label='Name'
                            {...register("name", { required: false })}
                        />

                        <InputText
                            // id='description'
                            label='Description'
                            // name='name'
                            multiline
                            minRows={2}
                            {...register("description", { required: false })}
                        />

                        <InputSwitch
                            id='transcode'
                            label='Transcode for production'
                            name='transcode'
                            // value={questionData.last_position}
                            // onChange={handleChangeChecked}
                        />

                        <div>Uploading: {file?.filename}</div>
                        <div>Filetype: {file?.filetype}</div>
                        <div>Size: {humanFileSize(file?.filesize)}</div>
                        {/*{uploading && <div>"Uploading"</div>}*/}
                        {uploading && <Alert severity="info">Currently uploading</Alert>}
                        {uploadSuccess && <Alert severity="success">Upload was a success!</Alert>}
                        <div>Progress: {parseInt(progress)}% ({humanFileSize((progress / 100.0) * file?.filesize)})
                        </div>
                        <UploadProgress progress={progress} />
                    </Stack>
                </form>

            </DialogContent>
            <DialogActions>
                <Button type="button" color="primary" variant="outlined" size="small" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" size="small" onClick={handleSave}>
                    Save
                </Button>
            </DialogActions>

        </Dialog>
    );
};


export default UploadVideoDialog;
