import { HTMLProps } from 'react';
import {
    InteractionMode,
    InteractionManager,
    TreeEnvironmentContextProps,
    TreeItem,
    TreeItemActions,
    TreeItemRenderFlags,
} from 'react-complex-tree';
// import { isControlKey } from '../isControlKey';

export const isControlKey = (e) =>
    e.ctrlKey ||
    (navigator.platform.toUpperCase().indexOf('MAC') >= 0 && e.metaKey);

export const TreeInteractionManager = {
    environment: null,
    mode: 'click-item-to-expand',
    createInteractiveElementProps: (item, treeId, actions, renderFlags) => {
        return {
            onClick: e => {
                actions.focusItem();
                if (e.shiftKey) {
                    actions.selectUpTo(!e.ctrlKey);
                } else if (isControlKey(e)) {
                    if (renderFlags.isSelected) {
                        actions.unselectItem();
                    } else {
                        actions.addToSelectedItems();
                    }
                } else {
                    if (item.isFolder && !renderFlags.isExpanded) {
                        actions.toggleExpandedState();
                    }
                    actions.selectItem();

                    if (!item.isFolder || this.environment?.canInvokePrimaryActionOnItemContainer) {
                        actions.primaryAction();
                    }
                }
            },
            onDoubleClick: () => {
                actions.focusItem();
                actions.selectItem();

                if (item.isFolder) {
                    actions.toggleExpandedState();
                }

                if (!item.isFolder || this.environment?.canInvokePrimaryActionOnItemContainer) {
                    actions.primaryAction();
                }
            },
            onFocus: () => {
                actions.focusItem();
            },
            onDragStart: e => {
                e.dataTransfer.dropEffect = 'move';
                actions.startDragging();
            },
            onDragOver: e => {
                e.preventDefault(); // Allow drop
            },
            draggable: renderFlags.canDrag && !renderFlags.isRenaming,
            tabIndex: !renderFlags.isRenaming
                ? renderFlags.isFocused
                    ? 0
                    : -1
                : undefined,
        };
    }
};

export default TreeInteractionManager;

