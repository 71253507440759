import React, { useCallback, useMemo } from 'react';
import tw from 'twin.macro';
import { useDropzone } from "react-dropzone";
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import { Box, Button, IconButton, LinearProgress, Typography } from "@mui/material";
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import apiClient from "../../../context/APIClient";
import axios from "axios";

import InputText from "../../Quizzes/QuizEditor/FormControls";
import UploadVideoDialog from "./UploadVideoDialog";
import UploadProgress from "./UploadProgress";


const VideoUploader = ({ video, thumbnail_url, updateVideo }) => {
    // const [size, setSize] = React.useState(0);
    // const [selectedFile, setSelectedFile] = React.useState(null);
    // const [showURLInput, setShowURLInput] = React.useState(false);

    // uploading state
    const [isUploading, setIsUploading] = React.useState(false);
    const [isUploaded, setIsUploaded] = React.useState(false);
    const [uploadProgress, setUploadProgress] = React.useState(0);
    const [error, setError] = React.useState(null);
    const [uploadingFile, setUploadingFile] = React.useState(null);
    const isUploadSuccess = isUploaded && !error;

    // dropping a file begins the upload process
    function onDrop(acceptedFiles) {
        if (acceptedFiles.length > 0) {
            uploadFile(acceptedFiles[0]);
        }
    }

    // upload a file to S3
    const uploadFile = useCallback(file => {

        const onUploadProgress = event => {
            // console.log('progress:', event, event.loaded, event.total);
            const progress = (event.loaded / event.total) * 100;
            setUploadProgress(progress);
        }

        // Do something with the files
        console.log('file upload:', file);
        const payload = {
            video_id: video?.id,
            filename: file.name,
            filetype: file.type,
            filesize: file.size,
            last_modified: file.lastModified,
            last_modified_date: file.lastModifiedDate,
        }

        setError(null);
        setIsUploading(true);
        setUploadingFile(payload);

        // 1. Get the upload URL from the API.
        return apiClient.post('/api/video_upload/', payload)
            .then(res => {
                const { filename, upload_id, upload_url } = res.data;
                // console.log('response', res.data);

                if (!upload_url) {
                    // TODO: handle error
                    console.error('upload_url not found');
                    setError('upload_url not found');
                    setIsUploading(false);
                    return;
                }

                // change the filename on the file object to match the name on the server
                const newFile = new File([file], filename, { type: file.type });
                console.log("Uploading: " + filename + " to: " + upload_url);

                // 2. Upload the file to S3 using Axios.
                return axios.put(upload_url, newFile, {
                    headers: {
                        'Content-Type': file.type
                    },
                    onUploadProgress: onUploadProgress
                }).then((response) => {

                    // upload success
                    setIsUploaded(true);
                    setIsUploading(false);

                    // Notify your Django backend that upload is complete
                    return apiClient.put('/api/video_upload/', {
                        video_id: video?.id,
                        upload_id: upload_id,
                        filename: file.name,
                        filetype: file.type,
                        filesize: file.size,
                        lastModified: file.lastModified,
                        lastModifiedDate: file.lastModifiedDate
                    });
                });
            })
            .catch(error => {
                setIsUploading(false);
                setError(error.message);
                console.log('error', error);

            });

    }, [video]);

    const video_url = false;
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        // noClick: true
    });

    return (
        <div className={'relative border-2 border-solid rounded-lg w-full cursor-pointer'}>

            <UploadVideoDialog
                uploading={isUploading}
                uploadSuccess={isUploadSuccess}
                progress={uploadProgress}
                file={uploadingFile}
                error={error}
            />

            <div
                className={'w-full min-h-[56px] flex justify-center items-center '}
                {...getRootProps()}
            >
                <input {...getInputProps()} />

                {error && <div className={'absolute top-0 left-0 w-full h-full bg-black bg-opacity-90 text-white flex flex-col justify-center items-center px-4'}>
                    <h1>
                        Error: {error}
                    </h1>
                </div>}


                {/*<NewVideoDialog progress={uploadProgress} />*/}

                {/*
                <div className={'z-40 fixed inset-0 bg-black bg-opacity-90 text-white flex flex-col justify-center items-center px-4'}>
                    <h1>
                        Uploading...
                    </h1>
                    <UploadProgress progress={uploadProgress} />
                </div>

                */}

                {isUploading ?
                    <div className={'absolute top-0 left-0 w-full h-full bg-black text-white bg-opacity-50 flex justify-center items-center'}>
                        <UploadProgress progress={uploadProgress} />
                    </div>
                    :
                    (video_url ?
                            <div className={'text-[#999999] bg-[#eeeeee] hover:bg-[#dddddd] active:bg-[#ffffff] font-bold text-xl min-h-[56px] w-full h-full flex justify-center items-center'}>
                                {video_url}
                            </div>
                            :
                            <div className={'text-[#999999] bg-[#eeeeee] hover:bg-[#dddddd] active:bg-[#ffffff] font-bold text-xl min-h-[56px] w-full h-full flex justify-center items-center'}>
                                {isDragActive ? "Drop here" : "Drag file here, or click."}
                            </div>
                    )
                }
            </div>
            {/*
            <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
                {question?.image_url &&
                    <IconButton
                        // sx={{ position: 'absolute', right: 0, top: 0 }}
                        onClick={() => updateQuestion({ id: question.id, image_url: null })}
                    >
                        <DeleteSharpIcon />
                    </IconButton>}
                <IconButton
                    // sx={{ position: 'absolute', right: 0, top: 0 }}
                    onClick={() => setShowURLInput(!showURLInput)}
                >
                    <ModeEditSharpIcon />
                </IconButton>
            </Box>

            {showURLInput &&
                <div className={'flex justify-center items-center m-2'}>
                    <InputText
                        id='image_url'
                        label='URL'
                        name='image_url'
                        // value={quiz?.title}
                        // onChange={handleChangeValue}
                        // value={choice ? choice.choice_text : question?.image_url}
                        // onChange={(e) => {
                        //     if (choice) {
                        //         updateChoice({ id: choice.id, choice_text: e.target.value, content_image: true })
                        //     } else {
                        //         updateQuestion({ id: question.id, image_url: e.target.value })
                        //     }
                        // }}
                    />
                </div>
            }
            */}
        </div>
    );
};

export default VideoUploader;
