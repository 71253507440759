import React, { useMemo, useState } from 'react';
import tw, { css } from 'twin.macro';
import SplitPane, { Pane } from 'split-pane-react';
import { StaticTreeDataProvider, Tree, UncontrolledTreeEnvironment, ControlledTreeEnvironment } from "react-complex-tree";
import { useStudio } from "../../Context/StudioContext";
import { createTreeData } from "../CourseTreeData";
import { Container, Paper } from "@mui/material";
import 'split-pane-react/esm/themes/default.css';
import PanelView, { PanelPaper } from "../PanelView";
import { SET_PROJECT } from "../../../VideoEditor/ProjectEditor";
import LessonGroupEditor from "../../Lessons/LessonGroupEditor";
import GroupEditor from "../Editors/GroupEditor";
import TreeInteractionManager from "../TreeInteractionManager";
import CourseEditor from "../Editors/CourseEditor";
import VideoEditor from "../Editors/VideoEditor";
import apiClient from "../../../context/APIClient";
import { v4 as uuidv4 } from "uuid";
import { useDevTool } from "../../../context/DevTool";

const LibraryTreeView = ({ course, children, onSelectItem = null, selectedItem = null }) => {
    const { lessons, videos, courses, mutateCourses } = useStudio();
    const treeId = 'course-tree';
    const rootItem = 'root';

    // get the course directly from the studio context
    console.log('refresh tree data');
    const realCourse = courses.find(c => c.id === course.id);
    console.log('realCourse before', realCourse);
    const treeData = useMemo(() => {
        console.log('!!!!!! course did change', realCourse);
        const tree =  createTreeData(realCourse, null, lessons);
        console.log('treeData', tree);
        return { ...tree };
    }, [realCourse, lessons]);

    console.log('realCourse after', realCourse);

    const [paneSizes, setPaneSizes] = useState(['100%', '30%']);

    console.log('treeData', treeData);

    // const viewState = {
    //     selectedItems: ['course'],
    //     expandedItems: ['course'],
    //     focusedItem: ['course'],
    // };

    const canDrag = (items) => {
    };
    const onDrop = (items, target) => {
    };
    const canDropAt = (items, target) => {
    };
    const onDragOver = (items, target) => {
    };
    const layoutCSS = {
        height: '100%',
        display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center'
    };

    const handleSelectItem = (items, treeId) => {
        console.log('!!! onSelectItems', items, treeId);
        const item = treeData[items[0]];
        console.log("!!! SELECTED ITEM", item);
        if (!item) {
            console.log('!!! Item not found: ', treeData);
            return;
        }
        if (onSelectItem) {
            onSelectItem({...item});
        }
    };


    const handleUpdateGroup = (group) => {
        console.log('update group', group);

        // temporary update the group
        const newCourses = courses?.map(c => {
            if (c.id === course.id) {
                const newGroups = c.groups.map(g => g.id === group.id ? ({ ...g, ...group, saving: true }) : g);
                console.log('newGroups', newGroups);
                const newC = {
                    ...c,
                    groups: [...c.groups.map(g => g.id === group.id ? ({ ...g, ...group, saving: true }) : g)]
                }
                console.log('new course', newC);
                return newC;
            }
            return course;
        });
        console.log('mutateCourses', newCourses, mutateCourses);
        // mutateCourses(newCourses, false);

        // save it
        const groupId = group.id;
        // delete group['id'];
        apiClient.patch(`/api/admin/lesson_groups/${groupId}/`, group)
            .then((response) => {
                console.log(response);
                // mutateLessons();
                // mutateCourses();
                const { data } = response;
                console.log('new group', data);
                if (data) {
                    const newCourses = courses?.map(c => {
                        if (c.id === course.id) {
                            return {
                                ...c,
                                groups: [...c.groups.map(group => group.id === data.id ? data : group)]
                            }
                        }
                        return c;
                    });
                    console.log('!!!! mutateCourses', newCourses, mutateCourses);
                    // mutateCourses(newCourses, false);
                    mutateCourses();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleUpdateCourse = (course) => {
        // temporary update the group
        const newCourses = courses?.map(c => {
            if (c.id === course.id) {
                return { ...c, ...course, saving: true };
            }
            return course;
        });
        console.log('!!!! mutateCourses', newCourses, mutateCourses);
        mutateCourses(newCourses, false);

        // save it
        // const groupId = group.id;
        // delete group['id'];
        apiClient.patch(`/api/admin/course/${course.id}/`, course)
            .then((response) => {
                console.log(response);
                // mutateLessons();
                // mutateCourses();
                const { data } = response;
                console.log('new group', data);
                if (data) {
                    const newCourses = courses?.map(c => {
                        if (c.id === course.id) {
                            return {
                                ...c,
                                groups: [...c.groups.map(group => group.id === data.id ? data : group)]
                            }
                        }
                        return c;
                    });
                    console.log('mutateCourses', newCourses, mutateCourses);
                    // mutateCourses(newCourses, false);
                }
            })
            .catch((error) => {
                console.log(error);
            });

    };


    // const selectedContent = useMemo(() => {
    //     console.log(selectedItem)
    //     if (!selectedItem) {
    //         return 'Select an item';
    //     }
    //     const { itemType, item } = selectedItem;
    //     switch (itemType) {
    //         case "Course":
    //             return (<CourseEditor course={item} onUpdate={()=>{}}/>);
    //         case "Lesson":
    //             return (<VideoEditor lesson={item} onUpdate={()=>{}}/>);
    //         case "Group":
    //         case "Parent Group":
    //             return (<GroupEditor lessonGroup={item} updateLessonGroup={(group) => handleUpdateGroup(group)} />);
    //         default:
    //             return 'Select an item';
    //     };
    // },[handleUpdateGroup, selectedItem] );

    const [focusedItem, setFocusedItem] = useState('course');
    const [expandedItems, setExpandedItems] = useState(['course']);
    const [selectedItems, setSelectedItems] = useState(['course']);
    const selectedItemItem = useMemo(() => {
        if (!selectedItem) return null;

        console.log('!!!! looking for item', selectedItem?.id);
        console.log('!!!! real course', realCourse);

        const { itemType, item } = selectedItem;
        switch (itemType) {
            case "Course":
                return realCourse;
            case "Lesson":
                return lessons.find(l => l.id = item.id);
            case "Group":
            case "Parent Group": {
                let i2 = realCourse.groups.find(g => g.id = selectedItem?.id);
                if( i2 ) {
                    i2 = { ...i2 };
                }
                console.log('!!!!!! LOOKING: ', selectedItem?.id);
                console.log('!!!!!! found group', i2);
                console.log('!!!!!! found group:', i2.id);
                console.log('!!!!!! found group:', i2.title);
                console.assert(i2?.id === selectedItem?.id, 'group not found')
                return i2;
            }
            default:
                return null;
        };

    }, [realCourse, lessons, selectedItem]);

    console.log('!!!! selectedItem', selectedItem);
    console.log('!!!! selectedItemItem', selectedItemItem);
    useDevTool('Library Tree', { course, selectedItem, selectedItemItem });

    const dataProvider = useMemo(
        () => {
            console.log('!!!! treeData changed', treeData)
            if (!treeData || Object.keys(treeData).length === 0) return null;
            return new StaticTreeDataProvider(treeData, (item, data) => ({
                ...item,
                data,
            }));
        }, [treeData]);

    const uniqueId = useMemo( () => uuidv4() , [treeData, selectedItemItem?.id]);
    console.log('!!!! uniqueId', uniqueId);
    return (
        <Container maxWidth={null} style={{ display: 'flex', alignItems: 'stretch' }}>
            <div className='my-5 p-[10px] w-full flex grow' style={{ alignItems: 'stretch' }}>
                <SplitPane
                    split='vertical'
                    sizes={paneSizes}
                    onChange={setPaneSizes}
                >
                    <Pane minSize={200}>
                        <div
                            className='h-full w-full flex grow'
                            style={{
                                alignItems: 'stretch',
                                // backgroundColor: 'white',
                                // border: '1px solid lightgray',
                                // boxShadow: 'inset 1px 1px 2px #000000'
                            }}
                        >
                            <PanelPaper
                                id={uniqueId}
                                style={{
                                    ...layoutCSS,
                                    // flexGrow: '1',
                                    // background: 'green',
                                }}
                                // className={'mr-2 h-full  flex grow bg-[orange]'}
                            >
                                <ControlledTreeEnvironment
                                    // ref={treeEnvironmentRef}
                                    // key={treeHash}
                                    // key={selectedGroup ?? treeHash}
                                    // dataProvider={dataProvider}
                                    dataProvider={dataProvider}
                                    getItemTitle={item => item?.title}
                                    // viewState={viewState}
                                    items={treeData}
                                    viewState={{
                                        [treeId]: {
                                            focusedItem,
                                            expandedItems,
                                            selectedItems: [selectedItem?.id],
                                        },
                                    }}
                                    onFocusItem={item => setFocusedItem(item.index)}
                                    onExpandItem={item => setExpandedItems([...expandedItems, item.index])}
                                    onCollapseItem={item =>
                                        setExpandedItems(expandedItems.filter(expandedItemIndex => expandedItemIndex !== item.index))
                                    }
                                    // getItemTitle={item => item?.title}
                                    // onSelectItems={(items, treeId) => handleSelectGroup(items, treeId)}

                                    // rendering
                                    // renderTreeContainer={renderTreeContainer}
                                    // renderItemTitle={renderItemTitle}
                                    // renderItem={renderItem}
                                    // renderItemsContainer={renderItemsContainer}

                                    // drag & drop
                                    // canDragAndDrop={true}
                                    // canDropOnFolder={true}
                                    // canDropOnNonFolder={false}
                                    // canReorderItems={true}

                                    canDrag={canDrag}
                                    onDrop={onDrop}
                                    canDropAt={canDropAt}
                                    onDragOver={onDragOver}

                                    onSelectItems={handleSelectItem}
                                    defaultInteractionMode={TreeInteractionManager}

                                    // defaultInteractionMode={{
                                    //     mode: 'custom',
                                    //     extends: 'click-item-to-expand',
                                    //     createInteractiveElementProps: (item, treeId, actions, renderFlags) => ({
                                    //         onMouseOver: () => {
                                    //             document.querySelectorAll(`[data-rct-tree="${treeId}"] [data-rct-item-id]`)
                                    //                 .forEach(element => (element.style.background = 'transparent'));
                                    //             document.querySelector(`[data-rct-tree="${treeId}"]  [data-rct-item-id="${item.index}"]`).style.background =
                                    //                 'red';
                                    //         },
                                    //     }),
                                    // }}

                                    // canDrag={(items) => {
                                    //     if (items.length !== 1 || !items[0]) return false;
                                    //     const item = items[0];
                                    //     const draggableItemTypes = ['Lesson'];
                                    //     const can = draggableItemTypes.includes(item?.itemType);
                                    //     // console.log('can drag', item?.itemType, can);
                                    //     return can;
                                    //     // return items.map(item => item.data.startsWith('A')).reduce((a, b) => a && b, true)
                                    // }}
                                    //
                                    // onDrop={(items, target) => {
                                    //     if (items.length !== 1 || !items[0]) return false;
                                    //     const item = items[0];
                                    //     console.log('onDrop', item?.itemType, target);
                                    // }}
                                    //
                                    // canDropAt={(items, target) => {
                                    //
                                    //     if (items.length !== 1 || !items[0]) return false;
                                    //     const item = items[0];
                                    //     // console.log('canDropAt', item?.itemType, target);
                                    //     const { targetType, treeId } = target;
                                    //
                                    //     if (targetType === 'root') {
                                    //         // root
                                    //         const { targetItem, depth, linearIndex } = target;
                                    //         console.log('can drop on root', targetItem, depth, linearIndex);
                                    //         return false;
                                    //     } else if (targetType === 'between-items') {
                                    //         // between items
                                    //         const { childIndex, depth, linePosition, linearIndex, parentItem } = target;
                                    //         console.log('can drop between: ', linearIndex, 'on', parentItem, 'at', childIndex, linePosition, 'depth', depth);
                                    //         // const targetItem = treeData[target.targetItem];
                                    //         // console.log(targetItem);
                                    //         const item = treeData[parentItem];
                                    //         // console.log('parent', item, target);
                                    //         return true;
                                    //     } else if (targetType === 'item') {
                                    //         // on item
                                    //         const { depth, linearIndex, parentItem, targetItem } = target;
                                    //         console.log('can drop on item', targetItem, parentItem, depth, linearIndex);
                                    //         // get the item
                                    //         const item = treeData[targetItem];
                                    //         // console.log('item', item);
                                    //         return true;
                                    //     } else {
                                    //         console.log('can drop on unknown', targetType)
                                    //     }
                                    //     // console.log(target.targetType, target.depth, );
                                    //     return false;
                                    //     return true;
                                    // }}
                                    //
                                    // onDragOver={(items, target) => {
                                    //     console.log('onDragOver', items, target);
                                    //     // if( items.length !== 1 || !items[0]) return false;
                                    //     // const item = items[0];
                                    // }}
                                    // /*
                                    //
                                    // //     const findTargetInTree = loadedTree[target.targetItem];
                                    // //     const item = items[0];
                                    // //     if (findTargetInTree?.itemType === 'LessonGroup') {
                                    // //
                                    // //         if (item.itemType === 'Lesson') {
                                    // //             handleUpdateLessons(findTargetInTree, item)
                                    // //         }
                                    // //         if (item.itemType === 'LessonGroup') {
                                    // //             handleUpdateLessonGroup(findTargetInTree, item)
                                    // //         }
                                    // //         if (target.targetType === 'between-items') {
                                    // //             if (item.itemType === 'Lesson') {
                                    // //                 handleUpdateLessons(findTargetInTree, item, target.childIndex)
                                    // //             }
                                    // //         }
                                    // //     }
                                    // //
                                    // //
                                    // // }}
                                    // // canDropAt={(items, target) => {
                                    // //     const findTargetInTree = loadedTree[target.targetItem];
                                    // //     const findTargetParent = loadedTree[target.parentItem]
                                    // //     const itemType = items[0].itemType;
                                    // //     if (target.targetType === 'between-items') {
                                    // //         if (itemType === 'Lesson' && findTargetParent?.itemType === 'Course') return false
                                    // //         if (itemType === 'Lesson' && findTargetParent?.itemType === 'Root') return false
                                    // //         if (itemType === 'LessonGroup' && findTargetInTree?.itemType === 'Root') return false
                                    // //     }
                                    // //     if (itemType === 'Lesson' && findTargetInTree?.itemType === 'Course') return false
                                    // //     if (itemType === 'LessonGroup' && findTargetInTree?.itemType === 'Lesson') return false
                                    // //
                                    // //     else return true;
                                    // //
                                    // // }}
                                    // // onSelectItems={(item) => onSelected(loadedTree[item[0]])}
                                    //
                                    // onExpandItem={(item, treeId) => {
                                    //     // const treeId = info.treeId;
                                    //     // const itemId = 'container-' + item.id;
                                    //     const itemId = item.id;
                                    //     console.log('click item', treeId, itemId);
                                    //     // toggleExpandedItem(treeId, itemId);
                                    //     toggleExpandedItem(treeId, itemId);
                                    // }}
                                    // // onExpandItem={item => setExpandedItems([...expandedItems, item.index])}
                                    //
                                    // */
                                    //
                                    // // renderItemArrow={renderItemArrow}
                                    //
                                    // renderTreeContainer={renderTreeContainer}
                                    // renderItemTitle={renderItemTitle}
                                    // renderItem={renderItem}
                                    // renderItemsContainer={renderItemsContainer}
                                >
                                    <Tree
                                        className={'w-full h-full bg-[orange]'}
                                        treeId={treeId}
                                        rootItem={rootItem}
                                        treeLabel={'Course'}
                                    />

                                    {children}
                                </ControlledTreeEnvironment>
                            </PanelPaper>
                        </div>
                    </Pane>

                    <Pane minSize={200} maxSize='50%'>
                        <PanelPaper style={{ ...layoutCSS }} className={'ml-2'}>
                            {selectedItemItem && <>
                                {selectedItem?.itemType === 'Course' && <CourseEditor id={selectedItemItem.id} course={selectedItemItem} onUpdate={handleUpdateCourse} />}
                                {selectedItem?.itemType === 'Lesson' && <div id={selectedItemItem.id} lessonGroup={selectedItemItem} updateLessonGroup={(group) => handleUpdateGroup(group)} />}
                                {(selectedItem?.itemType === 'Parent Group' || selectedItem?.itemType === 'Group') &&
                                    <GroupEditor
                                        id={selectedItemItem?.id}
                                        lessonGroup={selectedItemItem}
                                        updateLessonGroup={handleUpdateGroup}
                                    />}
                            </>}
                        </PanelPaper>
                        {/*
                    <div style={{ ...layoutCSS, background: '#d5d7d9' }}>
                        pane2
                    </div>
                    */}
                    </Pane>
                </SplitPane>
            </div>
        </Container>
    )
    // return (
    //     <Container maxWidth={null} style={{ display: 'flex', alignItems: 'stretch' }}>
    //         <div
    //             className='my-5 p-[10px] w-full flex grow'
    //             style={{
    //                 alignItems: 'stretch',
    //                 // backgroundColor: 'white',
    //                 // border: '1px solid lightgray',
    //                 // boxShadow: 'inset 1px 1px 2px #000000'
    //             }}
    //         >
    //             <Paper className={'p-2'} style={{
    //                 flexGrow: '1',
    //                 padding: '10px',
    //                 backgroundColor: 'white',
    //                 // boxShadow: '1px 1px 2px #000000',
    //                 border: '1px solid lightgray'
    //             }}>
    //
    //             </Paper>
    //         </div>
    //     </Container>
    // );
};

export default LibraryTreeView;
