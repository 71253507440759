import * as React from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import DeleteIcon from '@mui/icons-material/Delete';
import Label from '@mui/icons-material/Label';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import InfoIcon from '@mui/icons-material/Info';
import ForumIcon from '@mui/icons-material/Forum';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import HelpSharpIcon from '@mui/icons-material/HelpSharp';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import VideoLibrarySharpIcon from '@mui/icons-material/VideoLibrarySharp';
import LibraryBooksSharpIcon from '@mui/icons-material/LibraryBooksSharp';

import IconButton from '@material-ui/core/IconButton';
import { useCallback, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { ControlledTreeEnvironment, StaticTreeDataProvider, Tree, UncontrolledTreeEnvironment } from "react-complex-tree";
import { useDevTool } from "../../../context/DevTool";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.primary,
    // color: theme.palette.text.secondary,
    // [`& .${treeItemClasses.content}`]: {
    //     color: theme.palette.text.secondary,
    //     borderTopRightRadius: theme.spacing(2),
    //     borderBottomRightRadius: theme.spacing(2),
    //     paddingRight: theme.spacing(1),
    //     fontWeight: theme.typography.fontWeightMedium,
    //     '&.Mui-expanded': {
    //         fontWeight: theme.typography.fontWeightRegular,
    //     },
    //     '&:hover': {
    //         backgroundColor: theme.palette.action.hover,
    //     },
    //     '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
    //         backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
    //         color: 'var(--tree-view-color)',
    //     },
    //     [`& .${treeItemClasses.label}`]: {
    //         fontWeight: 'inherit',
    //         color: 'inherit',
    //     },
    // },
    // [`& .${treeItemClasses.group}`]: {
    //     marginLeft: 0,
    //     [`& .${treeItemClasses.content}`]: {
    //         paddingLeft: theme.spacing(2),
    //     },
    // },
}));

function StyledTreeItem(props) {
    const {
        nodeId,
        nodeType,
        bgColor,
        color,
        labelIcon,
        labelInfo,
        labelText,
        label,
        onNodeDoubleClick,
        ...other
    } = props;

    const handleClick = (e) => {
        // e.stopPropagation();
        console.log(e.target);
        console.log('clicked', nodeType, nodeId);
        // check if this node is expanded or not

    }

    const handleDoubleClick = (e) => {
        console.log('double-clicked', nodeId);
        e.stopPropagation();
        if (onNodeDoubleClick) {
            onNodeDoubleClick(e, nodeId);
        }
        //     console.log('double click');
        //     // expand or collapse the node when double clicked
        //     if (props.onNodeToggle) {
        //         props.onNodeToggle(e, props.nodeId);
        //     }
    }
    const defaultCollapseIcon = (<ExpandMoreIcon />);
    const defaultExpandIcon = (<ChevronRightIcon />);
    const LabelIcon = labelIcon || ExpandMoreIcon;

    // console.log('icon', LabelIcon);
    // // iconCollapse={<CustomIconButton><ExpandMoreIcon /></CustomIconButton>}
    // // iconExpand={<CustomIconButton><ChevronRightIcon /></CustomIconButton>}
    return (
        <StyledTreeItemRoot
            expandIcon={defaultExpandIcon}
            collapseIcon={defaultCollapseIcon}
            nodeId={nodeId}
            // label={'shit'}
            label={
                <Stack direction={'row'}>
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', pr: '10px', my: '4px', mr: '10px' }}
                        onDoubleClick={handleDoubleClick}
                    >
                        <Box
                            // onClick={handleClick}
                            // onDoubleClick={handleDoubleClick}
                            component={LabelIcon}
                            color="inherit"
                            sx={{
                                // mr: 1,
                                width: '32px',
                                height: '32px',
                            }}
                        />
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'inherit',
                                fontSize: '18px',
                                flexGrow: 1
                            }}
                            // onClick={handleClick}
                        >
                            {label}
                        </Typography>
                        <Typography variant="caption" color="inherit">
                            {labelInfo}
                        </Typography>
                    </Box>
                </Stack>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            {...other}
        />
    );
}

// recursive function to find a node in the tree
const findTreeNode = (node, id) => {
    if (!node || !node.id)
        return null;
    if (node.id === id) return node;
    if (node.children) {
        for (let i = 0; i < node.children.length; i++) {
            const found = findTreeNode(node.children[i], id);
            if (found) return found;
        }
    }
    return null;
}

export const FilesTree = ({ root, roots, className, onSelected, files }) => {
    // start the tree


    // reduce course groups and add the root obj
    const loadedTree = useMemo(() => {

        if (!files)
            return null;


        // convert list of files into buckets based on type
        const buckets = files.reduce((acc, file) => {
            if (!acc[file.fileType])
                acc[file.fileType] = [];
            acc[file.fileType].push(file);
            return acc;
        }, {});
        console.log('buckets', buckets);
        const tree =
            {
                root: {
                    index: 'root',
                    itemType: 'Root',
                    children: [],
                    title: 'root',
                    data: 'Root item',
                    item: null
                },
                // [course.id]: {
                //     itemType: 'Course',
                //     index: course.id,
                //     id: course.id,
                //     isFolder: true,
                //     children: [],
                //     data: course.title,
                //     item: course
                // },
            };

        // add each bucket to the tree
        Object.keys(buckets).forEach(file_type => {
            const bucket = buckets[file_type];
            tree.root.children.push(file_type);
            tree[file_type] = {
                title: file_type,
                itemType: 'Folder',
                index: file_type,
                id: file_type,
                isFolder: true,
                children: [],
                data: bucket,
                item: file_type
            };
            // buckets[bucket].forEach(file => {
            //     tree[bucket].children.push(file.id);
            //     tree[file.id] = {
            //         itemType: 'File',
            //         index: file.id,
            //         isFolder: false,
            //         data: file.name ?? `file ${file.id}`,
            //         title: file.name ?? `file ${file.id}`,
            //         item: file
            //     };
            // });
        });

        // create an item for each LessonGroup
        return files.reduce((acc, file) => {
            acc[file.id] = {
                itemType: 'File',
                index: file.id,
                isFolder: false,
                data: file.name ?? `file ${file.id}`,
                title: file.name ?? `file ${file.id}`,
                // lessonGroup: group.id,
                item: file
            };

            // only add children to the course child if it is not a parent
            if (!file.parent)
                // acc.root.children.push(file.id);
                acc[file.fileType].children.push(file.id);
            else
                acc[file.parent].children.push(file.id);


            return acc;
            // if the course group has parent
            // find that parent and add it as a child to the obj
            // const childGroups = course?.groups?.filter((g) => g.parent === group.id) ?? [];
            // const childGroupIds = childGroups.map(g => g.id)
            // const lessonIds = group.lessons.map(lessonId => `${group.id}:${lessonId}`)
            // acc[group.id] = {
            //     itemType: 'LessonGroup',
            //     index: group.id,
            //     isFolder: true,
            //     children: [...childGroupIds],
            //     data: group.title,
            //     item: group
            // };

            // add the lessons for this group
            // acc = group.lessons?.reduce((acc, lessonId) => {
            //     // get the lesson object
            //     const lesson = course?.lesson_objects.find(lesson => lesson.id === lessonId);
            //     if (!lesson)
            //         return acc;
            //
            //     // create a pseudo id
            //     const pseudoLessonId = `${group.id}:${lessonId}`;
            //     acc[pseudoLessonId] = {
            //         itemType: 'Lesson',
            //         index: pseudoLessonId,
            //         isFolder: false,
            //         data: lesson.title,
            //         lessonGroup: group.id,
            //         item: lesson
            //     };
            //     acc[group.id].children.push(pseudoLessonId)
            //     return acc;
            // }, acc);

            //     // let lessonChildren = [];
            //     // if (lesson.quiz_id) lessonChildren.push(lesson.quiz_id)
            //     // if (lesson.video) lessonChildren.push(lesson.video)
            //     // construct a pseudo-id from the group & lesson
            //     return acc;
            // }, acc);

            // // only add children to the course child if it is not a parent
            // if (!file.parent)
            //     acc.root.children.push(file.id);
            // return acc;
        }, tree);
        // course?.lesson_objects?.reduce((acc, lesson) => {
        //     let lessonChildren = [];
        //     if (lesson.quiz_id) lessonChildren.push(lesson.quiz_id)
        //     if (lesson.video) lessonChildren.push(lesson.video)
        //     acc[lesson.id] = {
        //         ...lesson,
        //         itemType: 'Lesson',
        //         index: lesson.id,
        //         isFolder: true,
        //         children: lessonChildren,
        //         data: lesson.title,
        //     };
        //     return acc;
        // }, tree);
        // course?.video_objects.reduce((acc, video) => {
        //     Object.values(tree).forEach(item => {
        //         item.children.forEach(child => {
        //             if (child === video.id) {
        //                 acc[video.id] = {
        //                     ...video,
        //                     itemType: 'Video',
        //                     index: video.id,
        //                     isFolder: false,
        //                     children: [],
        //                     data: video.title,
        //                 };
        //             }
        //         })
        //     });
        //     return acc;
        // }, tree)
    }, [files]);


    // const insert = (arr, index, newItem) => [
    //     // part of the array before the specified index
    //     ...arr.slice(0, index),
    //     // inserted item
    //     newItem,
    //     // part of the array after the specified index
    //     ...arr.slice(index)
    // ]
    //
    // const handleUpdateLessons = (lessonGroup, lesson, childIndex) => {
    //     if (childIndex) {
    //         const lesson = insert(lessonGroup.item.lessons, lesson.item.id, childIndex)
    //         const payload = { lessons: lesson }
    //         apiClient.patch(`/api/admin/lesson_groups/${lessonGroup.item.id}/`, payload)
    //             .then((response) => {
    //                 console.log(response);
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             });
    //         return
    //     }
    //     Object.values(loadedTree).forEach(item => {
    //         if (item?.item?.lessons) {
    //             let foundChild = item?.item?.lessons.find(ids => ids === lesson.item.id);
    //             if (foundChild) {
    //                 let removeLesson = item?.item?.lessons.filter(ids => ids !== lesson.item.id)
    //                 const payload = { lessons: removeLesson }
    //                 apiClient.patch(`/api/admin/lesson_groups/${item.item.id}/`, payload)
    //                     .then((response) => {
    //                         const payload = { lessons: [...lessonGroup.item.lessons, lesson.item.id] }
    //                         apiClient.patch(`/api/admin/lesson_groups/${lessonGroup.item.id}/`, payload)
    //                             .then((response) => {
    //                                 console.log(response);
    //                             })
    //                             .catch((error) => {
    //                                 console.log(error);
    //                             });
    //                     })
    //                     .catch((error) => {
    //                         console.log(error);
    //                     });
    //             }
    //         }
    //     })
    // }
    //
    // // const handleUpdateLessonGroup = (lessonGroupParent, lessonGroup) => {
    // //     const payload = { parent: lessonGroupParent.id }
    // //     apiClient.patch(`/api/admin/lesson_groups/${lessonGroup.id}/`, payload)
    // //         .then((response) => {
    // //             console.log(response);
    // //         })
    // //         .catch((error) => {
    // //             console.log(error);
    // //         });
    // // }

    useDevTool('FilesTree', { loadedTree, files });
    console.log('loadedTree', loadedTree);
    // return null;
    // console.log('tree course', course?.id, course);

    return (
        <UncontrolledTreeEnvironment
            dataProvider={new StaticTreeDataProvider(loadedTree, (item, title) => ({ ...item, title }))}
            getItemTitle={item => item.title}
            viewState={{}}
            // dataProvider={new StaticTreeDataProvider(loadedTree, (item, data) => ({ ...item, data }))}
            // getItemTitle={item => item.title}
            // canDragAndDrop={false}
            // canDropOnFolder={false}
            // canReorderItems={false}
            // onDrop={(items, target) => {
            //     const findTargetInTree = loadedTree[target.targetItem];
            //     const item = items[0];
            //     if (findTargetInTree?.itemType === 'LessonGroup') {
            //         if (item.itemType === 'Lesson') {
            //             handleUpdateLessons(findTargetInTree, item)
            //         }
            //         if (item.itemType === 'LessonGroup') {
            //             handleUpdateLessonGroup(findTargetInTree, item)
            //         }
            //         if (target.targetType === 'between-items') {
            //             if (item.itemType === 'Lesson') {
            //                 handleUpdateLessons(findTargetInTree, item, target.childIndex)
            //             }
            //         }
            //     }
            //
            //
            // }}
            // canDropAt={(items, target) => {
            //     const findTargetInTree = loadedTree[target.targetItem];
            //     const findTargetParent = loadedTree[target.parentItem]
            //     const itemType = items[0].itemType;
            //     if (target.targetType === 'between-items') {
            //         if (itemType === 'Lesson' && findTargetParent?.itemType === 'Course') return false
            //         if (itemType === 'Lesson' && findTargetParent?.itemType === 'Root') return false
            //         if (itemType === 'LessonGroup' && findTargetInTree?.itemType === 'Root') return false
            //     }
            //     if (itemType === 'Lesson' && findTargetInTree?.itemType === 'Course') return false
            //     if (itemType === 'LessonGroup' && findTargetInTree?.itemType === 'Lesson') return false
            //
            //     else return true;
            //
            // }}
            // onSelectItems={(item) => onSelected(loadedTree[item[0]])}
            // viewState={{
            //     ['files-tree']: {
            //         // focusedItem: course?.id,
            //         // selectedItems: [course?.id],
            //         // expandedItems: [course?.id],
            //         // expandedItems: ["891dafea-8e12-4187-93d1-e869b036d2a3"],
            //     },
            // }}
        >
            <Tree treeId="files-tree" rootItem="root" treeLabel="Files Tree" />
        </UncontrolledTreeEnvironment>
    );
}


export default FilesTree;