import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import apiClient from "../../../context/APIClient";
import { useNavigate } from "react-router-dom";

const NewPlaylistDialog = ({ open, onClose }) => {
    const { register, handleSubmit, trigger, formState: { errors }, control } = useForm();

    const handleClose = () => {
        onClose();
    }
    const navigate = useNavigate();

    const onSubmit = data => {
        console.log(data);
        // post the data to the server
        apiClient.post('/api/admin/topics/', data)
            .then((response) => {
                console.log(response);
                const {data} = response;
                navigate(`/playlist/${data.id}`)
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const showError = (error, text = null) => {
        if (error) {
            // console.log(error);
            let message = text;
            if (!message) {
                if (error.type === 'required') message = 'This field is required';
                else message = error.message;
            }

            return (
                <div className='error-container text-red-500'>
                    <p className='error-tag'>{`* ${message}`}</p>
                </div>
            );
        }
        return null;
    };
    // console.log(errors);
    return (
        <Dialog onClose={handleClose} open={open} maxWidth={"sm"} fullWidth={true}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Create New Topic</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Stack spacing={3} sx={{ paddingTop: '10px' }}>
                             <TextField
                                autoFocus
                                fullWidth
                                // onChange={onChange}
                                // value={value}
                                label={"Name"}
                                {...register("name", { required: true })}
                            />
                            {showError(errors.name)}
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type="button" color="primary" variant="outlined" size="small" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" variant="contained" size="small" onClick={() => trigger()}>
                        Create
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default NewPlaylistDialog;