import * as React from 'react';
import { useMemo } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {DataGrid, renderBooleanCell} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { QUESTION_TYPE_CHOICES } from "../constants";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab } from "@mui/material";
import AddIcon from "@material-ui/icons/Add";
import ConfirmDialog from "../ConfirmDialog";
import Loading from "../../../../Components/Loading/Loading";
import { useDeleteQuestions, useQuizEditor } from "../QuizEditorProvider";
import RichText from "../../../../Components/RichText/RichText";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
        className: 'h-full bg-green-200 whitespace-nowrap',
        style: { whiteSpace: 'nowrap' }
    };
}

const columns = [
    // { field: 'index', headerName: '#', width: 50 },
    // { field: 'id', headerName: 'ID', width: 150 },
    {
        field: 'level',
        headerName: 'Level',
        headerAlign: 'center',
        // width: 100,
        cellClassName: 'font-bold cursor-pointer select-none',
        align: 'center',
        renderCell: (params) => {
            if (params.value === 0)
                return <div className={'font-bold text-gray-600'}>Silver</div>;
            if (params.value === 1)
                return <div className={'font-bold text-yellow-500'}>Gold</div>;
            if (params.value === 2)
                return <div className={'font-bold text-blue-500'}>Platinum</div>;
            return <div className={'font-bold'}>{params.value}</div>
        }
    },

    {
        field: 'question_index',
        headerName: 'Number',
        // width: 100,
        align: 'center',
        headerAlign: 'center',
        cellClassName: 'font-normal cursor-pointer select-none',
        renderCell: (params) => {
            return params.value + 1;
            // if (params.value === 0)
            //     return <div className={'font-bold text-gray-600'}>Silver</div>;
            // if (params.value === 1)
            //     return <div className={'font-bold text-yellow-500'}>Gold</div>;
            // if (params.value === 2)
            //     return <div className={'font-bold text-blue-500'}>Platinum</div>;
            // return <div className={'font-bold'}>{params.value}</div>
        }

    },
    {
        field: 'type',
        headerName: 'Type',
        width: 160,
        cellClassName: 'font-normal cursor-pointer select-none',
        renderCell: (params) => {
            // find the choice title with the given value
            const choice = QUESTION_TYPE_CHOICES.find((choice) => choice[0] === params.value)
            if (choice)
                return choice[1];
            return params.value;
            // return choice[1];
            // QUESTION_TYPE_CHOICES.map((choice) => {
            //     if (choice[0] === params.value)
            //         return choice[1];
            // }
        }
    },
    {
        field: 'question_text',
        headerName: 'Question',
        flex: 1,
        cellClassName: 'font-normal cursor-pointer select-none w-full',
        renderCell: (params) => {
            return ( <RichText text={params.value}  />);
        }
    },
    {
        field: 'warmup',
        headerName: 'Warmup',
        cellClassName: 'font-normal cursor-pointer select-none',
        type: 'boolean',
        renderCell: (params) => {
            if(params.value) {
                return renderBooleanCell(params);
            }
            return '';
        }
    },
    // {
    //     field: 'valid',
    //     headerName: 'Valid',
    //     cellClassName: 'font-normal cursor-pointer select-none',
    //     type: 'boolean',
    //     renderCell: (params) => {
    //         return renderBooleanCell(params);
    //     }
    // },
    // { field: 'updated', headerName: 'Updated', width: 150 },
    // { field: 'col2', headerName: 'Column 2', width: 150 },
];


export function QuestionList({ quiz }) {
    const [levelFilter, setLevelFilter] = React.useState(0);
    const navigate = useNavigate();
    // const { quizId } = useParams();
    const { deleteQuestion, createNewQuestion } = useQuizEditor();
    const deleteQuestions = useDeleteQuestions();

    const handleChange = (event, newValue) => {
        setLevelFilter(newValue);
    };

    const rows = useMemo(() => {
        const questions = quiz?.questions?.filter((question, index) => {
            // 3 == 'all levels'
            if (levelFilter === 0)
                return true;
            return question.level === (
                levelFilter - 1
            );
        });

        return questions?.map((question, index) => {
            return question;
            // return {
            //
            //     id: question.id,
            //     level: question.level,
            //     index: question.question_index,
            //     text: question.question_text,
            // };
        });
    }, [quiz?.questions, levelFilter])

    const onRowClick = (params, event, details) => {
        console.log('row clicked', params, event, details);
        navigate(`/quiz/${quiz.id}/question/${params.id}`);
    }

    const handleCreateQuestion = () => {
        console.log('create question');
        createNewQuestion(quiz);
    };

    const addButton = (
        <Fab color="primary" aria-label="add" onClick={handleCreateQuestion}>
            <AddIcon />
        </Fab>
    );

    const [selectedRows, setSelectedRows] = React.useState([]);
    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleDelete = () => {
        console.log('delete selected rows', selectedRows);
        setLoading(true);
        deleteQuestions(selectedRows)
            .then(() => {
                console.log('questions deleted');
                setLoading(false);
                setShowConfirmDelete(false);
            })
            .catch(err => {
                console.error('[handleDelete] ERROR:', err);
                setLoading(false);
                setShowConfirmDelete(false);
            });
        // const promises = selectedRows.map(questionId => deleteQuestion(questionId));
    }

    return (
        <div className={'flex flex-row min-h-full flex-grow'}>
            <Loading loading={loading} />

            <Tabs
                orientation="vertical"
                // variant="scrollable"
                value={levelFilter}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{
                    borderRight: 1,
                    borderColor: 'divider',
                    height: '100%',
                }}
            >
                <Tab label="All Questions" {...a11yProps(0)} />
                <Tab label="Silver" {...a11yProps(1)} />
                <Tab label="Gold" {...a11yProps(2)} />
                <Tab label="Platinum" {...a11yProps(3)} />
            </Tabs>
            <div className={'pl-2 w-full'}>
                <div className={'flex flex-row justify-between mb-2'}>
                    <div>
                        <Typography variant={'h5'}>
                            Questions
                        </Typography>
                    </div>
                    <div className='flex flex-row gap-4'>
                        <Button disabled={!selectedRows.length} variant={'contained'} onClick={() => setShowConfirmDelete(true)}>
                            Delete
                        </Button>
                        {addButton}
                    </div>
                </div>
                {rows &&
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        onRowClick={onRowClick}
                        autoHeight={true}
                        hideFooter={true}
                        checkboxSelection
                        onSelectionModelChange={(newSelection) => {
                            // newSelection is an array of selected row ids
                            console.log(newSelection);
                            setSelectedRows(newSelection);
                        }}

                        // initialState={{
                        //     sorting: {
                        //         sortModel: [{ field: 'title', sort: 'asc' }],
                        //     },
                        // }}
                    />}
            </div>
            {/*<Dialog
                open={showConfirmDelete}
                onClose={() => setShowConfirmDelete(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Questions?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the selected questions?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowConfirmDelete(false)}>Cancel</Button>
                    <Button onClick={() => setShowConfirmDelete(false)} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            */}
            <ConfirmDialog
                open={showConfirmDelete}
                setOpen={setShowConfirmDelete}
                onConfirm={handleDelete}
                title={'Delete Questions?'}
                message={`Are you sure you want to delete these ${selectedRows?.length} questions?`}
            />
            {/*
            <TabPanel value={value} index={6}>
                Item Seven
            </TabPanel>
            */}
        </div>
    );
}

export default QuestionList;