import React from 'react';
import DynamoPlayer from "./DynamoPlayer";
import { DynamoEditor } from "./DynamoEditor";

const DynamoContainer = (props) => {
    return (
        <div>
            <DynamoPlayer/>
            <DynamoEditor/>
        </div>
    );
};

export default DynamoContainer;
