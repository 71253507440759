import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import apiClient from "../../context/APIClient";
import { QUESTION_TYPE_MULTIPLE_CHOICE, QUIZ_LEVEL_CHOICES, QUIZ_TYPE_CHOICES, QUIZ_TYPE_MINI, QUIZ_TYPE_STANDARD } from "./QuizEditor/constants";
import { useNavigate } from "react-router-dom";

const createQuizQuestions = (levelCount, questionCount, choiceCount) => {
    // this will create a quiz scaffold with the given number of levels, questions, and choices.
    // this will return a list of quizzes

    let questions = [];
    for (let level = 0; level < levelCount; level++) {
        for (let question_index = 0; question_index < questionCount; question_index++) {
            let question = {
                level: level,
                question_index: question_index,
                type: QUESTION_TYPE_MULTIPLE_CHOICE,
                choices: []
            };
            for (let choice_index = 0; choice_index < choiceCount; choice_index++) {
                question.choices.push({
                    choice_index: choice_index
                });
            }
            // add the question to the quiz
            questions.push(question);
        }
    }
    return questions;
}

const NewQuizDialog = ({ open, onClose }) => {
    const { register, handleSubmit, trigger, formState: { errors }, control } = useForm();

    const handleClose = () => {
        onClose();
    }
    const navigate = useNavigate();

    const onSubmit = data => {
        console.log(data);
        // construct a scaffold for the quiz
        if (data.quiz_type === QUIZ_TYPE_STANDARD) {
            // standard quiz is 3 levels of 10 questions each, each question has 4 choices.
            data.questions = createQuizQuestions(3, 10, 4);
        } else if (data.quiz_type === QUIZ_TYPE_MINI) {
            // a mini quiz is 3 levels of 5 questions each, each question has 4 choices.
            data.questions = createQuizQuestions(3, 5, 4);
        }

        // post the data to the server
        apiClient.post('/api/quiz/', data)
            .then((response) => {
                console.log(response);
                const { data } = response;
                navigate(`/quiz/${data.id}`)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const showError = (error, text = null) => {
        if (error) {
            // console.log(error);
            let message = text;
            if (!message) {
                if (error.type === 'required') message = 'This field is required';
                else message = error.message;
            }

            return (
                <div className='error-container text-red-500'>
                    <p className='error-tag'>{`* ${message}`}</p>
                </div>
            );
        }
        return null;
    };
    // console.log(errors);
    return (
        <Dialog onClose={handleClose} open={open} maxWidth={"sm"} fullWidth={true}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Create New Quiz</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Stack spacing={2} sx={{ paddingTop: '10px' }}>
                            {/*
                            <Controller
                                name={"name"}
                                required={true}
                                control={control}
                                render={({ field: { onChange, value } }) => (
*/}
                            <TextField
                                autoFocus
                                fullWidth
                                // onChange={onChange}
                                // value={value}
                                label={"Name"}
                                {...register("title", { required: true })}
                            />
                            {/*
                                )}
                            />
                            */}
                            {showError(errors.name)}

                            <FormControl fullWidth>
                                <InputLabel id="quiz-type-label">Type</InputLabel>
                                <Select
                                    id="quiz-type"
                                    labelId="quiz-type-label"
                                    label="Type"
                                    {...register("quiz_type", { required: true })}
                                    defaultValue={QUIZ_TYPE_STANDARD}
                                    // value={String(question?.level)}
                                    // onChange={handleChangeLevel}
                                >
                                    {QUIZ_TYPE_CHOICES.map((item) => (<MenuItem key={item[0]} value={String(item[0])}>{item[1]}</MenuItem>))}
                                </Select>
                            </FormControl>
                            {/*
                            <Controller
                                name={"quiz_type"}
                                required={true}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControl fullWidth>
                                        <InputLabel id="quiz-type-label">Level</InputLabel>
                                        <Select
                                            id="quiz-type"
                                            labelId="quiz-type-label"
                                            label="Type"
                                            // value={String(question?.level)}
                                            // onChange={handleChangeLevel}
                                        >
                                            {QUIZ_TYPE_CHOICES.map((item) => (<MenuItem value={String(item[0])}>{item[1]}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                )}
                            />
*/}
                            {showError(errors.quiz_type)}

                            <Controller
                                name={"description"}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        variant='outlined'
                                        label='Description'
                                        multiline
                                        minRows={2}
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                            {showError(errors.description)}

                            <Controller
                                name={"notes"}
                                control={control}
                                required={false}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        label='Notes'
                                        variant='outlined'
                                        multiline
                                        minRows={2}
                                        fullWidth
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />

                        </Stack>

                        {/*<input defaultValue="Name" {...register("name")} />*/}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type="button" color="primary" variant="outlined" size="small" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" variant="contained" size="small" onClick={() => trigger()}>
                        Create
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default NewQuizDialog;