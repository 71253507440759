import './App.css';

import React from "react";

import Player from "./demo/Player";
import UserProvider, {useUser} from "./context/UserContext";

import {
    createBrowserRouter,
    createRoutesFromElements, Navigate,
    RouterProvider,
} from "react-router-dom";

import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";

import Login from "./Login/Login";
import Layout from "./Layout/Layout";
import AdminLayout from "./Layout/AdminLayout";
import ProjectsContainer from "./Projects/ProjectsContainer";

import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {darkTheme} from "./Theme";
import {DevToolProvider} from "./context/DevTool";
import ProjectEditorContainer from "./VideoEditor/ProjectEditorContainer";
import AuthProvider, {useAuth} from "./context/AuthProvider";
import HomeContainer from "./Studio/HomeContainer";
import QuizzesContainer from "./Studio/Quizzes/QuizzesContainer";
import QuizEditorContainer from "./Studio/Quizzes/QuizEditor/QuizEditorContainer";
import QuestionEditorContainer from "./Studio/Quizzes/QuizEditor/QuestionEditor/QuestionEditorContainer";
import VideosContainer from "./Studio/Videos/VideosContainer";
import VideoEditor from "./Studio/Videos/VideoEditor";
import VideosList from "./Studio/Videos/VideosList";
import ImagesContainer from "./Studio/Images/ImagesContainer";
import QuizEditorProvider from "./Studio/Quizzes/QuizEditor/QuizEditorProvider";
import StudioContainer from "./Studio/StudioContainer/StudioContainer";
import ItemEditorContainer from "./Studio/StudioContainer/ItemEditorContainer";
import ItemListContainer from "./Studio/StudioContainer/ItemListContainer";
import {PlaylistEditorProvider, PlaylistEditorContainer, PlaylistContainer} from './Studio/Playlists/index'
import StudioWorkspace from "./Studio/StudioContainer/StudioWorkspace/StudioWorkspace";
import apiClient from "./context/APIClient";
import {SWRConfig} from "swr";
import StudioContext from "./Studio/Context/StudioContext";
import GenerateImage from "./Studio/Images/GenerateImage";
import MathLive from "./Studio/Quizzes/QuizEditor/QuestionEditor/QuestionTextEditor/vendors/MathLive/MathLive";
import EditorTest from "./editor/EditorTest";
import DynamoContainer from "./dynamo/DynamoContainer";
import ChatGPT from "./openai/ChatGPT";
import ManagementContainer from "./Manage/ManagementContainer";
import LessonsList from './Studio/Lessons/LessonsList';
import {LessonEditorAppContainer} from './Studio/Lessons/LessonEditor';
import WorksheetView from "./Studio/Quizzes/Worksheet/Worksheet";
import AIWorkspace from "./openai/AIWorkspace";
import {SessionProvider} from "./context/Sessions";
import AccountCreationContainer from "./Admin/AccountCreation/AccountCreationContainer";
import HomeDashboard from "./Home/HomeDashboard";
import {AdminHomeContainer} from "./Admin/AdminHomeContainer";
import AccountsContainer from "./Admin/Accounts/AccountsContainer";
import ShareQuizContainer from "./Studio/Quizzes/QuizEditor/Share/ShareQuizContainer";
import UnauthenticatedApp from "./Unauthenticated/UnauthenticatedApp";
import LogRocketService from "./util/LogRocketService";
import LibraryEditorContainer from "./Studio/Library/LibraryEditorContainer";
import "react-complex-tree/lib/style-modern.css";
import UploadList from "./Studio/Uploads/UploadList";
import LibraryList from "./Studio/Library/LibraryList";
import { useDevTool } from "@muzology/muzologylib";

// import AccountCreation from './Admin/AccountCreation/AccountCreation';
// import AccountTemplates from "./Admin/AccountCreation/AccountTemplates";

// import LessonEditorContainer from "./Studio/Lessons/LessonEditorContainer";
// import SourceVideoDetails from "./Studio/Videos/SourceVideos/SourceVideoDetails";
// import { admin_menu_items, root_menu_items } from "./Admin/admin_menu_items";
// import { studio_menu_items } from "./Studio/studio_menu_items";
// import CardMenu from "./Studio/CardMenu";
// import { Paper, Grid, Typography } from "@mui/material";
// import DashboardCard from "./Components/Dashboard/DashboardCard";
// import IconTextList from "./Components/Dashboard/IconTextList";
// import VideoEditorContainer from "./VideoEditor/VideoEditorContainer";

function NotFound() {
    return (
        <div>
            <h1 className="text-3xl font-bold p-6 ">
                404 - Not Found
            </h1>
        </div>
    );
}

const Logout = (props) => {
    const {logout} = useAuth();
    console.log("<Logout> Logging out...");
    logout();
    return <div>Logging out...</div>;
}

// function CreateQuestionRedirect() {
//     return null;
// }

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/" element={<Layout title={'Muzology'}/>}>
                {/*<Route index path='/' element={<HomeDashboard/>}/>*/}
                <Route index path='/' element={<Navigate to={'/studio'}/>}/>

                <Route path='/lessons'>
                    <Route path=':lessonId' element={<LessonEditorAppContainer/>}/>
                    <Route index element={<LessonsList/>}/>
                </Route>

                <Route path='/videos' element={<VideosContainer/>}>
                    <Route path=':videoId' element={<VideoEditor/>}/>
                    <Route index element={<VideosList/>}/>
                </Route>

                <Route path='/images' element={<ImagesContainer/>}/>

                {/* quizzing */}
                <Route path='/quizzes' element={<QuizzesContainer/>}/>
                <Route path='/quiz/worksheet' element={<WorksheetView/>}/>
                <Route path='/quiz' element={<QuizEditorProvider/>}>
                    <Route path=':quizId' element={<QuizEditorContainer/>}/>
                    {/*<Route path=':quizId/question/create' element={<QuestionEditorContainer createQuestion={true} />} />*/}
                    <Route path=':quizId/question/:questionId' element={<QuestionEditorContainer/>}/>
                </Route>

                <Route path='/playlists' element={<PlaylistContainer/>}/>
                <Route path='/playlist' element={<PlaylistEditorProvider/>}>
                    <Route path=':topicId' element={<PlaylistEditorContainer/>}/>
                </Route>

                <Route path='/projects' element={<ProjectsContainer/>}/>
                <Route path="/project/:projectId" element={<ProjectEditorContainer/>}/>


                <Route path="/player" element={<Player/>}/>
                {/* Experimental stuff */}
                <Route path='/manage/' element={<ManagementContainer/>}/>

                <Route path="/gpt/" element={<ChatGPT/>}/>
                <Route path="/ai" element={<AIWorkspace/>}/>
                <Route path="/dynamo/" element={<DynamoContainer/>}/>
                <Route path="/math-live/" element={<MathLive/>}/>
                <Route path="/editor/" element={<EditorTest/>}/>
                <Route path="/image/" element={<GenerateImage/>}/>

                <Route path="/login" element={<Navigate to={'/'}/>}/>
                <Route path="/logout" element={<Logout/>}/>

                {/*<Route path="*" element={<NotFound />} />*/}
            </Route>

            <Route path='/ops' element={<Layout title={'Operations'}/>}>
                <Route index path='' element={<HomeContainer menu_items={null} title={'Operations'}/>}/>
                {/*
                    <Route path='servers' element={<ServerList />} />
                    <Route path='status' element={<StatusPage />} />
                    */}
            </Route>

            <Route path='/admin' element={<AdminLayout/>}>

                <Route index path='' element={<AdminHomeContainer/>}/>

                <Route path='console' element={<ManagementContainer/>}/>

                <Route path="create-account" element={<AccountCreationContainer/>}/>

                <Route path="accounts" element={<AccountsContainer/>}/>


                {/*
                    <Route path="account/:accountId" element={<AccountDetailsComponent />} />
                    <Route path="accounts" element={<AccountsContainer />} />
                    <Route path="accounts/add" element={<AddAccountContainer />} />
                    <Route path="user/:userId" element={<UserProfileContainer />} />
*/}
                {/*
                        <Route index path="" element={<AccountTemplates />} />
                        <Route path="new" element={<AccountCreation />} />
                        <Route path="create" element={<AccountCreation />} />
                        <Route path="templates" element={<AccountTemplates />} />
                    */}

            </Route>

            <Route path="/studio" element={<Layout/>}>

                <Route path='' element={<StudioContainer/>}>

                    <Route path='' element={<StudioWorkspace/>}>

                        {/*<Route index element={<StudioWorkspace />} />*/}
                        <Route path='library'>
                            <Route index element={<LibraryList/>}/>
                            <Route path=':libraryId' element={<LibraryEditorContainer/>}/>
                        </Route>

                        <Route path='uploads'>
                            <Route index element={<UploadList/>}/>
                            <Route path=':uploadId' element={<UploadList/>}/>
                        </Route>

                        <Route path='quizzes' element={<QuizzesContainer/>} />

                        {/*<Route path='quizzes' element={<QuizzesContainer />} />*/}
                        <Route path='quiz' element={<QuizEditorProvider/>}>
                            <Route path=':quizId' element={<QuizEditorContainer/>}/>
                            {/*<Route path=':quizId/question/create' element={<CreateQuestionRedirect  />} />*/}
                            <Route path=':quizId/question/:questionId' element={<QuestionEditorContainer/>}/>
                        </Route>

                        <Route path='videos' element={<VideosContainer/>}>
                            <Route path=':videoId' element={<VideoEditor/>}/>
                            <Route index element={<VideosList/>}/>
                        </Route>

                        <Route path='lessons'>
                            <Route path=':lessonId' element={<LessonEditorAppContainer/>}/>
                            <Route index element={<LessonsList/>}/>
                        </Route>

                        <Route path='images' element={<ImagesContainer/>}/>

                        <Route path=':itemType' element={<ItemListContainer/>}/>
                        <Route path=':itemType/:itemId' element={<ItemEditorContainer/>}/>

                        {/*<Route index element={<StudioWorkspace />} />*/}
                        <Route index element={<HomeContainer
                            title={'Studio'}
                            rootTitle={'Home'}
                            rootPath={'/'}
                            // initialBreadcrumbs={[
                            //     { href: '/', title: 'Home' },
                            // ]}
                        />}
                        />


                        {/*<Route path='/workspace/:itemType' element={<StudioWorkspace />} />*/}
                    </Route>
                </Route>
            </Route>

            <Route path='/print/worksheet/:quizId' element={<WorksheetView/>}/>
            <Route path="/share/:quiz_id" element={<ShareQuizContainer/>}/>
        </>
    )
);

const AuthenticatedApp = (props) => {
    return (
        <LogRocketService>
            <StudioContext>
                <RouterProvider router={router}/>

                {/*
            <Layout>
                <Routes>

                    <Route index path="/" element={<HomeContainer menu_items={studio_menu_items}/>} />
                    <Route path="/studio" element={<StudioContainer />}>
                        <Route path='' element={<StudioWorkspace />}>
                            <Route path='quizzes' element={<QuizzesContainer />} />
                            <Route path='quiz' element={<QuizEditorProvider />}>
                                <Route path=':quizId' element={<QuizEditorContainer />} />
                                <Route path=':quizId/question/create' element={<QuestionEditorContainer createQuestion={true} />} />
                                <Route path=':quizId/question/:questionId' element={<QuestionEditorContainer />} />
                            </Route>
                            <Route path='videos' element={<VideosContainer />}>
                                <Route path=':videoId' element={<VideoEditor />} />
                                <Route index element={<VideosList />} />
                            </Route>
                            <Route path='lessons'>
                                <Route path=':lessonId' element={<LessonEditorAppContainer />} />
                                <Route index element={<LessonsList />} />
                            </Route>
                            <Route path='images' element={<ImagesContainer />} />
                            <Route path=':itemType' element={<ItemListContainer />} />
                            <Route path=':itemType/:itemId' element={<ItemEditorContainer />} />
                            <Route index element={<HomeContainer />} />
                            <Route path='/workspace/:itemType' element={<StudioWorkspace />} />
                        </Route>
                    </Route>
                </Routes>
            </Layout>
            */}
            </StudioContext>
        </LogRocketService>
    );
};


const RootApp = (props) => {
    const {isLoggedIn} = useAuth();
    return isLoggedIn ? <AuthenticatedApp/> : <UnauthenticatedApp/>;
};

function App(props) {
    const swrConfig = {
        revalidateOnFocus: false,
        fetcher: apiClient
    }
    useDevTool('App', props);
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <DevToolProvider>
                <SWRConfig value={swrConfig}>
                    {/*<BrowserRouter>*/}
                    <SessionProvider>
                        <AuthProvider>
                            <UserProvider>
                                <RootApp/>
                            </UserProvider>
                        </AuthProvider>
                    </SessionProvider>
                    {/*</BrowserRouter>*/}
                </SWRConfig>
            </DevToolProvider>
        </ThemeProvider>
    );
    /*
        return <Player />;
        return (
            <div className="App">
                <h1 className="text-3xl font-bold underline">
                    Hello world!
                </h1>
            </div>
        );
    */
}

export default App;
