import React from 'react';
import tw, { styled } from "twin.macro";
import { EditorState, Editor as DraftEditor } from "draft-js";
import { customStyleFn } from "./customStyles";
import "draft-js/dist/Draft.css";
import Toolbar from "./toolbar";
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { mathBlockRenderer } from "./math";

//Root Wrapper of the Editor
const EditorWrapper = styled.div`
    //min-width: 700px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    //margin-top: 3em;
    //background: red;

    .DraftEditor-root {
        flex: 1 1 auto;
        //background: orange;
    }
`;

//DraftEditor Container
const EditorContainer = styled.div`
    display: flex;
    //min-height: 9em;
    border-radius: 0 0 3px 3px;
    //background-color: #ff0;
    padding: 5px;
    font-size: 17px;
    font-weight: 400;
    //box-shadow: 0 0 3px 1px rgba(15, 15, 15, 0.17);
    border: 1px solid #e1e1e1;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
`;

const QuestionTextEditor = ({ value, setValue }) => {
    // const { editorState } = this.state;
    // Debug your Editor and notice that the EditorState gets update on every
    // character you type
    // console.log("EditorSTATE: ", this.state.editorState);
    //Render the Draftjs Editor Component
    /*
    The Editor Takes the current editorState and provides
    you with onChange callback to update the current EditorState being stored on your state.
    */
    const [editorState, setEditorState] = React.useState(EditorState.createWithContent(stateFromHTML(value ?? '')));
    // const [editorState, setEditorState] = React.useState(EditorState.createEmpty());

    const onBlur = () => {
        // copy the editor state back to the parent component
        if (setValue)
            setValue(stateToHTML(editorState.getCurrentContent()));
    }

    return (
        <EditorWrapper>
            <EditorContainer>

                <Toolbar
                    editorState={editorState}
                    updateEditorState={setEditorState}
                />

                <DraftEditor
                    onBlur={onBlur}
                    // placeholder="Explore Your Way In..."
                    editorState={editorState}
                    onChange={setEditorState}
                    customStyleFn={customStyleFn}
                    blockRendererFn={(contentBlock) => {
                        console.log('block render function');
                        mathBlockRenderer(contentBlock, editorState.getCurrentContent())
                    }}
                />

            </EditorContainer>
        </EditorWrapper>

    );
};

export default QuestionTextEditor;