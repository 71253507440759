import React, { useMemo } from 'react';
import CourseTree from "./CourseTree";
import { useItemEditor } from "./StudioContainer";
import SplitPane, { Pane } from 'react-split-pane';
import './styles.css';
import CourseEditorContainer from "../Courses/CourseEditorContainer";
import LessonGroupEditor from "../Lessons/LessonGroupEditor";
import LessonEditor from "../Lessons/LessonEditor";
import VideoEditor from "../Videos/VideoEditor";

const ItemEditorContainer = (props) => {
    const { item } = useItemEditor();
    // const [selectedItemID, setSelectedItemID] = React.useState(null);
    const [selectedItem, setSelectedItem] = React.useState(null);
    // console.log("SELECTION: ", selectedItem);
    // const selectedItem = useMemo(() => {
    //     // crawl the tree to find the selected item
    //     if (!selectedItemID)
    //         return null;
    //     if (!item)
    //         return null;
    //
    //     const findItem = (item) => {
    //         if (item.id === selectedItemID) {
    //             // console.log('found item', item);
    //             return item;
    //         }
    //
    //         // console.log('searching item', item);
    //         if( item.children ) {
    //             for (let child of item.children) {
    //                 const found = findItem(child);
    //                 if (found) {
    //                     // console.log('found', found);
    //                     return found;
    //                 }
    //             }
    //         }
    //         return null;
    //     }
    //     return findItem(item);
    // }, [item, selectedItemID]);
    // console.log('selected item:', selectedItem);
    const editorView = useMemo(() => {
        switch (selectedItem?.itemType) {
            case 'Course':
                return (
                    <CourseEditorContainer
                        course={selectedItem.item}
                        treeItem={selectedItem}
                    />
                );
            case 'LessonGroup':
                return (
                    <LessonGroupEditor
                        lessonGroup={selectedItem.item}
                        updateLessonGroup={(update) => {
                            console.log('updateLessonGroup', update);
                            setSelectedItem((item) => {
                                return {
                                    ...item,
                                    ...update
                                }
                            });
                            // setIsDirty(true);
                        }}
                        treeItem={selectedItem}
                    />
                );
            case 'Lesson':
                return <LessonEditor
                    key={selectedItem.item.id}
                    lesson={selectedItem.item}
                    lessonGroup={selectedItem.lessonGroup}
                    treeItem={selectedItem}
                />
            case 'Video':
                return <VideoEditor video={selectedItem} videoId={selectedItem?.id} />
            case 'Quiz':
                return <div>Activity Editor</div>
            default:
                return <div>{selectedItem?.itemType}</div>
        }
    }, [selectedItem]);

    return (
        <SplitPane
            split="vertical"
            minSize={300}
            defaultSize={400}
            className={'h-full'}
            allowResize={true}
        >
            <Pane className={'overflow-y-auto overflow-x-hidden h-full bg-white text-black '}>
                <CourseTree
                    course={item}
                    className={'md:min-w-[500px] h-full'}
                    onSelected={setSelectedItem}
                />
            </Pane>

            <Pane className={'h-full'}>
                <div className={'bg-red-100- bg-gray-100 h-full'}>
                    {editorView}
                    {/*<div>{selectedItem?.itemType} : {selectedItem?.id}</div>*/}
                </div>
            </Pane>
        </SplitPane>
    )

};


export default ItemEditorContainer;
