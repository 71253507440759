import React from 'react';
import useSWR from "swr";
import { useDevTool } from "../../../context/DevTool";
import { Button, Grid, Stack } from "@mui/material";
import ReactPlayer from "react-player";
import BitmovinPlayer from "../../../Components/Bitmovin";
import { createSourceConfig, createVideoPoster } from "../../../Components/Bitmovin/BitmovinPlayer";
import { addStatusRow } from "../../Quizzes/QuizEditor/HealthCheck";
import ConfirmDialog from "../../Quizzes/QuizEditor/ConfirmDialog";
import apiClient from "../../../context/APIClient";
import { addRow } from "../panels/InfoTable";

// export const createSourceConfig = (video) => {
//
//     // create a source config
//     return {
//         // title: video.title,
//         // description: video.description,
//
//         dash: video.url_dash,
//         hls: video.url_hls,
//         progressive: video.url_mp4,
//         // smooth: 'https://test.playready.microsoft.com/smoothstreaming/SSWSS720H264/SuperSpeedway_720.ism/manifest',
//         // poster: video.url_thumbnail
//         poster: createVideoPoster(video.url_thumbnail)
//     };
// };

const SourceVideoDetails = ({ video, sourceVideo, uploadId, setUploadId  }) => {
    const { data } = useSWR(`/api/video_upload/?upload_id=${uploadId}`);
    useDevTool('SourceVideoDetails', { sourceVideo, data });
    const source = data?.data;

    // React.useEffect(() => {
    //     if (!videoId) return;
    //     console.log('useEffect', videoId, videos)
    //     const video = videos?.find(video => video.id === videoId);
    //     console.log('video', video);
    //     setVideo(video);
    // }, [videoId, videos]);

    const fakeVideo = {
        id: sourceVideo?.id,
        url_dash: sourceVideo?.mpd_manifest_url,
        url_hls: sourceVideo?.hsl_manifest_url,
        // progressive: video.url_mp4,
    }

    const [confirmDelete, setConfirmDelete] = React.useState(false);

    const handleDelete = () => {
        // delete this source
        apiClient.delete(`/api/uploads/${sourceVideo.id}/`)
            .then(response => {
                if( setUploadId )
                    setUploadId(null);
            })
            .catch(error => {
                console.log(error);
                alert("There was an error deleting the video source.")
            });
    };

    return (
        <Grid container>
            <Grid item xs={12} lg={8}>
                <Stack>
                    <Stack spacing={2}>
                        <div className={'border-red-100 min-h-[200px]'}>

                            {sourceVideo && <BitmovinPlayer
                                // source={createSourceConfig({
                                //     url_dash:sourceVideo?.mpd_manifest_url,
                                //     url_hls: sourceVideo?.hsl_manifest_url,
                                //     // progressive: video.url_mp4,
                                // })}
                                // aria-label={`video player for ${video.title}`}
                                video={fakeVideo}
                                // user={user}
                                // assignment={assignment}
                                // key={sessionId}
                                // className='sticky-video'
                                autoplay={false}
                                controls={true}
                                // sources={sources}
                                // poster={createVideoPoster(video?.url_thumbnail ?? '')}
                                // metaData={metaData}
                                // onReady={onVideoStart}
                                // onPlay={onVideoPlay}
                                // onEnd={onVideoEnd}
                                // postVideoSessionRanges={postVideoSessionRanges}
                            />}
                        </div>
                        <ConfirmDialog
                            open={confirmDelete}
                            setOpen={setConfirmDelete}
                            onConfirm={handleDelete}
                            title={'Delete Video Source Revision'}
                            message={'Are you sure you want to delete this video revision?'}
                        />

                        <table className="table-auto w-full">
                            <tbody>
                            {addRow('Name', sourceVideo?.name)}
                            {addRow('Video', video?.title)}
                            {addRow('Revision', sourceVideo?.revision)}
                            {addRow('Status', sourceVideo?.status)}
                            {addRow('Created', sourceVideo?.created)}
                            {addRow('Size', sourceVideo?.file_size)}
                            {addRow('Hash', sourceVideo?.file_hash)}
                            {addRow('Upload Success', sourceVideo?.upload_success)}
                            {addRow('Upload ID', sourceVideo?.upload_id)}
                            {addRow('Upload Source', sourceVideo?.upload_source)}
                            {addRow('Upload Start', sourceVideo?.upload_start)}
                            {addRow('Upload End', sourceVideo?.upload_end)}

                            {addRow('Video Duration', sourceVideo?.video_duration)}
                            {addRow('Video Width', sourceVideo?.video_width)}
                            {addRow('Video Height', sourceVideo?.video_height)}

                            {addRow('Video Bitrate', sourceVideo?.video_bitrate)}
                            {addRow('Video Framerate', sourceVideo?.video_framerate)}
                            {addRow('Video Codec', sourceVideo?.video_codec)}

                            {addRow('HLS Manifest', sourceVideo?.hsl_manifest_url)}
                            {addRow('MPD Manifest', sourceVideo?.mpd_manifest_url)}

                            <tr>
                                <td colSpan={2} className="px-4 py-2">
                                    <Button onClick={() => setConfirmDelete(true)} variant='outlined' size='small' color='success'>
                                        Make Current Revision
                                    </Button>
                                </td>
                            </tr>

                            <tr>
                                <td colSpan={2} className="px-4 py-2">
                                    <Button onClick={() => setConfirmDelete(true)} variant='outlined' size='small' color='error'>
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        {/*<pre>{JSON.stringify(data?.data, null, 4)}</pre>*/}
                        {/*<pre>{JSON.stringify(video, null, 4)}</pre>*/}
                    </Stack>


                    {/*
                <ReactPlayer
                    url={source?.public_url}
                    width="640"
                    height="360"
                    controls
                />
                */}

                </Stack>

            </Grid>

            {/*
            <Grid item xs={12} lg={4}>
            </Grid>
            */}
        </Grid>
    );
};


export default SourceVideoDetails;
