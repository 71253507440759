import React, { useState } from "react";
import "twin.macro";
import {
    Button,
    TextField,
    Grid,
    Paper,
    Typography,
    AppBar,
    Toolbar,
    Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import Loading from "../Components/Loading/Loading";

const Login = (props) => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState(null);
    // const { login } = useUser();
    const { login } = useAuth()
    const [loading, setLoading] = useState(false);
    // const { register, handleSubmit, errors } = useForm();

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('submitting');
        setLoading(true);
        login(username, password)
            .then((success) => {
                setLoading(false);
                if (success) {
                    console.log('Login successful')
                } else {
                    setErrors("This username or password is incorrect.");
                    console.log('Login failed')
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log('Login failed', error)
            });
    };

    return (
        <div className={'w-screen h-screen pt-20'}>
            <Loading loading={loading} />
            <div className={'w-full h-full '}>
                <Grid container spacing={0} justify="center" direction="row">
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        className="login-form"
                    >
                        <Paper
                            variant="elevation"
                            elevation={2}
                            className="login-background"
                            // justifyContent="center"
                            // alignItems="center"
                            sx={{
                                marginTop: '20px',
                                padding: "20px",
                            }}
                        >

                            <Grid item className={'w-[300px]'}>
                                <Typography component="h1" variant="h5">
                                    Sign in
                                    <br />
                                    <br />
                                </Typography>
                            </Grid>

                            <Grid item>
                                <form onSubmit={handleSubmit}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <TextField
                                                type="username"
                                                placeholder="Username or Email"
                                                fullWidth
                                                name="username"
                                                variant="outlined"
                                                value={username}
                                                onChange={(event) =>
                                                    setUsername(event.target.value)
                                                    // this.setState({
                                                    //     [event.target.name]: event.target.value,
                                                    // })
                                                }
                                                required
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                type="password"
                                                placeholder="Password"
                                                fullWidth
                                                name="password"
                                                variant="outlined"
                                                value={password}
                                                onChange={(event) => setPassword(event.target.value)
                                                    // this.setState({
                                                    //     [event.target.name]: event.target.value,
                                                    // })
                                                }
                                                required
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                className="button-block"
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                            {/*
                            <Grid item>
                                <Link href="#" variant="body2">
                                    Forgot Password?
                                </Link>
                            </Grid>
                            */}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Login;

