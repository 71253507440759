import React from 'react';
import { Grid, Card, CardHeader, CardContent, Typography, Container, Box, CardActionArea } from "@mui/material";

const templates = [
    {
        id: 'school',
        name: "School",
        description: "A school with multiple teachers and students"
    },
    {
        id: 'teacher',
        name: "Teacher",
        description: "A teacher with multiple students"
    },
    {
        id: 'parent',
        name: "Parent",
        description: "A new parent account"
    },
    // {
    //     id: 'parent-subscription',
    //     name: "Parent Subscription",
    //     description: "A parent account with a subscription"
    // },
    // {
    //     id: 'teacher-trial',
    //     name: "Teacher Trial",
    //     description: "A teacher account with a free trial"
    // },
    {
        id: 'parent-demo',
        name: "Parent Demo",
        description: "A parent account with demo data"
    },
    {
        id: 'teacher-demo',
        name: "Teacher Demo",
        description: "A teacher account with demo data"
    },
    {
        id: 'test-roster',
        name: "Test Roster",
        description: "A roster of test students"
    },

];

const AccountTemplates = ({ onSelect }) => {
    return (
        <Container maxWidth="md" className='mt-0' spacing={2}>

            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <Typography variant="h4" marginTop={0} marginBottom={0}>
                        Account Types
                    </Typography>
                </Grid>

                <Grid item xs={12} container spacing={3}>
                    {templates.map((template) => (
                        <Grid key={template.id} item xs={12} sm={6} md={4}>
                            <Card>
                                {/*<CardActionArea onClick={() => history.push(`/studio/account-creation/${template.id}`)}>*/}
                                <CardActionArea onClick={() => onSelect(template)}>
                                <CardHeader variant={'h6'} title={template.name} />
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                        {template.description}
                                    </Typography>
                                </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

            </Grid>

        </Container>
    );
};


export default AccountTemplates;
