import React from 'react';
import InputText from "../Quizzes/QuizEditor/FormControls";
import { useGetAPI } from "../../context/api";
import { Dialog, DialogContent, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Add, Clear, Delete, Edit, Visibility, VisibilityOff } from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useNavigate } from "react-router-dom";
import { useStudio } from "../Context/StudioContext";
import apiClient from '../../context/APIClient';

const linkComponent = ({ className, ...props }) => {
    // console.log('linkComponent', props);
    return (
        <div className={`select-none ${className} underline underline-offset-4 cursor-pointer`} {...props} >
            {props.value}
        </div>
    )
}

const LessonListDialog = ({ open, setOpen, lessons, lessonId, onSelectVideo, quizzes }) => {

    const handleSelectQuiz = (event, id) => {
        if (onSelectVideo)
            onSelectVideo(id);
    };
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="lessons-dialog"
        >
            <DialogTitle id="lessons-dialog">Quizzes</DialogTitle>
            <DialogContent>
                <List>
                    {quizzes?.map((quiz) => (
                        <ListItem disablePadding key={quiz.id}>
                            <ListItemButton
                                // selected={lessonId === video.id}
                                onClick={(event) => handleSelectQuiz(event, quiz.id)}
                            >
                                <ListItemText primary={quiz.title} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
};


const QuizInputField = ({ quizId, updateQuiz, filterUsedQuizzes = true }) => {
    // console.log('quiz id', quizId);
    const navigate = useNavigate();
    const { data } = useGetAPI('/api/quizzes/');
    const { lessons } = useStudio();
    const [showQuizDialog, setShowQuizDialog] = React.useState(false);
    // console.log('quiz data', data);

    if( !data  ) return null;

    // filter out any quizzes that already belong to a lesson
    let quizzes = data ?? [];

    // find the quiz
    const quiz = quizzes?.find(q => q.id === quizId);
    // console.log('quiz', quiz);

    // filter out used quizzes
    if( filterUsedQuizzes ) {
        quizzes = quizzes?.filter(quiz => !lessons.some(lesson => quiz.id === lesson.quiz_id));
    }

    // console.log('quizzes', quizzes);

    const onClickEdit = (quiz) => {
        // navigate to the quiz
        if (quizId)
            navigate(`/quiz/${quizId}`);
    };

    const onClickRemove = () => {
        updateQuiz({ quiz: null });
    };

    const endAdornment = (
        <InputAdornment position="end">
            <IconButton
                disabled={!quizId}
                aria-label="editlesson"
                onClick={onClickEdit}
                // onMouseDown={handleMouseDownPassword}
                // edge="end"
            >
                <Edit />
            </IconButton>

            <IconButton
                aria-label="add lesson"
                onClick={() => setShowQuizDialog(true)}
                // onMouseDown={handleMouseDownPassword}
                edge="end"
            >
                <Add />
            </IconButton>

            <IconButton
                aria-label="remove lesson"
                onClick={onClickRemove}
                // onMouseDown={handleMouseDownPassword}
                edge="end"
            >
                <Clear />
            </IconButton>
        </InputAdornment>
    );


    return (
        <>
            <InputText
                id='quiz'
                name='quiz'
                label="Quiz"
                value={quiz?.title ?? quizId ?? ''}
                // value={lessonId}
                readOnly={true}
                // InputLabelProps={{ shrink: true }}
                endAdornment={endAdornment}
                // startAdornment={ <InputAdornment position="start">{lesson?.id}</InputAdornment>}

                InputProps={{
                    startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    endAdornment: endAdornment,
                    // inputComponent: linkComponent,
                }}

                inputComponent={linkComponent}
                inputProps={{
                    onClick: () => {
                        setShowQuizDialog(true);
                    },

                    // inputComponent: linkComponent,
                    // startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    endAdornment: endAdornment,
                    url: `/studio/quizzes/${quizId}/`
                }}
            />
            <LessonListDialog
                open={showQuizDialog}
                setOpen={setShowQuizDialog}
                // lessons={lessons}
                quizzes={quizzes}
                lessonId={quizId}
                onSelectVideo={(id) => {
                    console.log('selected quiz', id);
                    updateQuiz(id);
                    setShowQuizDialog(false);
                }}
            />
        </>
    );
};


export default QuizInputField;
