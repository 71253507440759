import React, { useEffect } from 'react';
import { useAuth } from "../context/AuthProvider";
import { useUser } from "../context/UserContext";
// const Context = React.createContext();
const LogRocketService = ({ children }) => {
    // only
    const { isLoggedIn } = useAuth();
    const { user, account, tabId } = useUser();
    // const account = useAccount();

    // setup logrocket
    useEffect(() => {
        console.log("<LogRocketService> Initializing", user);
        if (!user) {
            return;
        }
        const logRocket = window.LogRocket;
        if (!logRocket) {
            console.warn('LogRocket is not installed');
            return;
        }

        // identify the user with logrocket
        try {
            console.log('[LogRocket] identify user', user);

            const ident = {
                name: user.full_name || user.username,
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
                username: user.username,
                user_id: user.user_id,
                user_role: user.role === 1 ? 'student' : 'teacher',
                user_persona: user.account_type === 1 ? (user.role === 1 ? 'student' : 'teacher') : (user.role === 1 ? 'child' : 'parent'),
                account_type: user.account_type_name,
                account_name: account.name,
                account_id: account.account_id,
                account_guid: account.variant,
                is_staff: user.is_staff,
                is_superuser: user.is_superuser,
                is_admin: user.is_admin,
                is_teacher: user.is_teacher,
                is_parent: user.is_parent,
                is_student: user.is_student,
                is_child: user.is_child,
                tab_id: tabId,
            }
            logRocket.identify(user.id, ident);
        } catch (e) {
            console.error(e);
        }

        // cleanup
        return () => {
            console.log('logrocket cleanup');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // when the login state changes, update log rocket
        console.log('[LogRocket] user/login changed: ', isLoggedIn, user);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, user]);


    return children;
};
//
// export function setup_logrocket(user) {
//     const logRocket = window.LogRocket;
//     if (!logRocket || !user) {
//         return false;
//     }
//
//     //const isStaging = process.env.NODE_ENV !== 'production';
//     // LogRocket.init(api_key, {
//     //     shouldShowReportingButton: is_staging ? 'MdZ7Y8zk0f' : false
//     // });
//
//     logRocket.identify(user.id, {
//         name: user.full_username || user.username,
//         email: user.email,
//         first_name: user.first_name,
//         last_name: user.last_name
//     });
//
//     // const getLogRocketSessionURL = () => {
//     //     return window.LogRocket.sessionURL;
//     // };
//     //
//     // const getLogRocketSessionURLCallback = function (sessionURLCallback) {
//     //     console.log('calling session url function');
//     //     LogRocket.getSessionURL((sessionURL) => {
//     //         console.log('GOT SESSION URL', sessionURL);
//     //         //!! sessionURLCallback(sessionURL, initialStore);
//     //     });
//     // };
//     //
//     // // set the session url
//     // LogRocket.getSessionURL(function (sessionURL) {
//     //     window.logRocketSessionURL = sessionURL;
//     // });
//
//     // console.log('[Heap Analytics] identify user', user);
//     // heap.identify(user.user_id);
//     // heap.addUserProperties({
//     //     user_id: user.user_id,
//     //     account_id: user.account,
//     //     auth_method: user.auth_method_name,
//     //     role: user.role,
//     //     user_role: user.role === 1 ? 'student' : 'teacher',
//     //     is_student: user.role === 1,
//     //     is_teacher: user.role === 2,
//     //     is_staff: user.is_staff
//     // });
// }

export default LogRocketService;
